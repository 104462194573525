.toolsandcalculatortxt {
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
    color: #042b62;
}

.toolBoxSection {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr; 
    grid-template-rows: 1fr 1fr 1fr; 
    gap: 0px 0px; 
    grid-template-areas: 
      ". . ."
      ". . ."
      ".";
    grid-column-gap: 2rem;
    grid-row-gap: 3rem;
    place-items: center;
    margin-top: 3rem;
}

.toolBox {
    border: 1px solid #DEDEDE;
    background: #fff;
    padding: 1rem;
    box-shadow: 0px 15px 0px 0px #042b62;
    border-radius: 16px;
}
.toolBox:hover{
    border: 1px solid #042b62;
    cursor: pointer;
}
.toolBox.selected{
    border: 2px solid #042b62;
}
.toolBox .toolstype{
    font-size: 1.3rem;
    font-weight: bold;
    padding: 1rem 0;
}
.toolBox img{
    width: 100%;
    height: 100%;
}
.toolBox .toolsDesc{
    color: #606060;
    font-size: 1rem;
    padding-bottom: 1rem;
}
@media only screen and (max-width: 600px) {
    .toolBoxSection{
        grid-template-columns: 1fr;
    }
    .toolBox .toolstype{
        font-size: 1rem;
        padding: .8rem 0;
    }
}