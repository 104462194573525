
::-webkit-scrollbar {
  width: 3px !important;
  height: 2px !important;
}

::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background-color: #042b62;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
textarea {
  resize: none !important;
}
.Logo {
  grid-area: logo;
  margin-top: 1rem;
  margin-left: 4rem;
  /* height: 70px;
	 */
}
.white-bg {
  background-image: none!important;
  background-color: #fff!important;
}
.main-layout {
  background-image: none !important;
  background-color: #f0f4f3 !important;
}
.main-layout .page-header {
  font-size: 28px;
  font-weight: 550;
  line-height: 1.5;
}
.main-layout .login-illustration {
  background: url("./Assets/Images/main/login-illustration.svg") no-repeat 0;
  background-size: 102%;
  padding: 0;
}
.main-layout .otp-illustration {
  background: url("https://images.minty.co.in/static/userflow/img/otp-illustration.png")
  no-repeat 0;
background-size: 500px 500px;
padding: 0;
}
.main-layout .registration-illustration {
  background: url("https://images.minty.co.in/static/userflow/img/registration-illustration.png")
  no-repeat 0;
background-size: 600px 600px;
padding: 0;
padding: 0;
}
.main-layout .h100 {
  height: 100vh;
}
.main-layout .material {
  border: none;
  padding: 0;
  position: relative;
  width: 100%;
  margin: 20px 0;
  margin-bottom: 10px;
}
.main-layout .material input,
.main-layout .material textarea {
  background: 0 0;
  padding: 5px 0 5px 0;
  display: block;
  resize: none;
  width: 100%;
  color: #383838;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid rgba(0, 82, 99, 0.31);
  appearance: none;
  -webkit-appearance: none;
}
.main-layout .refresh_captcha {
  width: 20%;
  margin-top: 15px;
  float: left;
  cursor: pointer;
}
.main-layout .checkbox-container .custom-checkbox {
  display: block;
  position: absolute;
  opacity: 0;
  left: 4px;
  top: 5px;
  cursor: pointer;
  z-index: 1;
}
.main-layout .checkbox-container .custom-checkbox + label {
  position: relative;
  cursor: pointer;
  padding: 0;
  padding-left: 30px;
  display: block;
  line-height: 20px;
}
.main-layout .checkbox-container .custom-checkbox + label::before {
  content: "";
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 18px;
  height: 18px;
  background: 0 0;
  border: 1px solid #042b62;
  border-radius: 4px;
  position: absolute;
  left: 0;
  top: 2px;
}
.main-layout .default-btn {
  display: inline-block;
  cursor: pointer;
  text-align: center;
  background: #042b62;
  color: #fff;
  border: 1px solid transparent;
  position: relative;
  z-index: 1;
  padding: 10px 50px;
  border-radius: 20px;
  margin: 15px auto;
  transition: 0.2s all ease-out;
}
.main-layout .default-btn:hover {
  background: #042b62;
  color: #fff;
}
.main-layout .blue-link {
  display: inline-block;
  color: #042b62;
  font-weight: 500;
  transition: 0.2s all ease-out;
  text-decoration: none;
  cursor: pointer;
  margin-top: 15px;
}
.main-layout .login-header .back-arrow {
  top: 17px;
}
.main-layout .back-arrow {
  display: block;
  position: absolute;
  cursor: pointer;
  top: 30px;
  left: 30px;
  background-color: rgba(0, 82, 99, 0.14);
  padding: 14px;
  border-radius: 12px;
  z-index: 1;
}
.main-layout .login-header {
  position: absolute;
  top: 0;
  width: 59%;
  z-index: 1;
  padding: 15px;
}
.main-layout .login-header .logo img {
  vertical-align: middle;
  display: block;
  margin: 0 auto;
}
.main-layout .back-arrow img {
  display: block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
}
.main-layout p {
  margin: 0px;
  line-height: 22px;
}
.main-layout .privacy-banner {
  padding: 4.28rem 0 3.57rem;
  background-color: #fff !important;
  margin-top: -0.6rem !important;
}
.main-layout .privacy-policy-section h2 {
  color: #042b62;
  font-size: 36px;
  font-weight: 700;
  margin-top: 1.83rem;
  margin-bottom: 1.83rem;
}
.main-layout .privacy-policy-section p {
  margin-bottom: 20px;
  font-size: 16px;
}
.main-layout .privacy-policy-section ol li{
  font-size: 16px;
}
.main-layout .privacy-policy-section h3 {
  font-weight: 700;
  margin-top: 0.83rem;
  margin-bottom: 0.83rem;
}
.main-layout .columnss {
  width: 25%;
  padding: 8px;
  float: left;
  margin-left: 30px;
}
.main-layout .columns {
  float: left;
  width: 36%;
  padding: 8px;
}
.main-layout .price {
  list-style-type: none;
  border: 1px solid #eee;
  margin: 0;
  padding: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 8px 8px 0 rgb(0 0 0 / 20%);
}
.privacy-banner {
  padding: 90px 0 50px;
}
.privacy-banner img{
  width: 100%;
}
.main-layout .offer_img img {
  width: 85%;
  left: -7%;
  position: absolute;
}
.main-layout .offer_img p {
  font-size: 13px;
  text-align: left !important;
  margin-top: 80px !important;
  padding: 10px;
}
.main-layout .width-sign {
  width: 15px;
}
.main-layout .price li {
  border-bottom: 1px solid #eee;
  /* padding: 10px;
	 */
  text-align: center;
  font-size: 12px;
  /* min-width: 141px;
	 */
  position: relative;
}
.f-bold{
  font-weight: 600;
  font-size: 16px;
}
.main-layout .plan-width {
  width: 60px;
}
.main-layout .price .grey {
  background-color: #eee;
  font-size: 12px;
}
.main-layout .price li {
  height: 70px;
}
.main-layout .place_text_in_center {
  position: absolute;
  top: 50%;
  margin: 0;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 14px;
}
.main-layout .inner-content {
  font-size: 10px !important;
}
.main-layout .price li p {
  text-align: center;
  width: 100%;
}
.main-layout .price li div.place_text_in_center {
  padding: 0px 10px 0px 10px;
  width: 100%;
}
.main-layout .button {
  border-style: solid;
  border-color: #042b62;
  padding: 5px 5px;
  text-align: center;
  text-decoration: none;
  font-size: 12px;
  border-radius: 12px;
  color: #042b62;
  cursor: pointer;
}
.main-layout .button:hover {
  background: #042b62;
  color: white;
}
.main-layout .headtype {
  text-align: left !important;
  /* min-width: 207px !important;
	 */
  position: relative;
  padding-left: 10px;
}
.main-layout .input-code input {
  margin: 0 10px;
  flex: 0 0 15%;
  max-width: 15%;
  text-align: center;
}
.main-layout .input-code {
  display: flex;
  justify-content: center;
}
.main-layout .gst_info p {
  text-align: right;
  margin-right: 1.5rem !important;
  color: #808080;
  font-size: 12px;
}
.main-layout .tax_info p {
  text-align: right;
  margin-right: 4.5rem !important;
  color: #808080;
  font-size: 12px;
}
.main-layout .pad-zero {
  border-bottom: none !important;
  padding: 0px !important;
  font-size: 10px !important;
  line-height: 16px;
  color: #808080;
  margin: 0px;
}
.main-layout .price-tag {
  font-size: 20px;
}

.main-layout .tenures-class {
  color: #808080;
}
.main-layout .nav-tabs > li.active > a,
.main-layout .nav-tabs > li.active > a:focus,
.main-layout .nav-tabs > li.active > a:hover {
  color: #666;
  font-weight: 500;
  border: none !important;
  border-bottom: 2px solid #042b62 !important;
}
.main-layout .nav > li > a {
  position: relative;
  display: block;
  padding: 11px 15px;
  text-decoration: none;
}
.main-layout .nav-tabs {
  border-bottom: 1px solid #ddd;
}
.main-layout a {
  color: #000;
  text-decoration: none;
}
.main-layout .income-tax-column {
  float: left;
  padding: 8px;
  width: 10.9%;
}
.main-layout .tax-price li {
  height: 115px !important;
}
.main-layout .tax-price li:first-child {
  height: 210px !important;
}
.main-layout .left-header {
  width: 20.5%;
}
.main-layout .tax-column {
  width: 10.5%;
}
.main-layout section.video-promo-hero:before {
  content: "";
  background: rgba(0, 0, 0, 0.6);
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
}
.main-layout .color-white {
  color: #fff;
}
.main-layout .outline-btn.white {
  border: 1px solid #fff;
  color: #fff;
}
.main-layout .outline-btn {
  min-width: 150px;
  padding: 10px 15px;
}
.main-layout .outline-btn {
  display: block;
  text-align: center;
  cursor: pointer;
  background: 0 0;
  color: #272727;
  min-width: 120px;
  font-weight: 500;
  border: 1px solid #042b62;
  border-radius: 30px;
  padding: 10px 50px;
  margin: 15px auto;
  transition: 0.2s all ease-out;
}
.main-layout section.video-promo-hero .banner-content .button-container button {
  margin-right: 20px !important;
  background: #fff;
  color: #272727;
  font-weight: 590;
  border: 1px solid transparent;
}
.main-layout
  section.video-promo-hero
  .banner-content
  .button-container
  button:hover {
  background: 0 0;
  border: 1px solid #fff;
  color: #fff;
}
.main-layout .outline-btn.white:hover {
  background: #fff;
  color: #000;
}
.main-layout section.video-promo-hero .video-background-container #bg-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.main-layout section.video-promo-hero .video-background-container {
  position: absolute;
  z-index: -1;
}
.main-layout .video-promo-hero p {
  text-align: justify;
  font-size: 16px;
}
.main-layout section.video-promo-hero ul.financial-services {
  list-style-type: circle;
  line-height: 22px;
  margin: 16px;
  letter-spacing: 0.2px;
  padding: 0;
}
.dg-layout {
  background-image: none !important;
  background-color: #fff !important;
  padding-left: 220px;
  /* ont-family: 'Muli'; */
  font-family: "Muli", sans-serif !important;
  font-size: 14px !important;
  height: 100vh;
}

.dg-layout .DataGridViewform {
  padding: 2rem 0;
  display: flex;
  justify-content: flex-end;
}

.dg-layout .DataGridViewform button {
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  width: max-content;
  outline: 0;
  border: 0;
  width: 150px;
  padding: 8px;
  /* padding: 8px 3rem; */
  background-color: #042b62;
  border-radius: 20px;
}

.dg-layout .DataGridViewform .PageSelect {
  padding: 5px;
  outline: none;
  border: 1px solid #8B909A;
  background: transparent;
  border-radius: 5px
}

.InvestmentPhilosophy {
  height: 350px;
}

.dg-layout .bg-lightgreen {
  background-color: #c8dbe2 !important;
}

.dg-layout .hide {
  display: block;
}

.dg-layout .m-popup-header {
  font-family: "Tangerine";
  font-size: 2.6rem;
  font-weight: normal;
  padding: 0px !important;
}

.dg-layout .total-amt {
  font-size: 1rem;
  font-weight: 600;
}

.dg-layout .linkholdings_btn {
  font-size: 14px;
  padding: 8px 15px;
  display: block;
  /* background: #042b62;
  border: solid 1px #042b62; */
  background: #042b62;
  border: solid 1px #042b62;
  color: #fff;
  border-radius: 20px;
  width: fit-content;
  width: -webkit-fit-content;
  margin: 30px auto;
  display: block;
}

.dg-layout .white-box .pageHolder p {
  font-size: 14px;
  font-weight: 400;
}

.dg-layout .risk-radio .radio {
  width: fit-content;
}

.dg-layout .slidesmooth {
  /* transform: translate(100px, 0); */
  transition: all 0.5s ease-in-out;
}

.dg-layout a {
  text-decoration: none;
}

.dg-layout ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dg-layout .sidebar {
  height: 100vh;
  overflow: hidden;
  width: 245px;
  max-width: 245px;
  flex: 0 0 220px;
  position: fixed;
  top: 0rem;
  left: 0;
  z-index: 10;
  background: #fff;
}

.dg-layout .reports .right-navigation li a img {
  width: 20px;
  vertical-align: middle;
  margin: 0 10px;
}

.dg-layout .right-navigation {
  list-style-type: none;
  padding: 0;
  margin: 0;
  /* overflow-y: scroll !important;
  height: 350px !important; */
  scrollbar-width: thin;
}

.dg-layout .right-navigation::-webkit-scrollbar {
  width: 2px;
}

.dg-layout .right-navigation li a .tick:before,
.dg-layout .right-navigation li a .tick:after,
.dg-layout .right-navigation li a .incomelete {
  cursor: default;
}

.dg-layout .right-navigation li a .tick:before {
  content: " ";
  display: block;
  border: solid 7px #042b62;
  border-radius: 50%;
  height: 0;
  width: 0;
  position: absolute;
  right: 20px;
  top: 51%;
  margin-top: -8px;
}

.dg-layout .right-navigation li a .tick:after {
  content: " ";
  display: block;
  width: 4px;
  height: 8px;
  border: solid #fff;
  border-width: 0 0.01em 0.01em 0;
  position: absolute;
  right: 25px;
  top: 44%;
  margin-top: -3px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.dg-layout .right-navigation li a .incomplete {
  display: block;
  border-radius: 50%;
  height: 14px;
  width: 14px;
  color: #fff;
  background: red;
  position: absolute;
  right: 20px;
  top: 51%;
  text-align: center;
  line-height: 14px;
  margin-top: -8px;
}

.dg-layout .right-navigation li {
  margin: 0;
  position: relative;
}

.dg-layout .right-navigation li a {
  padding: 15px 8px 15px 60px;
  display: block;
  font-size: 12px;
  border-left: 5px solid transparent;
}

.dg-layout .reports .right-navigation li a {
  color: #555;
  clear: left;
  padding: 15px 8px 15px 0px;
  font-size: 12px;
}

.dg-layout .sidebar .navigation-container {
  margin-top: 13px;
  height: calc(100% - 250px);
  overflow: auto;
}

.dg-layout .sidebar .top-left-block {
  height: 190px;
}

.dg-layout .progress {
  width: 130px;
  height: 130px;
  margin: 0 auto;
  position: relative;
  background: #fff;
}

.dg-layout .progress svg {
  width: 100%;
}

.dg-layout .progress .value {
  position: absolute;
  top: 28%;
  width: 100%;
  text-align: center;
  font-size: 25px;
  display: block;
  font-weight: 600;
}

.dg-layout .progress .status {
  position: absolute;
  top: 55%;
  width: 100%;
  text-align: center;
  font-size: 10px;
  display: block;
}

.dg-layout a.download-btn.download-step1 {
  font-size: 13px;
  /* height: 10px;
	 */
  border: 1px solid #042b62;
  background: #042b62;
  padding: 5px 9px;
  margin: 50px;
  border-radius: 11px;
  color: #fff;
}

.dg-layout .download-btn.download-step-1 {
  font-size: 13px;
  /* height: 10px; */
  border: 1px solid #042b62 !important;
  background: #042b62;
  padding: 3px 9px;
  margin: -4px 50px;
  border-radius: 11px;
  color: #fff;
  cursor: pointer;
}

.dg-layout .logo {
  display: inline-block;
  padding: 10px 20px 0px;
  width: 100%;
  text-align: center;
}

.dg-layout .mobile-menu-close-btn {
  display: none;
}

.dg-layout .mobile-bottom-nav {
  display: none;
}

.dg-layout .reports .right-navigation li a.active {
  border-left: 5px solid #042b62;
  background: #e5edef;
  color: #242424;
  font-weight: 700 !important;
}

.dg-layout .background-div .bg#bg-about {
  background: url("./Assets/Images/ill-introduction.svg") no-repeat right top;
}

.dg-layout .background-div .bg#bg-incomeexpense {
  background: url("https://static.fintoo.in/static/assets/img/bg/income-expenses.svg") no-repeat right top;
}

.dg-layout .background-div .bg#bg-goals {
  background: url("https://static.fintoo.in/static/assets/img/bg/goals.svg") no-repeat right top;
}

.dg-layout .background-div .bg#bg-assets {
  background: url("https://static.fintoo.in/static/assets/img/bg/assets.svg") no-repeat right top;
}

.dg-layout .background-div .bg#bg-inusrance {
  background: url("https://static.fintoo.in/static/assets/img/bg/insurance.svg") no-repeat right top;
}

.dg-layout .background-div .bg#bg-Doc {
  background: url("https://static.fintoo.in/static/assets/img/bg/document.svg") no-repeat right top;
}

.dg-layout .background-div {
  /* background: url("./Assets/Images/back.svg") no-repeat left bottom; */
  height: 210px;
  background-size: cover;
  overflow: hidden;
  position: relative;
  z-index: -1;
}

.dg-layout .circle-cls img {
  width: 90px;
  z-index: 999;
  position: absolute;
}

.dg-layout .background-div .bg {
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
  right: 0;
  transform: translateY(210px);
  background-size: auto 170px !important;
  -webkit-transition: 0.9s ease all;
  -ms-transition: 0.9s ease all;
  transition: 0.9s ease all;
}

.dg-layout .background-div .active {
  transform: translateY(0px);
}

.dg-layout .white-box {
  border-top-left-radius: 45px;
  margin-top: -45px;
  padding: 40px;
  min-height: 50vh;
  background: #fff;
}

.dg-layout .top-tab-menu {
  text-transform: uppercase;
}

.dg-layout .top-tab-menu .tab-menu-item {
  margin: 0 20px;
}

.dg-layout .top-tab-menu .tab-menu-item .tab-menu-title {
  cursor: pointer;
  color: #353535;
  font-size: 14px;
}

.dg-layout .top-tab-menu .tab-menu-item.active {
  border-bottom: 3px solid #042b62;
}

.dg-layout .top-tab-menu .tab-menu-item.active .tab-menu-title {
  color: #042b62;
  font-weight: 700;
  padding-bottom: 3px;
}

.dg-layout .material input {
  border: 0;
  border-bottom: 1px solid #ced4da;
  border-radius: 0;
}

.dg-layout .tab-box {
  padding: 10px;
  margin-bottom: 10px;
}

.dg-layout .space75 {
  padding-left: 0.75rem;
}

.dg-layout .flow-form .step {
  position: relative;
  min-height: 50px;
  padding-top: 5px;
  padding-bottom: 20px;
  overflow: hidden;
}

.dg-layout .flow-form .step.done .step-no {
  position: absolute;
  left: 5px;
  background: #042b62;
  color: #fff;
  display: block;
  width: 25px;
  height: 25px;
  text-align: center;
  border-radius: 50%;
  border: solid 5px #fff;
  box-sizing: content-box;
  line-height: 25px;
  z-index: 0;
}

.dg-layout .flow-form .step.step-uncheck .step-no {
  background: red !important;
}

.dg-layout .flow-form .step.done .step-body {
  display: block;
  opacity: 0.8;
}

.dg-layout .flow-form .step .step-body {
  display: none;
  padding-left: 70px;
}

.dg-layout .flow-form .step .step-title {
  font-size: 1em;
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 0.83rem;
  width: fit-content;
}

.dg-layout .risk-radio .radio {
  margin: 14px 40px 14px 0;
}

.dg-layout .material-radio .radio input[type="radio"]+.radio-label {
  font-size: 1em;
  color: #333;
  line-height: 1rem;
  margin-top: 1rem;
  cursor: pointer;
}

.dg-layout .material-radio .radio input[type="radio"]:disabled+.radio-label:before {
  box-shadow: inset 0 0 0 3px #fff;
  border-color: #bfbfbf;
  background: #bfbfbf;
}

.dg-layout .reports .space75 {
  color: rgb(240, 128, 109);
  /* padding-top: 20px; */
  padding-bottom: 10px;
  padding-right: 20px;
}

.dg-layout .material-radio .radio input[type="radio"]:disabled+.radio-label:before {
  pointer-events: none;
}

.dg-layout .risk-radio .radio input[type="radio"]+.radio-label:before {
  border: 1px solid #042b62;
  margin-right: 0.8em;
}

.dg-layout .material-radio .radio input[type="radio"]+.radio-label:before {
  content: "";
  background: #fff;
  border-radius: 100%;
  border: 1px solid #bfbfbf;
  display: inline-block;
  width: 0.875rem;
  height: 0.875rem;
  position: relative;
  top: 0px;
  margin-right: 0.7rem;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  -webkit-transition: all 250ms ease;
  -ms-transition: all 250ms ease;
  transition: all 250ms ease;
}

.dg-layout .material-radio .radio input[type="radio"] {
  position: absolute;
  opacity: 0;
}

.dg-layout .material select {
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-radius: 0px;
}

.dg-layout .material input {
  outline: none;
}

.dg-layout .flow-form .step.done .step-no:after {
  content: "";
  width: 1px;
  height: 1000px;
  border-right: dashed 1px #042b62;
  position: absolute;
  left: 11px;
  top: 32px;
}

.dg-layout .flow-form .step.step-uncheck .step-no::after {
  border-right: dashed 1px red;
}

.dg-layout .btn-container .form-arrow:after {
  top: 8px;
}

.dg-layout .btn-container .previous-btn,
.dg-layout .btn-container .next-btn {
  color: #042b62;
  background: #e5edef;
  padding: 6px 28px;
  margin-left: 2px;
  margin-right: 2px;
  cursor: pointer;
  vertical-align: middle;
  font-size: 1rem;
  font-weight: 700;
}

.dg-layout .btn-container .previous-btn {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

.dg-layout .btn-container .next-btn {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.dg-layout .btn-container .form-arrow span {
  width: 0;
  overflow: hidden;
  display: inline-block;
  transition: 0.3s ease all;
}

.dg-layout .btn-container .form-arrow:hover span {
  width: 92px;
}

.dg-layout .btn-container .next-btn:hover span {
  width: 78px;
}

.dg-layout .step-progress {
  position: relative;
  right: 0rem;
  float: right;
}

.dg-layout .step-progress svg {
  width: 60px;
  position: relative;
}

.dg-layout .step-progress span.value {
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 60px;
  line-height: 60px;
  color: #042b62;
  font-size: 12px;
  text-align: center;
}

.dg-layout .inner-box .inner-container {
  position: relative;
  border: 1px solid #042b62;
  border-radius: 17px;
  padding: 20px 30px;
  margin-bottom: 15px;
  width: 100%;
}

.dg-layout .inner-box .inner-container:first-child {
  margin-top: 0.5rem;
}

.dg-layout .inner-box h4 {
  margin: 0;
  margin-bottom: 15px;
  font-size: 1.1em;
  font-weight: 700;
}

.dg-layout .inner-box .inner-container .display-style {
  display: flex;
  align-items: center;
}

.dg-layout .inner-box .inner-container .display-style span {
  font-weight: bold;
  color: #042b62;
  padding-right: 5px;
  font-weight: 500;
}

.dg-layout .inner-box .inner-container .display-style span,
.dg-layout .inner-box .inner-container .display-style p {
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  letter-spacing: 0.2px;
  font-weight: 700;
}

.dg-layout .inner-box .inner-container .display-style p {
  font-weight: 500;
}

.dg-layout .add-delete-icon svg {
  margin-left: 8px;
  font-size: 14px;
}

.dg-layout .accordion-header .accordian-img {
  vertical-align: middle;
  margin-right: 10px;
  width: 25px;
  height: 25px;
}

.dg-layout .accordion-header .accordion-heading {
  font-size: 1.1em;
  font-weight: 700;
}

.dg-layout .accordion .accordion-panel .accordion-header {
  background: #e5edef;
  border-radius: 17px;
  padding: 5px 19px 5px 20px;
  min-height: 54px;
  position: relative;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  display: flex;
  align-items: center;
}

.dg-layout .card-list {
  display: flex;
  list-style: none;
  padding: 0;
  margin-top: 1em;
  flex-wrap: wrap;
}

.dg-layout .card-list li label {
  font-weight: 400;
}

.dg-layout .card-list li label span {
  font-weight: 600;
  font-size: 0.9rem;
}

.dg-layout .card-list li {
  width: calc(100% / 3);
  padding-right: 21px;
  padding-bottom: 16px;
  position: relative;
}

.dg-layout .card-list li input[type="radio"]:checked~label {
  border: 2px solid #042b62;
}

.dg-layout .card-list li label img {
  width: 35px;
  height: 25px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
  position: absolute;
  left: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.dg-layout ul.card-list li label {
  text-overflow: ellipsis;
  overflow: hidden;
  height: 1.2em;
  white-space: nowrap;
  display: inline-block;
}

.dg-layout .card-list li label {
  background: #fff;
  text-decoration: none;
  box-shadow: 0 2px 1px 0 rgb(54 54 54 / 15%);
  font-size: 13px;
  width: 100%;
  padding: 20px 10px;
  display: flex;
  font-weight: 600;
  color: #000;
  cursor: pointer;
  border-radius: 15px;
  padding-left: 55px;
  position: relative;
  min-height: 60px;
  align-items: center;
  border: 2px solid transparent;
  -webkit-transition: 0.3s ease all;
  -ms-transition: 0.3s ease all;
  transition: 0.3s ease all;
}

.dg-layout .card-list li label:hover {
  box-shadow: 0 2px 15px 0 rgb(54 54 54 / 15%);
}

.dg-layout .card-list li input[type="radio"] {
  opacity: 0;
  position: absolute;
}

.dg-layout .accordion .accordion-panel .accordion-content {
  padding: 25px 45px;
}

.dg-layout .accordion .accordion-panel .accordion-content form .align-items-baseline {
  position: relative;
}

.dg-layout .accordion .accordion-panel .accordion-content form .col-md-8 {
  position: relative;
}

.dg-layout .accordion .accordion-panel.active {
  border: 1px solid #042b62;
  overflow: hidden;
}

.dg-layout .accordion .accordion-panel {
  margin-bottom: 10px;
  border: 1px solid transparent;
  border-radius: 17px;
}

.dg-layout .gradient-btn {
  border: none;
  background: -moz-linear-gradient(to right,
      #042b62 0%,
      #65d48e 50%,
      #042b62 100%);
  background: -webkit-linear-gradient(to right,
      #042b62 0%,
      #65d48e 50%,
      #042b62 100%);
  background: linear-gradient(to right, #042b62 0%, #65d48e 50%, #042b62 100%);
  background-size: 200% auto;
  background: #fdf2f0;
  color: #042b62;
  transition: 0.5s;
  margin-right: 20px;
  margin-left: 20px;
}

.dg-layout .default-btn {
  border: none;
  padding: 8px 30px;
  border-radius: 30px;
  transition: 0.3s ease all;
  text-align: center;
  outline: none;
  font-weight: 600;
  display: inline-block;
  vertical-align: middle;
  background: #e5edef;
  color: #042b62;
  transition: 0.5s;
}

.dg-layout .dob9 .react-date-picker__wrapper {
  border: none;
}

.dg-layout .info-hover-box {
  position: relative;
  right: 0;
  cursor: pointer;
}

.dg-layout .floatinfo .info-hover-box {
  right: 12px;
  top: 30px;
  position: absolute;
}

.info-hover-left-box {
  position: relative;
  left: 0;
  cursor: pointer;
  z-index: 9;
}

.info-hover-diff-box {
  position: relative;
  left: 0;
  cursor: pointer;
}

.dg-layout .material .info-hover-left-box {
  /* position: absolute; */
  position: absolute;
  right: 0;
  bottom: 4px;
  cursor: pointer;
}

.dg-layout .material .info-hover-box {
  /* position: absolute; */
  position: absolute;
  right: 0px;
  bottom: 4px;
  cursor: pointer;
  z-index: 10;
}

.dg-layout .floatinfo .form-floating {
  /* bottom: 23px ; */
  position: relative;
}

/* .dg-layout .floatinfo .material .info-hover-box{
  position: absolute;
  bottom: 14px;
} */
.dg-layout .material .info-hover-diff-box {
  /* position: absolute; */
  position: absolute;
  right: -20px;
  bottom: 0px;
  cursor: pointer;
}

.dg-layout .info-hover-box .msg {
  display: none;
  background: #f5fcf8;
  border: solid 1px #042b62;
  border-radius: 15px;
  position: absolute;
  top: -10px;
  right: -10px;
  width: 250px;
  padding: 15px;
  transform: translateY(-100%);
  font-size: 12px;
  z-index: 1;
}

.dg-layout .info-hover-left-box .msg {
  display: none;
  background: #f5fcf8;
  border: solid 1px #424242;
  border-radius: 15px;
  position: absolute;
  top: -10px;
  left: -10px;
  width: 250px;
  padding: 15px;
  transform: translateY(-100%);
  font-size: 12px;
  z-index: 1;
}

.dg-layout .info-hover-diff-box .msg {
  display: none;
  background: #f5fcf8;
  border: solid 1px #424242;
  border-radius: 15px;
  position: absolute;
  top: -10px;
  left: -10px;
  width: 250px;
  padding: 15px;
  transform: translateY(-100%);
  font-size: 12px;
  z-index: 1;
}

.dg-layout .info-hover-left-box .msg:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #424242;
  position: absolute;
  bottom: -8px;
  left: 11px;
}

.dg-layout .info-hover-box .msg:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #042b62;
  position: absolute;
  bottom: -8px;
  right: 11px;
}

.dg-layout .info-hover-diff-box .msg:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #042b62;
  position: absolute;
  bottom: -8px;
  right: 11px;
}

.dg-layout .info-hover-left-box:hover .msg {
  display: block;
}

.dg-layout .info-hover-diff-box:hover .msg {
  display: block;
}

.dg-layout .info-hover-box:hover .msg {
  display: block;
}

.dg-layout .datagathering {
  width: 15rem;
}

.dg-layout .datagathering .react-date-picker__wrapper {
  border: 0px;
  border-radius: 0px;
  padding: 0px;
}

.dg-layout .datagathering .react-date-picker__wrapper svg {
  stroke: #c2bfbf;
}

.dg-layout .datagathering .react-date-picker__button:enabled:hover .react-date-picker__button__icon,
.dg-layout .datagathering .react-date-picker__button:enabled:focus .react-date-picker__button__icon {
  stroke: #c2bfbf;
}

.dg-layout .dt-box-1 {
  line-height: 28px;
}

.dg-layout .white-box .form-floating>.form-control:focus~label,
.dg-layout .white-box .form-floating>.form-control:not(:placeholder-shown)~label {
  transform: none !important;
  opacity: 1;
  padding: 0px;
}

.dg-layout .white-box .material {
  border: none;
  padding: 0;
  position: relative;
  width: 100%;
  /* margin: 6px 0; */
  margin-bottom: 5px;
  /* margin-top: 1px; */
}

.dg-layout .white-box .material input,
.dg-layout .white-box .material textarea {
  background: none;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  line-height: 1.5;
  display: block;
  resize: none;
  width: 100%;
  color: #383838;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #dadada;
  appearance: none;
  -webkit-appearance: none;
}

.dg-layout .white-box .material input:focus,
.dg-layout .white-box .material textarea:focus {
  box-shadow: none;
}

.dg-layout .white-box .material input {
  height: calc(2rem + 5px);
  height: 33px;
  padding-left: 0px !important;
  /* padding: 0; */
}

.dg-layout .white-box .material select {
  padding-left: 0px;
}

.dg-layout .white-box .material .bar {
  position: relative;
  display: block;
  width: 100%;
}

.dg-layout .white-box .material .bar::before {
  content: "";
  height: 1px;
  width: 0;
  bottom: 0px;
  position: absolute;
  background: #383838;
  transition: 0.3s ease all;
  left: 0%;
}

.dg-layout .white-box .material label {
  padding-left: 0px;
  position: absolute;
  pointer-events: none;
  transition: 0.3s ease all;
  color: #042b62;
  color: #333;
  left: 0px;
  top: -10px;
  font-size: 12px;
  font-weight: 500;
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
}

.dg-layout .white-box .material .InsuranceAmt {
  top: -12px;
}

.dg-layout .white-box .opt-options {
  float: right;
}

.dg-layout .white-box .opt-options span {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  cursor: pointer;
}

.dg-layout .white-box .opt-options svg {
  font-size: 1.2rem;
  color: #042b62;
}

.dg-layout .white-box .opt-options svg:hover {
  color: rgb(91 93 93 / 70%);
}

.dg-layout .white-box .opt-options .opt-options-2 svg {
  font-size: 1.4rem;
  color: #042b62;
}

.dg-layout .white-box .opt-options .opt-options-2 svg:hover {
  /* color: rgba(0, 82, 99, 0.7); */
  color: rgb(91 93 93 / 70%);
}

.dg-layout .inner-box {
  margin: 20px 0;
}

.dg-layout .my-document .upload-files .upload-form {
  padding: 30px 20px 20px;
}

.dg-layout .dropzone.dz-clickable {
  cursor: pointer;
  margin-top: 2px;
  margin-bottom: 6px;
  background: transparent;
  background-color: #e5edef;
  border: 1px dashed #042b62;
  padding: 10px;
  white-space: nowrap;
  /* overflow: auto; */
  /* height: 100%; */
  height: 8rem;
  padding: 10px;
  position: relative;
}

/* .dg-layout .dz-default .dz-message div{
  background: url(https://static.fintoo.in/static/assets/img/drag_files.svg) no-repeat 10px center;
    background-size: 20px;
    text-align: left;
    padding-left: 45px;
} */
.dg-layout .Documentlistarea:after {
  content: "Click to add More files";
  position: absolute;
  top: 22px;
  right: -107px;
  color: #424242;
  width: 90px;
  white-space: pre-wrap;
  text-align: center;
  bottom: 0;
  height: 90px;
  font-size: 12px;
  background: url(https://static.fintoo.in/static/assets/img/drag_files.svg) no-repeat center 16px;
  padding-top: 42px;
  /* border: dashed 1px rgba(0, 168, 162, 0.3); */
  border: dashed 1px rgba(82, 84, 84, 0.3);
  border-radius: 16px;
  border-radius: 20px;
  pointer-events: none;
}

.custom_link {
  color: #6151c9 !important;
}

.dg-layout .dropzone.dz-clickable .dz-message {
  cursor: pointer;
  font-size: 12px;
  margin: 3em 0;
  text-align: center;
  /* background: url(https://static.fintoo.in/static/assets/img/drag_files.svg) no-repeat center center;
  background-size: 20px;
  /* text-align: left; */
  /* padding-left: 45px; */
}

.dg-layout .Documentlistarea {
  position: absolute;
  margin-top: -9.2rem;
}

.dg-layout .FileArea {
  /* position: absolute; */
  top: 8px;
  padding: 10px;
  background: linear-gradient(to bottom, #eee, #ddd);
  overflow: hidden;
  width: 90px;
  height: 90px;
  /* position: relative; */
  display: grid;
  margin: 0.9rem;
  z-index: 9;
  background: #e6e6e6;
  border-radius: 20px;
  border: solid 1px #424242;
  position: relative;
  place-items: center;
}

.dg-layout .bgStyleTable {
  position: relative;
  text-align: left;
  width: 100%;
  font-size: 14px;
  border: solid 1px #1c94ad;
}

.dg-layout .bgStyleTable th {
  border-right: 1px solid #a3a3a3 !important;
  font-weight: bold;
  background-color: #042b62;
  color: #fff;
  border-bottom: 1px solid #a3a3a3;
  border-top: 1px solid #a3a3a3;
  padding: 15px 15px;
  border-right: 1px solid #a3a3a3;
}

.dg-layout .bgStyleTable tr:nth-child(even) td {
  background: #f8f8f8;
}

.dg-layout .bgStyleTable tr td {
  padding: 12px 12px;
  border-right: 1px solid #a3a3a3 !important;
  /* width: 50%; */
}

.dg-layout .download-box {
  width: 180px;
  height: 120px;
  background: #e5edef;
  padding: 1rem 1rem;
  border-radius: 10px;
  margin: 10px auto;
  position: relative;
}

.dg-layout .download-box .download-btn {
  border: 1px solid #042b62;
  background: #042b62;
  padding: 8px 15px;
  margin: 12px;
  border-radius: 17px;
  font-weight: 500;
  color: #fff;
}

/* .dg-layout .download-box > div {
  display: none;
} */
.rp-layout .download-box>div.active {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.rp-layout .download-box>div .close-download,
.rp-layout .download-box>div .close-download-step {
  position: absolute;
  top: 8px;
  right: 8px;
  font-family: monospace;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
}

.rp-layout .close-download img {
  display: block;
  width: 12px;
  height: 12px;
}

.rp-layout .download-box .download-btns {
  list-style: none;
  text-align: center;
  margin: 10px auto;
  padding: .5rem 0rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: .5rem;
  grid-row-gap: 0px;
}

.rp-layout .download-box .download-btns li {
  display: inline-block;
}

.rp-layout .download-btns li a {
  cursor: pointer;
}

.rp-layout .download-box .download-btns li a.email {
  background: #042b62 url(https://static.fintoo.in/static/assets/img/download/download-white-mail.svg) center center no-repeat;
}

.rp-layout .download-box .download-btns li a:hover.email {
  background: #ffffff url(https://static.fintoo.in/static/assets/img/download/download-green-mail.svg) center center no-repeat;
}

.rp-layout .download-box .download-btns li a.pdf {
  background: #042b62 url(https://static.fintoo.in/static/assets/img/download/download-white-pdf.svg) center center no-repeat;
}

.rp-layout .download-box .download-btns li a:hover.pdf {
  background: #ffffff url(https://static.fintoo.in/static/assets/img/download/download-green-pdf.svg) center center no-repeat;
}

.rp-layout .download-box .download-btns li a.print {
  background: #042b62 url(https://static.fintoo.in/static/assets/img/download/download-white-print.svg) center center no-repeat;
}

.rp-layout .download-box .download-btns li a:hover.print {
  background: #ffffff url(https://static.fintoo.in/static/assets/img/download/download-green-print.svg) center center no-repeat;
}

.rp-layout .download-box .download-btns li a {
  border: 1px solid #042b62;
  padding: 8px 15px;
  border-radius: 50%;
  color: #fff;
  display: block;
  width: 40px;
  height: 40px;
  text-indent: -1000px;
}

.dg-layout .download-box>div.active {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.dg-layout .report-intro .intro-quote {
  background: #042b62;
  border-radius: 20px;
  color: #fff;
  margin-bottom: 30px;
}

.dg-layout .report-intro .founders-note {
  font-family: "Caveat";
  font-size: 28px;
  font-weight: 700;
  padding: 1.4rem;
}

.dg-layout .report-intro .founder-username {
  font-family: "Caveat", medium !important;
  font-size: 32px;
  font-weight: bold;
  margin: 0px 0px 8px 0px;
}

.dg-layout .report-intro p {
  line-height: 26px !important;
}

.dg-layout .founder-name {
  font-family: "Caveat";
  font-size: 2em;
}

.dg-layout .no-data-found {
  padding: 30px;
}

.dg-layout .no-data-found p {
  line-height: 26px !important;
  padding: 2em;
}

.dg-layout .no-data-found .link {
  font-size: 14px;
  padding: 8px 15px;
  display: block;
  background: #042b62;
  border: solid 1px #042b62;
  color: #fff;
  border-radius: 20px;
  width: fit-content;
  width: -webkit-fit-content;
  margin: 30px auto;
  display: block;
}

.dg-layout .bottom-btn-container {
  overflow: auto;
  z-index: 1;
  width: 100%;
  position: sticky;
  bottom: 0;
}

.dg-layout .btn-container {
  text-align: center;
  margin: auto;
  display: block;
  /* position: fixed; */
  width: 100%;
  /* bottom: -40px; */
  padding: 25px;
  /* bottom: 0 !important; */
  /* left: 8.7%; */
}

.rp-layout .fixedBtn {
  text-align: center;
  margin: auto;
  display: block;
  position: fixed;
  width: 100%;
  bottom: 0px;
  padding: 25px;
  left: 8.7%;
}

.rp-layout .tabledata {
  border-bottom: 1px solid #000 !important;
}

/* .rp-layout table tr td {
  border: 1px solid #a3a3a3;
} */
.rp-layout table .borderRemove tr td {
  border: none;
}

.rp-layout table tr:last-child td {
  border-bottom: 0;
}

.dg-layout .reports .btn-container .form-arrow {
  /* -webkit-box-shadow: 1px 2px 5px 0 #000; */
  /* box-shadow: 1px 2px 5px 0 #000; */
  cursor: pointer;
}

.dg-layout .btn-container .next-btn {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  color: #042b62;
  text-align: left;
}

.dg-layout .nav-buttons {
  padding: 10px;
  margin-bottom: 20px;
  display: flex;
  /* position: sticky; */
  top: 0;
  background: #fff;
  overflow: auto;
  overflow-x: scroll !important;
  width: 100%;
  z-index: 1;
  font-size: 16px;
  justify-content: center;
  white-space: nowrap;
}

.dg-layout .bgStyleTable .colorGreen {
  background-color: #e2e2e2 !important;
}

.dg-layout .nav-buttons li {
  border-radius: 20px;
  font-weight: 700;
  margin: 0 20px;
  border: 1px solid #042b62;
  display: inline-block;
}

.dg-layout #grossinflow,
.OutFlow {
  display: grid;
  place-items: center;
}

.dg-layout .nav-buttons li a {
  font-size: 14px;
  padding: 8px 15px;
  display: block;
  cursor: pointer;
}

.dg-layout .reports .nav-buttons li.active {
  background: #042b62;
}

.dg-layout .reports .nav-buttons li.active a {
  color: #fff;
}

.dg-layout .rTitle {
  /* margin-bottom: 18px !important; */
  font-size: 16px;
  font-weight: 700;
  text-transform: capitalize;
}

.dg-layout .rTitle img {
  display: inline-block !important;
  max-width: 30px;
  max-height: 30px;
  vertical-align: middle;
  margin-right: 10px;
}

.dg-layout .riskAppetiteBox {
  background-color: #fafafa;
  position: relative;
  padding: 30px;
}

.dg-layout .riskAppetiteBox h3 {
  text-transform: capitalize;
}

.dg-layout .riskAppetiteBox ol li {
  text-align: justify !important;
  line-height: 26px !important;
}

.dg-layout .notes {
  font-weight: 700;
}

.dg-layout .notes_sec_div {
  border-radius: 15px;
  min-height: 100px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding-top: 45px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 50px;
}

.dg-layout .notes_head_div {
  position: absolute;
  border: 1px solid #d2d2d3;
  left: 2rem;
  top: -32px;
  width: 140px;
  height: 50px;
  border-radius: 10px;
  padding: 14px;
  background-color: #f4f4f5;
  text-align: center;
  padding-left: 50px;
  font-weight: 600;
}

.dg-layout .notes_head_div i {
  background: url(https://www.fintoo.in/static/userflow/img/09_notes.svg) no-repeat;
  background-size: 100%;
  width: 60px;
  height: 70px;
  display: block;
  position: absolute;
  top: -11px;
  left: -5px;
}

.dg-layout .notes_sec_div .notes_text_div {
  padding-left: 20px;
  padding-top: 20px;
}

.dg-layout .notes_sec_div .notes_text_div ul li p {
  padding: 0;
}

.dg-layout .rTitle img {
  display: inline-block !important;
  max-width: 30px;
  max-height: 30px;
  vertical-align: middle;
  margin-right: 10px;
}

.dg-layout .profileHolderBox {
  background-color: #fafafa;
  position: relative;
  padding: 50px;
  padding-top: 38px !important;
  color: #353535;
}

.dg-layout .about-you-list {
  width: fit-content;
  width: -moz-fit-content;
  margin: 0 auto;
}

.dg-layout .about-you-list .YourDataLabel {
  font-size: 1em;
  font-weight: 600;
}

.dg-layout .about-you-list li {
  margin: 15px 0;
}

.dg-layout .about-you-list .icon-box {
  position: relative;
  padding-left: 30px;
  margin-bottom: 10px;
}

.dg-layout .about-you-list .icon-box a {
  color: #353535;
  text-decoration: none;
}

.dg-layout .about-you-list .icon-box img {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0;
  top: 0;
}

.dg-layout .memeber-box {
  background-color: #f4f9fb;
  position: relative;
  padding: 20px;
  color: #353535;
  margin: 20px 0;
  max-width: 315px;
}

.dg-layout .memeber-box .relation {
  background-color: #f5fcf8;
  background: #dbf5e5;
  background: #d1edf9;
  border-radius: 20px;
  display: block;
  padding: 8px 15px;
  font-size: 12px;
  margin-top: -37px;
  width: fit-content;
}

.dg-layout .memeber-box .relation i {
  display: inline-block;
  vertical-align: middle;
  height: 20px;
  width: auto;
}

.dg-layout .memeber-box .relation i::before {
  margin: 0;
  color: #042b62;
  font-size: 16px;
}

.dg-layout .memeber-box .aboutmember-earnings {
  width: 305px !important;
}

.dg-layout .memeber-box .about-you-list li {
  margin: 15px 0;
}

.dg-layout .memeber-box .icon-box {
  position: relative;
  padding-left: 30px;
  margin-bottom: 10px;
}

.dg-layout .dob8 .react-date-picker__wrapper {
  flex-direction: row-reverse;
}

.dg-layout .dob8 .react-date-picker__wrapper .react-date-picker__button {
  display: none;
}

/* .dg-layout .li-options.active label {
  border: 2px solid #00a8a2 !important;
} */
.dg-layout .li-options.active label {
  border: 2px solid #042b62 !important;
}

.dg-layout .grossOutflowContainer {
  height: 420px;
  margin: 10px auto;
  width: 100%;
  position: relative;
}

.dg-layout .grossOutflowContainer .top-left {
  top: 0;
  left: 0;
  background: #fafafa url(https://static.fintoo.in/static/assets/img/reports/go-top-right.svg) top right no-repeat;
  background-size: 100%;
}

.dg-layout .grossOutflowContainer .expander {
  height: 200px;
  position: absolute;
  width: calc(50% - 10px);
  padding: 20px;
  z-index: 1;
  overflow: hidden;
  -webkit-transition: 0.6s ease all;
  -ms-transition: 0.6s ease all;
  transition: 0.6s ease all;
}

.dg-layout .grossOutflowContainer .expander .viewExpanded {
  color: #042b62;
  font-size: 14px;
  display: inline-block;
  cursor: pointer;
  font-weight: bolder;
  border-bottom: 2px solid #042b62;
}

.dg-layout .grossOutflowContainer .expander .expandedContent {
  display: none;
  width: 60%;
}

.dg-layout .grossOutflowContainer .expander .amount {
  margin: 8px 0;
  font-size: 20px;
}

.dg-layout .grossOutflowContainer .expander .closeExpander {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  right: 0px;
  font-size: 50px;
  display: none;
  line-height: 23px;
  z-index: 5;
  height: 30px;
  color: #333;
  cursor: pointer;
  width: 30px;
  margin-left: auto;
}

.dg-layout .grossOutflowContainer .top-right {
  top: 0;
  right: 0;
  background: #fafafa url(https://static.fintoo.in/static/assets/img/reports/go-top-right.svg) top right no-repeat;
  background-size: 100%;
}

.dg-layout .grossOutflowContainer .bottom-left {
  bottom: 0;
  left: 0;
  background: #fafafa url(https://static.fintoo.in/static/assets/img/reports/go-bottom-left.svg) top right no-repeat;
  background-size: 100%;
}

.dg-layout .grossOutflowContainer .bottom-right {
  bottom: 0;
  right: 0;
  background: #fafafa url(https://static.fintoo.in/static/assets/img/reports/go-bottom-right.svg) top right no-repeat;
  background-size: 100%;
}

.dg-layout .risk-appetite-bar {
  width: 400px;
  position: relative;
  padding: 10px 0;
}

.dg-layout .risk-appetite-bar .risk-bar {
  display: flex;
  width: 100%;
  border-radius: 30px;
  overflow: hidden;
}

.dg-layout .riskAppetiteBox ul li:before {
  content: "";
  position: absolute;
  left: 6px;
  top: 18px;
  width: 5px;
  height: 5px;
  background: #000;
  border-radius: 50%;
}

.dg-layout .risk-appetite-bar .risk-indicator {
  position: absolute;
  width: 25px;
  height: 25px;
  background: #fff;
  border-radius: 50%;
  top: 4px;
  left: 50%;
  -webkit-box-shadow: 0px 0px 5px 0px #000;
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 5px 0px #000;
}

.risk-appetite-bar .risk-bar li {
  height: 15px;
  width: 100%;
  padding: 0;
}

.dg-layout .riskAppetiteBox p {
  font-weight: 600;
}

.dg-layout .RetirementType {
  font-weight: 700;
  text-transform: capitalize;
}

.riskAppetiteBox ul li {
  padding: 10px 0px 10px 20px;
  list-style: none;
  position: relative;
}

.reports .riskAppetiteBox ul li {
  text-align: left !important;
  line-height: 26px !important;
}

.dg-layout .risk-appetite-bar .level1 {
  background: #07a500;
}

.dg-layout .risk-appetite-bar .level2 {
  background: #85e503;
}

.dg-layout .risk-appetite-bar .level3 {
  background: #f8d700;
}

.dg-layout .risk-appetite-bar .level4 {
  background: #fe7900;
}

.dg-layout .risk-appetite-bar .level5 {
  background: #f60400;
}

.dg-layout .gridCalBox {
  background-color: #fafafa;
  padding: 30px 0 0;
  text-align: center;
}

.dg-layout .gridCalBox p {
  padding: 20px 20px 0;
}

.dg-layout .gridCalBox .scorebard-box {
  position: relative;
  width: 138px;
  margin: 0 auto;
}

.dg-layout .gridCalBox .colorBgBand {
  background-color: #042b62;
  color: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 1px 10px;
  text-align: center;
  margin: 10px 0;
}

.dg-layout .gridCalBox span {
  position: absolute;
  top: 0;
  bottom: 0;
  text-align: center;
  margin: auto;
  height: 25px;
  color: #fff;
  left: 0;
  width: 100%;
  font-size: 20px;
  letter-spacing: 1.2px;
}

.dg-layout .rectangle {
  padding: 7px;
  width: 250px;
  background-color: #042b62;
  border-radius: 50px;
  color: white;
  font-size: 21px;
  margin: auto;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  text-align: center;
}

.dg-layout .sc_needle_container .sc_needle_area {
  width: 100%;
  height: 50%;
  top: 0;
  left: 0;
  position: absolute;
}

.dg-layout .sc_needle_container {
  position: relative;
}

.dg-layout .sc_needle {
  background: url(https://www.fintoo.in/static/assets/img/reports/needle.svg) center bottom no-repeat;
  background-size: contain;
  height: 100%;
  display: block;
  width: 20px;
  left: -2rem;
  right: 0;
  margin: auto;
  bottom: -12px;
  position: absolute;
  transform-origin: bottom center;
  transform: rotate(323deg);
}

.dg-layout .sc_needle_legend {
  margin-bottom: 70px;
  margin: 10px;
  line-height: 30px;
}

.dg-layout .sc_needle_legend li span {
  display: inline-block;
  vertical-align: text-bottom;
  width: 15px;
  height: 15px;
  margin-right: 5px;
  border-radius: 50%;
}

.dg-layout li .excellent {
  background: #588036;
}

.dg-layout li .verygood {
  background: #4faa36;
}

.dg-layout li .good {
  background: #e1b624;
}

.dg-layout li .average {
  background: #f88221;
}

.dg-layout li span.notgood {
  background: #f9411f;
}

.dg-layout .recommen_sec_div {
  border-radius: 15px;
  min-height: 100px;
  border: 1px solid #d7d7d7;
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding-top: 45px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 50px;
}

.dg-layout .recommen_sec_div .rec_head_div {
  position: absolute;
  border: 1px solid #d2d2d3;
  left: 40%;
  top: -36px;
  width: 250px;
  height: 50px;
  border-radius: 40px;
  padding: 14px;
  background-color: #f4f4f5;
  text-align: center;
  padding-left: 45px;
  box-shadow: 0px 2px 0px 0px #cccbcc;
}

.dg-layout .recommen_sec_div .rec_head_div span {
  font-size: 18px;
  color: #042b62;
  font-weight: 700;
}

.recommen_sec_div .bgStyleTable .total_td {
  font-size: 1.2rem !important;
  color: #042b62;
  font-weight: 600;
}

.dg-layout .recommen_sec_div .rec_head_div i {
  background: url(https://www.fintoo.in/static/userflow/img/07_bulb.svg) no-repeat;
  background-size: 100%;
  width: 60px;
  height: 70px;
  display: block;
  position: absolute;
  top: -27px;
  left: -5px;
}

.bgStyleTable td {
  background: #fff;
}

.bgStyleTable tr:nth-child(2n) td {
  background: #f8f8f8;
}

.dg-layout .recommen_sec_div .inner_text_div ul li {
  font-size: 15px;
  line-height: 23px;
  margin-bottom: 10px;
  padding: 0 0 0 30px;
  list-style: none;
  /* background-image: url(https://www.fintoo.in/static/userflow/img/10_Arrow.png); */
  background-repeat: no-repeat;
  background-position: left 0px top 5px;
  background-size: 15px;
  font-style: italic;
  color: #787878;
}

.dg-layout .recommen_sec_div .inner_text_div p {
  font-size: 16px;
  text-align: left !important;
  line-height: 26px !important;
  /* color: #787878; */
}

.dg-layout .bold {
  font-weight: 700;
}

.dg-layout .goalSummaryHolder {
  padding: 0 15px;
}

.dg-layout .rContent {
  margin-top: 1.5rem;
}

.dg-layout .goalSummaryHolder .rContent {
  margin-bottom: 25px;
  margin-top: 1rem;
}

.dg-layout .total-value td {
  font-size: 18px !important;
  text-transform: capitalize;
}

.dg-layout .bgStyleTable {
  position: relative;
  text-align: left;
  margin-top: 1rem;
  width: 100%;
  font-size: 14px;
  border: solid 1px #a3a3a3;
}

.dg-layout .bgStyleTable tr.color td {
  border-right: 1px solid #a3a3a3;
  background-color: #042b62;
  color: #fff;
}

.dg-layout .bgStyleTable td:last-child {
  border-right: none;
}

.dg-layout .bgStyleTable .top-line {
  border-top: solid 1px #a3a3a3 !important;
}

.dg-layout .bgStyleTable .top-line td {
  /* font-weight: 500; */
  font-size: 14px;
}

.dg-layout .notes_sec_div {
  /* border-radius: 15px;
	 */
  min-height: 100px;
  border: none !important;
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding-top: 5px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 50px;
}

.dg-layout .notes_sec_div i {
  background: url(https://static.fintoo.in/static/userflow/img/09_notes.svg) no-repeat;
  background-size: 100%;
  width: 50px;
  height: 70px;
  display: block;
  position: absolute;
  top: -22px;
  left: -12px;
}

.dg-layout .notes_sec_div ul li,
.dg-layout .notes_sec_div p,
.dg-layout .notes_sec_div .recommen_sec_div .inner_text_div ul li {
  font-size: 15px;
  line-height: 23px;
  margin-bottom: 10px;
  padding: 0 0 0 30px;
  list-style: none;
  /* background-image: url(https://static.fintoo.in/static/userflow/img/icons/next-arrow-blue.png); */
  background-repeat: no-repeat;
  background-position: left 0px top 5px;
  background-size: 15px;
  font-style: italic;
  color: #787878;
}

.dg-layout .notes_sec_div .hidebglist ul li {
  background-image: none !important;
}

.dg-layout .asset-table tr {
  border-bottom: solid 1px #a3a3a3 !important;
}

.dg-layout .notes_sec_div p {
  background-image: none;
}

.dg-layout .asset-mapping .step-progress {
  text-align: center;
}

.dg-layout .asset-mapping .step-progress svg {
  width: 40px;
  position: relative;
}

.dg-layout .asset-mapping .step-progress span.value {
  position: absolute;
  left: 0;
  right: 0;
  width: 40px;
  height: 40px;
  line-height: 40px;
  color: #042b62;
  font-size: 12px;
  text-align: center;
  margin: 0 auto;
}

.dg-layout .invertment-goal-mapping {
  background: #fafafa;
  padding: 18px;
  /* width: 100% !important; */
  margin: 0 auto;
  margin-top: 30px;
  position: relative;
}

.dg-layout .invertment-goal-mapping .goal-name-cls {
  position: relative;
  /* bottom: -8px; */
  width: 100%;
  top: 5rem;
}

.dg-layout .invertment-goal-mapping .goal-name-cls .circle-cls {
  padding: 0px !important;
  width: 0.4%;
}

.dg-layout .invertment-goal-mapping .goal-name-cls .goal-text-cls {
  background: #f2f3f3;
  border-radius: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 20px;
  margin-left: 4rem;
  width: 375px;
}

.dg-layout .invertment-goal-mapping .goal-name-cls .goal-text-cls div {
  padding-left: 0.5rem;
  white-space: pre-wrap;
  max-width: 375px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.dg-layout .analysis-section img {
  max-width: 450px;
  display: block;
  margin: 0 auto;
}

.dg-layout .goal-bar {
  border: none !important;
  background: #8cc541;
  border-radius: 15px;
  padding: 30px 0 !important;
  margin-top: 0px;
  margin: 10px 4px;
  margin-bottom: 50px;
}

.dg-layout .goal-bar .border-right-cls {
  border-right: solid 1px #fff !important;
}

.dg-layout .goal-bar .border-right-cls p {
  text-align: center !important;
}

.dg-layout .goal-bar .white-cls {
  color: #fff !important;
  font-size: 1em !important;
}

.dg-layout .goal-bar .wid-img-cls {
  width: 16px !important;
}

/* 
.dg-layout .gs-slider .rc-slider {
  position: relative;
}
.dg-layout .gs-slider {
  position: relative;
}

.dg-layout .gs-slider .rc-slider-handle:before {
  position: absolute;
  content: attr(aria-valuenow);
  visibility: visible;
  display: inline;
  top: 13px;
  left: -5px !important;
}
.dg-layout .gs-slider.hidenumber .rc-slider-handle:before {
  display: none;
} */
.dg-layout .dark-label {
  border: none;
  padding: 0;
  position: relative;
  width: 100%;
  margin: 0px 0;
  margin-bottom: 10px;
  padding-top: 4px;
}

.dg-layout .addMaturity .dark-label {
  /* width: 13rem; */
  padding: 0;
}

.dg-layout .addMaturity {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;
}

.dg-layout .BonusData .addMaturity {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.dg-layout .Boxmaturity {
  display: flex;
  flex-direction: column;
}

.dg-layout .fltr-section {
  padding-top: 0px;
}

.dg-layout .white-box .addMaturity .material input {
  height: calc(2.5rem + 2px);
}

/* .dg-layout .dark-label .form-label{
  font-weight: 600;
} */
/* .dg-layout .material .form-label{
  font-weight: 600 !important;
} */
.dg-layout .notes_head_div span {
  font-size: 18px;
  color: #042b62;
  font-weight: 700;
}

.dg-layout .dark-label label {
  padding-left: 0px;
  position: absolute;
  pointer-events: none;
  transition: 0.3s ease all;
  color: #042b62;
  color: #333;
  left: 0px;
  top: 0px;
  font-size: 12px;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dg-layout .dark-label .dt-conbx {
  padding: 0.375rem 0 0.375rem 0;
  padding-top: 14px;
  border-bottom: 1px solid #dadada;
}

.dg-layout .AssetLib .dark-label .dt-conbx {
  padding-top: 7px;
}

.dt-conbx input[type="text"] {
  padding: 0.375rem 2.25rem 0.375rem 0.75rem !important;
  background-image: none !important;
}

.dg-layout .recomm-box {
  background-color: #f9fcf5;
  padding: 30px;
  margin-bottom: 20px;
  margin-top: 1rem;
}

.dg-layout .recomm-box .green {
  background-color: #042b62;
  color: #fff;
  display: flex;
  /* align-items: center; */
}

.dg-layout .recomm-box .cardBox {
  margin-left: -30px;
  width: -webkit-fit-content;
  margin-bottom: 25px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  font-size: 13px;
  height: 36px;
  line-height: 36px;
  padding: 0 15px;
}

.dg-layout .bgStyleTable th {
  font-weight: bold;
  background-color: #042b62;
  color: #fff;
  font-size: 1em;
  white-space: nowrap;
  /* white-space: nowrap; */
  padding: 12px 12px;
  border-right: 1px solid #a3a3a3 !important;
}

.dg-layout .bgStyleTable tr.outline td {
  background-color: #e9f3f7;
  color: #000;
  font-weight: 600;
  /* padding: 15px 15px; */
  border-bottom: 1px solid #1c94ad !important;
}

.dg-layout .bgStyleTable .outline .boxed .text-center {
  font-weight: 600;
}

.dg-layout .bgStyleTable .outline .boxed .colorGreen {
  padding-top: 1rem;
  font-weight: 600;
  font-size: 1.8rem;
  color: #042b62;
}

.dg-layout .bgStyleTable .outline .boxed .colorGreen1 {
  padding-top: 1rem;
  font-weight: 600;
  font-size: 1.8rem;
  color: #042b62;
}

.dg-layout .net-worth-thermo-container {
  display: flex;
  justify-content: space-evenly;
}

.dg-layout .net-worth-thermo-container .net-worth-thermo {
  display: block;
  width: 100px;
  margin: 0 auto;
  background: #e8f7d0;
  z-index: 0;
  position: relative;
}

.dg-layout .net-worth-thermo-container .net-worth-thermo .progress {
  width: 96%;
  position: absolute;
  bottom: 2px;
  margin: 0 auto;
  left: 2px;
  z-index: 2;
  background: #8bc53f;
}

.dg-layout .net-worth-thermo-container .net-worth-thermo .RedBg {
  height: 47.15158487405014%;
  background: red !important;
}

.dg-layout .net-worth-thermo-container .net-worth-thermo img {
  position: relative;
  display: block;
  z-index: 3;
  width: 100%;
}

.recomm-box .cardBox.green img {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin-right: 8px;
}

.dg-layout .pyramid-cls p {
  font-weight: 600;
  font-size: 1.1em;
}

.dg-layout .gridCalculation .col-md-6 {
  margin-bottom: 30px;
}

.dg-layout .reports p {
  text-align: justify !important;
  line-height: 26px !important;
  /* font-size: 0.9rem; */
}

.dg-layout .gridCalBox p {
  padding: 20px 30px 0;
}

.dg-layout .scoreCardLabel {
  text-align: center;
  color: #e1b624;
  font-size: 1em;
  font-weight: 600;
  padding: 1rem;
  margin-top: 1rem;
}

.dg-layout .star-rating {
  white-space: nowrap;
}

.dg-layout .asset-recomm h1 {
  font-size: 1.3rem;
}

.dg-layout .star-rating .Unfill {
  color: gray;
}

.dg-layout .star-rating-container .star-list {
  display: block;
  flex-direction: row;
  flex-wrap: wrap;
  white-space: nowrap;
}

.dg-layout .star-rating-container .star-list span {
  margin-left: 2px !important;
}

.dg-layout .contingencyPlanningHolder .contingencyPlanningHead {
  padding: 40px 30px;
  background-color: #fafafa;
  margin-bottom: 20px;
  padding-top: 20px !important;
}

.dg-layout #borderRightaactive .outline td:last-child {
  border-right: 1px solid #1c94ad !important;
}

.dg-layout .TBData .Boredernone td {
  border: none !important;
}

.dg-layout .MedicalInsurance {
  margin-left: 20px;
  margin-top: 10px;
}

.dg-layout .MedicalInsurance li::marker {
  font-weight: 500;
}

.dg-layout .MedicalInsurance li {
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 0;
}

.dg-layout .medicalLabel {
  font-size: 16px;
  font-weight: 600;
}

.right-navigation .navigation-icon .active {
  font-weight: 600 !important;
}

.right-navigation .active {
  font-weight: 600 !important;
}

.dg-layout .bgStyleTable .RemoveBorderRight tr .child {
  border-right: none !important;
}

.dg-layout .cashFlowTables .expandColumns {
  display: block;
  color: #042b62;
  padding-top: 10px;
  font-size: 12px;
  font-weight: normal;
  cursor: pointer;
  border-bottom: solid 1px #042b62;
  width: fit-content;
  width: -moz-fit-content;
}

.dg-layout .cashFlowTables .recommendation {
  display: block;
  color: #fff;
  padding-top: 10px;
  font-size: 11px;
  font-weight: normal;
  cursor: pointer;
  width: fit-content;
  width: -moz-fit-content;
}

.dg-layout .cashFlowTables .recommendation-table {
  text-align: left !important;
}

.dg-layout .flow-form .default-btn {
  padding: 5px 15px;
  font-size: 12px;
  margin: 15px 25px 0 0;
  background: #042b62;
  color: #fff;
}

.dg-layout .invertment-goal-mapping .rec-slider {
  transition: all 500ms ease 0s;
  right: auto;
  top: auto;
  left: -3555.56px;
}

.dg-layout .invertment-goal-mapping .rec-arrow-left {
  color: #1c94ad !important;
  background-color: white !important;
  box-shadow: 0 0 2px 0 #333 !important;
  border: 1px solid #1c94ad !important;
  font-size: 1.2em;
}

.dg-layout .invertment-goal-mapping .rec-arrow-right {
  color: #1c94ad !important;
  background-color: white !important;
  box-shadow: 0 0 2px 0 #333 !important;
  border: 1px solid #1c94ad !important;
  font-size: 1.2em;
}

.dg-layout .seperator {
  width: 100%;
}

.dg-layout .controls-wrapper {
  width: 100%;
}

.dg-layout .carousel-wrapper {
  width: 100%;
}

.dg-layout .rec-carousel-item:focus {
  outline: none;
  box-shadow: inset 0 0 1px 0px violet;
}

.dg-layout .btnNextPrev {
  border: none !important;
  background-color: none !important;
  box-shadow: none !important;
  height: 30px;
  border-radius: 50%;
  padding: 0.6rem;
  background: transparent !important;
}

.dg-layout .rec-pagination {
  display: none !important;
}

.dg-layout .form-select option {
  padding: 20px !important;
  margin: 1rem;
}

.dg-layout .bgStyleTable .action {
  text-align: center;
  cursor: pointer;
}

.dg-layout .popupmodal .outline-btn {
  background: transparent;
  border: 1px solid #042b62;
  color: #353535;
  padding: 6px 28px;
  border-radius: 30px;
  outline: none;
}

.dg-layout .popupmodal .secuirtyBtn {
  background: transparent;
  border: 1px solid #424242;
  color: #353535;
  padding: 6px 28px;
  border-radius: 0 !important;
}

.dg-layout .popupmodal .outline-btn:hover {
  color: #fff;
  background: #042b62;
}

.dg-layout .table-responsive::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #fff;
  /* or add it to the track */
}

.dg-layout .modal-content {
  border-radius: 22px;
}

.dg-layout .popupmodal .ModalHead {
  display: block;
  background-color: #042b62;
  color: #fff;
  font-size: 1.2rem;
  border: 1px solid #042b62 !important;
  font-weight: 600;
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
}

.dg-layout .sortSelect {
  min-height: 2rem !important;
  border-radius: 0px;
  border: none !important;
  border-bottom: 1px solid #dadada !important;
  height: auto !important;
  outline: none !important;
}

.dg-layout .sortSelect__control {
  min-height: 2rem !important;
  border-radius: 0px;
  border: none !important;
  border-bottom: 1px solid #dadada !important;
  height: auto !important;
  outline: none !important;
  box-shadow: none !important;
  background-color: transparent;
}

.dg-layout .sortSelect__menu {
  margin-top: -0.4rem;
  height: auto !important;
  z-index: 100;
  /* max-height: 120px !important; */
  /* overflow-y: scroll!important; */
}

.dg-layout .sortSelect__menu-list {
  min-height: 20px;
  height: auto !important;
  max-height: 8rem !important;
}

.dg-layout .react-datepicker {
  border: none !important;
}

.dg-layout .sortSelect__value-container {
  padding: 0px !important;
}

.dg-layout .sortSelect__indicator-separator {
  background-color: none !important;
  display: none !important;
}

.dg-layout .react-datepicker {
  border: none !important;
}

.dg-layout .sortSelect__value-container {
  padding: 0px !important;
}

.dg-layout .sortSelect__indicator-separator {
  background-color: none !important;
  display: none !important;
}

.dg-layout .react-datepicker__month-select,
.react-datepicker__year-select {
  border: none !important;
  background-color: #e5edef !important;
  outline: none;
}

.react-datepicker__year-select {
  overflow-y: scroll;
  width: 100%;
  width: 5rem !important;
}

.react-datepicker__year-select::-webkit-scrollbar {
  width: 1rem;
}

.dg-layout .react-datepicker__month {
  outline: none !important;
  outline: none;
}

.dg-layout .react-datepicker__current-month {
  display: none !important;
  background-color: none !important;
  outline: none;
}

.dg-layout .react-datepicker__month-dropdown-container {
  padding: 2px 10px;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  /* border-bottom: solid 1px #042b62; */
  background: #e5edef;
  border-radius: 0;
  margin: 0 5px;
  visibility: visible !important;
}

.dg-layout .react-datepicker__month-select {
  border-bottom: solid 1px #042b62 !important;
  width: 5rem;
}

.dg-layout .react-datepicker__year-select {
  border-bottom: solid 1px #042b62 !important;
}

.dg-layout .react-datepicker__year-dropdown-container {
  padding: 2px 10px;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  /* border-bottom: solid 1px #042b62; */
  background: #e5edef;
  border-radius: 0;
  margin: 0 5px;
  visibility: visible !important;
}

.dg-layout .react-datepicker__year-read-view--down-arrow {
  display: none;
}

.dg-layout .react-datepicker__month-read-view--down-arrow {
  display: none;
}

.dg-layout .react-datepicker__navigation-icon {
  position: relative;
  top: 6px !important;
  font-size: 20px;
  width: 0;
  color: #042b62;
}

.dg-layout .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
  border-color: #042b62 !important;
}

.dg-layout .react-datepicker__day-names {
  padding-top: 0.6rem;
}

.dg-layout .react-datepicker__month-container {
  background: #e5edef !important;
  width: 250px !important;
  border-radius: 14px;
}

.dg-layout .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-radius: 14px;
}

/* .dg-layout .react-datepicker__navigation-icon::before{
  content: '&#9658;';
  display: block;
  width: 0; 
  height: 0; 
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent; 
  
  border-right:10px solid blue; 
} */
.dg-layout .react-datepicker__input-container input {
  border: none !important;
}

.dg-layout .react-datepicker__day--selected {
  background-color: #042b62;
}

.dg-layout .react-datepicker__header {
  border-bottom: none !important;
}

.dg-layout .react-datepicker__triangle {
  display: none;
}

.dg-layout .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::after {
  border-top-color: #042b62;
  /* border: 8px solid #042b62; */
}

.dg-layout .react-datepicker__month-option .react-datepicker__navigation {
  display: none !important;
}

.dg-layout .react-datepicker__year-option .react-datepicker__navigation {
  display: none !important;
}

.dg-layout .material-radio .radio img {
  width: 14px !important;
  height: 14px !important;
  margin-top: 4px !important;
}

/* .main-layout .privacy-policy-section .ComplaintTable tr, td, th{
  border: 1px solid #dee2e6 !important;
} */
.privacy-policy-section .table tr td:first-child {
  text-align: center;
}

.privacy-policy-section ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.main-layout .DropMenu .dropdown .btn-primary {
  background-color: transparent !important;
  width: 100%;
  text-align: left;
  color: gray;
  border-radius: 0px;
  border: none !important;
  border-bottom: 1px solid rgba(0, 82, 99, 0.31) !important;
}

.main-layout .DropMenu .dropdown .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;

  padding-left: 0rem;
  padding-top: 1rem;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.main-layout .dropdown-menu {
  width: 100% !important;
}

.main-layout .dropdown-menu .dropdown-item {
  color: #000;
}

.main-layout .dropdown-menu .dropdown-item:hover {
  background: #f0f4f3;
  color: #042b62;
}

.main-layout .DropMenu .dropdown .btn:hover {
  background-color: none !important;
}

.main-layout .sortSelectData {
  min-height: 2rem !important;
  border-radius: 0px;
  border: none !important;
  border-bottom: 1px solid #dadada !important;
  height: auto !important;
  outline: none !important;
}

.main-layout .sortSelectData__control {
  min-height: 2rem !important;
  border-radius: 0px;
  border: none !important;
  border-bottom: 1px solid #dadada !important;
  height: auto !important;
  outline: none !important;
  box-shadow: none !important;
}

.main-layout .sortSelectData .sortSelectData__control {
  margin-top: -0.4rem;
  height: auto !important;
  /* max-height: 120px !important; */
  overflow-y: scroll !important;
}

.main-layout .sortSelectData__value-container {
  padding: 0px !important;
}

.main-layout .sortSelectData__indicator-separator {
  background-color: none !important;
  display: none !important;
}

.main-layout .about-banner {
  background: url(https://static.fintoo.in/static/userflow/img/about_us.svg) no-repeat right bottom;
  background-size: 65%;
  margin-top: -2rem;
}

.main-layout .about-banner p {
  font-weight: 500;
  font-size: 18px;
}

.main-layout .about-banner h2 {
  font-size: 34px;
  font-weight: 700;
  line-height: 40px;
}

.main-layout .about-banner .col-md-5 {
  padding-left: 45px;
}

.main-layout .sectionPadding {
  padding: 60px 0;
}

.main-layout .sectionPadding .core-icon {
  width: 80px;
  height: 80px;
}

.main-layout .sub-header,
h3 {
  font-size: 18px;
  font-weight: 500;
  /* padding-top: 1rem; */
  padding-bottom: 1rem;
}

.main-layout .who-are-we {
  background: #f0f4f3;
}

.main-layout .who-are-we .who-are-we-img {
  width: 300px;
  margin: 0 auto;
  display: block;
}

.main-layout .who-are-we .page-header {
  color: #042b62;
  font-weight: 600;
}

.main-layout .who-are-we h2 {
  font-weight: 600;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.main-layout .why-us {
  position: relative;
}

.main-layout .why-us .why-us-block {
  border-radius: 37px;
  background-color: #fff;
  box-shadow: 2px 2px 10px 4px rgb(216 216 216 / 63%);
  position: absolute;
  width: 70%;
  bottom: 0;
  margin: 0 auto;
  left: 0;
  right: 0;
  padding: 30px;
}

.main-layout .minty-products .bg-grey {
  background: #f0f4f3;
}

.main-layout .itr-clients {
  background: snow;
}

.main-layout .minty-products .page-header {
  font-size: 2rem;
  font-weight: 600;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.main-layout .minty-products .Textinfo {
  font-size: 1em;
}

.main-layout .sectionPadding .page-header {
  color: #042b62;
  font-size: 2rem;
  font-weight: 600;
}

.main-layout .PartnerSlideImg .rec-pagination,
.rec-arrow {
  display: none;
}

.main-layout .PartnerSlideImg img {
  width: 120px !important;
  height: 100px;
  vertical-align: middle;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.main-layout .PartnerSlideImg img:hover {
  width: 120px !important;
  height: 100px;
  cursor: pointer;
  vertical-align: middle;
  /* -webkit-filter: grayscale(100%); */
  filter: grayscale(0%);
}

.main-layout .ClientSlideImg {
  display: block;
  width: 100%;
}

.main-layout .ClientSlideImg .rec-arrow {
  display: none;
}

.main-layout .ClientSlideImg .rec-dot {
  margin-left: 1rem;
  background-color: #042b62 !important;
  box-shadow: 0 0 1px 3px #042b62 !important;
  border-radius: 30px !important;
  width: 5px !important;
  height: 5px !important;
}

.main-layout .ClientSlideImg .rec-dot_active {
  width: 78px !important;
}

.main-layout .video-slider-content {
  position: relative;
}

.main-layout .video-slider-content .video-play-icon a {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5) url(https://static.fintoo.in/static/userflow/img/play.svg) center center no-repeat;
  background-size: 100px;
}

.main-layout .testimonial-media {
  display: flex;
  align-items: center;
  text-align: left;
  padding: 15px;
  width: 100%;
  background-color: #ffff;
}

.main-layout .testimonial-media .client-img {
  width: 40px !important;
  height: 40px;
  margin-right: 15px;
}

.main-layout .testimonial-media h3 {
  margin: 0;
}

.main-layout .client-info .client-Name {
  font-weight: 500;
}

.main-layout .our-events .search-wrapper {
  margin: 20px 0;
  position: relative;
}

.main-layout .Events .search-wrapper .textbox {
  width: 100% !important;
  /* margin-top: 3rem; */
  border-radius: 30px;
  border: none;
  padding: 10px 30px;
  padding-left: 50px;
}

.main-layout .Events .search-wrapper .search-btn {
  border: none;
  width: 40px;
  height: 40px;
  background: 0 0;
  position: absolute;
  left: 10px;
  top: 0px;
}

.main-layout .Events .sorting {
  /* margin-top: 3rem; */
  margin-left: 6rem;
}

.main-layout .Events .sort-by {
  color: #000;
  font-weight: 600;
}

.main-layout .Events .no-events-img img {
  width: 500px;
  margin: 0 auto;
  display: block;
}

.main-layout #overlay-example .tooltip-arrow {
  display: none !important;
}

.main-layout #overlay-example .tooltip-inner {
  background-color: #fff !important;
  color: #000 !important;
  padding: 1rem !important;
  font-size: 1rem;
}

.main-layout #overlay-example .tooltip-inner .SortList:hover p {
  color: #042b62;
}

.main-layout #previous .events-cover {
  position: relative;
  overflow: visible;
}

.main-layout #previous .knowledge-base-cover {
  border-radius: 14px;
  background-color: #fff;
  box-shadow: 0 2px 9px 0 rgb(0 0 0 / 12%);
  margin-bottom: 35px;
  /* position: relative; */
}

.main-layout #previous .video-slider-content {
  position: relative;
}

.main-layout #previous .video-slider-content {
  position: relative;
}

.main-layout #previous .video-slider-content .video-thumb-grid {
  position: relative;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  overflow: hidden;
}

.main-layout #previous .video-slider-content .video-thumb-grid a {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.main-layout .video img {
  width: 100%;
}

.main-layout #previous .knowledge-base-cover .knowledge-base-media {
  padding-left: 30px;
  position: relative;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

.main-layout #previous .testimonial-media {
  display: flex;
  align-items: center;
  text-align: left;
  padding: 15px;
}

.main-layout #previous .our-events .client-info {
  width: 100%;
}

.main-layout #previous .our-events .testimonial-media .client-info {
  font-size: 14px;
}

.main-layout #previous .event_name {
  margin-bottom: 0px;
  color: #000;
}

.main-layout #previous .event_name:hover {
  color: #337ab7;
}

.main-layout #previous .our-events .event-details {
  display: flex;
  align-items: center;
  max-width: 100%;
  flex: 0 0 100%;
}

.main-layout #previous .our-events .date-details {
  flex: 0 0 66%;
  max-width: 66%;
}

.main-layout .event-date {
  position: relative;
  padding-left: 30px;
  margin-bottom: 10px;
  color: #a6a6a6;
  white-space: nowrap;
}

.main-layout .knowledge-base-cover .knowledge-base-media p {
  color: #a6a6a6;
}

.main-layout .event-date img {
  background: url(https://static.fintoo.in/static/userflow/img/icons//calendar.svg) no-repeat center center;
  background-size: 20px;
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  left: 0;
  top: 2px;
}

.main-layout .event-location {
  position: relative;
  color: #a6a6a6;
  padding-left: 30px;
  margin-bottom: 10px;
}

.main-layout .event-location img {
  background: url(https://static.fintoo.in/static/userflow/img/icons/grey-location.svg) no-repeat center center;
  background-size: 20px;
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
}

.main-layout .knowledge-base {
  /* margin-top: 60px; */
  background: #f0f4f3;
}

.main-layout .knowledge-base .news_section {
  background: #fff;
  text-align: center;
  padding: 15px;
  border-radius: 15px;
  box-shadow: 0 2px 9px 0 rgb(0 0 0 / 12%);
  margin-bottom: 30px;
  min-height: 285px;
  box-shadow: 0 3px 5px 0 rgb(0 0 0 / 8%);
  transition: box-shadow 0.3s ease-in-out;
}

.main-layout .knowledge-base .news_section:hover {
  box-shadow: 0px 2px 15px 1px #808080;
}

.main-layout .knowledge-base .news_section img {
  max-width: 50%;
}

.main-layout .knowledge-base .news_section p {
  margin-top: 30px;
  font-weight: 600;
  min-height: 90px;
}

.main-layout .address-section {
  background-color: #ffff;
}

.main-layout .contact-us .page-header,
h2 {
  font-weight: 600;
}

.main-layout .address-section {
  padding: 20px 0 40px;
}

.main-layout .address-section .color-blue {
  color: #042b62;
  font-size: 600;
}

.main-layout .address-section ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.main-layout .address-section .icon-list li {
  position: relative;
  margin-bottom: 20px;
  padding-left: 30px;
  font-size: 1rem;
}

.main-layout .address-section .icon-list li.address:before {
  content: "";
  background: url(https://static.fintoo.in/static/userflow/img/icons/location.png) no-repeat center;
  background-size: 20px;
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  left: 0;
}

.main-layout .address-section .icon-list li.call:before {
  content: "";
  background: url(https://static.fintoo.in/static/userflow/img/icons/call.png) no-repeat center;
  background-size: 20px;
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  left: 0;
}

.main-layout .address-section .icon-list li.mail:before {
  content: "";
  background: url(https://static.fintoo.in/static/userflow/img/icons/mail.png) no-repeat center;
  background-size: 20px;
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  left: 0;
}

.main-layout .address-section .icon-list li.hours:before {
  content: "";
  background: url(https://static.fintoo.in/static/userflow/img/icons/hours.png) no-repeat center;
  background-size: 20px;
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  left: 0;
}

.main-layout .address-section .color-light-green {
  color: #8bc53f;
}

.main-layout .container {
  max-width: 1140px !important;
}

.knowledge-base-cover .knowledge-base-media .share-icon {
  position: absolute;
  right: 30px;
  top: 20px;
  z-index: 1;
  background: url(https://static.fintoo.in/static/userflow/img/icons/share.svg) no-repeat center center;
  background-size: 20px;
  width: 20px;
  height: 20px;
}

.dg-layout .bgStyleTable tr .child:nth-child(2) {
  border: none !important;
}

.dg-layout .bgStyleTable .CashFlow tr .child:nth-child(2) {
  border-right: solid 1px #1c94ad !important;
}

.dg-layout .bgStyleTable .CashFlow tr .child:last-child {
  border: none !important;
}

.dg-layout .bgStyleTable tr .cashFlowTables {
  background-color: #e2e2e2 !important;
}

.dg-layout .bgStyleTable tr.color .cashFlowTablesTH {
  background-color: #e2e2e2 !important;
  border-bottom: solid 1px #1c94ad !important;
  color: #000 !important;
}

.dg-layout .recommen_sec_div .bgStyleTable {
  text-align: center !important;
  /* font-weight: 700; */
  font-size: 15px;
}

.dg-layout .recommen_sec_div .bgStyleTable tr td {
  border: 1px solid #1c94ad;
}

.dg-layout .goal-asset-red-class {
  color: red !important;
}

.dg-layout .smaller-Header {
  font-size: 1.7rem !important;
  font-weight: 600;
  color: #020202;
}

.table-responsive ::-webkit-scrollbar {
  height: 2px !important;
}

.dg-layout .react-datepicker__navigation-icon--next::before {
  left: 0px !important;
}

.dg-layout .react-datepicker__navigation-icon--previous::before {
  right: 0px !important;
}

.dg-layout .pageHolder .glossarydata .labelHead {
  font-size: 16px;
  font-weight: 600;
}

.dg-layout .pageHolder .glossarydata .labelHead:first-child {
  margin-top: 1rem;
}

.dg-layout .apexcharts-legend-text {
  font-weight: 600 !important;
}

.dg-layout .OutFlow .apexcharts-legend {
  justify-content: left !important;
}

.dg-layout .OutFlow .apexcharts-legend .apexcharts-legend-series {
  padding-left: 1rem !important;
  padding-bottom: .4rem !important;
}

.dg-layout .OutFlow .apexcharts-legend .apexcharts-legend-series:nth-child(2) {
  margin-left: 21px !important;
}

.dg-layout #investmentStyle {
  background-color: #fff;
}

.dg-layout #investmentStyle .apexcharts-legend-text {
  font-size: 18px !important;
  font-weight: 400 !important;
}

.dg-layout #investmentStyle .apexcharts-legend-marker {
  display: none;
}

.dg-layout .cashflowIn tr td:last-child {
  font-weight: bold;
}

.dg-layout .bgStyleTable .CashInflow .borderBottom {
  border-bottom: 1px solid black !important;
}

.dg-layout .bgStyleTable tr:last-child .borderBottom {
  border-bottom: 1px solid #1c94ad !important;
}

.dg-layout .CashInflow {
  border: 1px solid #1c94ad !important;
}

.dg-layout .cashFlowTables th,
.cashFlowTables td {
  width: 150px !important;
}

.dg-layout .bgStyleTable .CDFF .colorGreen {
  background-color: #e2e2e2 !important;
  color: #000;
  border-bottom: 1px solid #1c94ad !important;
  /* font-weight: 600; */
}

.dg-layout .bgStyleTable .main_header td {
  font-weight: 600;
}

.dg-layout .apexcharts-xaxis-label {
  display: none !important;
}

.dg-layout .apexcharts-xaxis-title-text {
  margin-top: -2rem;
}

.dg-layout #performance .highcharts-tick {
  display: none;
}

.dg-layout .material label {
  font-size: 12px !important;
  font-weight: 700 !important;
}

.dg-layout .material-switch .switch-label {
  font-size: 14px !important;
}

.dg-layout .Nobold label {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.dg-layout .react-datepicker-wrapper .react-datepicker__day--keyboard-selected {
  background: transparent !important;
  color: #000 !important;
  font-weight: normal !important;
}

.dg-layout .react-datepicker__day--keyboard-selected {
  background-color: transparent !important;
  color: #000 !important;
}

.dg-layout .react-datepicker__month-select {
  appearance: none !important;
  text-align: center !important;
}

.dg-layout .react-datepicker__year-select {
  appearance: none !important;
  text-align: center !important;
}

.dg-layout .react-datepicker__navigation--previous {
  left: 2px !important;
  content: "";
  display: block;
  top: 0.7rem;
  width: 20px;
  height: 8px;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid #042b62 !important;
}

.dg-layout .react-datepicker__navigation--next {
  right: 2px !important;
  content: "";
  display: block;
  top: 0.7rem;
  width: 20px;
  height: 8px;
  transform: rotate(180deg);
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid #042b62 !important;
}

/* .dg-layout .invertment-goal-mapping button:{
  background-color:  transparent !important;
} */
.dg-layout .invertment-goal-mapping button:disabled {
  background-color: transparent !important;
  cursor: not-allowed !important;
  pointer-events: all !important;
  opacity: 0.2;
}

.dg-layout .slider-container {
  width: 100%;
  height: 100%;
  position: fixed;
  /* add this code for position fixed */
  top: 0px;
  /* set top and left 0 */
  left: 0px;
}

.dg-layout .slider-item-div {
  padding: 20px;
  background-color: white;
  text-align: center;
  height: 100%;
  width: 100%;
}

.dg-layout .carousel-style {
  height: 100% !important;
}

.dg-layout .carousel-inner>.item {
  height: 500px !important;
}

.dg-layout .react-datepicker__day--outside-month {
  color: transparent !important;
  pointer-events: none;
}

.dg-layout .dot {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: inline-block;
}

.AssettotalValue {
  border-bottom: 1px solid #d7d7d7;
  padding-top: .7rem;
  /* width: 400px; */
}
.generateReportDG{
  width: 70%;
  margin: auto;
}
/* CSS For Mobile Responsive */
@media only screen and (max-width: 600px) {
  .dg-layout .analysis-section img {
    max-width: inherit;
  }

  .dg-layout .background-div {
    background: #fff3df url("./Assets/Images/back.svg") no-repeat left top;
  }

  .dg-layout .white-box {
    border-top-left-radius: 0px;
    padding: 23px;
  }

  .dg-layout .right-navigation {
    height: 100% !important;
  }

  .main-layout .color-white {
    color: #fff;
    margin-top: 13rem;
  }

  .mymodal .mybtn-close {
    position: fixed;
    background: #cfdddf;
    border-radius: 0.5rem;
    border: 0px;
    padding: 3px !important;
    left: 13px;
    width: 48px;
    height: 48px;
  }

  .dg-layout .sidebar {
    width: 271px;
    height: inherit;
    height: 100vh;
  }

  .dg-layout .nav-buttons {
    overflow: auto;
    /* width: max-content; */
    margin-bottom: 0px;
    justify-content: flex-start;
  }

  .profileSidebar {
    display: block !important;
  }

  .dg-layout {
    padding-left: 0;
  }

  .dg-layout .tab-box {
    justify-content: left;
    padding: 0;
    padding-bottom: 0px;
    padding-left: 0rem;
    margin: 0;
    margin-bottom: 20px;
    display: flex;
    position: sticky;
    top: 0;
    background: #fff;
    overflow: auto;
    z-index: 1;
    font-size: 16px;
    width: 100%;
  }

  .dg-layout .accordion .accordion-panel .accordion-content {
    padding: 13px;
  }

  .dg-layout .tab-box .top-tab-menu {
    /* white-space: nowrap; */
    font-size: 0.8rem;
    /* margin-left: 34rem; */
  }

  .dg-layout .tab-box .income-expense-menu {
    font-size: 0.8rem;
    margin-left: 0rem;
  }

  .dg-layout .card-list .LabelName {
    font-size: 0.9rem;
  }

  .dg-layout .card-list {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
    /* grid-template-rows: repeat(3, 1fr); */
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }

  .dg-layout .tab-menu-item {
    justify-content: left;
    padding: 0;
    white-space: nowrap;
    padding-bottom: 0px;
  }

  .dg-layout .card-list li {
    width: 137px;
    padding-right: 8px;
    padding-bottom: 16px;
    position: relative;
  }

  .dg-layout .card-list li label img {
    width: 24px;
    height: 19px;
    display: inline-block;
    vertical-align: middle;
    /* margin-right: 8px; */
    position: absolute;
    left: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .dg-layout .card-list li label {
    background: #fff;
    text-decoration: none;
    box-shadow: 0 2px 1px 0 rgb(54 54 54 / 15%);
    width: 100%;
    padding: 19px 0px;
    font-weight: 600;
    color: #000;
    border-radius: 15px;
    padding-left: 41px;
    position: relative;
    min-height: 60px;
    align-items: center;
    border: 2px solid transparent;
    transition: 0.3s ease all;
  }

  .dg-layout .btn-container {
    position: relative;
    bottom: 0;
    margin-top: 10px;
    background: transparent;
    padding: 0;
    left: 0;
  }

  .dg-layout .btn-container .save-btn {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  .dg-layout .reports .btn-container .form-arrow {
    display: inline-block;
    margin-top: 4rem;
  }

  .dg-layout .btn-container .previous-btn,
  .dg-layout .btn-container .next-btn {
    color: #042b62;
    background: #e5edef;
    padding: 6px 20px;
    margin-left: 2px;
    margin-right: 2px;
    cursor: pointer;
  }

  .dg-layout .btn-container .previous-btn {
    color: #042b62;
    background: #e5edef;
    padding: 6px 20px;
    margin-left: 2px;
    margin-right: 2px;
    cursor: pointer;
  }

  .dg-layout .white-box .opt-options {
    float: right;
    margin-top: -1.8rem;
  }

  .dg-layout .reports .space75 {
    color: rgb(240, 128, 109);
    padding-top: 0px;
    padding-right: 20px;
  }

  .dg-layout .white-box .opt-options svg {
    font-size: 0.9rem;
  }

  .dg-layout .white-box .opt-options .opt-options-2 svg {
    font-size: 1.2rem;
    color: #042b62;
  }

  .dg-layout .w-rem-22 {
    font-size: 0.7rem;
  }

  .dg-layout .notes_head_div {
    margin-top: 4rem;
  }

  .dg-layout .notes_sec_div .notes_text_div {
    padding-top: 48px;
  }

  .dg-layout .no-data-found {
    padding: 0;
  }

  .dg-layout .no-data-found img {
    width: 200px;
  }

  .dg-layout .risk-appetite-bar {
    width: 100%;
  }

  .InvestmentPhilosophy {
    width: 400px;
    height: 100%;
  }
}

.bg-color {
  background-color: #f0f4f3 !important;
}

.bg-color .sortSelect__value-container {
  padding: 0px;
}

.bg-color .sortSelect__control {
  min-height: 2rem !important;
  border-radius: 0px;
  border: none !important;
  border-bottom: 1px solid rgba(0, 82, 99, 0.31) !important;
  height: auto !important;
  outline: none !important;
  box-shadow: none !important;
  background: transparent !important;
}

.bg-color .sortSelect__indicator-separator {
  display: none;
}

.UAE-contact .color-blue {
  color: #042b62 !important;
}

.UAE-contact .color-blue {
  font-size: 28px !important;
  font-weight: 500 !important;
  color: #042b62 !important;
}

.UAE-contact .icon-list li.address:before {
  content: "";
  background: url(https://static.fintoo.in/static/userflow/img/icons/location.png) no-repeat center;
  background-size: 20px;
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  left: 0;
}

.UAE-contact .icon-list li.call:before {
  content: "";
  background: url(https://static.fintoo.in/static/userflow/img/icons/call.png) no-repeat center;
  background-size: 20px;
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  left: 0;
}

.UAE-contact .icon-list li.mail:before {
  content: "";
  background: url(https://static.fintoo.in/static/userflow/img/icons/mail.png) no-repeat center;
  background-size: 20px;
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  left: 0;
}

.UAE-contact .icon-list li.hours:before {
  content: "";
  background: url(https://static.fintoo.in/static/userflow/img/icons/hours.png) no-repeat center;
  background-size: 20px;
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  left: 0;
}

.UAE-contact .icon-list li {
  position: relative;
  margin-bottom: 20px;
  padding-left: 30px;
  font-size: 14px;
}

.UAE-contact .icon-list li {
  position: relative;
  margin-bottom: 20px;
  padding-left: 30px;
  font-size: 14px;
}

.UAE-contact .color-light-green {
  color: #8bc53f;
  font-weight: bold;
  white-space: nowrap;
}

.dg-layout .fintoo-datepicker input[type="text"] {
  background: transparent;
  padding-left: 0 !important;
  padding-bottom: 2px !important;
  color: #000;
}

.dg-layout .fintoo-datepicker input {
  background-image: none !important;
}

.dg-layout .sorting {
  position: relative;
  top: 0px;
  /* right: 105px; */
}

.dg-layout .sort-by {
  color: #042b62;
  font-weight: bold;
  cursor: pointer;
}

.sorting .sort-menu {
  background: #fff;
  top: 23px;
  position: absolute;
  right: 0;
  /* display: none; */
  z-index: 1;
  width: 150px;
  text-align: left;
  /* visibility: hidden;
  opacity: 0; */
  box-shadow: 2px 0px 23px 0px rgb(0 0 0 / 20%);
  -webkit-box-shadow: 2px 0px 23px 0px rgb(0 0 0 / 20%);
  -moz-box-shadow: 2px 0px 23px 0px rgba(0, 0, 0, 0.2);
  -webkit-transition: 0.4s all ease;
  -ms-transition: 0.4s all ease;
  transition: 0.4s all ease;
}

.sorting .sort-menu li a {
  color: #000;
}

.sorting .sort-menu li {
  padding: 12px 20px 12px;
  cursor: pointer;
}

.sorting .sort-menu li:hover {
  background: #f0f4f3;
}

.sort-by:hover .sort-menu {
  display: block;
}

.dg-layout .fltr-items {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.dg-layout .subcate {
  height: 150px;
  overflow-y: auto;
}

.dg-layout .Memebsrs {
  height: 110px;
  overflow-y: auto;
}

.dg-layout .fltr-section .fltr-items .fltr-items-li {
  width: 100%;
}

.dg-layout .borderactive2 button {
  border: 2px solid #042b62;
  outline: #042b62;
}

.addwishbookmark {
  width: 20px;
}

.completeKycDigi {
  margin: 0 15rem;
}

@media only screen and (max-width: 600px) {
  .UAE-contact .RowReverse {
    display: flex;
    flex-direction: column-reverse;
  }

  .UAE-contact .page-header2 {
    margin-top: 2rem;
  }

  .dg-layout .white-box .opt-options {
    display: flex;
    /* float: none; */
    justify-content: flex-end;
    margin-top: -1.2rem;
  }

  .dg-layout .sortSelect__control {
    background-color: transparent;
  }

  /* .dg-layout .reports p {
    line-height: 19px !important;
    font-size: 12px;
  } */
  .dg-layout .fltr-section .fltr-items .fltr-items-li {
    width: 100%;
  }

  .dg-layout .addMaturity .dark-label {
    width: 100%;
  }

  .dg-layout .total-amt {
    font-size: 0.9rem;
  }

  .dg-layout .recommen_sec_div .rec_head_div {
    left: 6%;
    width: 221px;
  }

  .dg-layout .total-value td {
    font-size: 14px !important;
  }

  .dg-layout .top-tab-menu .tab-menu-item {
    margin: 0 7px;
  }

  .dg-layout .nav-buttons li {
    margin: 0 7px;
  }

  .dg-layout .riskAppetiteBox ol li {
    font-size: 0.9rem;
  }

  .dg-layout .addMaturity {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.5rem;
  }

  .dg-layout .BonusData .addMaturity {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.5rem;
  }

  .dg-layout .info-hover-left-box .msg {
    width: 223px;
  }

  .dg-layout .about-you-list {
    width: 100%;
  }

  .dg-layout .profileHolderBox {
    padding: 30px;
  }

  .dg-layout .rectangle {
    font-size: 1rem;
    padding: 11px;
  }

  .dg-layout .gridCalBox p {
    padding: 20px 16px 0;
  }

  .dg-layout .recommen_sec_div .rec_head_div span {
    font-size: 12px;
  }

  .dg-layout .invertment-goal-mapping .goal-name-cls .goal-text-cls {
    width: 200px;
  }

  .dg-layout .circle-cls img {
    width: 79px;
  }

  .dg-layout .smaller-Header {
    font-size: 1rem !important;
  }

  .dg-layout .invertment-goal-mapping .goal-name-cls {
    position: static;
    margin-left: 0rem;
    padding-left: 0em;
  }

  .invertment-goal-mapping .btnNextPrev {
    left: -2px !important;
    justify-content: end !important;
  }

  .dg-layout .rec .rec-carousel-item .rTitle {
    display: grid;
    place-items: center;
    gap: 1rem;
  }

  .addwishbookmark {
    width: 17px;
  }

  .login-section {
    margin-top: 7rem;
  }

  .login-otp {
    margin-top: 2rem;
  }

  .dg-layout .top-tab-menu .tab-menu-item .tab-menu-title {
    font-size: 13px;
  }

  .completeKycDigi {
    margin: 0rem;
  }

  .dg-layout .DigiNotes {
    font-size: 1.2rem;
  }

  .dg-layout .DigiPara {
    font-size: 1rem;
  }
}

.dg-layout .arrowSpace .sortSelect__indicator {
  padding-right: 26px;
}

.dg-layout .sortSelect__indicator {
  padding-right: 26px;
}

.dg-layout .DguploadDoc .srv-validation-message {
  position: absolute;
  width: 100%;
  white-space: nowrap;
}

.CashflowNetSurplus .rContent ul li {
  background-image: none;
  padding: 5px 0;
}

.CashflowNetSurplus .rContent p {
  padding: 0;
}

.recommendation-inner-tables-border tr td {
  border-bottom: 1px solid #1c94ad !important;
  white-space: nowrap;
}

.recommendation-inner-tables tr td {
  width: 150px;
}

.recommendation-inner-tables-border tr:last-child td {
  border-bottom: none !important;
}

.dg-layout .bgStyleTable .recommendation-inner-tables-border tr .child:nth-child(2) {
  border-bottom: 1px solid #1c94ad !important;
  border-right: 1px solid #1c94ad !important;
}

.dg-layout .bgStyleTable .recommendation-inner-tables-border tr:last-child .child:nth-child(2) {
  border-bottom: none !important;
}

.dg-layout .bgStyleTable .recommendation-inner-tables-border tr .child:last-child {
  border-right: none !important;
}

.borderactive .active {
  border: 2px solid;
  transition: 0.9s ease all;
}

.dg-layout .reports .invertment-goal-mapping {
  width: 94% !important;
}

.dg-layout .DobDG {
  top: 6px !important;
}

.dg-layout .LInkOTPBTn {
  background: #042b62;
  margin: 0 auto;
  border: none;
  padding: 8px 30px;
  border-radius: 30px;
  margin: 20px;
  color: #fff;
  font-weight: 600;
  transition: 0.3s ease all;
  text-align: center;
  outline: none;
  font-weight: 600;
  margin-right: 25px;
  display: inline-block;
  vertical-align: middle;
}

.rp-layout .Recommandedtext p b {
  /* font-weight: bold; */
  color: #000;
}

/* .dg-layout .Toastify__toast--success{
  background-color: #042b62 !important;
  color: #fff;
}
.dg-layout .Toastify__close-button {
  display: none;
}
.dg-layout .Toastify__toast-icon svg {
  background-color: #fff;
}
.dg-layout .Toastify__progress-bar {
  background-color: #042b62;
} */
/* .dg-layout .Toastify__toast--success::before {
  content: url('./Assets/Images/SubCheck1.png') !important;
  width: 30px !important;
  position:relative; 
  z-index:100000;
  left:12px;
  /* display:   none; 
  top:6px; */
/* } */

/* Styles for DisableField class */
.DisableField {
  user-select: none;
  pointer-events: none;
  opacity: 0.5;
}

/* Styles for EnableField class (to enable the form) */
.EnableField {
  user-select: auto;
  /* Re-enable text selection */
  pointer-events: auto;
  /* Re-enable pointer events */
  opacity: 1;
  /* Set the opacity back to 100% */
}

.DigiNotes {
  color: #746868;
  padding: 0.7rem 0;
  font-size: 1.7rem;
  font-weight: bold;
}

.DigiPara {
  font-size: 1.2rem;
}

.DigisubPara {
  color: gray;
}

.DigisubPara a {
  color: #042b62;
}

.WhyDigibox {
  border: 1px solid #000 #aeaeae;
  box-shadow: 1px 4px 11px -1px rgba(0, 0, 0, 0.75);
  padding: 0rem 2rem 3rem 1rem;
  border-radius: 10px;
}

.ProceedPayBtn {
  display: grid;
  place-content: center;
  margin: auto;
  margin: 5rem 0rem;
  padding: .8rem 3rem;
  border: 0;
  color: #fff;
  background-color: #042b62;
  font-weight: bold;
  outline: none;
  font-size: 1.2rem;
  border-radius: 1.7rem;

}

.ProceedPayBtn:hover {
  background-color: #686767;
}

.kyc-success-card {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
}

.card {
  width: 70rem;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.card-body {
  padding: 5rem;
  text-align: center;
}

.success-icon {
  font-size: 4rem;
  color: #042b62;
  /* Green color for success */
}

.success-title {
  font-size: 2rem;
  margin: 2rem 0;
  font-weight: bold;
}

.success-message {
  font-size: 1.7rem;
  color: #333;
}

.custom-button {
  margin: 1rem 0rem;
  padding: 0.7rem 4rem;
  border: 0;
  color: #fff;
  background-color: #042b62;
  font-weight: bold;
  outline: none;
  font-size: 1.2rem;
  border-radius: 40px;
}

.custom-button:hover {
  background-color: #042b62;
  /* Darker blue color on hover */
}

.dgReporttext {
  margin: 0rem 1rem;
  line-height: 1.9rem;
  font-size: 1rem
}

.dgReportNote {
  /* line-height: 17px !important; */
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
  padding: 10px;
  font-weight: 600;
  font-size: .8rem;
  line-height: 1.8rem;
}

.dg-layout input::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  margin: 0;
}

.sortSelect__option:active {
  background-color: #fff !important;
}

.register_div {
  height: 3.5rem;
}

.link_asset_style {
  font-size: 12px;
  font-weight: 700;
  margin-top: 4px;
}

.retirementCashflow .expandColumns {
  display: block;
  color: #042b62;
  padding-top: 10px;
  font-size: 12px;
  font-weight: normal;
  cursor: pointer;
  border-bottom: solid 1px #042b62;
  width: fit-content;
  width: -moz-fit-content;
}

.retirementCashflow .recomm_expandcolor {
  color: #fff !important;
}

.retirementCashflow .expandColumns:after {
  content: '>';
  padding-left: 5px;
  position: absolute;
}

.retirementCashflow .expandColumns.rotate:after {
  content: '>';
  padding-left: 0;
  transform: rotate(180deg);
  padding-right: 5px;
}

.DeamtBasicDetailsSection .sortSelect__control {
  border-radius: 7px;
  padding: 4px 10px;
  border: 1px solid #E3E2E2 !important;
  outline: none !important;
  box-shadow: none !important;
  background-color: transparent;
}

.DeamtBasicDetailsSection .sortSelect__indicator {
  padding-right: 3px;
  color: #000;
}

.Nsdlcsdl-modal-width .sortSelect__control {
  border-radius: 7px;
  padding: 4px 10px;
  border: 1px solid #E3E2E2 !important;
  outline: none !important;
  box-shadow: none !important;
  background-color: #fff;
}

.Nsdlcsdl-modal-width .sortSelect__indicator {
  padding-right: 3px;
  color: #000;
}

.dg-layout .custom-input .srv-validation-message {
  font-size: 12px;
  position: absolute;
  bottom: 0px;
}

.dg-layout .accordion-content .srv-validation-message {
  position: absolute;
}

.dg-layout .custom-input .error {
  font-size: 12px;
  position: absolute;
  bottom: 0px;
}

.dg-layout .accordion-content .error {
  font-size: 12px;
  position: absolute;
  bottom: 0px;
}

.popupmodal2 .ModalHead {
  background-color: #042b62;
  color: #fff;
  font-size: 1.2rem;
  border: 1px solid #042b62 !important;
  font-weight: 600;
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
}

.popupmodal2 .modal-content {
  border-radius: 22px;
}

.Consent_Denied_popup .modal-content {
  padding: 0 2.5rem;
}

/* 
.dg-layout .custom-input #DocName .srv-validation-message {
  position: absolute;
  top: 72px;
} */
.dg-layout .react-datepicker__month .react-datepicker__week:last-child {
  display: none;
}

.animateRMMail {
  animation: marqueemail 6s linear infinite;
}

.animateaddress {
  animation: marquee 6s linear infinite;
}

.FixdgHeader {
  transition: top 0.3s ease;
}
.DGmainHeaderFix{
  position: sticky;
  top: 0px;
  z-index: 10;
  background-color: #fff;
}
.DGsubheaderFix{
  position: sticky;
  top: 47px;
  background: #fff;
  z-index: 100;
  height: 123px;
  box-shadow: 0 2px 1px 0 rgb(54 54 54 / 15%);
  border-bottom-left-radius: 17px;
  border-bottom-right-radius: 17px;
  z-index: 10;
  transition: top 0.3s ease;
}
.DGsubheaderFixasset{
  position: sticky;
  top: 47px;
  background: #fff;
  z-index: 100;
  height: 123px;
  box-shadow: 0 2px 1px 0 rgb(54 54 54 / 15%);
  border-bottom-left-radius: 17px;
  border-bottom-right-radius: 17px;
  z-index: 10;
  transition: top 0.3s ease;
}
.DGsubheaderFix .assetsLiab{
  padding-top: 10px;
}
.data-gathering {
  transition: opacity 0.5s ease-in-out;
}


@keyframes marquee {
  0% {
    text-indent: 5%;
  }

  100% {
    text-indent: -100%
  }
}

@keyframes marqueemail {
  0% {
    text-indent: 5%;
  }

  100% {
    text-indent: -100%
  }
}
@keyframes fadeIn {
  from {
    opacity: .5;
  }
  to {
    opacity: 1;
  }
}
.data-gathering .navigation-icon{
  margin-left: 2rem !important;
}
.fade-in {
  animation: fadeIn 1s ease-in-out;
  transition: all .3s;
}
.UAE-contact .color-blue{
  color: #042b62 ;
}
.UAE-contact .color-blue {
  font-size: 28px ;
  font-weight: 500 ;
  color: #042b62 ;
}
.UAE-contact .icon-list li.address:before {
  content: '' ;
  background: url(https://static.fintoo.in/static/userflow/img/icons/location.png) no-repeat center ;
  background-size: 20px ;
  width: 20px ;
  height: 20px ;
  display: block ;
  position: absolute ;
  left: 0 ;
}
.UAE-contact .icon-list li.call:before {
  content: '';
  background: url(https://static.fintoo.in/static/userflow/img/icons/call.png) no-repeat center;
  background-size: 20px;
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  left: 0;
}
.UAE-contact .icon-list li.mail:before {
  content: '';
  background: url(https://static.fintoo.in/static/userflow/img/icons/mail.png) no-repeat center;
  background-size: 20px;
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  left: 0;
}
.UAE-contact .icon-list li.hours:before {
  content: '';
  background: url(https://static.fintoo.in/static/userflow/img/icons/hours.png) no-repeat center;
  background-size: 20px;
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  left: 0;
}
.UAE-contact .icon-list li {
  position: relative;
  margin-bottom: 20px;
  padding-left: 30px;
  font-size: 14px;
}
.UAE-contact .icon-list li {
  position: relative;
  margin-bottom: 20px;
  padding-left: 30px;
  font-size: 14px;
}
.UAE-contact .color-light-green {
  color: #8bc53f;
  font-weight: bold;
  white-space: nowrap;
}
@media only screen and (max-width: 600px){
  .UAE-contact .RowReverse{
    display: flex;
    flex-direction: column-reverse;
  }
  .UAE-contact .page-header2{
    margin-top: 2rem;
  }
}
.Consent_Denied_popup .modal-content {
  padding: 0 2.5rem;
}