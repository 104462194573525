.OurFeatureSection {
    padding: 5rem;
}

.OurFeaturestitle {
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
    color: #042b62;

}

.OurFeatureSection .FeatureView {
    padding-top: 3rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 3rem;
    grid-row-gap: 3rem;
}

.OurFeatureSection .FeatureView .FeatureCard {
    padding: 2rem;
    display: flex;
    gap: 1rem;
    border-radius: 20px;
    border: 1px solid #191A23;
    box-shadow: 0px 5px 0px 0px #191A23;
    /* cursor: pointer; */
}

.OurFeatureSection .FeatureCardTitle {
    font-size: 1.3rem;
    color: #042b62;
    font-weight: bold;
}

.OurFeatureSection .FeatureCardDes {
    font-size: 1rem;
    color: #042b62;
    padding: 1rem 0;
}

@media only screen and (max-width: 600px) {
    .OurFeaturestitle {
        font-size: 1.5rem;
    }
    .OurFeatureSection{
        padding: 2rem;
    }
    .OurFeatureSection .FeatureView{
        grid-template-columns: 1fr;
        place-content: center;
        /* place-items: center; */
    }
    .OurFeatureSection .FeatureCardTitle{
        font-size: 1rem;
    }
    .OurFeatureSection .FeatureCardDes{
        font-size: .9rem;
    }
}