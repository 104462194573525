.internationalequityFeaturesSection{
    margin-bottom: 3rem;
}
.internationalequityFeaturesSection .FeaturesSectionTxt {
    text-align: center;
    color: #042b62;
    font-size: 2rem;
    font-weight: 500;
} 

.internationalequityFeaturesSection .CardView {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 2.5rem;
    grid-row-gap: 2.5rem;
    margin: 1rem 0;
}

.internationalequityFeaturesSection .cardBox {
    border: 1px solid #191A23;
    background: #FFF;
    box-shadow: 0px 5px 0px 0px #191A23;
    border-radius: 45px;
    padding: 1rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.internationalequityFeaturesSection .cardBox .textSection {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0rem 2rem;
}

.internationalequityFeaturesSection .cardBox .cardboxtitle {
    font-size: 1.4rem;
    font-weight: 500;
    color: #042b62;
}

.internationalequityFeaturesSection .cardBox .cardboxdes {
    padding: 1rem 0;
    color: #4A4A4A;
    font-weight: 400;
    margin-bottom: 15px;
}

.internationalequityFeaturesSection .cardBox .learnMore {
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
}
.internationalequityFeaturesSection .cardBoximg{
    width: 100px;
}

@media only screen and (max-width: 600px){
    .internationalequityFeaturesSection .CardView{
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
    }
    .internationalequityFeaturesSection .cardBox{
        padding: 1rem;
        height: 300px;
    }
    .internationalequityFeaturesSection .FeaturesSectionTxt{
        font-size: 1.5rem;
    }
}