
.bgfamily{
    background: url(https://static.fintoo.in/static/assets/img/bg/form-bg-family.svg) no-repeat bottom right !important;
}
.bgincomeexpense{
    background: url(https://static.fintoo.in/static/assets/img/bg/form-bg-expenses.svg) no-repeat bottom right !important;
}
.bgGoals{
    background: url(https://static.fintoo.in/static/assets/img/bg/form-bg-goals.svg) no-repeat bottom right !important;
}
.bgAssetLib{
    background: url(https://static.fintoo.in/static/assets/img/bg/form-bg-assets.svg) no-repeat bottom right !important;
}
.bgInsurance{
    background: url(https://static.fintoo.in/static/assets/img/bg/form-bg-assets.svg) no-repeat bottom right !important;
}
/* .bgfamily .selected{
border: 2px solid;
} */
.HideSHowicon{
    color: #042b62;
    font-size: 20px;
    cursor: pointer;
    font-weight: 700;
}
.moneyjarcontainer{
    padding-right: 30px;
    z-index: 1;
    position: fixed;
    top: 255px;
    right: 0;
    text-align: center;
}
@media (max-width: 992px){
    .moneyjarcontainer{
        padding-right: 30px;
        z-index: 0;
        position: static;
        right: 0;
        text-align: center;
    } 
}