.Ourfeature_section {
    /* background-image: url('./Bg_Cirlcle.svg'); */
    background-repeat: no-repeat;
    background-size: 500;
    background-position: center center;
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem;
    height: 100%;
    /* padding-bottom: 5rem; */

}

.Ourfeature_section .ourfeaturesone {
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: 2;
    grid-column-end: 3;
    color: #fff;
    font-size: 3rem;
    font-weight: bold;
    background-color: #000;
    border-radius: 20px;
    display: grid;
    place-items: unset;
    place-content: center start;
    padding: 0 4rem;
}

.Ourfeature_section .FeatureCard {
    border-radius: 22px;
    background: #fff;
    box-shadow: 0px 13px 100px 0px rgba(199, 199, 199, 0.25);
    padding: 2rem;
}
.Ourfeature_section .FeatureCard img{
    width: 150px;
}
.Ourfeature_section .FeatureCard .featuretitle{
    font-size: 1.2rem;
    font-weight: bold;
    padding: 10px 0;
}

@media only screen and (max-width: 600px) {
    .Ourfeature_section{
        display: grid;
        grid-template-rows : 1fr;
        grid-template-columns : 1fr;
    }
    .Ourfeature_section .ourfeaturesone{
        font-size: 1.5rem;
        padding: 4rem;
    }
    .Ourfeature_section .FeatureCard .featuretitle{
        text-align: center;
    }
    .Ourfeature_section .FeatureCard .featureDesc{
        text-align: center;
    }
    .Ourfeature_section .ourfeaturesone{
        grid-row-start: unset;
        grid-column-start: unset;
        grid-row-end: unset;
        grid-column-end: unset;
    }
}