.faqtitle{
    font-size: 2rem;
    color: #042b62;
    text-align: center;
    font-weight: 500;
    padding-bottom: 3rem;
}
.accordianitem {
    height: 100%;
    border-top: 1px solid #D0D0D0;
}
.accordian-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0rem;
    border-radius: 5px 5px 5px 5px;
    /* box-shadow: -1px 7px 15px 0px rgb(0 0 0 / 7%); */
    cursor: pointer;
    font-size: 1.123rem;
    font-weight: 600;
}
.boldtxt{
    font-weight: 600;
    color: #042b62;
    font-size: 1.1rem;
}
.accordian-content {
    background-color: rgba(255, 255, 255, 0);
    padding: 0 0rem;
    /* margin: 0.5rem 0; */
    border-radius: .2em;
    height: 95px;
    overflow: hidden;
    color: #666666;
    transition: height ease 0.2s;

    font-size: 1rem;
    font-weight: 600;
    line-height: 24px;
}
.anchor-style {
    text-decoration: none;
    color: #042b62;
}
@media only screen and (max-width: 600px) {
    .faqtitle{
        font-size: 1.5rem;
    }
    .accordian-header{
        font-size: 1rem;
        padding: 1rem 1rem;
    }
    .accordian-content{
        padding: 0 1rem;
        font-size: .9rem;
    }
    .boldtxt{
        font-size: .9rem;
    }
}