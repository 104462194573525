.Teampage {
    width: 100%;
    background: #fff;
    padding-bottom: 2rem;
    /* margin-bottom: 2rem; */
}

.ourTeam {
    width: 60%;
    margin: auto;
    padding-top: 4rem;
}

.Teampage .aboutusText {
    color: #fff;
    text-align: center;
    font-size: 2.2rem;
    font-weight: bold;
}

.Teampage .primargBg {
    position: relative;
    background: url("https://fintooinvest.in/assets/img/inner-wave.svg") center bottom/100% no-repeat #02003c;
    padding: 100px 0;
}

.teamSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.teamSection .teamRightSection {
    margin-left: 3rem;
}

.teamSection .teammemberProfile img {
    width: 150px;
}

.teamSection .teammemberName {
    font-size: 1.4rem;
    font-weight: bold;
}

.teamSection .designation {
    font-size: 1rem;
    color: #A1A1A1;
    font-weight: 500;
}

@media screen and (max-width: 650px) {
    .primargBg {
        padding: 50px 0;
        background-size: contain;
    }
    .Teampage{
        padding-bottom: 0;
    }
    .Teampage .aboutusText {
        text-align: center;
        /* padding-left: 1rem; */
    }

    .ourTeam {
        width: 100%;
    }

    .teamSection {
        display: grid;
        place-items: center;
        padding: 1rem;
    }
    .teamSection .teamRightSection {
        margin-left: 0rem;
    }
    .teammemberName, .designation{
        text-align: center;
    }
    .teammemberName{
        padding-top: 1rem;
    }
}