.FinancialReport {
  background-color: #e8f6fc;
  padding: 1rem;
  display: grid;
 place-items: center;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .FinancialReport {
    background-color: #e8f6fc;
    padding: 1rem;
    display: block;
  }
}
