.wrapper {
  display: flex;
  background: #ffff;
  flex-direction: column!important;
}
.navbar {
  width: 100%;
  text-align: center;
  background: #fff;
  padding: 1rem;
  /* border-top-left-radius: 29px; */
  /* border-bottom-left-radius: 29px; */
  border-right: 1px solid #eeee;
  overflow-y: auto;
}
.navbar svg {
  color: #042b62;
  width: 2rem;
  height: 2rem;
}
.menuitem {
  /* display: flex; */
  padding: 1em;
  margin-top: 0rem;
  margin-left: rem;
  margin-bottom: 0rem;
}
.menutext {
  font-size: 1rem;
  padding-top: 0.3em;
  font-weight: 600;
  margin-left: 0.5em;
  /* width: 100%; */
  cursor: pointer;
  color: #9b9b9b!important;
  white-space: nowrap;
}
.menuitem img {
  opacity: .2;
}
.menutext:hover {
  color: #042b62!important;
}
.activeMenu .menutext {
  color: #042b62!important;
}
.activeMenu img {
  opacity: 1;
}
.menuitem a {
  outline: none;
  color: #9b9b9b;
}

.menuitem .active {
  color: #042b62 !important;
}

.navbar .menutext:first-child {
  color: #042b62;
}
.menutext:hover {
  color: #042b62;
  /* border-right: 1px solid #042b62; */
}
.contentWrapper {
  display: flex;
  width: 100%;
  flex-direction: column !important;
}

.SidebarBox {
  display: flex;
  flex-direction: row;
}

@media (min-width: 768px) {
  .contentWrapper {
    width: 87%;
  }
  .wrapper {
    flex-direction: row!important;
  }
  .navbar {
    min-height: 100vh;
  }
  .SidebarBox {
    display: block;
  } 
}
.menuLogo {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.mobileHumburger img {
  width: 20px;
}


.navbar .RmBox {
  margin-top: 3rem;
  width: 100%;
  text-align: left;
  margin-left: 1rem;
}
.navbar .RmBox .RmImg {
  margin-bottom: 0rem;
}
.navbar .RmBox .RmImg img {
  width: 45px;
  border-radius: 50%;
}
.navbar .RmBox .RmProfile {
  color: #a1a1a1;
  font-weight: 600;
  font-size: 0.8rem;
  padding-top: 0.3rem;
}

.navbar .RmBox .RmName {
  color: #015ea1;
  font-weight: 700;
  font-size: 1rem;
  width: 121px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  /* white-space: pre-wrap; */
  line-break: anywhere;
}
.navbar .RmBox .MailTxt {
  color: #a1a1a1;
  font-size: 0.8rem;
  font-weight: 600;
  padding-left: 0.3rem;
}
.navbar .RmBox .Rminfo {
  color: #015ea1;
  font-weight: 700;
  font-size: 0.9rem;
  width: 121px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  /* white-space: pre-wrap; */
  line-break: anywhere;
}
.navbar .RmBox .bookDemo button {
  padding: 6px;
  /* padding-left: 10px; */
  /* padding-left: 10px;/ */
  background-color: #015ea1;
  color: #fff;
  font-weight: 700;
  font-size: 0.7rem;
  border-radius: 20px;
  margin-top: 1rem;
  border: none;
  white-space: nowrap;
  width: 9rem;
  text-transform: uppercase;
  outline: none;
}
.ChatBot {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 10000;
  margin: 12px;
  width: 62px;
  background-color: #fff;
  height: 62px;
  border-radius: 10px;
  box-shadow: 0 2px 18px 0 rgb(0 0 0 / 30%);
}
.ChatBotRefresh {
  background-color: #fff;
  position: fixed;
  bottom: 7rem;
  right: 2rem;
  border-radius: 50%;
  z-index: 100009;
}
.ChatBotRefresh img {
  width: 50px;
}
.ChatBotClose {
  background-color: #fff;
  position: fixed;
  bottom: 2.6rem;
  right: 2rem;
  border-radius: 10px;
  z-index: 100009;
}
.ChatBotClose img {
  width: 50px;
  cursor: pointer;
}
.ChatBot img {
  width: 100%;
  margin: 0;
  cursor: pointer;
}
.chatBotframe {
  position: fixed;
  bottom: 2rem;
  right: 7rem;
  width: 25rem;
  border-radius: 20px;
  z-index: 10009;
}
.overlay {
  z-index: 10008;
  background: rgba(0, 0, 0, 0.7);
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
}
.chatBotframe iframe {
  border-radius: 10px;
  /* padding: 1rem; */
}
.chatButton {
  display: grid;
}

.activeMenu {
  color: #042b62 !important;
}

@media only screen and (min-width: 768px) {
  .navbar {
    width: 13%;
  }
  .container {
    min-height: 110vh;
    /* padding-left: 5rem;
        padding-right: 5rem; */
    min-width: initial;
  }
}
@media only screen and (max-width: 768px) {
  
  .ChatBot {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    z-index: 100009;
  }
  .chatBotframe {
    width: 100%;
    height: 100%;
    bottom: 0rem;
    right: 0rem;
    z-index: 100009;
  }

  .chatBotframe iframe {
    height: 100%;
  }
  .ChatBotClose {
    right: 1rem;
    z-index: 1000009;
    top: 0.5rem;
    height: 0%;
  }
  .ChatBotClose img {
    width: 40px;
  }
  .ChatBotRefresh {
    right: 1rem;
    top: 3rem;
    height: 0%;
    z-index: 1000009;
  }
  .ChatBotRefresh img {
    width: 40px;
  }
  
  .contentWrapper .navbar hr {
    display: none;
  }
  .contentWrapper .mobile-nav__sidebar {
    position: static;
  }
  .contentWrapper .navbar .SidebarBox {
    display: flex;
    border-bottom: 1px solid #d8d8d8;
    background: #fff;
    border-radius: 20px;
    overflow-x: scroll;
  }
  .contentWrapper .menuitem {
    display: flex;
    padding: 1em;
    margin-top: 0rem;
    /* margin-left: 1rem; */
    margin-bottom: 0rem;
    border-bottom: none;
    outline: none;
  }
  .contentWrapper .mobile-nav__sidebar .mobile-nav__sidebar-box {
    width: 100%;
    height: 100%;
  }
  .contentWrapper .menulink.Inactive {
    color: #000;
  }
  .contentWrapper .menulink {
    padding-left: 1rem;
  }
  .contentWrapper .menulink:first-child {
    padding-left: 0rem;
  }
  
}
