.Loanform {
    display: flex;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0px 0px 60px 30px rgba(0, 0, 0, 0.03);
    border-radius: 10px;
}

.leftSection {
    flex: 1;
    background-color: #12223c;
    color: white;
    padding: 40px;
    border-radius: 14px;
}

.leftSection h2 {
    font-size: 2rem;
    font-weight: bold;
}

.uspList {
    list-style: none;
    padding: 0;
    margin-top: 2rem;
}

.uspItem {
    display: flex;
    align-items: center;
    margin-bottom: 3rem;
}

.iconWrapper {
    margin-right: 0.5rem;
}

.uspText {
    font-size: 1rem;
    color: #fff;
    font-weight: 600;
}

.rightSection {
    flex: 2;
    padding: 40px 40px 0 40px;
    /* background-color: #f7f8fa; */
    border-radius: 10px;
}

.rightSection .loantxt {
    font-size: 1rem;
    font-weight: bold;
    color: #011C2A;
}

.loanType {
    display: flex;
    /* justify-content: space-between; */
    gap: 1rem;
    padding: 1rem 0;
}

.customRadio {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
}

.customRadio input[type="radio"] {
    opacity: 0;
    position: absolute;
}

.radioIcon {
    width: 20px;
    height: 20px;
    background: url('./uncheck.svg') no-repeat center center;
    background-size: contain;
    margin-right: 0.5rem;
    transition: background-image 0.3s ease;
}

.customRadio input[type="radio"]:checked+.radioIcon {
    background-image: url('./check.svg');
}

.radioText {
    font-size: 16px;
    color: black;
}

.Loanform .formGroup {
    margin-bottom: 20px;
    margin-top: 1rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;

}

.Loanform .formGroup input {}

.Loanform .formGroup span {
    color: red;
    font-size: 0.9rem;
}

.Loanform h4 {
    color: #8D8D8D;
    font-size: .9rem;
    font-weight: 500;
    padding: 2rem 0;
}

.loanPartners {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
}

.partner,
.partnerSelected {
    display: inline-block;
    padding: 1rem;
    border: 2px solid transparent;
    box-shadow: 0px 13px 100px 0px rgba(199, 199, 199, 0.25);
    border-radius: 8px;
    transition: border-color 0.3s ease;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
}

.partner:hover {
    border-color: #042b62;
}

.partnerSelected {
    border-color: #042b62;
}

.Loanform img {
   width: 100%;
}

.placeholder {
    width: 80px;
    height: 60px;
    background-color: #ddd;
}

.submitButton {
    width: 100%;
    padding: 15px;
    margin: 1rem 0;
    background-color: #042b62;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1.2rem;
    cursor: pointer;
    width: 200px;
    font-weight: 600;
}

.submitButton:hover {
    background-color: #042b62;
}

.Loanform .disclaimer {
    font-size: 0.8rem;
    color: #666;
    margin-top: 10px;
    text-align: center;
}
@media only screen and (max-width: 600px) {
    .Loanform{
        display: grid;
    }
    .leftSection{
        padding: 2rem;
    }
    .leftSection h2{
        font-size: 1rem;
    }
    .loanPartners {
        /* display: grid; */
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, 1fr);
        /* grid-column-gap: 1rem; */
        /* grid-row-gap: 1rem; */
    }
    .uspItem{
        margin-bottom: 2rem;
    }
    .rightSection{
        padding: 10px 0;
    }
    .radioText{
        font-size: .7rem;
    }
}