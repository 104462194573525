.ITRCards {
  background-color: #0000;
  padding: 4rem;
}
.ITRCardssectioncontainer {
  width: 80%;
  margin: auto;
  position: relative;
  z-index: 10;
}
.ITRCardssectioncontainer h2 {
  font-size: 3rem;
  font-weight: 700;
}
.ITRplanCards {
  display: grid;
  grid-gap: 2rem;
  margin: 2rem 0;
}
.ITRplanCards .Plancards{
  background-color: #ffff;
  border-radius: 12px;
  /* width: max-content; */
  padding: 1rem;
  margin: 3rem 0;
  -webkit-box-shadow: -2px 4px 15px 6px rgba(229,229,229,1);
  -moz-box-shadow: -2px 4px 15px 6px rgba(229,229,229,1);
  box-shadow: -2px 4px 15px 6px rgba(229,229,229,1);
  height: max-content;
}
.mostPopular{
  position: relative;
  top: -2rem;
  background-color: #ffff;
  border-radius: 12px;
  /* width: max-content; */
  padding: 3rem 1rem;
  padding-bottom: 1rem;
  margin-top: 2rem;
  -webkit-box-shadow: -2px 4px 15px 6px rgba(229,229,229,1);
  -moz-box-shadow: -2px 4px 15px 6px rgba(229,229,229,1);
  box-shadow: -2px 4px 15px 6px rgba(229,229,229,1);
  height: max-content;
}
.borderBtm{
  border-bottom: .8px solid #e7e7e7;
  padding-top: 1rem;
}
.popularTag{
  position: absolute;
  top: -.7rem;
  left: 3rem;
  background-color: #042b62;
  color: #ffff;
  font-size: .8rem;
  padding: 4px 1rem;
  font-weight: 600;
  border-radius: 5px;
}
.plantype{
  font-size: 1.3rem;
  max-width: 200px;
  font-weight: 700;
  margin-top: 1rem;
  white-space:pre-wrap;
}
.Premium{
  color: #042b62;
  font-size: .8rem;
}
.PlanPrice{
  font-size: 1.5rem;
  font-weight: 700;
}
.PlanPrice sup{
  font-size: .7rem;
  font-weight: 600;
}
.PlanPrice span{
  text-decoration: line-through;
}
.PlanFeatures{
  margin-top: 2rem;
  padding: 0rem .6rem;
  height: 400px;
}
.PlanFeatures div{
padding: .6rem 0rem;
font-size: .9rem;
font-weight: 600;
max-width: 300px;
white-space: pre-wrap;
}
.PlanFeatures div .Check svg{
  /* background-color: #f0fbff; */
  color: #042b62;
  font-size: 1.6rem;
}
.PlanFeatures  .Unheck {
  color: #cacaca ;
}
.PlanFeatures  .Unheck span svg{
  /* background-color: #f0fbff; */
  color: #042b62;
  font-size: 1.6rem;
}
.PlanBuy{
  margin-top: 2rem;
  text-align: center;
}
.PlanBuy button{
  width: 100%;
  outline: none;
  padding: 6px;
  border: 1px solid #042b62;
  background-color: #042b62;
  border-radius: 10px;
  color: #fff;
  font-weight: 700;
}
.PlanMoreDetails{
  text-align: center;
  margin-top: 1rem;
  color: #042b62;
  font-weight: 600;
}
.PlanMoreDetails a{
  color: #042b62;
}
.PlanMoreDetails svg {
  font-size: 1.3rem;
}
@media only screen and (max-width: 850px) {
  .ITRCardssectioncontainer {
    width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .ITRCard {
    padding: 1rem;
  }
  .ITRCardssectioncontainer {
    display: grid;
    z-index: 10;
    width: 100%;
  }
}

/* Screen larger than 600px? 2 column */
@media (min-width: 600px) {
  .ITRplanCards {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Screen larger than 900px? 3 columns */
@media (min-width: 900px) {
  .ITRplanCards {
    grid-template-columns: repeat(4, 1fr);
  }
}
