.investsection{
    background-color: #E8F6FC;
}
.featured-in-xuiisis8 .slick-arrow::before {
    display: none;
}
.featured-in-xuiisis8 .slick-arrow i {
    font-size: 25px;
    color: #000;
}
/* .btnopt::before {
    display: none;
} */