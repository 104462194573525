.appointment-section{
    padding-bottom: 5rem;
}

.SectionTitle {
    font-size: 2.9rem;
    color: #042b62;
    font-weight: 600;
}

.appointment-section-container .appointment-section-container-title {
    font-size: 55px;
    font-weight: 500;
    font-size: 2.5rem;
    width: 72%;
}

.appointment-section-container1 {
    display: block;
}

.appointment-section-text {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 1.3rem;

}

.ITR-appointment-section-text {
    /* justify-content: flex-start; */
    padding: 14rem 0;
    display: grid;
    place-items: center;
}

.appointment-section-iframe {
    display: flex;
    align-items: center;
}

.ITR-Appointment {
    padding: 0 9rem;
}

.appointment-section-sub-text {
    /* font-size: 2rem; */
    color: #042b62;
    font-weight: 500;
    font-size: 3rem;
}

.detailContainer {
    border: 1px solid #B2B2B2;
    border-radius: 10px;
    box-shadow: 0px 49px 100px 0px rgba(207, 207, 207, 0.25);
    /* aspect-ratio: 1; */
}

.logoContainer {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    margin: 0 auto 1rem auto;
    border-bottom: 1px solid #B2B2B2;
}

.inputDetailsContainer {
   padding:3rem 5rem
   
}

.inputElemTitle {
    font-size: 1.3rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.inputElemSubTitle {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: #99A1B7;
}

.maskedNo {
    font-size: 1.4rem;
    font-weight: 600;
    color: #042b62;
}

.inputElemContainer {
    margin-bottom: 1rem;
}

.inputElem {
    width: 100%;
    /* height: 50px; */
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px solid #B2B2B2;
    border-radius: 5px;
    font-size: 1rem;
    /* margin: 1.5rem 0px .5rem 0; */
}

.inputElemLabel {
    font-size: 1.2rem;
    font-weight: 700;
}

.btnElem {
    color: #fff;
    background-color: #042b62;
    padding: 10px 40px;
    border: none;
    border-radius: 30px;
    margin: auto;
    cursor: pointer;
    width: fit-content;
    margin: auto;
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
}

.otpfields {
    margin: 1.3rem 0;
    display: flex;
    justify-content: space-between;

}

.otpfields input[type="text"] {
    width: 60px;
    height: 60px;
    border: 1px solid #ffff !important;
    -webkit-appearance: none;
    box-shadow: 0px 0px 2px 0px #b5afa1b8;
    -webkit-box-shadow: 0px 0px 2px 0px #b5afa1b8;
    border-radius: 6px;
    margin-left: 1.6rem;
    text-align: center;
    color: #000;
    font-size: 1.2rem;
    /* padding-left: 1rem; */
    background: transparent;
    font-weight: 600;
}

.otpfields input[type="text"]:first-child {
    margin-left: 0rem;
}

.otpfields input[type="text"]:focus {
    border-color: #042b62 !important;
    /* Warna orange saat focus */
}

.terms input[type="checkbox"] {
    cursor: pointer;
}

.termsSection {
    margin-bottom: 1rem;
}

.termsSection label {
    font-size: .9rem;
    color: rgb(158, 158, 158);
    cursor: pointer;
}

.termsSection .termsSectionTxt {
    font-size: 1rem;
}

/* ------------------------------------------------------------------------media query----------------------------------------------------------------- */

@media only screen and (max-width: 850px) {
    .logoContainer {
        width: 90%;
    }

    .inputDetailsContainer {
        width: 90%;
    }

    .otpfields input[type="text"] {
        width: 45px;
        height: 45px;
    }
}

@media only screen and (max-width: 600px) {

    .appointment-section-container {
        display: grid;
        grid-template-columns: 1fr;
    }
    .appointment-section-container .appointment-section-container-title{
        font-size: 1.5rem;
        width: 100%;
    }
    .detailContainer{
        margin-top: 2rem;
    }
    .appointment-section-text {
        /* text-align: center; */
        line-height: 2rem;
        font-size: 1rem;
        padding: .6rem 0;
        font-weight: 400;
    }

    .appointment-section-iframe {
        display: flex;
        align-items: center;
        height: 60vh;
        margin-top: 8rem;
    }

    .ITR-Appointment {
        padding: 0 1.6rem !important;
    }

    .appointment-section-sub-text {
        font-size: 2rem;
        white-space: nowrap;
        padding-bottom: 1rem;
    }

    .ITR-appointment-section-text {
        font-size: 1.5rem;
    }

    .ITR-appointment-section-text {
        padding: 6rem 0 0rem 0;
    }

    .containerDemo .otpfields input[type="text"] {
        width: 50px;
        height: 50px;
    }
    .inputDetailsContainer{
        padding: 2rem;
    }
}