.Mfcalculator {
  padding: 5rem;
}

.Mfcalculator .Mfcalculatortitle {
  font-size: 2rem;
  font-weight: 500;
  text-align: center;
  color: #042b62;
}

.calculatorContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 3rem;
}

.Mfcalculator .switcher {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 2rem;
}

.Mfcalculator .Divider {
  border-right: 1px solid #f3f3f3;
  width: 0;
  /* padding: 0 1rem; */
  /* height: 100px; */
}

.Mfcalculator label {
  cursor: pointer;
  font-size: 1rem;
  margin-right: 2rem;
  position: relative;
  font-weight: 500;
}

.Mfcalculator input[type="radio"] {
  appearance: none;
  background-color: #fff;
  margin-right: 0.5rem;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #042b62;
  outline: none;
  cursor: pointer;
}

.Mfcalculator input[type="radio"]:checked {
  background-color: #042b62;
}

.Mfcalculator label::before {
  content: '';
  display: inline-block;
  position: absolute;
  top: -0.5rem;
  left: -0.8rem;
}

.Mfcalculator .sliderContainer {
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  /* margin-bottom: 2rem; */
}

.Mfcalculator .labelRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.Mfcalculator .labelRow span {
  font-size: 1rem;
  font-weight: 600;
  color: #808080;
}

.Mfcalculator .inputWithUnit {
  /* background-color: #042b629c; */
  border: 1px solid #042b62;
  border-radius: 6px;
  width: 131px;
  padding: .5rem 0;
  /* height: 35px; */
  font-size: 1rem;
  color: #424242;
  text-align: center
}

.Mfcalculator .inputNumber {
  background-color: transparent;
  border: none;
  border-radius: 10px;
  font-size: .9rem;
  color: #042b62;
  font-weight: bold;
  text-align: center;
}

.Mfcalculator .inputNumber:focus {
  outline: none;
}

.Mfcalculator .value {
  background-color: #e0f7ff;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  font-weight: bold;
  color: #042b62;
}

.Mfcalculator .slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 8px;
  border-radius: 5px;
  background: #e0e0e0;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.15s ease-in-out;
  margin-top: 2rem;
}

.Mfcalculator .slider:hover {
  opacity: 1;
}
.slider::-webkit-slider-runnable-track {
  width: 100%;
  height: 10px;
  background: lightgray; /* Track color */
  border-radius: 5px;
}
.slider::-moz-range-track {
  width: 100%;
  height: 10px;
  background: lightgray; /* Track color */
  border-radius: 5px;
}

.slider::-ms-track {
  width: 100%;
  height: 10px;
  background: transparent;
  border-color: transparent;
  color: transparent;
  border-radius: 5px;
}
.slider::-webkit-slider-runnable-track {
  background: linear-gradient(to right, #4CAF50, lightgray);
}

.slider::-moz-range-progress {
  background-color: #4CAF50; /* Progress color */
}

.slider::-ms-fill-lower {
  background-color: #4CAF50; /* Progress color */
}
.Mfcalculator .slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #042b62;
  cursor: pointer;
  margin-top: -8px;
}

.Mfcalculator .slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #042b62;
  cursor: pointer;
  margin-top: -8px;
}

.Mfcalculator .rangeLabels {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
  color: #b0b0b0;
  font-size: 1rem;
}

.Mfcalculator .summaryContainer {
  background-color: #fff;
  /* padding: 2rem; */
  border-radius: 15px;
  text-align: center;
}

.Mfcalculator .summaryContainer h2 {
  font-size: 2rem;
  font-weight: bold;
}

.Mfcalculator .totalValue {
  font-size: 2.5rem;
  font-weight: bold;
  color: #042b62;
  margin-top: 1rem;
}

.Mfcalculator .donutChartPlaceholder {
  height: 150px;
  width: 150px;
  margin: 2rem auto;
  border-radius: 50%;
  background-image: conic-gradient(#042b62 60%, #e0f0f8 0);
}

.Mfcalculator .valueBreakdown {
  display: flex;
  justify-content: space-around;
  margin-top: 1.5rem;
  text-align: center;
}

.Mfcalculator .valueBreakdown h4 {
  font-size: 2rem;
  color: #042b62;
  margin-bottom: 0.5rem;
}

.Mfcalculator .valueBreakdown p {
  color: #042b62;
}

.summaryContainer p {
  font-size: 1.2rem;
  font-weight: 500;
}

.Mfcalculator .investNowButton {
  display: flex;
  justify-content: center;
  text-align: center;
  border: 1px solid #042b62;
  font-size: 1rem;
  background: transparent;
  font-weight: 500;
  color: #042b62;
  border-radius: 10px;
  margin: auto;
  padding: 10px 30px;
}

.Mfcalculator .investedValue {
  border-radius: 4px;
  background: #042b62;
  font-size: 1.1rem;
  font-weight: 600;
  padding: .7rem 1.5rem;
  color: #fff;
  width: 213px;
}

.Mfcalculator .estimatedReturns {
  border-radius: 4px;
  background: #042b629c;
  font-size: 1.1rem;
  font-weight: 600;
  color: #fff;
  width: 213px;
  padding: .7rem 2rem;
}

.Mfcalculator .donutContainer {
  margin: 0 auto;
  max-width: 280px;
}

.Mfcalculator input[type=range]::range-track {
  width: 100%;
  height: 20px;
  border-radius: 10px;
  background-color: #eee;
  border: 2px solid #ccc;
}

.Mfcalculator input[type=range]::range-thumb {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: white;
  border: 2px solid #1976D2;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
}

.Mfcalculator input[type=range]::range-progress {
  height: 20px;
  border-radius: 10px 0 0 10px;
  background-color: #2196F3;
  border: 2px solid #1976D2;
}

.Mfcalculator .roundedCircle {
  height: 14px;
  width: 14px;
  border-radius: 50%;
  display: inline-block;
}
.sliderContainer {
  width: 100%;
  padding: 10px 0;
}

.sliderTrack {
  position: relative;
  width: 100%;
  height: 10px;
  background-color: lightgray;
  border-radius: 5px;
  cursor: pointer;
}

.sliderProgress {
  position: absolute;
  height: 100%;
  background-color: #042b62; 
  border-radius: 5px 0 0 5px;
}

.sliderThumb {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  background-color: #042b62; 
  border-radius: 50%;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .Mfcalculator {
    padding: 2rem;
  }

  .Mfcalculator .valueBreakdown {
    display: grid;
  }

  .Mfcalculator .summaryContainer h2 {
    font-size: 1.5rem;
  }

  .Mfcalculator .totalValue {
    font-size: 2rem;
  }

  .Mfcalculator .valueBreakdown p {
    padding-top: .6rem;
  }
}