.AdvisorycardSection {
  background-color: #e8f6fc;
  padding: 4rem;
}

.AdvisorycardSection-section-container {
  display: flex;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  margin: auto;
  position: relative;
  z-index: 10;
  text-align: center;
}

.Advisorycard {
  background-color: #ffffff;
  
  display: flex;
  flex-direction: column;
  padding: 2rem;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #f5f5f5;
  border-radius: 5px 5px 5px 5px;
  width: 40%;
}

.CardPara {
  color: #656565;
  font-size: 1.2rem;
  padding: 1rem;
}

.CardIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 5rem;
  color: #042b62;
}

.CardTitle {
  margin: 0;
  padding: 0;
  color: #000000;
  font-size: 1.7rem;
  font-weight: 500;
}

.btnStart {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  padding-top: 1em;
  flex-direction: column;
}

.AdvisorycardBtn {

  padding-top: 1em;
  flex-direction: column;
  padding: 0.6rem 2rem;
  bottom: 2rem;
  border-radius: 5px;
  background: #fff;
  border: 2px solid #042b62;
  color: #000000;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
}

.Advisorycard:hover {
  background-color: #042b62;
  color: #fff;
  transition: all 0.4s ease;
}

.Advisorycard:hover .CardTitle {
  color: #fff;
  transition: all 0.4s ease;
}

.Advisorycard:hover .AdvisorycardBtn {
  border-color: #fff;
  transition: all 0.4s ease;
  color: blue;
  border-radius: 5px;
  text-decoration: none;
}

.Advisorycard:hover .CardPara {
  color: #fff;
  transition: all 0.4s ease;
}

.Advisorycard:hover .CardIcon {
  color: #fff;
  transition: all 0.4s ease;
}

@media only screen and (max-width : 850px) {
  .Advisorycard {
    width: 50%;
  }
}

@media only screen and (max-width: 768px) {
  .AdvisorycardSection {
    background-color: #e8f6fc;
    padding: 1rem;
    margin-bottom: 2rem;
  }

  .AdvisorycardSection-section-container {
    display: grid;
    margin: 2rem 0rem 2rem 0rem;
  }

  .Advisorycard {
    background-color: #ffffff;
    padding: 1rem;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #f5f5f5;
    border-radius: 5px 5px 5px 5px;
    width: 100%;
    height: 100%;
    margin-bottom: 2rem;
  }

  .CardIcon {
    font-size: 4rem;
  }

  .CardTitle {
    margin: 0;
    padding: 0;
    color: #000000;
    font-size: 1.3rem;
    font-weight: 500;
  }

  .CardPara {
    color: #656565;
    font-size: 1rem;
    padding: 1rem;
  }
}