.sidebar--open {
  width: 250px;
  transition: width 0.3s ease;
}
.page-profile-inside .btn-fintoo-back {
  font-size: 1.7rem;
  color: #042b62;
}
.page-profile-inside .right-menu {
  display: flex;
  justify-content: space-between;
}
.page-profile-inside .right-menu .space-bet {
  padding-right: 0.7em;
}
.page-profile-inside .right-menu .space-bet img {
  padding-top: 0.5em;
  width: 25px;
}
.page-profile-inside .right-menu .space-bet:last-child {
  padding-right: 0;
}
.page-profile-inside .right-menu .space-bet span {
  font-weight: 500;
}
.page-profile-inside .right-menu .space-bet .ProfileName {
  font-size: 0.9em;
  font-weight: 500;
  padding-top: 0.5em;
}
.page-profile-inside .right-menu img {
  width: 28px;
  padding-right: 0.6em;
  cursor: pointer;
}
.page-profile-inside #wrapper {
  padding-left: 2rem;
  padding-right: 2rem;
  /* padding-top: 2rem; */
  padding-bottom: 0;
}
.page-profile-inside .OTpConfirm {
  background: #042b62;
  text-align: center;
  font-weight: bold;
  color: #fff;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  cursor: pointer;
}
.page-profile-inside .NomineeModal .rounded-otp input {
  border: 1px solid #d6d6d6;
  border-radius: 6px;
  font-weight: 600;
  outline: none !important;
  box-shadow: none;
  margin-top: -0.5rem;
}
.page-profile-inside .NomineeModal .modal-content {
  border-radius: 1em;
}
.page-profile-inside .profile-container #content-wrapper {
  padding: 0.7rem;
  flex-grow: 1;
  border-left: 1px solid #eee;
}
.page-profile-inside .profile-container #content-wrapper #header-menu {
  border-bottom: 1px solid #f3f3f3;
}
.page-profile-inside
  .profile-container
  #content-wrapper
  #header-menu
  .ProfilePic {
  width: 28px;
}
.page-profile-inside .profile-container .fintoo-dashboard-logo {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.page-profile-inside .profile-container .fintoo-dashboard-logo img {
  width: 60px;
}
.page-profile-inside .profile-container .profile-menu .profile-menu-list {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.page-profile-inside .profile-container #wrapper-bx {
  background: #fff;
  border-radius: 0.7rem 0.7rem 0 0;
  overflow: hidden;
  /* display: flex; */
  min-height: calc(100vh - 2rem);
}
.page-profile-inside .profile-container #wrapper-bx #sidebar {
  /* text-align: center; */
  width: 8rem;
  color: #fff;
  /* background-color: #042b62; */
}
.page-profile-inside .profile-container #wrapper-bx #sidebar .profile-menu {
  outline: none;
}
.page-profile-inside
  .profile-container
  #wrapper-bx
  #sidebar
  .profile-menu
  .profile-menu-list
  a {
  text-decoration: none;
  /* color: #042b62; */
  color: #9b9b9b;
  font-size: 0.8em;
  font-weight: 600;
}
.page-profile-inside .profile-container #wrapper-bx #content {
  flex-grow: 1;
}
.page-profile-inside .profile-container #header-menu .Profile-Header {
  display: flex;
  justify-content: space-between;
  margin: 1rem;
}
.page-profile-inside
  .profile-container
  #header-menu
  .Profile-Header
  .ProfileBack {
  width: 20px;
}
.page-profile-inside
  .profile-container
  #header-menu
  .Profile-Header
  .hover-dropdown-box {
  width: 290px;
  position: absolute;
  top: 25px;
  z-index: 111;
  color: #000;
  font-weight: 400;
  display: none!important;
  cursor: pointer;
}
.page-profile-inside
  .profile-container
  #header-menu
  .Profile-Header
  .hover-dropdown-box
  .my-arrow {
  width: 10px;
  height: 10px;
  border-top: solid 1px #d1d1d1;
  border-left: solid 1px #d1d1d1;
  position: absolute;
  transform: rotate(45deg);
  right: 40px;
  top: 6px;
  background-color: #fff;
}
.page-profile-inside
  .profile-container
  #header-menu
  .Profile-Header
  .hover-dropdown-box
  .hover-dropdown-content {
  background: #fff;
  border-radius: 10px;
  border: 1px solid #d1d1d1;
  padding: 0px;
  margin-top: 15px;
}
.page-profile-inside
  .profile-container
  #header-menu
  .Profile-Header
  .hover-dropdown-box
  .hover-dropdown-content
  .profile-nm-details {
  padding: 0em;
  background: #e5e5e5;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
}
.page-profile-inside
  .profile-container
  #header-menu
  .Profile-Header
  .hover-dropdown-box
  .hover-dropdown-content
  .profile-nm-details
  .User-details {
  font-size: 0.9em;
  padding: 1px;
  font-weight: 500;
  padding: 0.5em 2em;
}
.page-profile-inside
  .profile-container
  #header-menu
  .Profile-Header
  .hover-dropdown-box
  .hover-dropdown-content
  .profile-nm-details
  .User-details
  .user-mail {
  font-size: 600;
  color: #929292;
  font-weight: 500;
}
.page-profile-inside
  .profile-container
  #header-menu
  .Profile-Header
  .hover-dropdown-box
  .hover-dropdown-content
  .profile-sub-details
  .text-label {
  font-size: 1em;
  color: #000;
  font-weight: 500;
  padding: 1em;
}
.page-profile-inside
  .profile-container
  #header-menu
  .Profile-Header
  .hover-dropdown-box
  .hover-dropdown-content
  .profile-other-details {
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 0em;
}
.page-profile-inside
  .profile-container
  #header-menu
  .Profile-Header
  .hover-dropdown-box
  .hover-dropdown-content
  .profile-other-details
  .profiledata {
  padding: 1em 0.6em 1em 0.6em;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5e5;
}
.page-profile-inside
  .profile-container
  #header-menu
  .Profile-Header
  .hover-dropdown-box
  .hover-dropdown-content
  .profile-other-details
  .profiledata:first-child {
  padding-top: 1.4em;
}
.page-profile-inside
  .profile-container
  #header-menu
  .Profile-Header
  .hover-dropdown-box
  .hover-dropdown-content
  .profile-other-details
  .profiledata:last-child {
  border: none;
}
.page-profile-inside
  .profile-container
  #header-menu
  .Profile-Header
  .hover-dropdown-box
  .hover-dropdown-content
  .profile-other-details
  .profiledata
  a {
  text-decoration: none;
  color: #000;
}

.page-profile-inside
  .profile-container
  #header-menu
  .Profile-Header
  .hover-dropdown-box
  .hover-dropdown-content
  .profile-other-details
  .profiledata
  .textlabel {
  font-size: 0.9em;
  font-weight: 500;
  padding-left: 0.4em;
}
.page-profile-inside
  .profile-container
  #header-menu
  .Profile-Header
  .hover-dropdown-box
  .hover-dropdown-content
  .profile-other-details
  .profiledata
  img {
  width: 35px;
  padding-top: 0;
}
.page-profile-inside
  .profile-container
  #header-menu
  .Profile-Header
  .hover-dropdown-box
  .download-report-1 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.page-profile-inside
  .profile-container
  #header-menu
  .Profile-Header
  .hover-dropdown-box::before {
  content: "";
  position: absolute;
  display: block;
  width: 0px;
  left: 80%;
  top: 5%;
  border: 15px solid transparent;
  border-top: 0;
  border-bottom: 15px solid #e5e5e5;
  transform: translate(-50%, calc(-100% - 5px));
}
.page-profile-inside
  .profile-container
  #header-menu
  .Profile-Header
  .hover-dropdown:hover
  .download-report-box {
  display: block!important;
}
.page-profile-inside
  .profile-container
  #header-menu
  .Profile-Header
  .resultOptionsBtn
  .hover-dropdown-content {
  padding: 0;
}
.page-profile-inside
  .profile-container
  #header-menu
  .Profile-Header
  .resultOptionsBtn
  .download-report-box {
  padding: 0px;
  top: 30px !important;
  left: -26px !important;
  width: 10rem;
}
.page-profile-inside
  .profile-container
  #header-menu
  .Profile-Header
  .resultOptionsBtn
  .custom-dropdown-9
  div {
  font-weight: bold;
  font-size: 0.8rem;
  text-align: center;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  cursor: pointer;
}
.page-profile-inside .profile-container .mobileHumburger {
  display: none;
}
.page-profile-inside .profile-container .ProfileDashboard {
  margin-left: 2rem;
}
.page-profile-inside .profile-container .ProfileDashboard .MobileData {
  display: none;
}
.page-profile-inside .profile-container .ProfileDashboard .RightPanel {
  margin-top: 5rem;
}
.page-profile-inside
  .profile-container
  .ProfileDashboard
  .RightPanel
  .Nominee_Identity
  .option:first-child {
  color: gray;
}
.page-profile-inside
  .profile-container
  .ProfileDashboard
  .mobile-manadate-status,
.page-profile-inside .profile-container .ProfileDashboard .MobileData,
.page-profile-inside .profile-container .ProfileDashboard .mobileNomineeText,
.page-profile-inside .profile-container .ProfileDashboard .mobileNomineeList,
.page-profile-inside .profile-container .ProfileDashboard .mobileOTP,
.page-profile-inside
  .profile-container
  .ProfileDashboard
  .mobile-view-add-member-field,
.page-profile-inside .profile-container .ProfileDashboard .mobileDigiPay {
  display: none;
}
.page-profile-inside .profile-container .ProfileDashboard .desktopView {
  display: block;
}
.page-profile-inside .profile-container .ProfileDashboard .text-label-info {
  line-height: 3px;
}
.page-profile-inside .profile-container .ProfileDashboard .secondTextinfo {
  font-size: 0.8em;
}
.page-profile-inside .profile-container .ProfileDashboard .SIPinfo {
  font-size: 0.8em;
}
.page-profile-inside
  .profile-container
  .ProfileDashboard
  .text-label-info
  .Hrline {
  border-bottom: 1px solid #e5e5e5;
  margin-top: 0.6rem;
}
.page-profile-inside
  .profile-container
  .ProfileDashboard
  .manadte-data
  .Hrline {
  border-bottom: 1px solid #e5e5e5;
  margin-top: 1rem;
}
.page-profile-inside .profile-container .ProfileDashboard .text-label {
  font-weight: 700;
}
.page-profile-inside
  .profile-container
  .ProfileDashboard
  .text-label-info
  .Hrline {
  border-bottom: 1px solid #e5e5e5;
  margin-top: 0.6rem;
}
.page-profile-inside
  .profile-container
  .ProfileDashboard
  .manadte-data
  .Hrline {
  border-bottom: 1px solid #e5e5e5;
  margin-top: 1rem;
}
.page-profile-inside .profile-container .ProfileDashboard .text-label {
  font-weight: 700;
  font-size: 0.9em;
}
.page-profile-inside .profile-container .ProfileDashboard .min-h-screen {
  /* background-color: aqua;
  */
  height: 91.3vh !important;
  overflow-y: scroll;
}
.page-profile-inside .profile-container .ProfileDashboard .h-screen {
  height: 93.3vh;
  /* position: fixed;
  */
}
.page-profile-inside
  .profile-container
  .ProfileDashboard
  .min-h-screen::-webkit-scrollbar {
  display: none;
}
.page-profile-inside .profile-container .ProfileDashboard .nav-item,
.page-profile-inside .profile-container .ProfileDashboard .navbar,
.page-profile-inside .profile-container .ProfileDashboard .navBar {
  /* background-color: #042b62;
  */
  z-index: 10000;
  border-top-right-radius: 12px;
  border-bottom: 1px solid #e5e5e5;
}
.page-profile-inside .profile-container .ProfileDashboard .P-details {
  margin-top: 3rem;
  max-width: 75%;
  line-height: 20px;
}
.page-profile-inside .profile-container .ProfileDashboard .details-fi {
  margin-top: 1rem;
}
.page-profile-inside
  .profile-container
  .ProfileDashboard
  .P-details
  .Text-label {
  color: #a0a0aa;
  white-space: nowrap;
  font-size: 0.8em;
  font-weight: 500;
  padding-bottom: 0.5rem;
}
.addressDetails{
  max-width: 100% !important;
}
.Address-Data{
 white-space: nowrap;
}
.page-profile-inside
.profile-container
  .ProfileDashboard
  .text-label-info
  .text-label {
  font-size: 1em;
  margin-top: 0.6em;
}
.page-profile-inside .profile-container .ProfileDashboard .b-layout .bank-data {
  margin-top: 0.4rem;
}
.page-profile-inside
  .profile-container
  .ProfileDashboard
  .P-details
  .Text-label-Data {
  color: #000;
  font-weight: 600;
  border-bottom: 1px solid #e5e5e5;
  font-size: 0.9em;
}
.page-profile-inside
  .profile-container
  .ProfileDashboard
  .b-layout
  .bank-label {
  color: #a3a3a3;
  font-size: 80%;
  font-weight: 500;
}
.page-profile-inside .profile-container .ProfileDashboard .b-layout .bank-info {
  font-size: 0.9em;
  font-weight: 600;
  color: #000;
}
.page-profile-inside .profile-container .ProfileDashboard .Bank-P-de {
  margin-top: 0rem;
}
.page-profile-inside
  .profile-container
  .ProfileDashboard
  .Bank-P-de
  .ptTableBx {
  padding-top: 2rem;
}
.page-profile-inside
  .profile-container
  .ProfileDashboard
  .nomineeData
  .b-subdetails {
  padding-top: 1em;
  padding-bottom: 1em;
  margin-top: 0rem;
}
.page-profile-inside .profile-container .ProfileDashboard .Add-bank {
  width: 7rem;
  background-color: #042b62;
  border: none;
  padding: 12px;
  color: #fff;
  font-size: 0.9em;
  border-radius: 20px;
}
.page-profile-inside .profile-container .ProfileDashboard .Add-bank a {
  text-decoration: none;
  color: #fff;
  font-weight: 600;
}
.page-profile-inside .profile-container .ProfileDashboard .Bank-details {
  border: 1px solid #d6d6d6;
  border-radius: 11px;
  padding: 1.4rem;
  margin-top: 2rem;
  margin-left: 0.1rem;
}
.page-profile-inside
  .profile-container
  .ProfileDashboard
  .Bank-details
  .b-subdetails {
  margin-top: 1rem;
}
.page-profile-inside
  .profile-container
  .ProfileDashboard
  .Bank-details
  .b-subdetails
  .bank-name {
  margin-left: 2.4rem;
}
.page-profile-inside
  .profile-container
  .ProfileDashboard
  .Bank-details
  .bank-buttons {
  display: flex;
}
.page-profile-inside .profile-container .ProfileDashboard .bank-buttons {
  margin-top: 0.7em;
}
.page-profile-inside
  .profile-container
  .ProfileDashboard
  .bank-buttons
  .Add-manadate {
  width: 7.5rem;
  background-color: #042b62;
  border: none;
  padding: 4px;
  color: #fff;
  font-size: 0.9em;
  border-radius: 20px;
}
.page-profile-inside
  .profile-container
  .ProfileDashboard
  .bank-buttons
  .Add-manadate
  a {
  text-decoration: none;
  font-weight: 600;
  color: #fff;
}
.page-profile-inside
  .profile-container
  .ProfileDashboard
  .bank-buttons
  .bank-List {
  width: 7.5rem;
  background-color: #042b62;
  border: none;
  padding: 4px;
  color: #fff;
  font-size: 0.9em;
  margin-left: 0.7em;
  border-radius: 20px;
}
.page-profile-inside
  .profile-container
  .ProfileDashboard
  .bank-buttons
  .bank-List
  a {
  text-decoration: none;
  font-weight: 600;
  color: #fff;
}
.page-profile-inside .profile-container .ProfileDashboard .flex svg {
  color: #042b62;
}
.page-profile-inside
  .profile-container
  .ProfileDashboard
  .bank-buttons
  .delete-bank
  img {
  width: 25px;
  color: #042b62;
  font-size: 26px;
  cursor: pointer;
  margin-left: 1rem;
}
.page-profile-inside
  .profile-container
  .ProfileDashboard
  .bank-buttons
  .delete-bank
  svg:hover {
  color: #043f58;
}
.page-profile-inside
  .profile-container
  .ProfileDashboard
  .manadte-data
  .Bank-details {
  margin-top: 1rem;
}
.page-profile-inside
  .profile-container
  .ProfileDashboard
  .manadte-data
  .bank-info
  .aprrove-text {
  color: #a3a3a3 !important;
  margin-top: 1.6rem;
  font-size: 1em;
  margin-left: 3em;
}
.page-profile-inside
  .profile-container
  .ProfileDashboard
  .manadte-data
  .d-auto-pay {
  margin-top: 3rem;
}
.page-profile-inside
  .profile-container
  .ProfileDashboard
  .b-subdetails
  .bank-name {
  margin-left: 2rem;
}
.page-profile-inside
  .profile-container
  .ProfileDashboard
  .bank-name
  .bank-data {
  margin-left: 1rem;
  margin-top: 0.5em;
  width: 100%;
}
.page-profile-inside .profile-container .ProfileDashboard .bank-name {
  display: flex;
}
.page-profile-inside .profile-container .ProfileDashboard .bank-logo {
  border: 1px solid #d6d6d6;
  border-radius: 50%;
}
.page-profile-inside .profile-container .ProfileDashboard .bank-logo img {
  width: 2rem;
}
.page-profile-inside .profile-container .ProfileDashboard .LineHr {
  height: 0.5rem;
  font-weight: 600 !important;
}
.page-profile-inside .profile-container .ProfileDashboard .DigitalAutoPay {
  margin-top: 3rem;
}
.page-profile-inside
  .profile-container
  .ProfileDashboard
  .DigitalAutoPay
  .Digital-Pay-amount {
  margin-top: -0.5rem;
  border-bottom: 1px solid #d6d6d6;
}
.page-profile-inside
  .profile-container
  .ProfileDashboard
  .Digital-Pay-amount
  input[type="text"] {
  outline: none;
  background: transparent;
  font-size: 1.1em;
  border: none;
  padding-bottom: 6px;
  font-weight: 700;
}
.page-profile-inside
  .profile-container
  .ProfileDashboard
  .DigitalAutoPay
  .b-layout
  .amt-add {
  width: 100%;
  background-color: #fff;
  border: 1px solid #042b62 !important;
  border: none;
  color: #042b62;
  padding: 5px;
  font-size: 0.8em;
  padding: 1em;
  font-weight: 600;
  border-radius: 20px;
  margin-top: -1rem;
}
.page-profile-inside
  .profile-container
  .ProfileDashboard
  .DigitalAutoPay
  .b-layout
  .amt-add:hover {
  background-color: #042b62;
  border: 1px solid #fff !important;
  color: #fff;
}
.page-profile-inside
  .profile-container
  .ProfileDashboard
  .DigitalAutoPay
  .b-layout
  .amt-add:hover {
  border: 1px solid #fff;
  color: #fff;
  background: #042b62;
}
.page-profile-inside .profile-container .ProfileDashboard .amt-Save {
  width: 10em;
  background-color: #042b62;
  border: none;
  padding: 4px;
  color: #fff;
  font-weight: 600;
  font-size: 0.9em;
  border-radius: 20px;
  margin-top: 2rem;
}
.page-profile-inside .profile-container .ProfileDashboard .amt-Save a {
  text-decoration: none;
  color: #fff f;
}
.page-profile-inside
  .profile-container
  .ProfileDashboard
  .DigitalAutoPay
  .amt-exact {
  width: 100%;
  background-color: #042b62;
  border: none;
  color: #fff;
  padding: 5px;
  font-size: 0.8em;
  padding: 1em;
  font-weight: 600;
  border-radius: 20px;
  margin-top: -1rem;
}
.page-profile-inside
  .profile-container
  .ProfileDashboard
  .manadte-data
  .manadate-text {
  color: #000;
  font-size: 0.9em;
}
.page-profile-inside .profile-container .ProfileDashboard .visuallyhidden {
  display: none;
}
.page-profile-inside .profile-container .ProfileDashboard .wizard-progress {
  list-style: none;
  list-style-image: none;
  margin: 0;
  margin-top: 0px;
  float: left;
  white-space: nowrap;
  margin-left: -2rem;
}
.page-profile-inside .profile-container .ProfileDashboard .wizard-progress li {
  float: left;
  text-align: center;
  position: relative;
  width: 60px;
}
.page-profile-inside
  .profile-container
  .ProfileDashboard
  .wizard-progress
  .step-name {
  display: table-cell;
  vertical-align: bottom;
  width: 40px !important;
  font-size: 14px;
  color: #a0a0a0;
}
.page-profile-inside
  .profile-container
  .ProfileDashboard
  .wizard-progress
  .step-name-wrapper {
  display: table-cell;
  height: 100%;
  vertical-align: bottom;
}
.page-profile-inside
  .profile-container
  .ProfileDashboard
  .wizard-progress
  .step-num {
  font-size: 13px;
  color: #fff;
  font-weight: bold;
  /* border: 1px solid #000;
  */
  border-radius: 55%;
  background: #042b62;
  /* border-radius: 50%;
  */
  width: 19px;
  display: inline-block;
  margin-top: 10px;
}
.page-profile-inside
  .profile-container
  .ProfileDashboard
  .wizard-progress
  .step-num:after {
  content: "";
  display: block;
  background: #e6dddd;
  height: 1px;
  width: 74px;
  position: absolute;
  top: 20px;
  bottom: 41px;
  left: 44px;
}
.page-profile-inside
  .profile-container
  .ProfileDashboard
  .wizard-progress
  li:last-of-type
  .step-num:after {
  display: none;
}
.page-profile-inside
  .profile-container
  .ProfileDashboard
  .wizard-progress
  .active-step
  .step-num {
  background-color: #042b62;
}
.page-profile-inside
  .profile-container
  .ProfileDashboard
  .wizard-progress
  .red-step
  .step-num {
  background-color: #a0a0a0;
}
.page-profile-inside .profile-container .ProfileDashboard .Edit-nominee button {
  width: 7rem;
  background-color: #042b62;
  border: none;
  padding: 4px;
  color: #fff;
  font-size: 0.9em;
  border-radius: 20px;
  font-weight: 600;
}
.page-profile-inside .profile-container .ProfileDashboard .delete-nominee {
  font-size: 15px;
  cursor: pointer;
  padding-left: 1rem;
  padding-top: 0.1em;
}
.page-profile-inside .profile-container .ProfileDashboard .delete-nominee img {
  width: 18px;
}
.page-profile-inside .profile-container .ProfileDashboard .text-nominee {
  line-height: 3px;
}
.page-profile-inside .profile-container .ProfileDashboard .text-nominee span {
  color: #7f7f7f;
  font-size: 0.8em;
}
.page-profile-inside .profile-container .ProfileDashboard .Nominee-name p {
  font-size: 1.2rem;
}
.page-profile-inside .profile-container .ProfileDashboard .nomiee-label {
  color: #a0a0a0;
  font-size: 1em;
  font-weight: 600;
}
.page-profile-inside .profile-container .ProfileDashboard .add-member-field {
  margin-top: 2rem;
}
.page-profile-inside
  .profile-container
  .ProfileDashboard
  .add-member-data
  .add-member-data-field {
  display: flex;
  justify-content: space-between;
}
.page-profile-inside
  .profile-container
  .ProfileDashboard
  .add-member-data
  .amt-Save {
  width: 7rem;
  font-size: 14px;
  margin-top: 0rem;
}
.page-profile-inside
  .profile-container
  .ProfileDashboard
  .add-member-field
  input[type="text"] {
  outline: none;
  border: none;
  border-bottom: 1px solid #d6d6d6;
  background: transparent;
  font-size: 20px;
  height: 1.5rem;
  font-weight: 500;
}
.page-profile-inside
  .profile-container
  .ProfileDashboard
  .add-member-data-field
  .text-label {
  font-size: 18px;
}
.page-profile-inside .profile-container .ProfileDashboard .inputs input {
  width: 50px;
  height: 50px;
  outline: none !important;
  box-shadow: none;
  border: 1px solid #d6d6d6;
  margin-top: -0.5rem;
}
.page-profile-inside
  .profile-container
  .ProfileDashboard
  .add-member-field
  input[type="text"]::-webkit-inner-spin-button,
.page-profile-inside
  .profile-container
  .ProfileDashboard
  input[type="text"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
  outline: none !important;
  box-shadow: none;
}
.page-profile-inside .profile-container .ProfileDashboard .Otp-text {
  width: 100%;
  font-size: 0.9em;
  font-weight: 500;
}
.page-profile-inside .profile-container .ProfileDashboard .rounded-otp input {
  border: 1px solid #d6d6d6;
  border-radius: 6px;
  font-weight: 600;
}
.page-profile-inside .profile-container .ProfileDashboard .Otp-resend-text {
  width: 12rem;
  white-space: nowrap;
  color: #042b62;
  cursor: pointer;
  font-weight: 500;
  /* margin-right: 3rem;
  */
}
.page-profile-inside
  .profile-container
  .ProfileDashboard
  .rounded
  input[type="password"] {
  border: 2px solid #d6d6d6;
  border-radius: 6px;
  height: 36px !important;
  width: 36px !important;
}
.page-profile-inside
  .profile-container
  .ProfileDashboard
  .add-member-field
  .text-label {
  font-size: 1em;
}
.page-profile-inside .profile-container .ProfileDashboard .email-data {
  margin-top: 2rem;
}
.page-profile-inside
  .profile-container
  .ProfileDashboard
  .add-member-data-field
  .email {
  background: transparent;
  border-bottom: 1px solid #d6d6d6;
  background: transparent;
  font-size: 1.2rem;
  height: 1.5rem;
}
.page-profile-inside
  .profile-container
  .ProfileDashboard
  .email-data
  input[type="email"] {
  outline: none;
  border: none;
  border-bottom: 1px solid #d6d6d6;
  background: transparent;
  font-size: 1.2rem;
  height: 1.5rem;
  font-weight: 500;
}
.page-profile-inside .profile-container .ProfileDashboard .Relational-data {
  margin-top: 4rem;
}
.page-profile-inside
  .profile-container
  .ProfileDashboard
  .Relational-data
  .form-select {
  border: none !important;
  border-radius: 0 !important;
  border-bottom: 1px solid #d6d6d6 !important;
  box-shadow: none !important;
}
.page-profile-inside .profile-container .ProfileDashboard .form-select {
  appearance: initial !important;
}
.page-profile-inside .profile-container .ProfileDashboard .form-select:focus {
  border: none !important;
  border-radius: 0 !important;
  border-bottom: 1px solid #d6d6d6 !important;
  background: local !important;
  box-shadow: none !important;
}
.page-profile-inside .profile-container .ProfileDashboard .memeber-logo img {
  width: 40px;
}
.page-profile-inside
  .profile-container
  .ProfileDashboard
  .nav-item
  .text-profile {
  font-size: 16px;
  font-weight: 500;
}
.page-profile-inside .DesktopNomineeName {
  display: block;
}
.page-profile-inside .mobileNomineeName {
  display: none;
}
.page-profile-inside .deleteBankPopup .DeleteBank {
  text-align: center !important;
  font-size: 1.1em;
  font-weight: 600;
}
.page-profile-inside .deleteBankPopup .buttonsDe {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 1em;
}
.page-profile-inside .deleteBankPopup .buttonsDe button {
  margin-left: 1em;
  padding: 5px;
  background-color: #042b62;
  border: 1px solid #042b62;
  border-radius: 20px;
  color: #fff;
  font-weight: 600;
  width: 10rem;
}

.menu-link-182.Inactive{
  color:#9b9b9b;
}
@media only screen and (max-width: 768px) {
  .page-profile-inside .mobileProfileSearch {
    display: none;
  }
  .page-profile-inside .DesktopNomineeName {
    display: none;
  }
  .page-profile-inside .mobileNomineeName {
    display: block;
  }
  .page-profile-inside #wrapper {
    padding-left: 0rem;
    padding-right: 0rem;
    padding-top: 2rem;
    padding-bottom: 0;
  }
  .page-profile-inside #wrapper #content-wrapper {
    padding: 0.7em !important;
  }
  .page-profile-inside .desktopViewBack {
    display: none;
  }
  .page-profile-inside .b-layout {
    margin-top: 1em !important;
  }
  .page-profile-inside .profile-container .mobileHumburger {
    display: block;
  }
  .page-profile-inside .profile-container .ProfileDashboard {
    margin-left: 0rem;
  }
  .page-profile-inside .profile-container .ProfileDashboard .desktopView {
    display: none;
  }
  .page-profile-inside .profile-container .ProfileDashboard .add-member-field {
    display: none;
  }
  .page-profile-inside .profile-container .ProfileDashboard .desktop-auto-pay {
    display: none;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .text-label-info
    .text-label {
    font-size: 0.9em;
    margin-top: 0.6em;
    white-space: nowrap;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .text-label-info
    .Hrline {
    margin-top: 0rem;
    margin-bottom: 0.6em;
  }
  .page-profile-inside .profile-container .ProfileDashboard .P-details {
    max-width: 100%;
    margin-top: 1.6rem;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .P-details
    .text-label {
    font-size: 0.7em;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .P-details
    .Text-label-Data {
    font-size: 0.7em;
  }
  .page-profile-inside .profile-container .ProfileDashboard .ProfileName {
    margin-top: 0.5em;
  }
  .page-profile-inside .profile-container .ProfileDashboard .Add-bank {
    width: 6rem;
    background-color: #042b62;
    border: none;
    padding: 8px;
    color: #fff;
    font-size: 11px;
    border-radius: 20px;
  }
  .page-profile-inside .profile-container .ProfileDashboard .MobileData {
    display: block;
    margin-top: 0rem;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .MobileData
    .Bank-details {
    padding: 1rem;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .MobileData
    .Bank-details
    .bank-info {
    font-weight: 600;
    font-size: 0.8em;
    margin-left: 0.6em;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .MobileData
    .Bank-details
    .BankLogo {
    width: 30px;
    border: 1px solid #a0a0aa;
    border-radius: 50%;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .MobileData
    .Bank-details
    .Add-manadate {
    width: 7rem;
    background-color: #042b62;
    border: none;
    padding: 3px;
    color: #fff;
    font-size: 11px;
    border-radius: 20px;
    margin-top: 1.2em;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .MobileData
    .Bank-details
    .Add-manadate
    a {
    text-decoration: none;
    color: #fff;
    font-size: 11px;
    font-weight: 600;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .MobileData
    .Bank-details
    .AccountDetails {
    margin-left: 2.4em;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .MobileData
    .Bank-details
    .AccountDetails
    .AccountLabel {
    color: #bebebe;
    font-size: 0.8em;
    font-weight: 500;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .MobileData
    .Bank-details
    .AccountDetails
    .AccountNo {
    white-space: nowrap;
    color: #000;
    font-size: 13px;
    font-weight: 700;
  }
  .page-profile-inside .profile-container .ProfileDashboard .Bank-details {
    padding: 1rem;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .Bank-details
    .bank-name {
    margin-top: 0.5em;
    margin-left: 0rem !important;
  }
  .page-profile-inside .profile-container .ProfileDashboard .SIPinfo {
    font-size: 0.7em !important;
  }
  .page-profile-inside .profile-container .ProfileDashboard .bank-data {
    margin-left: 0.5rem !important;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .mobileManadate
    .bank-data {
    margin-left: 0.5rem !important;
    margin-top: 1.8em;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .mobileManadate
    .mobilebankData {
    margin-left: 0 !important;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .mobileManadate
    .mobilebankData:first-child {
    margin-left: 0em !important;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .mobileManadate
    .mobilebankData:nth-child(2) {
    margin-left: 0em !important;
  }
  .page-profile-inside .profile-container .ProfileDashboard .bank-info {
    font-size: 0.8em !important;
    font-weight: 600 !important;
    color: #000;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .bank-info
    .aprrove-text {
    color: #a3a3a3 !important;
    color: #a3a3a3 !important;
    margin-top: 0.6rem !important;
    font-size: 1em !important;
    margin-left: em !important;
    margin-top: 1.3em !important;
    font-weight: 100;
  }
  .page-profile-inside .profile-container .ProfileDashboard .mobileManadate {
    margin-left: 2.4em;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .manadte-data
    .desktop-auto-pay {
    display: none;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .manadte-data
    .mobileDigiPay {
    display: grid;
    place-items: center;
    margin-top: 1em;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .manadte-data
    .mobileDigiPay
    .text-label {
    font-size: 1em;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .manadte-data
    .mobileDigiPay
    .d-auto-pay {
    margin-top: 1rem;
    font-weight: 600;
    font-size: 0.8em;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .manadte-data
    .mobileDigiPay
    .MobilePayValue {
    font-size: 1.6em;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .manadte-data
    .mobileDigiPay
    .MobilePayValue
    input[type="text"] {
    font-weight: 700;
    border: none;
    border-bottom: 1px solid #d6d6d6;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .manadte-data
    .mobileDigiPay
    .amt-add {
    background-color: #fff f;
    border: none;
    width: 6.5em;
    padding: 4px;
    color: #042b62;
    font-weight: 600;
    font-size: 12px;
    padding-left: 0.4em;
    border-radius: 20px;
    margin-left: 0.3em;
    border: 1px solid #042b62;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .manadte-data
    .mobileDigiPay
    .amt-add:hover {
    background-color: #042b62;
    color: #fff;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .manadte-data
    .mobileDigiPay
    .amt-exact {
    background-color: #042b62;
    border: none;
    padding: 4px;
    width: 5em;
    color: #fff;
    font-weight: 600;
    margin-left: 0.3em;
    padding-left: 0.4em;
    font-size: 12px;
    border-radius: 20px;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .manadte-data
    .Bank-details {
    margin-top: 1.6rem;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .desktop-manadate-status {
    display: none;
  }
  .page-profile-inside .profile-container .ProfileDashboard .mobileSave {
    display: flex;
    justify-content: center;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .mobileSave
    .amt-Save {
    width: 8em;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .mobile-manadate-status {
    display: block;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .mobile-manadate-status
    .manadate-data {
    border: 1px solid #d6d6d6;
    border-radius: 6px;
    padding: 1em 1em 0em;
    margin-top: 1rem;
    margin-left: 0.1rem;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .mobile-manadate-status
    .manadate-data
    .text-label {
    font-weight: 600;
    font-size: 12px;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .mobile-manadate-status
    .manadate-data
    #progress {
    padding-left: 0em !important;
    padding-top: 1.5em;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .mobile-manadate-status
    .manadate-data
    #progress
    .node {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    transition: all 1000ms ease;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .mobile-manadate-status
    .manadate-data
    #progress
    .activated {
    box-shadow: 0px 0px 3px 2px rgba(194, 255, 194, 0.8);
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .mobile-manadate-status
    .manadate-data
    #progress
    .divider {
    height: 40px;
    width: 2px;
    margin-left: 4px;
    transition: all 800ms ease;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .mobile-manadate-status
    .manadate-data
    #progress
    li
    p {
    display: inline-block;
    margin-left: 8px;
    color: #a3a3a3;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .mobile-manadate-status
    .manadate-data
    #progress
    li {
    list-style: none;
    line-height: 0px;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .mobile-manadate-status
    .manadate-data
    #progress
    .green {
    background-color: #042b62;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .mobile-manadate-status
    .manadate-data
    #progress
    .grey {
    background-color: rgba(201, 201, 201, 1);
    margin-top: -1em;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .mobile-manadate-status
    .manadate-data
    .manadateDataInfo {
    margin-top: 2.6em;
    line-height: 8px;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .mobile-manadate-status
    .manadate-data
    .manadateDataInfo
    .extra-space {
    margin-top: 1em;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .mobile-manadate-status
    .manadate-data
    .manadateDataInfo
    .text-manadate {
    color: #000;
    font-size: 12px;
    font-weight: 600;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .mobile-manadate-status
    .manadate-data
    .manadateDataInfo
    .text-manadate-val {
    display: inline-block;
    margin-left: 8px;
    font-weight: 500;
    font-size: 12px;
    color: #a3a3a3;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .nomineedata
    .mobileNomineeText {
    display: block;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .nomineedata
    .Edit-nominee
    button {
    width: 6rem;
    background-color: #042b62;
    border: none;
    padding: 8px;
    color: #fff;
    font-size: 11px;
    border-radius: 20px;
    margin-right: 1em;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .nomineedata
    .delete-nominee {
    padding-left: 0rem;
    padding-top: 0;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .nomineedata
    .delete-nominee
    img {
    width: 14px;
  }
  .page-profile-inside .profile-container .ProfileDashboard .mobileNomineeList {
    display: block;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .mobileNomineeList
    .Bank-details {
    border: 1px solid #d6d6d6;
    border-radius: 11px;
    padding: 1rem;
    margin-top: 2rem;
    margin-left: 0.1rem;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .mobileNomineeList
    .Bank-details
    .Nominee-name {
    margin-left: 0.6em;
    font-size: 0.9em !important;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .mobileNomineeList
    .Bank-details
    .bank-info {
    font-size: 0.9em !important;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .mobileNomineeList
    .Bank-details
    .nomiee-label {
    color: #a0a0aa;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .mobileNomineeList
    .Bank-details
    .nomiee-label
    p {
    line-height: 13px;
    font-size: 0.7em;
    font-weight: 500;
    margin-top: 1em;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .mobileNomineeList
    .Bank-details
    .nomineeDOB {
    padding-top: 1em;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .mobile-view-add-member-field {
    display: block;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .mobile-view-add-member-field
    .verifyOTP {
    width: 24px;
    margin-top: 1.5em;
    margin-left: 5.2em;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .mobile-view-add-member-field
    .add-member-data-field {
    margin-top: 1em;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .mobile-view-add-member-field
    .add-member-data-field
    .text-label {
    font-size: 0.9em;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .mobile-view-add-member-field
    .add-member-data-field
    .form-select {
    font-weight: 500;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #e5e5e5;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .mobile-view-add-member-field
    input {
    width: 100%;
    border: none;
    border-bottom: 1px solid #e5e5e5;
    font-weight: 500;
    outline: none;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .mobile-view-add-member-field
    .email {
    width: 100%;
    border: none;
    border-bottom: 1px solid #e5e5e5;
    font-weight: 500;
    outline: none;
    font-size: 1em;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .mobile-view-add-member-field
    .amt-verify {
    width: 6em;
    background-color: #042b62;
    border: none;
    color: #fff;
    font-weight: 600;
    margin-left: 1.4em;
    font-size: 11px;
    border-radius: 20px;
    margin-top: 1.6rem;
    margin-left: 4em;
  }
  .page-profile-inside
    .profile-container
    .ProfileDashboard
    .mobile-view-add-member-field
    input[type="checkbox"] {
    border: 1px solid #042b62 !important;
  }
  .page-profile-inside .delete-nominee {
    font-size: 15px !important;
  }
  .mobileOTP .card {
    border: none !important;
    position: fixed;
    bottom: -2em;
    width: 100%;
    background-color: #fff;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: #000 0px 11px 17px 14px, #000 0px 3px 7px -3px;
  }
  .mobileOTP .card-header {
    color: #000 !important;
    background: #fff;
    border: none;
    /* border-radius: 15px;
    */
    /* background: #3366ff !important;
    */
    text-align: center;
    margin-top: 1em;
  }
  .mobileOTP .card-header p {
    color: #a0a0aa;
    white-space: nowrap;
    font-size: 0.8em;
    font-weight: 500;
    margin-top: 2em;
  }
  .mobileOTP .card-header small {
    color: #000;
    font-size: 1.1em;
    font-weight: 600;
    letter-spacing: 1px;
  }
  .mobileOTP a {
    color: #042b62;
    font-weight: 500;
  }
  .mobileOTP .input-container input {
    width: 40px;
    height: 40px;
    border: 1px solid #a3a3a3;
    border-radius: 4px;
  }
  .mobileOTP .form-control:focus {
    box-shadow: none !important;
    border: 1px solid #042b62 !important;
  }
  .mobileOTP .verify-btn {
    border-radius: 20px !important;
    border: 0px !important;
    font-weight: 500;
    width: 140px;
    background-color: #042b62 !important;
  }
  .mobileOTP {
    display: block;
  }
  .DataFlex {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 66px;
    grid-row-gap: 0px;
  }
  .DataFlex p {
    margin-bottom: 0rem;
  }
  .DataFlex .Datatext {
    font-weight: 500;
    white-space: nowrap;
  }
  .menu-link-182.Inactive {
    color: #000;
}

}

.page-profile-inside .profile-others-data {
  max-height: 200px;
  overflow: auto;
}