.widget-section{
    background-color: white;
}
.widget-section-container {
    padding: 16px;
    box-shadow: -30px 30px 60px 0px rgb(0 0 0 / 7%);
    border-style: solid;
    border-width: 0px 0px 4px 0px;
    border-color: #39A8DF;
    border-radius: 0px 0px 0px 0px;
    transition: all 0.3s;
}
.widget-section-container:nth-child(odd) {
    padding: 16px;
    box-shadow: -30px 30px 60px 0px rgb(0 0 0 / 7%);
    border-style: solid;
    border-width: 0px 0px 4px 0px;
    border-color: #39A8DF;
    border-radius: 0px 0px 0px 0px;
    text-align: right;
}

.widget-item{
    display: flex;
    align-items: center;
}
.widget-item .widget-icons img{
    width: 60px;
}
.widget-Content{
    margin-left: 1rem;
}
.widget-section-container:nth-child(odd) .widget-Content{
    margin-right: 1rem;
}
.widget-section-container:nth-child(odd) .widget-item{
    display: flex;
    justify-content: flex-end;
}
.widget-item-heading {
    margin: 0px 0px 5px 0px;
    padding: 0px;
    color: #39A8DF;
    font-size: 23px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.2px;
}
.widget-content-text{
    color: #666666;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 24px;
}
.widget-section-container:hover .widget-content-text{
    color: #39A8DF;
}
/* --------------------------------------------------------------heading related styles-------------------------------------------------- */
.section-title {
    font-size: 36px;
    font-weight: 500;
    transition: all .3s ease;
    margin: 0;
    margin-bottom: 20px;
    text-align: center;
}

.section-sub-title {
    color: #000000;
    font-size: 16px;
    font-weight: 400;
    margin: -10px 0px 0px 0px;
}

.info-title {
       padding: 0px 0px 0px 0px;
    color: #000000;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.info-content {
    padding: 0px 0px 0px 0px;
    color: #8f8f8f;
    font-size: 1.1rem;
    letter-spacing: 0.5px;
    line-height: 1.6rem;
}

.heading-description {
    padding: 0px 0px 0px 0px;
    color: #5d5d5d;
    font-size: 1.1rem;
    letter-spacing: 0.5px;
    line-height: 1.6rem;
}

@media only screen and (max-width: 768px) {
    .widget-item{
        display: flex;
        align-items: center;
    }
}