.w-rem-22 {
  min-width: 22rem;
}

input:disabled {
  background-color: transparent !important;
}

.add-member-field button:disabled {
  background-color: #999 !important;
}

.react-calendar__tile:disabled {
  background-color: transparent !important;
}

.react-calendar__navigation button:disabled {
  background-color: transparent !important;
}

.react-calendar__month-view__days__day--weekend:disabled {
  color: #e39999;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 1em 0.5em;
}

.react-calendar__tile {
  padding: 10px 0px;
}

.stock-styling .mixed-chart #chart {
  width: 100%;
}

.stock-styling .BSE_line {
  border-bottom: 1px solid #d8dbda;
  padding-top: 1em;
  margin-left: -1rem !important;
}

.stock-styling .NoteStock {
  font-size: 0.8em;
  color: gray;
  max-width: 315px;
}

.stock-styling .Fund_Buy_Now {
  position: sticky;
  top: 63px;
}

.stock-styling .PaginationView {
  overflow: none;
}

.stock-styling .price .apexcharts-datalabel-label,
.stock-styling .price .apexcharts-text {
  font-size: 12px !important;
  text-align: center;
}

.stock-styling .VLine {
  border-right: 1px solid #d8dbda;
  padding-left: 1em;
}

.stock-styling .rec-dot {
  background-color: #d3d3d3;
  box-shadow: 0 0 1px 3px #d3d3d3;
}

.stock-styling .rec-dot_active {
  background-color: #042b62;
  box-shadow: 0 0 1px 3px #042b62;
}

.stock-styling [hidden] {
  display: grid !important;
}

.stock-styling .percent-text {
  color: #000 !important;
}

.stock-styling code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.stock-styling .text-group .percent-text {
  font-size: 28.6364px;
  fill: #000 !important;
}

.stock-styling .Nav {
  display: flex;
  grid-area: nav;
  grid-template-columns: repeat(4, auto);
  align-items: center;
  justify-items: center;
  margin-right: 50px;
}

.stock-styling .Nav a {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  padding-right: 40px;
  padding-left: 30px;
  transition: 0.5s;
  float: right;
  text-decoration: none;
}

.stock-styling .Nav a:hover {
  transform: scale(1.1);
}

.stock-styling .Nav button {
  padding: 10px;
  outline: none;
  border: none;
  font-size: 20px;
  color: #fff;
  font-weight: 600;
  background-color: rgba(255, 0, 0, 0.5);
  box-shadow: 0px 5px 0px 0px rgba(255, 0, 0, 0.25);
  border-radius: 10px;
  cursor: pointer;
  transition: 70ms;
}

.stock-styling .Nav button:active {
  transform: translatex(3px);
  box-shadow: 0px 2px 0px 0px rgba(255, 0, 0, 0.25);
}

.stock-styling .menu-title {
  margin: 0 0 10px;
  font-size: 18px;
  color: #2196f3;
}

.stock-styling .info {
  line-height: 1.7;
}

.stock-styling .menu {
  list-style: none;
  margin: 0;
  padding-left: 0;
  display: flex;
  justify-content: center;
}

.stock-styling .menu a {
  padding: 20px 20px;
  display: block;
  text-decoration: none;
  color: white;
}

.stock-styling .menu__item {
  position: relative;
}

.stock-styling .Mobile_Stock_search {
  display: none;
}

.stock-styling .menu__item:hover>.sub-menu-shadow {
  display: block;
}

.stock-styling .menu .sub-menu-shadow {
  position: absolute;
  display: none;
}

.stock-styling .dropdown-holder {
  position: absolute;
  width: 100%;
  left: 0;
  top: 100%;
}

.stock-styling .dropdown__bg,
.stock-styling .dropdown__arrow {
  position: absolute;
}

.stock-styling .dropdown__arrow {
  width: 0;
  height: 0;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent white;
  top: -20px;
  opacity: 0;
  transition: 0.2s ease;
  pointer-events: none;
}

.stock-styling .dropdown__bg {
  width: 450px;
  height: 400px;
  background-color: white;
  opacity: 0;
  transition: 0.25s ease;
  border-radius: 5px;
  overflow: hidden;
  z-index: -1;
}

.stock-styling .dropdown__bg-bottom {
  background-color: #fafafa;
  position: absolute;
  width: 100%;
  left: 0;
  top: 300px;
  height: 700px;
  transition: 0.3s ease;
}

.stock-styling .dropdown__wrap {
  overflow: hidden;
  position: absolute;
  transition: 0.25s ease;
  z-index: 1;
}

.stock-styling .dropdown__wrap .top-section,
.stock-styling .dropdown__wrap .bottom-section {
  padding: 20px;
}

.stock-styling .dropdown-menu__content {
  position: absolute;
  opacity: 0;
  transition: 0.25s ease;
  min-width: 200px;
}

.stock-styling .dropdown-menu__content ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.stock-styling .dropdown-menu__content a {
  color: inherit;
  text-decoration: none;
  display: block;
  padding: 5px 0;
}

.stock-styling .dropdown-menu__content a:hover {
  color: #333;
}

.stock-styling .dropdown-menu.active .dropdown-menu__content {
  opacity: 1;
}

.stock-styling #product .dropdown-menu__content {
  width: 640px;
}

.stock-styling #developer .dropdown-menu__content {
  width: 400px;
}

.stock-styling .col-2 {
  display: flex;
}

.stock-styling .col-2>ul,
.stock-styling .col-2>div {
  flex: 1 0 150px;
}

.stock-styling #product .col-2 li {
  display: block;
  padding: 20px 20px 10px;
}

.stock-styling #product .col-2 li h3 {
  font-size: 18px;
  margin: 0 0 10px;
  color: turquoise;
}

.stock-styling #product .col-2 li p {
  color: #999;
  margin: 0;
}

.stock-styling .menu-title {
  margin: 0 0 10px;
  font-size: 18px;
  color: #2196f3;
}

.stock-styling .info {
  line-height: 1.7;
}

.stock-styling .Bu_Now_Mobile .Card_Btn button {
  display: none;
}

.stock-styling .TabHeader {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}

@media (max-width: 700px) {
  .stock-styling .Header {
    grid-template-areas: "logo burger" "nav nav";
  }

  .stock-styling .Nav {
    display: grid;
    grid-template-rows: repeat(4, auto);
    grid-template-columns: none;
    grid-row-gap: 20px;
    padding: 30px 0 30px;
    background: #000;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-bottom-left-radius: 10px;
    margin-right: 0px;
    border-bottom-right-radius: 10px;
  }

  .stock-styling .Nav a {
    padding-left: 0;
    text-align: center;
  }

  .stock-styling .Burger {
    display: inline;
  }
}

.stock-styling .NavAnimation-enter {
  opacity: 0;
  transform: scale(0.5);
}

.stock-styling .NavAnimation-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 350ms, transform 350ms;
}

.stock-styling .NavAnimation-exit {
  opacity: 1;
}

.stock-styling .NavAnimation-exit-active {
  opacity: 0;
  transform: scale(0.5);
  transition: opacity 350ms, transform 350ms;
}

.stock-styling .Stock_Details .Path {
  padding-right: 1rem;
}

.stock-styling .MfNameNew {
  word-break: normal;
  width: 27rem;
}

.stock-styling .Other_Detail {
  display: flex;
  justify-content: space-between;
  margin-left: 0rem;
  color: gray;
}

.stock-styling .for_Mob_Share {
  display: none;
}

.stock-styling .mf_subdetails .Mf_Details_info {
  display: flex;
  color: gray;
  font-weight: 300;
  padding-left: 2rem;
}

.stock-styling .Mf_extraInfo {
  display: flex;
  margin-top: -3.5rem;
  margin-left: 10rem;
  height: 5rem;
}

.stock-styling .Mf_extraInfo .Mf_Price_Value {
  display: grid;
  line-height: 20px;
}

.stock-styling .mf_details {
  /* width: 100%;
  */
  height: 100%;
  margin: 1rem;
}

.stock-styling .diff {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.stock-styling .Mobile_Table {
  display: none;
}

.stock-styling .mf_header {
  display: flex;
  justify-content: space-evenly;
  /* margin-top: 10rem;
  */
}

.stock-styling .Mf_Name {
  margin-left: 0rem;
}

.stock-styling .Mf_Name h4 {
  font-size: 25px;
  font-weight: 900;
  position: relative;
  margin-top: -3.4rem;
  margin-left: 4rem;
}

.stock-styling .Mf_Price_Value span:nth-child(2) {
  font-size: 16px;
  font-weight: bold;
}

.stock-styling .stock_icon {
  width: 60px;
  height: 60px;
  background: #fff;
  border-radius: 50%;
}

.stock-styling .Share div {
  line-height: 30px;
}

.stock-styling .latest {
  float: right;
  font-size: 18px;
  font-weight: 200;
}

.stock-styling .latest_value {
  font-size: 24px;
  margin-left: 1rem;
  font-weight: 700;
}

.stock-styling .Rupee {
  width: 15px;
  height: 15px;
  position: relative;
  top: 2rem;
  left: 0rem;
}

.stock-styling .Line {
  /* border-right: 1px solid #c0c3c2;
  */
  margin-top: 0.6rem;
  margin-left: 10px;
}

.stock-styling .linkShare {
  display: flex;
  justify-content: space-evenly;
  /* float: right;
  */
}

.stock-styling .shareIcon {
  width: 20px;
  height: 20px;
  /* padding-right: 2rem;
  */
  /* padding-top: 1rem;
  */
}

.stock-styling .mf_subdetails {
  display: flex;
  justify-content: space-evenly;
  /* text-align: center;
  */
  padding-left: -1rem;
  width: 800px;
  /* border-right: 1px solid #d1d1d1;
  */
}

.stock-styling .toolbar {
  display: flex;
  justify-content: space-between;
}

.stock-styling .Spline {
  width: 62rem;
}

.stock-styling .mf_subdetails .Line {
  border-right: 1px solid #c0c3c2;
  margin-top: 0rem !important;
  margin-left: 10px;
  height: 20px !important;
  margin-right: 0.5rem;
  margin-bottom: 1rem;
}

.stock-styling .tab {
  margin-top: 4rem;
}

.stock-styling .path {
  display: flex;
  width: 90%;
  /* justify-content: space-around;
  */
}

.stock-styling .path span {
  padding-left: 10px;
}

.stock-styling .amnt img {
  vertical-align: middle !important;
}

.stock-styling .risk_Meter {
  display: grid;
  place-items: center;
  width: 450px;
  margin-top: 2rem;
  /* padding-top: 3rem;
  */
  height: 280px;
  border-radius: 20px;
  /* margin-top: -1rem !important;
  */
  color: #000 !important;
  border: 2px solid #fff;
  background: #f7f7f7;
  background: linear-gradient(180deg,
      to top rgba(247, 247, 247, 1) 0%,
      rgba(230, 230, 229, 1) 19%);
}

.stock-styling .MuiTypography-body1 {
  display: block !important;
  font-family: "Red Hat Display" !important;
}

.stock-styling .MuiTypography-body1 [hidden] {
  display: block !important;
}

.stock-styling #Calc_Amt_Range {
  color: gray;
  margin-top: 5rem;
  width: 77%;
}

.stock-styling #Calc_Amt_Range .return {
  margin-top: 3rem;
}

.stock-styling #Calc_Amt_Range .invest {
  margin-top: 3rem;
}

.stock-styling .riskGraph {
  margin-top: 4rem;
}

.stock-styling .RightSide h3 {
  color: gray !important;
}

.stock-styling .Rupeee {
  width: 15px;
  height: 15px;
  position: relative;
  top: -0.1rem;
  left: 0rem;
}

.stock-styling .Total_amnt {
  color: #000;
  font-size: 26px;
  font-weight: 900;
}

.stock-styling .Total_amnt img {
  width: 20px;
  height: 20px;
}

.stock-styling .Total_Val span:first-child {
  color: gray;
  text-align: center;
  /* float: right;
  */
  padding-left: 2.5rem;
}

.stock-styling .SIP_Lumpsum {
  margin-top: 4rem;
  width: 500px;
}

.stock-styling .bloc-tabs {
  display: flex;
}

.stock-styling .TabData {
  width: 458px;
  height: none;
  border-radius: 20px;
  /* margin-top: -1rem !important;
  */
  border: 2px solid #fff;
  background: #f7f7f7;
}

.stock-styling .TabData .tabs {
  padding: 15px;
  text-align: center;
  width: 50%;
  background: rgba(128, 128, 128, 0.075);
  border-top-left-radius: 20px;
  font-size: 20px;
  font-weight: 900;
  color: #969696;
  cursor: pointer;
  border-bottom: 1px solid #9dd0e7;
  box-sizing: content-box;
  position: relative;
  outline: none;
}

.stock-styling .TabHeader {
  height: 3rem;
}

.stock-styling .tabs:not(:last-child) {
  border-right: 2px solid #d0e6ef;
  border-bottom: 1px solid #9dd0e7;
}

.stock-styling .tabs:last-child {
  border-top-right-radius: 20px;
  border-top-left-radius: 0px;
  border-bottom: 1px solid #9dd0e7;
}

.stock-styling .tabs:hover {
  color: #042b62;
}

.stock-styling .active-tabs {
  background: #042b62;
  border-bottom: 1px solid transparent;
  color: #fff;
}

.stock-styling .active-tabs::before {
  content: "";
  display: block;
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% + 2px);
  /* height: 5px;
  */
  /* background: rgb(88, 147, 241);
  */
}

.stock-styling .Card_Btn button {
  border: none;
}

.stock-styling .content-tabs {
  flex-grow: 1;
}

.stock-styling .content {
  background: #fff;
  padding: 30px;
  /* display: none;
  */
  height: 462px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.stock-styling .content h2 {
  padding: 0px 0 5px 0px;
}

.stock-styling .content hr {
  width: 100px;
  height: 2px;
  background: #222;
  margin-bottom: 5px;
}

.stock-styling .active-content {
  display: block;
}

.stock-styling .Right_Btn {
  display: flex;
  /* width: 100% !important;
  */
}

.stock-styling .Right_Btn button:first-child {
  display: grid;
  place-content: center;
  margin-top: 0rem;
  cursor: pointer;
  /* justify-content: space-between;
  */
  width: 50%;
  bottom: 0;
  background-color: #042b62;
  color: #fff;
  height: 3.35rem;
  /* border-bottom-right-radius: 15px;
  */
  border-bottom-left-radius: 15px;
  border: none;
  vertical-align: middle;
  text-align: center;
  font-size: 20px;
  font-weight: 900;
  /* margin-top: 10.2rem;
  */
}

.stock-styling .Right_Btn button:nth-child(2) {
  display: grid;
  place-content: center;
  cursor: pointer;
  margin-top: 0rem;
  float: right;
  /* justify-content: space-between;
  */
  width: 50%;
  bottom: 0;
  background-color: #fff;
  color: #c0c3c2;
  height: 3.35rem;
  text-align: center;
  border-bottom-right-radius: 15px;
  /* border-bottom-left-radius: 15px;
  */
  border: none;
  font-size: 20px;
  font-weight: 900;
  /* margin-top: 10.2rem;
  */
}

.stock-styling .mobile_Button {
  visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stock-styling .mobile_Button {
  margin-bottom: 0;
}

.stock-styling .mobile_Button button:first-child {
  bottom: 1rem;
  width: 165px;
  border-radius: 15px;
  position: fixed;
  background-color: #042b62;
  color: #fff;
  left: 1rem;
  height: 2rem;
  /* right: 22rem;
  */
  border: none;
  font-size: 20px;
  font-weight: 900;
  margin-top: 10.2rem;
}

.stock-styling .mobile_Button button:nth-child(2) {
  bottom: 1rem;
  left: 13rem;
  border-radius: 15px;
  width: 165px;
  /* left: 50%;
  */
  position: fixed;
  background-color: #fff;
  color: gray;
  height: 2rem;
  border: none;
  font-size: 20px;
  font-weight: 900;
  margin-top: 10.2rem;
}

.stock-styling .Left_Graph {
  border-right: 1px solid #c0c3c2;
  padding-right: 1em;
}

@media (max-width: 768px) {
  .stock-styling .PaginationView {
    overflow: scroll !important;
  }

  .stock-styling .mobile_Button {
    visibility: visible;
  }

  .stock-styling .Brokers_Summary_Graph .Left_Graph {
    width: 80% !important;
    border: none;
  }
}

@media (max-width: 991px) {
  .TaxPaymentSectionIllustration {
    display: none;
  }
}

.stock-styling .MuiAppBar-colorDefault {
  color: rgba(0, 0, 0, 0.87);
  background: none !important;
}

.stock-styling Tabs {
  border-bottom: 1px solid gray !important;
}

.stock-styling .MuiTab-wrapper {
  /* text-transform: lowercase !important;
  */
  color: #a9a9a9;
  font-size: 17px !important;
  font-weight: 600;
}

.stock-styling .MuiTab-root {
  text-transform: inherit !important;
}

.stock-styling TabPanel {
  background-color: none !important;
}

.stock-styling .makeStyles-root-1 {
  width: 94% !important;
  background-color: none !important;
  background: none !important;
  padding-left: 4rem !important;
}

.stock-styling .MuiTypography-body1 {
  padding: 1rem !important;
}

.stock-styling .MuiTab-wrapper {
  width: 100%;
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-size: 16px;
  font-weight: 900;
  color: gray;
}

.stock-styling .MuiTab-wrapper:hover {
  color: #000 !important;
}

.stock-styling .Mui-selected .MuiTab-wrapper {
  color: #000 !important;
}

.stock-styling .MuiTabs-flexContainer {
  display: flex;
  justify-content: space-between !important;
}

.stock-styling .MuiPaper-elevation4 {
  box-shadow: none !important;
}

.stock-styling .MuiPaper-root {
  color: rgba(0, 0, 0, 0.87);
  /* transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  */
  background: -webkit-linear-gradient(to right, #f2fcfe, #f0f4f3);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #f2fcfe, #f0f4f3);
  border-bottom: 1px solid gray;
}

.stock-styling .OPanel {
  background-color: #fff !important;
  border-radius: 15px;
  margin-top: 2rem !important;
}

.stock-styling .RsVal {
  font-weight: bold !important;
}

.stock-styling .OPanel img {
  width: 12px;
  height: 12px;
}

.stock-styling .overview {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  /* padding-top: 0rem;
  */
}

.stock-styling .navTrnd {
  display: flex;
  justify-content: space-between;
}

.stock-styling .navTrnd span:first-child {
  color: gray;
}

.stock-styling .Cagr span:first-child {
  color: gray;
}

.stock-styling .Cagr {
  display: flex;
  justify-content: space-between;
}

.stock-styling .Graph {
  margin-top: 4rem;
  width: 100%;
}

.stock-styling .Fund_Details {
  margin-top: 2rem;
  overflow: hidden;
}

.stock-styling .Fund_subDetails {
  display: grid;
  /* place-items: center;
  */
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.stock-styling .Fund_subDetails .Names {
  display: grid;
  margin-top: 1rem;
}

.stock-styling .Fund_subDetails .Rupee {
  width: 15px;
  height: 15px;
  position: relative;
  top: 0.1rem;
  left: 0rem;
}

.stock-styling .Fund_subDetails .Names span:first-child {
  color: gray;
}

.stock-styling .Fund_subDetails .Names span:nth-child(2) {
  font-size: 17px;
  font-weight: 900;
}

.stock-styling hr {
  color: #d8dbda;
  height: 0px;
}

.stock-styling .Names img {
  width: 30px;
  height: 30px;
}

.stock-styling .Compare_Stock {
  background-color: #fff;
  padding: 1rem;
  border-radius: 15px;
}

.stock-styling .Table_Compare .header_NM {
  font-size: 20px;
  color: gray;
  font-weight: 900;
  padding-left: 1rem !important;
}

.stock-styling .Risk_Measure {
  border-collapse: collapse;
  width: 100%;
  border: none;
  /* margin-top: 1.8rem;
  */
}

.stock-styling .user-name a {
  text-decoration: none !important;
  color: #000;
}

.stock-styling #Stock_table {
  margin-top: 2rem;
  width: 100%;
}

.stock-styling #Stock_table td:first-child {
  text-align: left !important;
  color: #042b62;
  font-size: 17px;
  font-weight: 900;
}

.stock-styling .Table_Hold {
  margin-top: 2rem;
  width: 100%;
}

.stock-styling .Table_Compare h4 {
  color: gray;
  font-size: 20px;
}

.stock-styling #Stock_table td {
  padding: 1rem;
  text-align: center;
  border-bottom: 1px solid #d8dbda;
  /* color: grey;
  */
}

.stock-styling #Stock_table td:first-child {
  color: #042b62;
  font-size: 17px;
  font-weight: 900;
  padding: 2rem;
}

.stock-styling .Sample_Head {
  font-weight: bold;
}

.stock-styling #Stock_table th {
  padding: 1rem;
  text-align: center;
  background-color: #fff;
  color: Gray;
}

.stock-styling #Stock_table th img {
  width: 16px !important;
  height: 16px !important;
  margin-left: 0.5rem;
  cursor: pointer;
}

.stock-styling #Stock_table th:first-child {
  border-radius: 20px 0 0 20px;
  padding-left: 0rem !important;
}

.stock-styling #Stock_table th:last-child {
  border-radius: 0 20px 20px 0;
  padding-right: 2.3rem !important;
}

.stock-styling .Compare_Stock img {
  width: 30px;
  height: 20px;
}

.stock-styling .Compare {
  margin-top: 2rem !important;
}

.stock-styling .Table_Compare button {
  width: 7rem;
  height: 2rem;
  background-color: #042b62;
  color: #fff;
  font-size: 14px;
  border-radius: 15px;
  font-weight: 900;
  border: 2px solid #fff;
  cursor: pointer;
}

.stock-styling .Allocation {
  /* margin-top: 1rem !important;
  */
  margin-left: 1rem;
}

.stock-styling .Allocation hr {
  margin-top: 1rem;
}

.stock-styling .Sector_All {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  margin-top: 2rem;
}

.stock-styling .Sector_All div {
  padding: 2rem;
  padding-top: 1rem;
  width: 15rem;
}

.stock-styling .range input[type="range"] {
  outline: 0;
  border: 0;
  border-radius: 500px;
  width: 400px;
  max-width: 100%;
  margin-left: 0rem;
  /* margin: 24px 0 16px;
  */
  transition: box-shadow 0.2s ease-in-out;
}

.stock-styling .range .high_low {
  display: grid;
  padding-top: 12px;
  /* margin-top: -2rem;
  */
  padding-left: 0rem;
  padding-right: 0.5rem;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .stock-styling input[type="range"] {
    overflow: hidden;
    width: 15rem;
    -webkit-appearance: none;
    appearance: none;
    background-color: #d1d1d1;
    border-radius: 15px;
  }

  .stock-styling input[type="range"]::-webkit-slider-runnable-track {
    height: 10px;
    -webkit-appearance: none;
    color: #042b62;
    margin-top: -1px;
  }

  .stock-styling input[type="range"]::-webkit-slider-thumb {
    width: 0px;
    -webkit-appearance: none;
    height: 0px;
    cursor: ew-resize;
    background: #434343;
    box-shadow: -80px 0 0 80px #042b62;
  }
}

.stock-styling input[type="range"]::-moz-range-progress {
  background-color: #042b62;
}

.stock-styling input[type="range"]::-moz-range-track {
  background-color: #9a905d;
}

.stock-styling input[type="range"]::-ms-fill-lower {
  background-color: #042b62;
}

.stock-styling input[type="range"]::-ms-fill-upper {
  background-color: #9a905d;
}

.stock-styling .header_NM {
  font-size: 20px;
  color: gray;
  font-weight: 900;
  padding-left: 1rem !important;
}

.stock-styling .Holdings_table table th {
  /* padding: 1rem;
  */
  text-align: center;
  background-color: none !important;
  color: Gray;
  border-bottom: 1px solid #d1d1d1;
}

.stock-styling .Holdings_table table th:first-child {
  padding-left: 2rem;
  text-align: left;
  width: 24rem;
}

.stock-styling .Holdings_table .Nm {
  position: absolute;
  margin-top: 0.5rem;
  padding-left: 5px;
}

.stock-styling .Holdings_table td {
  padding: 1rem;
  text-align: center;
  height: 20px;
  /* border-bottom: 1px solid #d8dbda;
  */
  /* color: grey;
  */
}

.stock-styling .Holdings_table td:first-child {
  color: #042b62;
  font-size: 17px;
  font-weight: bold;
  /* padding: 2rem;
  */
  text-align: left;
}

.stock-styling .Holdings_table td img {
  width: 40px;
  height: 40px;
}

.stock-styling .Risk_Measure_List {
  margin-top: 0rem !important;
}

.stock-styling .Risk_Measure th {
  border-bottom: 1px solid #d1d1d1;
  padding-bottom: 1rem;
}

.stock-styling .Risk_Measure th:first-child {
  font-size: 20px;
  color: gray;
  font-weight: 900;
  text-align: left;
}

.stock-styling .Risk_Measure .Nm {
  position: absolute;
  margin-top: 0.5rem;
  padding-left: 5px;
}

.stock-styling .Risk_Measure td {
  padding: 1rem;
  padding-left: 0rem;
  /* text-align: center;
  */
  height: 20px;
  /* border-bottom: 1px solid #d8dbda;
  */
  /* color: grey;
  */
}

.stock-styling .Risk_Measure td:first-child {
  color: #042b62;
  font-size: 17px;
  font-weight: bold;
  /* padding: 2rem;
  */
  text-align: left;
}

.stock-styling .Risk_Measure td img {
  width: 40px;
  height: 40px;
}

.stock-styling .OthersPanel h4 {
  font-size: 20px;
  color: gray;
  font-weight: 900;
}

.stock-styling .OthersPanel img {
  width: 50px;
  height: 50px;
}

.stock-styling .OthersPanel .Nm {
  position: absolute;
  margin-top: 0.7rem;
  padding-left: 10px;
  color: #000;
  font-size: 19px;
  font-weight: 900;
}

.stock-styling .OthersPanel .Other_First span:nth-child(2) {
  font-size: 18px;
  font-weight: 900;
}

.stock-styling .Vertical_Line {
  border-right: 1px solid #d1d1d1;
}

.stock-styling .UserNM {
  color: #000;
  font-size: 19px;
  font-weight: 900;
}

.stock-styling #Calc_Amt_Range input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  overflow: visible !important;
  width: 28.5rem;
  /* height: 7px;
  */
  background: grey;
  border-radius: 5px;
  background-image: linear-gradient(#042b62, #042b62);
  background-repeat: no-repeat;
}

.stock-styling #Calc_Amt_Range input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 20px;
  margin-top: -4px;
  width: 20px;
  border-radius: 50%;
  background: #d1d1d1;
  cursor: pointer;
  box-shadow: 0 0 2px 0 #555;
  transition: background 0.3s ease-in-out;
}

.stock-styling #Calc_Amt_Range input[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

.stock-styling #Calc_Amt_Range input[type="range"]::-webkit-slider-thumb:hover {
  box-shadow: #fff 0px 0px 0px 8px;
}

.stock-styling #Calc_Amt_Range input[type="range"]::-webkit-slider-thumb:active {
  box-shadow: #fff 0px 0px 0px 11px;
  transition: box-shadow 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    left 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    bottom 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.stock-styling .amnt {
  display: grid;
  place-items: center;
  margin-top: 4rem;
  width: 90%;
}

.stock-styling .Box_Value {
  width: 100%;
  display: flex;
  place-items: center;
}

.stock-styling .Mobile_Table_Details {
  display: none;
}

.stock-styling .Stock_table1 {
  display: inline;
}

.stock-styling .Box_Value .IM {
  background-color: #042b62;
  height: 5rem;
  width: 50%;
  text-align: left;
  vertical-align: middle;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  padding: 15px;
  display: grid;
}

.stock-styling .Box_Value .IM span:first-child {
  color: gray;
}

.stock-styling .Box_Value .Est_Re {
  background-color: #042b62;
  height: 5rem;
  width: 37%;
  text-align: center;
  vertical-align: middle;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  padding: 15px;
  display: grid;
}

.stock-styling .Box_Value .Est_Re span:first-child {
  color: #fff;
}

.stock-styling .Total_Val {
  margin-top: 2rem;
}

.stock-styling .TabData input[type="text"] {
  outline: 0;
  /* border-width: 0 0 1px;
  */
  /* border-color: gray;
  */
  border: none;
  width: 100%;
  background: none;
  font-size: 35px;
  font-weight: 100;
  color: gray;
  padding-left: 1rem;
}

.stock-styling .TabData .bloc-tabs button:hover {
  color: #042b62;
}

.stock-styling .TabData input:focus {
  border-color: green;
}

.stock-styling .TabData .Input_Rupee {
  font-size: 35px;
  color: #042b62;
  font-weight: bold;
}

.stock-styling .TabData .Plan {
  display: flex;
  padding-top: 2rem;
  justify-content: space-between;
}

.stock-styling .TabData .Plan span:first-child {
  color: #9a9a9a;
  font-size: 16px;
}

.stock-styling .TabData .Plan .Plan_SIP span:nth-child(2) {
  padding-top: 1rem;
}

.stock-styling .TabData .Total_amnt .Value {
  color: #9a9a9a;
  font-weight: 400;
}

.stock-styling .TabData .years {
  padding-top: 1.5rem;
}

.stock-styling .TabData .years input[type="checkbox"] {
  width: 1.3em;
  height: 1.3em;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #ddd;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

.stock-styling .TabData .years input[type="checkbox"]:checked {
  background-color: #b6e6fd;
}

.stock-styling .TabData .years span:nth-child(2) {
  font-size: 18px;
  font-weight: 500;
  color: #9a9a9a;
  padding-top: 1rem !important;
}

.stock-styling .Allocation .TabData {
  width: 100%;
  /* height: 400px;
  */
  border-radius: 20px;
  /* margin-top: -1rem !important;
  */
  border: none;
  background: none !important;
}

.stock-styling .Allocation .content {
  background: none;
}

.stock-styling .Allocation .TabData .bloc-tabs button:first-child {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  /* background: #66c2ef;
  */
  border: 1px solid #d1d1d1;
  color: #969696;
  /* color: #d1d1d1;
  */
}

.stock-styling .Allocation .TabData .bloc-tabs button:last-child {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  border: 1px solid #d1d1d1;
  color: #969696;
}

.stock-styling .Allocation .TabData .bloc-tabs .active-tabs {
  background: #042b62;
  color: #fff !important;
}

.stock-styling .Allocation .TabData .bloc-tabs button:hover {
  color: #042b62;
}

.stock-styling .Stock_List_Table_selected {
  background-color: #fff;
  margin-top: 1rem;
  padding: 2rem;
  display: none;
  border-radius: 15px;
}

.stock-styling .Stock_List_Table_selected button {
  width: 9rem;
  height: 2rem;
  margin-top: 0.5rem;
  border-radius: 15px;
  text-align: center;
  background-color: #042b62;
  border: 1px solid #042b62;
  color: #fff;
}

.stock-styling .user {
  display: flex;
  justify-content: space-evenly;
}

.stock-styling .Stock_Details {
  /* width: 100%;
  */
  overflow: hidden !important;
  margin-top: 0rem;
  /* display: grid;
  */
  /* place-items: center;
  */
  margin-top: 8rem;
  /* margin-left: 0rem;
  */
}

.stock-styling .Stock_Details a {
  text-decoration: none;
}

.stock-styling .Stock_Header1 {
  /* display: flex;
  justify-content: space-between;
  */
  background-color: #042b62;
  padding: 2rem;
  padding-top: 2rem !important;
  height: 8rem;
  border-radius: 15px;
  color: #000;
  outline: none;
  border: none;
}

.stock-styling .react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: initial !important;
  list-style: none;
  padding: 6px 12px;
  font-weight: 900;
  cursor: pointer;
}

.stock-styling .react-tabs__tab:hover {
  border-bottom: 1px solid #fff !important;
}

.stock-styling .react-tabs__tab--selected {
  background: none !important;
  border: None !important;
  font-size: 19px !important;
  border-bottom: 1px solid #fff !important;
  border-radius: 0px 0px 0 0 !important;
  color: white !important;
  /* border-radius: 5px 5px 0 0;
  */
}

.stock-styling .react-tabs__tab-list {
  border-bottom: 1px solid #aaa;
  margin: 0 0 10px;
  padding: 0;
  display: flex !important;
  justify-content: space-between !important;
}

.stock-styling .Stock_Header1 div {
  cursor: pointer;
  font-size: 18px;
  outline: none;
  font-weight: 500;
}

.stock-styling .Stock_Header1 .active {
  color: #fff;
}

.stock-styling .Stock_Search {
  background-color: #fff;
  border-radius: 15px;
  /* height: 20vh;
  */
  margin-top: -1.5rem;
  padding: 2rem;
  height: 8rem;
}

.stock-styling .Stock_Search input[type="search"] {
  width: 30em;
  outline: none;
  border-radius: 20px;
  padding: 0.5rem;
  border: 1px solid grey;
}

.stock-styling .Stock_Search input[type="search"]::placeholder {
  color: #000;
}

.stock-styling .Stock_Search_Header {
  /* display: flex;
  justify-content: space-between;
  */
  margin-top: 1rem;
}

.stock-styling #search {
  background: url("./Assets/Images/search.svg") no-repeat right;
  background-position: 450px;
  background-size: 16px;
  background-repeat: no-repeat;
  padding-left: 1rem;
  /* margin-right: 25px;
  */
}

.stock-styling .filter {
  color: #000 !important;
  font-size: 19px;
  font-weight: normal;
}

.stock-styling .filter span {
  padding-left: 18px;
}

.stock-styling .filter img {
  width: 26px;
  height: 26px;
  text-align: right;
}

.stock-styling .Filter_Btn {
  margin-left: 4rem;
  color: #000 !important;
  display: flex;
  justify-content: flex-end;
  /* padding: 1rem;
  */
}

.stock-styling .css-yk16xz-control {
  width: 8rem !important;
  border-radius: 20px !important;
  border: 1px solid #042b62 !important;
  color: #000 !important;
}

.stock-styling .FilterItem .css-yk16xz-control {
  width: 100% !important;
  border-radius: 0px !important;
  border: 1px solid #042b62 !important;
  color: #000 !important;
}

.stock-styling .Filter_Btn .filter:nth-child(2) {
  margin-left: 1rem;
  font-weight: normal;
  width: 8rem;
}

.stock-styling .search {
  padding-left: 25px;
  background: url("./Assets/Images/search.svg") no-repeat right;
  background-repeat: no-repeat;
  background-size: 20px;
}

.stock-styling .Filter_Btn button {
  width: 10rem;
  height: 2.5rem;
  /* padding-right: 3rem;
  */
  border-radius: 20px;
  text-align: center;
  background-color: #042b62;
  border: 1px solid #042b62;
}

.stock-styling .Filter_Btn .Sort {
  width: 7rem;
  height: 2.5rem;
  /* padding-right: 3rem;
  */
  border-radius: 20px;
  text-align: center;
  background-color: #042b62;
  color: white !important;
  font-weight: 600;
  /* border: 1px solid #042b62;
  */
  text-align: left;
  border: none;
  outline: none;
  padding-left: 1.5rem;
}

.stock-styling .Filter_Btn select {
  appearance: none;
  background-image: url("./Assets/Images/down-arrow.png");
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
  background-size: 20px;
}

.stock-styling .Filter_Btn select option {
  background-color: #fff;
  outline: none;
  color: #000;
  margin-top: 2rem !important;
}

.stock-styling .Filter_Btn button:first-child {
  background-color: #042b62;
  color: white !important;
  text-align: left;
  padding-left: 2rem;
}

.stock-styling .Filter_Btn button:first-child img {
  width: 20px;
  height: 20px;
  margin-left: 2rem;
  /* float: right;
  */
}

.stock-styling .Filter_Btn .filter {
  background-color: #fff;
  color: grey;
}

.stock-styling .Filter_Btn .active {
  color: #fff;
}

.stock-styling .Sort_Btn button {
  width: 10rem;
  height: 2.5rem;
  /* padding-right: 3rem;
  */
  border-radius: 20px;
  text-align: center;
  background-color: #042b62;
  border: 1px solid #042b62;
}

.stock-styling .All_Stocks {
  margin-top: 1.5rem;
}

.stock-styling .react-tabs__tab-list {
  border: none !important;
}

.stock-styling .Stock_List {
  padding: 0rem;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  margin-top: 2rem;
  font-size: 16px;
  font-weight: bold;
}

@media screen and (min-width: 768px) {
  .stock-styling .pagination {
    overflow: scroll !important;
  }

  .stock-styling .Stock_List_Table {
    background-color: #fff;
    border-radius: 15px;
  }

  .stock-styling .fList {
    width: 100%;
  }

  .stock-styling .sList {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: 24px;
    padding-top: 16px;
    /* border-bottom: 1px solid #eee;
    */
    width: 100%;
  }

  .stock-styling .SOList {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* align-items: flex-start;
    */
    width: 100%;
    margin-right: 40px;
  }

  .stock-styling .StList {
    cursor: pointer;
  }

  .stock-styling .SooList {
    width: 300px !important;
  }

  .stock-styling .SoTList {
    display: flex;
    /* flex-direction: row;
    */
    justify-content: flex-start;
    align-items: center;
  }

  .stock-styling .SottLoist {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    /* width: 142px;
    */
  }
}

@media (max-width: 600px) {
  .stock-styling .SoTList {
    display: flex;
    justify-content: flex-start;
  }

  .stock-styling .Stock_List_Table {
    background-color: #fff;
    margin-top: 1rem;
    display: flex;
    align-items: flex-start;
    /* width: 100%;
    */
    justify-content: center;
    flex-direction: column;
    padding: 2rem;
    border-radius: 15px;
  }
}

.stock-styling .Stock_List div:first-child {
  text-align: left;
}

.stock-styling .Stock_List div {
  text-align: center;
}

.stock-styling .Stock_List_Table a {
  color: #000;
}

.stock-styling .Stock_List_Table .Data_td input[type="checkbox"] {
  width: 2rem;
  height: 1rem;
  border: 1px solid #d1d1d1;
  background: #000;
  border-radius: 15px !important;
  outline: none;
}

.stock-styling .bottom_text {
  display: flex;
  font-size: 13px;
  font-weight: 400;
  margin-left: 0.5rem;
  color: #878a89;
}

.stock-styling .bottom_text span:nth-child(2) {
  padding-left: 0.5rem;
}

.stock-styling .Explore {
  color: #fff !important;
}

.stock-styling .Data_td {
  text-align: center;
  color: black;
  font-weight: bold;
}

.stock-styling .Stock_List_Table button {
  width: 7rem;
  height: 2rem;
  margin-top: 0.5rem;
  border-radius: 15px;
  text-align: center;
  background-color: #042b62;
  border: 1px solid #042b62;
  color: #fff;
}

.stock-styling .sliding-panel-container {
  position: fixed;
  top: 0;
  font-size: 15px;
  left: 0;
  /* background-color: #fff;
  */
}

.stock-styling .filter_text {
  font-size: 20px !important;
}

.stock-styling .SideBar_Filter {
  display: flex;
  justify-content: space-between;
}

.stock-styling .SideBar_Filter button {
  background: none;
  outline: none !important;
  width: 30px;
  height: 30px;
  border-radius: 50% !important;
  border: 1px solid #878a89;
}

.stock-styling .sliding-panel-container.active {
  position: fixed;
  z-index: 15000;
  background-color: rgba(0, 0, 0, 0.3);
  will-change: transform;
}

.stock-styling .sliding-panel-container.active.click-through {
  pointer-events: none;
  background-color: transparent;
}

.stock-styling .sliding-panel-container.click-through>div {
  pointer-events: none;
}

.stock-styling .panel-content {
  height: 100%;
  width: 100%;
  background: #fff;
  pointer-events: all;
  padding: 2rem;
  margin-top: 5rem;
  overflow: hidden;
}

.stock-styling .panel-container-left-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.stock-styling .panel-container-left-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: transform 500ms, opacity 500ms;
}

.stock-styling .panel-container-left-exit {
  opacity: 1;
  transform: translateX(0);
}

.stock-styling .panel-container-left-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: transform 500ms, opacity 500ms;
}

.stock-styling .panel-container-right-enter {
  opacity: 0;
  transform: translateX(100%);
}

.stock-styling .panel-container-right-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: transform 500ms, opacity 500ms;
}

.stock-styling .panel-container-right-exit {
  opacity: 1;
  transform: translateX(0);
}

.stock-styling .panel-container-right-exit-active {
  opacity: 0;
  transform: translateX(100%);
  transition: transform 500ms, opacity 500ms;
}

.stock-styling .panel-container-top-enter {
  opacity: 0;
  transform: translateY(-100%);
}

.stock-styling .panel-container-top-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: transform 500ms, opacity 500ms;
}

.stock-styling .panel-container-top-exit {
  opacity: 1;
  transform: translateX(0);
}

.stock-styling .panel-container-top-exit-active {
  opacity: 0;
  transform: translateY(-100%);
  transition: transform 500ms, opacity 500ms;
}

.stock-styling .panel-container-bottom-enter {
  opacity: 0;
  transform: translateY(100%);
}

.stock-styling .panel-container-bottom-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: transform 500ms, opacity 500ms;
}

.stock-styling .panel-container-bottom-exit {
  opacity: 1;
  transform: translateX(0);
}

.stock-styling .panel-container-bottom-exit-active {
  opacity: 0;
  transform: translateY(100%);
  transition: transform 500ms, opacity 500ms;
}

.stock-styling h4 {
  margin-top: 1rem;
}

.stock-styling .fund_Option {
  margin-top: 2rem;
  display: flex;
  font-weight: 900;
  font-size: 14px;
  /* padding-left: 2rem;
  */
  /* justify-content: space-between;
  */
}

.stock-styling .fund_Option div:nth-child(2) {
  padding-left: 3rem;
}

.stock-styling .Category_Filter {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  font-weight: 900;
  font-size: 14px;
}

.stock-styling .SubCategory_Filter {
  margin-top: 1rem;
  font-size: 14px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  font-weight: 900;
}

.stock-styling .SubCategory_Filter div {
  margin-top: 1rem;
  padding-left: 0.5rem;
}

.stock-styling .Filter_Btn_panel {
  display: flex;
  margin-top: 2rem;
}

.stock-styling .Filter_Btn_panel button {
  width: 9rem;
  height: 2.5rem;
  /* padding-right: 3rem;
  */
  border-radius: 20px;
  text-align: center;
  background-color: #042b62;
  border: 1px solid #042b62;
}

.stock-styling .Filter_Btn_panel button:first-child {
  color: #fff;
  font-weight: 900;
}

.stock-styling .Filter_Btn_panel .reset_btn button {
  background-color: #c0c3c2 !important;
  border: 1px solid #c0c3c2;
  color: gray;
}

.stock-styling .Filter_Btn_panel .reset_btn {
  padding-left: 1rem;
}

.stock-styling input[type="checkbox"] {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  background: lightgray;
  height: 16px;
  width: 16px;
  border: 1px solid white;
}

.stock-styling input[type="checkbox"]:checked {
  /* background: url('../Assets/check.svg'), lightgray;
  */
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.stock-styling .Mobile_Stock_search {
  display: none;
}

.stock-styling .user {
  list-style: none;
  margin: 10px 0;
  padding: 1rem;
  background: #fff;
  display: flex;
  justify-content: space-between;
  border-radius: 18px;
  color: #000 !important;
}

.stock-styling .user span:first-child {
  width: 14rem;
}

.stock-styling .Search_tab ul {
  display: flex !important;
  color: #000;
  width: 100%;
  justify-content: space-between;
  list-style-type: none;
}

.stock-styling .Search_tab ul li:first-child {
  text-align: left;
  margin-left: -1.5rem;
  /* border-bottom: 2px solid #042b62;
  */
}

.stock-styling .Search_tab ul li:first-child a {
  color: #000;
}

.stock-styling .Search_tab ul li:hover {
  border-bottom: 2px solid #042b62;
}

.stock-styling .Search_tab ul li:hover a {
  /* border-bottom: 2px solid #042b62;
  */
  color: #000;
}

.stock-styling .Search_tab a {
  color: gray;
}

.stock-styling .user:nth-child(even) {
  background: #c0c3c2;
}

.stock-styling .user-id {
  /* color: red;
  */
  margin-right: 20px;
}

.stock-styling .user-age {
  margin-left: auto;
}

.stock-styling .sweetPagination {
  display: flex !important;
  justify-content: flex-end !important;
}

.stock-styling .sweetPagination .paginationUL {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}

.stock-styling .sweetPagination .paginationUL .pageItem {
  list-style: none;
}

.stock-styling .sweetPagination .paginationUL .pageItem .pageButton {
  font-size: 16px;
  font-weight: 600;
  border-radius: 5rem;
  width: 40px;
  height: 40px;
  cursor: pointer;
  margin: 5px;
  background-color: #ddd;
}

.stock-styling .sweetPagination .paginationUL .pageItem .pageButton:hover {
  color: #f1f2f6;
  background-color: #fd7238;
  fill: #f1f2f6;
}

.stock-styling .sweetPagination .paginationUL .pageItem .activeButton {
  color: #f1f2f6;
  background-color: #fd7238;
}

.stock-styling .navigationIcon {
  width: 12px;
}

.stock-styling .dottedIcon {
  width: 20px;
}

.stock-styling .sweetPagination .pageItem .commonButtonStyle {
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}

.stock-styling .style-1 .paginationUL .pageItem .pageButton {
  margin-left: 5px;
  padding: 0;
  width: 40px;
  height: 40px;
  line-height: 30px;
  background-color: transparent;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
}

.stock-styling .style-1 .paginationUL .pageItem .pageButton:hover {
  background-color: #042b62 !important;
  color: #f1f2f6;
  fill: #f1f2f6;
}

.stock-styling .style-1 .paginationUL .pageItem .activeButton {
  background-color: #042b62 !important;
  color: #f1f2f6;
}

.stock-styling .style-2 .paginationUL .pageItem .pageButton {
  font-size: 14px;
  margin: -1px;
  margin-top: 5px;
  border: 1px solid transparent;
  line-height: 1.5;
  border-color: #ddd;
  color: #4285f4;
  fill: #4285f4;
  background: #fff;
  border-radius: 0;
  height: 40px;
  width: 40px;
}

.stock-styling .style-2 .paginationUL .pageItem .activeButton {
  background-color: #4285f4;
  color: #eee;
}

.stock-styling .style-2 .paginationUL .pageItem .pageButton:hover {
  background-color: #eee;
  color: #4285f4;
  fill: #4285f4;
}

.stock-styling .style-2 .paginationUL .pageItem .leftNavigation {
  -moz-border-radius: 6px 0 0 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px 0 0 6px;
}

.stock-styling .style-2 .paginationUL .pageItem .rightNavigation {
  -moz-border-radius: 0 6px 6px 0;
  -webkit-border-radius: 0;
  border-radius: 0 6px 6px 0;
}

@media only screen and (max-width: 576px) {
  .stock-styling .sweetPagination .paginationUL .pageItem .pageButton {
    width: 25px;
    height: 25px;
    margin: 3px;
  }

  .stock-styling .style-2 .paginationUL .pageItem .pageButton {
    margin: -1px;
    margin-top: 5px;
  }
}

.stock-styling .rwt__tabs[data-rwt-vertical="true"] {
  display: flex;
  outline: none;
}

.stock-styling .rwt__tablist:not([aria-orientation="vertical"]) {
  border-bottom: 1px solid #ddd;
  outline: none;
}

.stock-styling .rwt__tablist[aria-orientation="vertical"] {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 0;
  border-right: 1px solid #ddd;
  margin-right: 1rem;
}

.stock-styling .Path {
  width: 100%;
  height: 100%;
  /* margin-top: 7rem;
  */
  margin-right: 10rem;
  display: flex;
  justify-content: flex-end;
  padding-right: 8rem;
}

.stock-styling .rwt__tab {
  background: transparent;
  border: 0;
  height: 5rem;
  font-family: inherit;
  font-size: inherit;
  padding: 1rem 2rem;
  transition: background 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.stock-styling .Stock_Details .Path {
  width: 100%;
  height: 100%;
  margin-top: 0rem;
  margin-right: 10rem;
  display: flex;
  justify-content: flex-end;
  /* padding-right: 8rem;
  */
}

.stock-styling .Stock_Details .Path div {
  padding-right: 1rem;
}

.stock-styling .RightSide .path img {
  width: 15px !important;
  height: 15px !important;
}

.stock-styling .imgC img {
  width: 25px;
  height: 25px;
  margin-top: -0.5rem;
  /* padding-bottom:10px ;
  */
}

.stock-styling .All_Stocks .imgC {
  width: 30px;
  height: 30px;
  margin-top: -0.9rem;
  padding-right: 4px;
}

.stock-styling .rwt__tab[aria-selected="false"]:hover,
.stock-styling .rwt__tab:focus {
  outline: 0;
  background-color: #f4f4f4;
  background-color: rgba(0, 0, 0, 0.05);
}

.stock-styling .rwt__tab[aria-selected="true"] {
  position: relative;
}

.stock-styling .rwt__tab[aria-selected="true"]:after {
  content: "";
  position: absolute;
}

.stock-styling .rwt__tablist:not([aria-orientation="vertical"]) .rwt__tab[aria-selected="true"]:after {
  bottom: -1px;
  left: 0;
  width: 100%;
  border-bottom: 3px solid #00d8ff;
}

.stock-styling .rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]:after {
  right: -1px;
  top: 0;
  height: 100%;
  border-right: 3px solid #00d8ff;
}

.stock-styling .Compare_Details {
  width: 100%;
  overflow: hidden !important;
  margin-top: 0rem;
  display: grid;
  place-items: center;
  margin-top: 10rem;
}

.stock-styling .Header_Compare {
  background-color: #fcfcfc;
  width: 80%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.stock-styling .Header_Compare .Header_Name {
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  border-bottom: 1px solid #d1d1d1 !important;
}

.stock-styling .Header_Compare .Header_Name input[type="search"] {
  outline: none;
  width: 50rem;
  padding: 0.5rem;
  border-radius: 15px;
  font-size: 20px;
  border: 1px solid #d1d1d1;
}

.stock-styling .Header_Compare .Header_Name input[type="search"]::placeholder {
  font-size: 18px;
}

.stock-styling .Header_Compare .line {
  width: 100%;
  padding: 0 !important;
}

.stock-styling .Stock_Compare_List {
  display: flex;
  padding-left: 2rem;
  justify-content: space-between;
}

.stock-styling .Stock_Compare_List div {
  border-right: 1px solid #d1d1d1;
  padding: 2rem;
  /* width: 20rem;
  */
}

.stock-styling .Stock_Compare_List .compare_dec {
  width: 16rem;
  padding-top: 2rem !important;
  padding-left: 0rem !important;
}

.stock-styling .Stock_Compare_List div:last-child {
  border: none;
}

.stock-styling .Stock_Compare_List h6 {
  font-size: 18px;
  font-weight: 800;
}

.stock-styling .Stock_Compare_List p {
  color: gray;
  font-size: 16px;
  padding-top: 1rem;
  font-weight: 400;
}

.stock-styling .Stock_Compare_List .Funds_Name {
  border: none;
  display: flex;
  margin-top: -2rem;
  height: 7rem;
  color: #000;
  font-weight: 800;
}

.stock-styling .Stock_Compare_List .Fund_Options {
  border: none !important;
  display: flex;
  justify-content: space-between;
  /* margin-left: -3rem;
  */
  color: gray;
  padding-left: 15px;
  font-weight: 600;
}

.stock-styling .Stock_Compare_List .Fund_Options .Line {
  border-right: 1px solid #c0c3c2;
  margin-top: 0.2rem !important;
  height: 1.4rem;
}

.stock-styling .Stock_Compare_List .Invest_Btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.stock-styling .Stock_Compare_List .Invest_Btn button {
  width: 10rem;
  height: 2.3rem;
  font-size: 16px;
  border-radius: 25px;
  padding: 1px;
  font-weight: 900;
  color: #fff;
  background-color: #042b62;
}

.stock-styling .AllComapareDetails {
  background-color: #fff;
  width: 80%;
  margin-top: -2rem;
  padding: 2rem;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.stock-styling [pointer-events="bounding-box"] {
  display: none;
}

.stock-styling .Performannce_Histoty {
  margin-top: 1rem;
}

.stock-styling .FundssDetails {
  margin-top: 2rem;
}

.stock-styling .FundssDetails .Fund_Sub {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(12, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.stock-styling .FundssDetails .Fund_Sub h6 {
  color: #000;
}

.stock-styling .FundssDetails .Fund_Sub div {
  border-right: 1px solid #d1d1d1;
  width: 15rem;
  padding-bottom: 2rem;
  float: left;
  padding-right: 4rem;
  color: gray;
}

.stock-styling .FundssDetails .Fund_Sub .First_Div {
  padding-top: 1rem;
}

.stock-styling .FundssDetails .Fund_Sub .Last_div {
  border: none;
}

.stock-styling .Asset_Allocation h5 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.stock-styling .Asset_Allocation .GraphAllocation {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  margin-top: 2rem;
}

.stock-styling .Asset_Allocation .GraphAllocation .DivGraph {
  border-right: 1px solid #d1d1d1;
  width: 12%;
}

.stock-styling .Asset_Allocation .GraphAllocation .borderGraph {
  border-right: 1px solid #d1d1d1;
  margin-left: -9rem;
  width: 24rem;
}

.stock-styling .Asset_Allocation .GraphAllocation .borderGraph:last-child {
  border: none;
}

.stock-styling .Asset_Allocation .GraphAllocation .bar {
  display: grid;
  place-items: center;
  margin-top: 2rem;
  /* padding-left: 5rem;
  */
}

.stock-styling .PortFolio h5 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.stock-styling .PortFolio .TopSectors {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.stock-styling .PortFolio .TopSectors div {
  border-right: 1px solid #d1d1d1;
  width: 15rem;
}

.stock-styling .PortFolio .TopSectors div:last-child {
  border: none;
}

.stock-styling .PortFolio .Sector_List {
  display: grid;
  padding-top: 1em;
}

.stock-styling .Sector_Head {
  padding-top: 1em;
}

.stock-styling .Sector_Allocation h5 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.stock-styling .Sector_Allocation .Sector_Allocation_List {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(9, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.stock-styling .Sector_Allocation .Sector_Allocation_List div {
  border-right: 1px solid #d1d1d1;
  width: 15rem;
  padding-bottom: 2rem;
  padding-top: 1rem;
  font-size: 14px;
}

.stock-styling .Sector_Allocation .Sector_Allocation_List .Last_div {
  border: none;
}

.stock-styling .Sector_Allocation .First_Div {
  border-right: 1px solid #d1d1d1;
  width: 15rem !important;
  padding-top: 2rem;
  padding-bottom: 2rem;
  float: left;
  padding-right: 4rem;
  color: gray;
}

.stock-styling .Sector_Allocation .Input_Div input[type="range"] {
  overflow: hidden;
  width: 12rem !important;
  -webkit-appearance: none;
  appearance: none;
  background-color: #d1d1d1;
  border-radius: 15px;
}

.stock-styling .TopHoldings h5 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.stock-styling .TopHoldings .Holding_Details {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.stock-styling .TopHoldings .Holding_Details .Two_Details {
  display: flex;
  justify-content: space-between;
  width: 19rem;
  margin-left: -4rem;
  padding-right: 1rem;
  border-right: 1px solid #d1d1d1;
}

.stock-styling .TopHoldings .Holding_Details div {
  padding-bottom: 1rem;
  font-size: 14px;
}

.stock-styling .Two_Details .Comp_Name {
  background-color: #e8e8e8;
  height: 2rem;
  padding: 5px;
  width: 11rem;
  text-align: center;
}

.stock-styling .Two_Details .Comp_Holding {
  background-color: #e8e8e8;
  height: 2rem;
  padding: 5px;
}

.stock-styling .Close_Fund {
  cursor: pointer;
}

.stock-styling .TopHoldings .Holding_Details .Two_Details:last-child {
  border: none;
}

.stock-styling .Holding_Details .First_Div {
  border-right: 1px solid #d1d1d1;
  width: 15rem !important;
  padding-top: 3rem;
  padding-bottom: 2rem;
  float: left;
  padding-right: 4rem;
  color: gray;
}

.stock-styling .Value img {
  width: 15px;
  height: 15px;
}

.stock-styling .Path_Home {
  width: 18px;
  height: 18px;
}

.stock-styling .Path_Arrow {
  width: 16px;
  height: 16px;
}

.stock-styling .Stock_Img img {
  width: 40px;
  height: 40px;
}

.stock-styling .TabHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.stock-styling .Stock_Img .imgCExplore {
  display: none !important;
}

@media only screen and (min-width: 992px) and (max-width: 1399px) {


  .stock-styling .All_Stocks .Tab_Nm {
    display: flex !important;
    white-space: nowrap !important;
  }

  .stock-styling .Stock_Search input[type="search"] {
    width: 30em;
    outline: none;
    margin-left: 2rem !important;
    border-radius: 20px;
    padding: 0.5rem;
    border: 1px solid grey;
  }

  .stock-styling .Mobile_Stock_search {
    display: none;
  }

  .stock-styling .sList {
    display: grid;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: 24px;
    padding-top: 16px;
    /* border-bottom: 1px solid #eee;
    */
    width: 100%;
  }

  .stock-styling .SOList {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* align-items: flex-start;
    */
    width: 100%;
    margin-right: 40px;
  }

  .stock-styling .StList {
    cursor: pointer;
    margin-top: 1rem;
    display: grid;
    place-items: stretch;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {

  .stock-styling .react-tabs {
    width: 700px;
  }

  .stock-styling .Range_chart {
    display: block;
  }

  .stock-styling .sList {
    display: grid;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: 24px;
    padding-top: 16px;
    /* border-bottom: 1px solid #eee;
    */
    width: 100%;
  }

  .stock-styling .SOList {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* align-items: flex-start;
    */
    width: 100%;
    margin-right: 40px;
  }

  .stock-styling .StList {
    cursor: pointer;
    margin-top: 1rem;
    display: grid;
    place-items: stretch;
  }

  .stock-styling .Stock_Img img {
    width: 40px;
    height: 40px;
  }

  .stock-styling .react-tabs__tab-list {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }

  .stock-styling .Stock_Header1 {
    font-size: 11px !important;
  }

  .stock-styling .imgC img {
    width: 14px;
    height: 14px;
  }

  .stock-styling .react-tabs__tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 5px 8px !important;
    cursor: pointer;
  }

  .stock-styling .react-tabs__tab--selected {
    background: none !important;
    border: None !important;
    font-size: 11px !important;
    /* border-bottom: 1px solid #fff !important;
    */
    border-radius: 0px 0px 0 0 !important;
    color: white !important;
    /* border-radius: 5px 5px 0 0;
    */
  }

  .stock-styling .All_Stocks div:first-child {
    display: none;
  }

  .stock-styling .Stock_Search input[type="search"] {
    width: 30em;
    outline: none;
    margin-left: 0rem;
    border-radius: 20px;
    padding: 0.5rem;
    border: 1px solid grey;
  }

  .stock-styling .Stock_Details .Path {
    width: 100%;
    height: 100%;
    margin-top: 0rem;
    margin-right: 10rem;
    display: flex;
    justify-content: flex-end;
    padding-right: 0rem;
  }

  .stock-styling .All_Stocks {
    display: none;
  }

  .stock-styling .Mobile_Stock_search {
    display: flex;
  }

  .stock-styling .Search_Filter_Mobile {
    display: flex;
    justify-content: space-between;
  }

  .stock-styling .Mobile_Stock_search {
    display: block;
  }

  .stock-styling .Mobile_Stock_search h4 {
    font-size: 15px;
    font-weight: 800;
  }

  .stock-styling .Search_Filter_Mobile {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }

  .stock-styling .Search_Filter_Mobile .Search_Field img {
    float: left;
  }

  .stock-styling .Search_Filter_Mobile .Filter_Field img {
    float: right;
    right: 0;
  }

  .stock-styling .Search_Modal {
    width: 100%;
    height: 100%;
  }

  .stock-styling .Search_Modal {
    width: 100%;
    height: 100%;
  }

  .stock-styling .react-responsive-modal-root {
    position: fixed;
    top: 0px;
    width: 100%;
    height: 100%;
    /* bottom: 0;
    */
    /* left: 0;
    */
    /* right: 0;
    */
    z-index: 10000000000;
  }

  .stock-styling .react-responsive-modal-overlay {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }

  .stock-styling .react-responsive-modal-container {
    height: 100%;
    outline: 0;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: center;
  }

  .stock-styling .Stock_Header1 div {
    cursor: pointer;
    font-size: 13px;
    outline: none;
    font-weight: 500;
  }

  .stock-styling .react-responsive-modal-containerCenter:after {
    width: 0;
    height: 100%;
    content: "";
    display: inline-block;
    vertical-align: middle;
  }

  .stock-styling .react-responsive-modal-modal {
    max-width: 100%;
    height: 100%;
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    background: #fff;
    box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
    /* margin: 1.2rem;
    */
    padding: 3rem;
    position: relative;
    overflow-y: auto;
    /* border-radius: 15px;
    */
    width: 100%;
  }

  .stock-styling .react-responsive-modal-closeButton {
    position: absolute;
    top: 14px;
    right: 14px;
    height: 14px;
    width: 14px;
    border: none;
    padding: 0;
    cursor: pointer;
    background-color: transparent;
    display: flex;
  }

  @keyframes react-responsive-modal-overlay-in {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes react-responsive-modal-overlay-out {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  @keyframes react-responsive-modal-modal-in {
    0% {
      transform: scale(0.96);
      opacity: 0;
    }

    100% {
      transform: scale(100%);
      opacity: 1;
    }
  }

  @keyframes react-responsive-modal-modal-out {
    0% {
      transform: scale(100%);
      opacity: 1;
    }

    100% {
      transform: scale(0.96);
      opacity: 0;
    }
  }

  .stock-styling .react-responsive-modal-modal input[type="search"] {
    outline: none;
    border: hidden;
    border-bottom: 1px solid #d1d1d1;
    width: 100%;
    font-size: 13px;
    height: 3rem;
  }

  .stock-styling .react-responsive-modal-modal input[type="search"]::placeholder {
    font-size: 13px;
    padding: 5px;
  }

  .stock-styling .react-responsive-modal-modal .mostpop {
    font-size: 13px;
    color: #abb2ba;
    padding-bottom: 0px;
  }

  .stock-styling .react-responsive-modal-modal .Top_Stock_List {
    width: 100%;
    position: relative;
  }

  .stock-styling .Top_Stock_List div:first-child {
    width: 75%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #aab1b9;
    font-size: 15px;
  }

  .stock-styling .react-responsive-modal-modal .Top_Stock_List p {
    font-size: 15px;
    /* width: 75%;
    */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #aab1b9;
    padding-bottom: 16px;
  }

  .stock-styling .Top_Stock_List {
    display: flex;
    justify-content: space-between;
  }

  .stock-styling .Fund_Opt {
    display: flex;
    justify-content: space-between;
    color: #aab1b9;
    font-size: 15px;
  }

  .stock-styling .Filter_Field .Fund_Opt input[type="checkbox"] {
    width: 10px !important;
    height: 10px !important;
    outline: none;
  }

  .stock-styling .Fund_Opt_SubC {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 0px;
    color: #aab1b9;
    font-size: 15px;
  }

  .stock-styling .Filter_Button {
    display: flex;
  }

  .stock-styling .Filter_Button button:first-child {
    width: 6rem;
    height: 2rem;
    /* margin-left: 1rem;
    */
    margin-top: 0.5rem;
    border-radius: 15px;
    text-align: center;
    background-color: #042b62;
    border: 1px solid #042b62;
    color: #fff;
    font-weight: bold;
  }

  .stock-styling .Filter_Button button:nth-child(2) {
    width: 6rem;
    height: 2rem;
    margin-left: 1rem;
    font-weight: bold;
    margin-top: 0.5rem;
    border-radius: 15px;
    text-align: center;
    background-color: #c0c3c2 !important;
    border: 1px solid #c0c3c2;
    color: gray;
  }

  .stock-styling .Stock_Search {
    background-color: #fff;
    border-radius: 15px;
    /* height: 20vh;
    */
    margin-top: -1.5rem;
    padding: 2rem;
    height: 5rem;
  }

  .stock-styling .SOList {
    display: grid;
    flex-direction: row;
    justify-content: space-between;
    /* align-items: flex-start;
    */
    width: 100%;
    margin-right: 40px;
  }

  .stock-styling .SoTList {
    display: flex;
    /* flex-direction: row;
    */
    justify-content: flex-start;
    align-items: center;
    margin-top: 2rem;
  }

  .stock-styling .Sample .Value {
    padding-top: 10px;
  }

  .stock-styling .Sample {
    width: 142px !important;
    font-size: 12px;
  }

  .stock-styling .Value img {
    width: 15px;
    height: 15px;
  }

  .stock-styling .TabHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .stock-styling .fixed_top {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 65rem;
    /* justify-content: space-around;
    */
    top: 80px;
    /* left: 0;
    */
    /* width: 1000px;
    */
    z-index: 10000;
    left: 5rem;
    box-shadow: 0 2px 24px 0 #000;
    background-color: #f7f7f7 !important;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    padding: 1rem;
  }

  .stock-styling .navbar {
    background-color: #fff;
    box-shadow: 0 4px 8px 0 #000;
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: unset;
    align-items: center;
    font-size: 1.2rem;
    z-index: 1000 !important;
  }

  .stock-styling .RightSide {
    display: block;
  }
}

@media only screen and (min-width: 200px) and (max-width: 767px) {

  .stock-styling html {
    width: 398px;
  }

  .stock-styling .Fund_Details {
    overflow: hidden;
  }

  .stock-styling .mf_details {
    display: block;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }

  .stock-styling modal {
    animation-fill-mode: forwards !important;
  }

  .stock-styling .Stock_Header1 {
    background-color: #042b62;
    padding: 1rem;
    padding-top: 15px !important;
    height: 6rem;
    border-radius: 15px;
    color: #000;
  }

  .stock-styling .Stock_Details {
    margin-top: 6rem;
  }

  .stock-styling .Stock_Img .imgCExplore {
    display: flex !important;
  }

  .stock-styling .Stock_Header1 .TabHeader:first-child {
    padding-left: 0rem !important;
    width: 5rem;
  }

  .stock-styling .Sample div {
    font-weight: bold;
  }

  .stock-styling .Stock_Details .Path {
    width: 100%;
    height: 100%;
    margin-top: 0rem;
    margin-right: 0rem;
    font-size: 13px;
    display: flex;
    justify-content: flex-end;
    padding-right: 1rem;
  }

  .stock-styling .Stock_List_Table {
    background-color: #fff;
    margin-top: 1rem;
    display: flex;
    align-items: flex-start;
    /* width: 100%;
    */
    justify-content: center;
    flex-direction: column;
    padding: 1rem;
    border-radius: 15px;
  }

  .stock-styling .bottom_text {
    font-size: 15px;
    font-weight: 400;
    margin-top: 0rem;
    margin-left: 0rem;
    color: #878a89;
    /* display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 0px;
    */
  }

  .stock-styling .bottom_text span:first-child {
    width: 10rem;
  }

  .stock-styling .bottom_text img {
    vertical-align: top;
    padding-top: 0.1rem;
  }

  .stock-styling .Stock_Search {
    background-color: #fff;
    border-radius: 15px;
    /* margin-top: -1.5rem;
    */
    padding: 1rem;
    padding-top: 1.5rem;
    height: 4rem;
  }

  .stock-styling .SooList .Stock_Img {
    margin-left: -0.5rem;
  }

  .stock-styling .Stock_Img img {
    width: 50px;
    height: 50px;
  }

  .stock-styling .SooList a {
    font-size: 18px;
    font-weight: 600;
    vertical-align: middle;
  }

  .stock-styling .Path_Home {
    width: 10px;
    height: 10px;
  }

  .stock-styling .Path_Arrow {
    width: 10px;
    height: 10px;
  }

  .stock-styling .All_Stocks {
    display: none;
  }

  .stock-styling .react-tabs__tab-list {
    border-bottom: 1px solid #aaa;
    margin: 0 0 0 0px;
    padding: 0;
    font-size: 11px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 25px;
    grid-row-gap: 0px;
    overflow-x: scroll;
  }

  .stock-styling .Stock_Header1 {
    padding: 0.3rem;
  }

  .stock-styling ::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
  }

  .stock-styling .react-tabs__tab--selected {
    font-size: 11px !important;
    border: none !important;
  }

  .stock-styling .imgC img {
    display: none;
  }

  .stock-styling .All_Stocks {
    margin-top: 0rem;
  }

  .stock-styling .Stock_Search input[type="search"] {
    width: 19em;
    outline: none;
    margin-left: 0rem;
    border-radius: 20px;
    padding: 0.5rem;
    border: 1px solid grey;
  }

  .stock-styling .Mobile_Stock_search {
    display: block;
  }

  .stock-styling .Mobile_Stock_search h4 {
    font-size: 15px;
    font-weight: 800;
  }

  .stock-styling .Search_Filter_Mobile {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }

  .stock-styling .Search_Filter_Mobile .Search_Field img {
    float: left;
  }

  .stock-styling .Search_Filter_Mobile .Filter_Field img {
    float: right;
    right: 0;
  }

  .stock-styling .Search_Modal {
    width: 100%;
    height: 100%;
  }

  .stock-styling .react-responsive-modal-root {
    position: fixed;
    top: 0px;
    width: 100%;
    height: 100%;
    /* bottom: 0;
    */
    /* left: 0;
    */
    /* right: 0;
    */
    z-index: 10000000000;
  }

  .stock-styling .react-responsive-modal-overlay {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }

  .stock-styling .react-responsive-modal-container {
    height: 100%;
    outline: 0;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: center;
  }

  .stock-styling .react-responsive-modal-containerCenter:after {
    width: 0;
    height: 100%;
    content: "";
    display: inline-block;
    vertical-align: middle;
  }

  .stock-styling .react-responsive-modal-modal {
    max-width: 800px;
    height: 100%;
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    background: #fff;
    box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
    /* margin: 1.2rem;
    */
    padding: 3rem;
    position: relative;
    overflow-y: auto;
    /* border-radius: 15px;
    */
    width: 100%;
  }

  .stock-styling .react-responsive-modal-closeButton {
    position: absolute;
    top: 14px;
    right: 14px;
    height: 14px;
    width: 14px;
    border: none;
    padding: 0;
    cursor: pointer;
    background-color: transparent;
    display: flex;
  }

  @keyframes react-responsive-modal-overlay-in {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes react-responsive-modal-overlay-out {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  @keyframes react-responsive-modal-modal-in {
    0% {
      transform: scale(0.96);
      opacity: 0;
    }

    100% {
      transform: scale(100%);
      opacity: 1;
    }
  }

  @keyframes react-responsive-modal-modal-out {
    0% {
      transform: scale(100%);
      opacity: 1;
    }

    100% {
      transform: scale(0.96);
      opacity: 0;
    }
  }

  .stock-styling .react-responsive-modal-modal input[type="search"] {
    outline: none;
    border: hidden;
    border-bottom: 1px solid #d1d1d1;
    width: 100%;
    font-size: 13px;
    height: 3rem;
  }

  .stock-styling .react-responsive-modal-modal input[type="search"]::placeholder {
    font-size: 13px;
    padding: 5px;
  }

  .stock-styling .react-responsive-modal-modal .mostpop {
    font-size: 13px;
    color: #abb2ba;
    padding-bottom: 0px;
  }

  .stock-styling .react-responsive-modal-modal .Top_Stock_List {
    width: 100%;
    position: relative;
  }

  .stock-styling .Top_Stock_List div:first-child {
    width: 75%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #aab1b9;
    font-size: 15px;
  }

  .stock-styling .react-responsive-modal-modal .Top_Stock_List p {
    font-size: 15px;
    /* width: 75%;
    */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #aab1b9;
    padding-bottom: 16px;
  }

  .stock-styling .Top_Stock_List {
    display: flex;
    justify-content: space-between;
  }

  .stock-styling .Fund_Opt {
    display: flex;
    justify-content: space-between;
    color: #aab1b9;
    font-size: 15px;
  }

  .stock-styling .Filter_Field .Fund_Opt input[type="checkbox"] {
    width: 10px !important;
    height: 10px !important;
    outline: none;
  }

  .stock-styling .Fund_Opt_SubC {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 0px;
    color: #aab1b9;
    font-size: 15px;
  }

  .stock-styling .Filter_Button {
    display: flex;
  }

  .stock-styling .Filter_Button button:first-child {
    width: 6rem;
    height: 2rem;
    /* margin-left: 1rem;
    */
    margin-top: 0.5rem;
    border-radius: 15px;
    text-align: center;
    background-color: #042b62;
    border: 1px solid #042b62;
    color: #fff;
    font-weight: bold;
  }

  .stock-styling .Filter_Button button:nth-child(2) {
    width: 6rem;
    height: 2rem;
    margin-left: 1rem;
    font-weight: bold;
    margin-top: 0.5rem;
    border-radius: 15px;
    text-align: center;
    background-color: #c0c3c2 !important;
    border: 1px solid #c0c3c2;
    color: gray;
  }

  .stock-styling .StList button {
    display: none;
  }

  .stock-styling .SooList {
    display: flex;
    justify-content: space-between;
  }

  .stock-styling .Stock_Img .imgCExplore {
    width: 20px;
    height: 20px;
    margin-top: 1rem;
  }

  .stock-styling .SoTList {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    white-space: nowrap;
    font-size: 11px;
    margin-top: 1rem;
  }

  .stock-styling .Sample img {
    width: 10px !important;
    height: 10px !important;
  }

  .stock-styling .Value input {
    margin-top: 3px;
    margin-left: 1rem;
  }

  .stock-styling .TabHeader {
    display: flex;
    justify-content: space-between;
    /* align-items: center;
    */
    outline: none;
  }

  .stock-styling .TabHeader div:nth-child(2) {
    width: 7rem;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    font-weight: 900;
  }

  .stock-styling .Stock_Details .Path div {
    padding-right: 0.4rem;
    /* font-size: 13px;
    */
  }

  .stock-styling .makeStyles-root-1 {
    padding-left: 0rem !important;
  }

  .stock-styling .fixed_top {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26.5rem !important;
    justify-content: space-around;
    top: 80px;
    left: 0;
    width: 1007px;
    z-index: 10000;
    /* left: 5rem;
    */
    box-shadow: 0 2px 24px 0 #000;
    background-color: #f7f7f7 !important;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    padding: 1rem;
  }

  .stock-styling .mf_header {
    /* margin-top: 6rem;
    */
    display: block;
    height: 11rem;
  }

  .stock-styling .Share {
    position: relative;
    top: -168px;
    left: 225px;
  }

  .stock-styling .Rupee {
    width: 15px;
    height: 15px;
    position: relative;
    top: 0rem;
    left: 0rem;
  }

  .stock-styling .latest_value {
    font-size: 15px;
    margin-left: 0rem;
    font-weight: 700;
  }

  .stock-styling .latest {
    float: none;
    font-size: 14px;
    font-weight: 500;
  }

  .stock-styling .Percent_Val {
    float: none;
    color: green;
    font-size: 14px;
    padding-left: 1rem;
  }

  .stock-styling .fixed_top {
    display: grid;
    justify-content: space-between;
    /* margin-top: 10rem;
    */
  }

  .stock-styling .linkShare {
    display: inline;
  }

  .stock-styling .linkShare span:nth-child(2) {
    padding-left: 1rem;
  }

  .stock-styling .mf_details .tab {
    margin-top: 0rem;
    width: 24.2rem;
  }

  .stock-styling .Mf_Name {
    margin-left: 0rem;
  }

  .stock-styling .mf_subdetails .Mf_Details_info {
    display: flex;
    color: gray;
    font-size: 12px;
    font-weight: 300;
    padding-left: 0rem;
    padding-top: 1rem;
  }

  .stock-styling .mf_subdetails .Line {
    border-right: 1px solid #c0c3c2;
    margin-top: 0rem !important;
    margin-left: 10px;
    height: 12px !important;
    margin-right: 0.5rem;
    margin-bottom: 1rem;
  }

  .stock-styling .Mf_Name h4 {
    font-size: 12px;
    font-weight: 900;
    position: relative;
    margin-top: -2.1rem;
    margin-left: 3rem;
  }

  .stock-styling .MfNameNew {
    word-break: normal;
    width: 10rem;
  }

  .stock-styling .Mf_Name .stock_icon {
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 50%;
  }

  .stock-styling .mf_subdetails {
    display: inline-block;
    justify-content: space-evenly;
    /* text-align: center;
    */
    /* padding-left: -1rem;
    */
    /* width:300px;
    */
    /* border-right: 1px solid #d1d1d1;
    */
  }

  .stock-styling .Mf_extraInfo {
    display: flex;
    margin-top: 0rem;
    margin-left: 0rem;
    height: 5rem;
  }

  .stock-styling .Mf_extraInfo {
    font-size: 11px;
  }

  .stock-styling .Mf_Price_Value span:nth-child(2) {
    font-size: 13px;
    font-weight: bold;
  }

  .stock-styling .RightSide .path {
    display: none;
  }

  .stock-styling .RightSide {
    display: none;
  }

  .stock-styling .overview {
    display: grid;
    justify-content: space-between;
    padding: 1rem;
    /* width: 355px;
    */
    height: 18rem;
    /* padding-top: 0rem;
    */
  }

  .stock-styling .navTrnd {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    font-size: 12px;
  }

  .stock-styling .Cagr {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    font-size: 12px;
  }

  .stock-styling .mf_details {
    margin-top: 0rem;
  }

  .stock-styling .MuiTab-wrapper {
    /* text-transform: lowercase !important;
    */
    color: #a9a9a9;
    font-size: 11px !important;
    font-weight: 600;
  }

  .stock-styling .MuiTabs-flexContainer {
    display: flex;
    justify-content: initial !important;
  }

  .stock-styling .MuiTypography-body1 {
    padding: 0.5rem !important;
  }

  .stock-styling .OPanel p {
    font-size: 12px;
  }

  .stock-styling .toolbar {
    display: grid;
  }

  .stock-styling .toolbar div:first-child {
    font-size: 13px;
  }

  .stock-styling .toolbar div:nth-child(2) {
    padding-top: 1rem;
  }

  .stock-styling .Spline {
    width: 22.2rem;
  }

  .stock-styling .Fund_subDetails {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }

  .stock-styling .Table_Compare .header_NM {
    font-size: 13px;
    color: gray;
    font-weight: 900;
    padding-left: 0rem !important;
  }

  .stock-styling .Fund_subDetails .Names span:nth-child(2) {
    font-size: 12px;
    font-weight: 600;
  }

  .stock-styling .Fund_subDetails .Rupee {
    width: 10px;
    height: 10px;
    vertical-align: inherit;
    position: relative;
    /* top: 0.1rem;
    */
    left: 0rem;
  }

  .stock-styling .Compare {
    margin-top: 0rem !important;
  }

  .stock-styling .Table_Compare button {
    width: 7rem;
    height: 1.9rem;
    padding: 3px;
    background-color: #042b62;
    color: #fff;
    font-size: 14px;
    border-radius: 15px;
    font-weight: 900;
    border: 2px solid #fff;
    cursor: pointer;
  }

  .stock-styling #Stock_table {
    margin-top: 2rem;
    font-size: 12px;
    display: none;
  }

  .stock-styling .Table_Compare p {
    display: none;
  }

  .stock-styling .Mobile_Compare_Opt .Mobile_Table {
    position: relative;
    display: flex;
  }

  .stock-styling .Mobile_Table th,
  .stock-styling tr,
  .stock-styling td {
    border-color: inherit;
    /* border-style:initial !important;
    */
    border-width: 1px !important;
  }

  .stock-styling .Mobile_Table th,
  .stock-styling td {
    padding: 8px 8px;
    border: 1px solid #9a9a9a;
    font-size: 13px;
  }

  .stock-styling .Mobile_Table .table-center {
    overflow-x: scroll;
  }

  .stock-styling .Mobile_Table .table-center::-webkit-scrollbar {
    display: inline !important;
    width: 5px;
    height: 2px;
    background-color: #aaa;
  }

  .stock-styling .Mobile_Table .table-center::-webkit-scrollbar-thumb {
    background: #000;
  }

  .stock-styling .Mobile_Table .tablr_Head th {
    padding-left: 0rem !important;
    position: static;
    border: none;
    background: #f3f3f3;
    text-align: center;
    font-size: 13px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .stock-styling .table-center {
    -webkit-overflow-scrolling: touch;
  }

  .stock-styling .table-center td {
    text-align: center;
    font-size: 13px;
    white-space: nowrap;
  }

  .stock-styling .Mobile_Table .table-left table th {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    background: white;
    font-size: 13px;
    white-space: pre;
  }

  .stock-styling .tablr_Head th {
    background: #fff;
  }

  .stock-styling .Mobile_Table .tablr_Head th:last-child {
    border-bottom-right-radius: 30px;
    border-top-right-radius: 30px;
    height: 1rem;
  }

  .stock-styling .Mobile_Table .tablr_Head td {
    font-size: 13px;
  }

  .stock-styling .Allocation .TabData {
    width: 100%;
    /* height: 400px;
    */
    border-radius: 20px;
    margin-top: 2rem !important;
    border: none;
    background: none !important;
  }

  .stock-styling .header_NM {
    font-size: 15px;
    color: gray;
    font-weight: 900;
    padding-left: 0rem !important;
  }

  .stock-styling .TabData h4 {
    font-size: 15px;
    font-weight: 900;
    color: gray;
  }

  .stock-styling .bloc-tabs .tabs {
    padding: 15px;
    text-align: center;
    width: 50%;
    background: rgba(128, 128, 128, 0.075);
    /* border-top-left-radius: 20px;
    */
    font-size: 12px;
    font-weight: 900;
    color: #969696;
    cursor: pointer;
    border-bottom: 1px solid #9dd0e7;
    box-sizing: content-box;
    position: relative;
    outline: none;
  }

  .stock-styling .Sector_All {
    display: block;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    margin-top: 0rem;
  }

  .stock-styling .Sector_All div {
    padding: 2rem;
    padding-top: 1rem;
    width: 19rem;
  }

  .stock-styling .Holdings_table {
    margin-top: 2rem;
  }

  .stock-styling .Table_Hold {
    display: none;
  }

  .stock-styling .Checkall {
    display: none;
  }

  .stock-styling .Mobile_Holdings {
    border-top: 1px solid #d1d1d1;
    margin-top: -1rem;
    display: block;
  }

  .stock-styling .Mobile_Holdings .Top_Holding_List {
    margin-top: 2rem;
    display: grid;
  }

  .stock-styling .Mobile_Holdings .RangeData {
    display: flex;
    margin-top: 10px;
    justify-content: space-between;
  }

  .stock-styling .Mobile_Holdings div {
    font-size: 13px;
  }

  .stock-styling .check {
    color: #fff;
    font-size: 13px;
    border-radius: 15px;
    font-weight: 600;
    height: 2rem;
    text-align: center;
    width: 8rem;
    background-color: #042b62;
  }

  .stock-styling .Risk_Measure_List {
    margin-top: 1rem !important;
  }

  .stock-styling .Risk_Measure_List h4 {
    font-weight: 600;
    color: gray;
    font-size: 17px;
  }

  .stock-styling .Risk_Measure_List table {
    border: none !important;
    margin-top: 1.8rem;
  }

  .stock-styling .Risk_Measure table,
  .stock-styling tr,
  .stock-styling td,
  .stock-styling th {
    border: none !important;
  }

  .stock-styling .Risk_Measure th:first-child {
    font-size: 13px;
    color: gray;
    font-weight: 900;
    text-align: left;
  }

  .stock-styling .Risk_Measure th {
    border-bottom: 1px solid #d1d1d1;
    padding-bottom: 1rem;
    font-size: 13px;
  }

  .stock-styling .Risk_Measure td:first-child {
    color: #042b62;
    font-size: 13px;
    font-weight: bold;
    /* padding: 2rem;
    */
    text-align: left;
  }

  .stock-styling .Risk_Measure td {
    padding: 0.5rem;
    padding-left: 0rem;
    font-size: 12px;
    /* text-align: center;
    */
    height: 20px;
    /* border-bottom: 1px solid #d8dbda;
    */
    /* color: grey;
    */
  }

  .stock-styling .for_Mob_Share {
    padding-left: 1rem;
  }

  .stock-styling .Desktop_Share {
    display: none;
  }

  .stock-styling .Mobile_Space {
    padding-top: 1.2rem;
  }

  .stock-styling .OthersPanel h4 {
    font-size: 17px;
    color: gray;
    font-weight: 900;
  }

  .stock-styling .Other_Detail {
    display: flex;
    justify-content: space-between;
    margin-left: 0rem;
    color: gray;
  }

  .stock-styling .OthersPanel img {
    width: 30px;
    height: 30px;
  }

  .stock-styling .OthersPanel .Nm {
    position: absolute;
    margin-top: 0.3rem;
    padding-left: 10px;
    color: #000;
    font-size: 13px;
    font-weight: 900;
  }

  .stock-styling .Other_First span:first-child {
    font-size: 12px;
  }

  .stock-styling .OthersPanel .Other_First span:nth-child(2) {
    font-size: 13px;
    font-weight: 900;
  }

  .stock-styling .diff {
    overflow: hidden;
  }

  .stock-styling .UserNM {
    color: #000;
    font-size: 11px;
    font-weight: 900;
  }

  .stock-styling .mobile_Button {
    display: flex;
    justify-content: space-around;
    background-color: #000;
    bottom: 14px;
    position: fixed;
    width: 98%;
    height: 3rem;
    color: #fff;
    margin-top: 5rem;
    border-radius: 28px;
    margin-left: 5px;
    margin-right: 5px;
  }

  .stock-styling .for_Mob_Share {
    display: block;
  }

  .stock-styling .mobile_Button button:first-child {
    bottom: 1.4rem;
    width: 165px;
    border-radius: 15px;
    position: fixed;
    background-color: #042b62;
    color: #fff;
    left: 1rem;
    height: 2rem;
    border: none;
    font-size: 18px;
    font-weight: normal;
    margin-top: 0rem;
    outline: none;
  }

  .stock-styling .mobile_Button button:nth-child(2) {
    bottom: 1.4rem;
    left: 13rem;
    border-radius: 15px;
    width: 165px;
    /* left: 50%;
    */
    position: fixed;
    background-color: #fff;
    color: gray;
    height: 2rem;
    outline: none;
    border: none;
    font-size: 18px;
    font-weight: normal;
    margin-top: 0rem;
  }

  .stock-styling .Header {
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    background: #fff;
    box-shadow: 2px 2px 6px 0 #000;
  }

  .stock-styling .Logo {
    grid-area: logo;
    margin-top: 1rem;
    margin-left: 1rem;
    width: 100px;
  }

  .stock-styling .Header {
    height: 62px;
  }
}

.stock-styling .nav {
  display: flex;
  flex-wrap: initial !important;
  padding-left: 10px !important;
  margin-bottom: 0;
  list-style: none;
}

.stock-styling .Funds_Sub_Details {
  width: 100%;
  /* height: 0%;
  */
  /* overflow: hidden !important;
  */
  margin-top: 0rem;
  /* display: grid;
  */
  /* align-items: center;
  */
  margin-left: 0rem;
}

.stock-styling .Fund_Details {
  height: 100%;
  display: flex;
  justify-content: space-evenly;
}

.stock-styling .dot_Green {
  height: 10px;
  width: 10px;
  margin-top: 0.3em;
  background-color: #21f19f f7;
  border-radius: 50%;
  display: inline-block;
}

.stock-styling .dot_Orange {
  height: 10px;
  width: 10px;
  background-color: #ff7f10;
  border-radius: 50%;
  margin-top: 0.3em;
  display: inline-block;
}

.stock-styling .dot_Yellow {
  height: 10px;
  width: 10px;
  margin-top: 0.3em;
  background-color: #f7d81b;
  border-radius: 50%;
  display: inline-block;
}

.stock-styling .text-group .percent-text {
  color: black !important;
  display: none;
}

.stock-styling .Fund_Details_All {
  width: 100% !important;
  /* width: 100%;
  */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  overflow: hidden !important;
  margin-left: 11rem;
}

.stock-styling .Funds_nm {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.stock-styling .Fund_Details hr {
  margin: 3rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

.stock-styling .Middle_Line {
  border-right: 1px solid #c0c3c2;
  height: 13rem;
  margin-top: 3rem;
  margin-left: 0.6rem;
}

.stock-styling .Funds_icons {
  margin-top: 0rem;
  cursor: pointer;
}

.stock-styling .ms .Flex_Diff {
  padding-right: 0rem;
}

.stock-styling a {
  text-decoration: none !important;
}

.stock-styling .fixed_top {
  position: fixed;
  display: flex;
  /* justify-content: space-around;
  */
  top: 80px;
  /* left: 0;
  */
  width: 856px;
  z-index: 10000000;
  box-shadow: 0 2px 24px 0 #000;
  background-color: #f7f7f7 !important;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  padding: 1rem;
}

.stock-styling .Funds_icons span {
  padding-right: 2rem;
}

.stock-styling .Funds_icons span:last-child {
  padding-right: 0rem !important;
}

.stock-styling .Stock_Header h1 {
  font-family: "Red Hat Display", sans-serif;
  font-size: 25px;
  font-weight: 600;
}

.stock-styling .Stock_nm {
  padding-left: 1rem;
  padding-top: 2rem !important;
}

.stock-styling .TabList {
  margin-top: 1.7rem;
}

.stock-styling .Funds_icons img {
  width: 25px !important;
  height: 25px !important;
  /* padding-right: 1rem;
  */
}

.stock-styling .icon-d {
  display: flex;
  top: 130%;
  left: -130%;
}

.stock-styling .icon-d a {
  padding-left: 0.7em;
  padding-right: 0.7em;
}

.stock-styling .icon-d img,
.stock-styling .icon-d svg {
  width: 35px !important;
  height: 35px !important;
}

.stock-styling .icon-d svg {
  display: inline-block;
}

.stock-styling .icon-d .share-links {
  display: none;
}

.stock-styling .share-links.icon-d {
  display: none !important;
}

.stock-styling .Fund_Buy_Now .Path {
  width: 100%;
  height: 100%;
  margin-top: 7rem;
  margin-left: 9rem;
}

.stock-styling .Fund_Buy_Now .Path {
  width: 100%;
  height: 100%;
  /* margin-top: 7rem;
  */
  margin-right: 8rem;
  display: flex;
  justify-content: unset;
  padding-right: 8rem;
}

.stock-styling .Fund_Buy_Now .path ul {
  list-style-type: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.stock-styling .Fund_Buy_Now .path ul li {
  list-style-type: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.stock-styling .Stock_img {
  width: 60px;
  height: 60px;
}

.stock-styling .info img {
  width: 14px !important;
  height: 14px !important;
}

.stock-styling .Card {
  border-radius: 20px;
}

.stock-styling .Graph {
  width: 40px;
  height: 40px;
}

.stock-styling .Card h2 {
  text-align: center;
  font-size: 1.2rem !important;
  width: 100%;
  margin-top: 1rem;
  color: black;
}

.stock-styling .BlueLine {
  border-bottom: 1px solid #d3d3d3;
}

.stock-styling .Card_Des {
  display: grid;
  place-items: center;
  align-items: center;
  padding: 1rem;
}

.stock-styling .Funds_icons .Card_Btn {
  display: block;
}

.stock-styling .Funds_icons .Card_Btn button {
  background-color: #042b62;
  bottom: 14px;
  width: 100%;
  color: #fff;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  padding: 1rem;
  font-weight: 900;
  font-size: 18px;
}

.stock-styling .tabbable-panel {
  /* border:1px solid #eee;
  */
  padding: 10px;
}

.stock-styling .nav-tabs {
  display: flex;
  justify-content: space-between;
}

.stock-styling .tabbable-line>.nav-tabs {
  border: none;
  margin: 0px;
  height: 2rem;
}

.stock-styling .tabbable-line>.nav-tabs>li:first-child {
  margin-right: 2px;
  margin-left: 0rem;
}

.stock-styling .tabbable-line>.nav-tabs>li {
  margin-left: 3.1rem;
}

.stock-styling .tabbable-line>.nav-tabs>li>a {
  border: 0;
  margin-right: 0;
  color: #a9a9a9;
  font-size: 17.3px;
  font-weight: 600;
}

.stock-styling .tabbable-line>.nav-tabs>li>a>i {
  color: #a6a6a6;
}

.stock-styling .tabbable-line>.nav-tabs>li.open,
.stock-styling .tabbable-line>.nav-tabs>li:hover {
  border-bottom: 2px solid #4eace1;
}

.stock-styling .tabbable-line>.nav-tabs>li.open>a,
.stock-styling .tabbable-line>.nav-tabs>li:hover>a {
  border: 0;
  background: none !important;
  color: #333;
}

.stock-styling .tabbable-line>.nav-tabs>li.open>a>i,
.stock-styling .tabbable-line>.nav-tabs>li:hover>a>i {
  color: #a6a6a6;
}

.stock-styling .tabbable-line>.nav-tabs>li.open .dropdown-menu,
.stock-styling .tabbable-line>.nav-tabs>li:hover .dropdown-menu {
  margin-top: 0px;
}

.stock-styling .tabbable-line>.nav-tabs>li.active {
  border-bottom: 2px solid #4eace1;
  /* position: relative;
  */
}

.stock-styling .tabbable-line>.nav-tabs>li.active>a {
  border: 0;
  color: #333;
}

.stock-styling .tabbable-line>.nav-tabs>li.active>a>i {
  color: #404040;
}

.stock-styling .tabbable-line>.tab-content {
  margin-top: 0px;
  /* background-color: #fff;
  */
  border: 0;
  padding: 0px 0;
}

.stock-styling .portlet .tabbable-line>.tab-content {
  padding-bottom: 0;
}

.stock-styling .tabbable-line.tabs-below>.nav-tabs>li {
  border-top: 4px solid transparent;
}

.stock-styling .tabbable-line.tabs-below>.nav-tabs>li>a {
  margin-top: 0;
}

.stock-styling .tabbable-line.tabs-below>.nav-tabs>li:hover {
  border-bottom: 0;
  border-top: 4px solid #fbcdcf;
}

.stock-styling .tabbable-line.tabs-below>.nav-tabs>li.active {
  margin-bottom: -2px;
  border-bottom: 0;
  border-top: 4px solid #f3565d;
}

.stock-styling .tabbable-line.tabs-below>.tab-content {
  margin-top: -10px;
  border-top: 0;
  border-bottom: 1px solid #eee;
  padding-bottom: 15px;
}

.stock-styling .Invest_Drop {
  display: none;
}

.stock-styling ul {
  list-style-type: none;
}

.stock-styling ul li {
  display: grid;
}

.stock-styling #outerContainer {
  display: block;
  width: 100%;
  justify-content: center;
  align-items: center;
  /* Tab navigation */
}

.stock-styling #outerContainer .TabsNSEBSE {
  height: auto;
  /* Tab Content Styles */
}

@media (max-width: 769px) {
  .stock-styling #outerContainer .TabsNSEBSE {
    padding: 0rem 0;
  }
}

.stock-styling #outerContainer .TabsNSEBSE ul.nav1 {
  width: 100%;
  margin: 0 auto 0rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #39a2db;
  border-radius: 2rem;
  border: 1px solid #fff;
  padding-left: 0px;
}

@media (max-width: 768px) {
  .stock-styling #outerContainer .TabsNSEBSE ul.nav1 {
    width: 75%;
  }
}

.stock-styling #outerContainer .TabsNSEBSE ul.nav1 li {
  width: 50%;
  padding: 0.5rem;
  list-style: none;
  text-align: center;
  cursor: pointer;
  transition: all 0.7s;
  font-weight: 600;
  background-color: #f9edde;
}

.stock-styling #outerContainer .TabsNSEBSE ul.nav1 li:first-child {
  border-bottom-left-radius: 2rem;
  border-top-left-radius: 2rem;
}

.stock-styling #outerContainer .TabsNSEBSE ul.nav1 li:last-child {
  border-bottom-right-radius: 2rem;
  border-top-right-radius: 2rem;
}

.stock-styling #outerContainer .TabsNSEBSE ul.nav1 li:hover {
  background: rgba(213, 218, 217, 0.15);
  color: #2196f3;
}

.stock-styling #outerContainer .TabsNSEBSE ul.nav1 li.active {
  background: linear-gradient(90deg, #042b62 0%, #92d3ef 100%);
  color: #fff;
}

.stock-styling #outerContainer .TabsNSEBSE .TabContent {
  font-size: 2rem;
  text-align: center;
}

.stock-styling #container {
  position: relative;
  width: 100%;
  margin: auto;
  height: 38px;
  background-color: #f9edde;
  border: 1px solid #fff;
  margin: 0;
  border-radius: 36px;
  touch-action: none;
}

.stock-styling #container.select-left .left span {
  opacity: 1;
  color: white;
  font-size: 1.1rem !important;
  font-weight: 900;
}

.stock-styling #container.select-right .right span {
  opacity: 1;
  color: #55a1ca;
  font-size: 12px !important;
  font-weight: 900;
}

.stock-styling #item {
  position: relative;
  width: 100%;
  height: 75px;
  border-radius: 36px;
  transition: all 0.05s cubic-bezier(0.04, 0.46, 0.36, 0.99);
  touch-action: none;
  user-select: none;
}

.stock-styling #item::after {
  position: absolute;
  /* top: -5px;
  */
  left: 0;
  width: 50%;
  border-radius: 42px;
  height: calc(100% + -39px);
  background: #042b62;
  background: linear-gradient(90deg,
      #042b62 0%,
      rgba(146, 211, 239, 1) 100%);
  content: "";
}

.stock-styling .left {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  user-select: none;
}

.stock-styling .left span {
  font-size: 19px;
  pointer-events: none;
  user-select: none;
  color: #7f93a8;
  transition: all 0.2s ease;
  font-weight: 500;
}

.stock-styling .right {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
  top: 0;
  right: 0;
  pointer-events: none;
  user-select: none;
}

.stock-styling .right span {
  font-size: 1.1em;
  pointer-events: none;
  user-select: none;
  color: #55a1ca;
  transition: all 0.2s ease;
  font-weight: 600;
}

.stock-styling .tab-pane p {
  margin-top: 2rem;
}

.stock-styling .Stock_Value {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0rem;
  font-size: 1em;
  font-weight: 600;
  color: gray;
}

.stock-styling .Right_Panel {
  display: block !important;
}

.stock-styling .Right_Panel p {
  float: left;
}

.stock-styling .Line {
  border-bottom: 1px solid #d8dbda;
  padding-bottom: 0.5rem;
}

.stock-styling .Price {
  font-size: 1em;
  font-weight: 900;
}

.stock-styling .Price_Pos_Neg {
  font-size: 1em;
  color: #042b62;
  font-weight: 900;
}

.stock-styling .Price_Range {
  display: flex;
  /* justify-content: space-between;
  */
  padding-top: 1rem;
}

.stock-styling .Stock_Value .Price_Line {
  border-bottom: 1px solid gray;
  /* padding-bottom: 2rem;
  */
  height: 3rem;
}

.stock-styling .Price_Line {
  border-right: 1px solid #c0c3c2;
}

.stock-styling .Price_Range .Price_Line {
  border-right: 1px solid #c0c3c2;
  padding-left: 7px;
  padding-right: 3px;
}

.stock-styling .Price_Range .Price_Pos_Neg {
  padding-left: 10px;
}

.stock-styling .Price_COV {
  display: grid;
  padding-top: 12px;
}

.stock-styling .Price_COV span:nth-child(2) {
  font-size: 12px;
  font-weight: 500;
}

.stock-styling .Graph_Details {
  display: flex;
  justify-content: space-between;
}

.stock-styling .Overview {
  background-color: #fff;
  padding: 1rem;
  border-radius: 15px;
}

.stock-styling .Overview h4 {
  font-size: 1.2rem;
  color: gray;
  font-weight: 900;
}

.stock-styling .right_Pane {
  margin-top: -2rem;
}

.stock-styling .tab-content .Flex_Diff {
  display: grid;
  padding-top: 14px;
  color: gray;
  font-size: 0.8em;
}

.stock-styling .Flex_Diff span:first-child {
  color: gray;
}

.stock-styling .Flex_Diff .upArrow img {
  float: right;
  margin-right: -1rem;
  margin-top: -1.8rem;
  padding-left: 0.5rem;
}

.stock-styling .Flex_Diff .upArrow .upArrow_Dual {
  float: right;
  margin-right: -2rem;
  margin-top: -2.3rem;
  padding-left: 0.5rem;
}

.stock-styling .Percent_Val {
  color: gray !important;
  font-size: 1rem !important;
}

.stock-styling .Flex_Diff .downArrow img {
  float: right;
  margin-right: -1rem;
  margin-top: -1.8rem;
  padding-left: 0.5rem;
}

.stock-styling .Flex_Diff .info img {
  margin-bottom: 0.4rem;
  margin-left: 0.5rem;
  cursor: pointer;
}

.stock-styling .Graph_Sub_Details {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.stock-styling .Left_Space {
  padding-right: 0.5rem;
}

.stock-styling .Right_Space {
  padding-left: 0.5rem;
}

.stock-styling .Graph_Overview {
  display: flex;
  justify-content: space-between;
  /* width: 30rem;
  */
}

.stock-styling .QVT p span {
  font-size: 1.5rem;
  color: #042b62;
  font-weight: 900;
  padding-right: 0.5rem;
}

.stock-styling .QVT p img {
  width: 20px;
  height: 20px;
}

.stock-styling .green {
  /* background-color:#89b23b ;
  */
  background: #89b23b !important;
  color: #89b23b;
  height: 12px;
  width: 12px;
  left: 0px;
  top: 0px;
  border-width: 0px;
  border-color: #fff;
  border-radius: 12px;
}

.stock-styling .orange {
  color: #ff7f10;
}

.stock-styling .Yellow {
  color: #f7d81b;
}

.stock-styling .QVT_Diff {
  display: flex;
  /* justify-content: space-between;
  */
}

.stock-styling .Qvt_Des span:first-child {
  font-size: 0.9em;
  font-weight: 900;
  color: gray;
}

.stock-styling .Qvt_Des .Strength {
  font-size: 1em;
  color: #00e396;
  font-weight: 500;
}

.stock-styling .Qvt_Des .Valuation {
  font-size: 0.8em;
  color: #f7d81b;
  font-weight: 500;
}

.stock-styling .Qvt_Des .Neutral {
  font-size: 0.8em;
  color: #ff7f10;
  font-weight: 500;
  white-space: nowrap;
}

.stock-styling #gauge-chart8 svg:first-child {
  display: block !important;
}

.stock-styling #gauge-chart8 svg:nth-child(2) {
  display: none !important;
}

.stock-styling .QVT_range {
  display: flex;
  background: #fff;
  border-radius: 26px;
  height: 2rem;
  text-align: center;
  padding: 7px;
  /* padding: 0rem;
  */
  font-size: 0.8em;
  font-weight: 500;
  margin-top: 1.5em;
  color: #000;
}

.stock-styling .Key_Metrics .Flex_Diff span:nth-child(2) {
  font-size: 1.1rem;
  font-weight: 800;
  text-align: center;
}

.stock-styling .Key_Metrics #chart .toolbar .active {
  outline: none;
  border-radius: 8px;
  margin-right: 0.5rem;
  color: #fff;
  border: 1px solid #042b62;
}

.stock-styling .Key_Metrics .Flex_Diff span:first-child {
  color: gray;
  font-size: 0.8em;
}

.stock-styling .Qvt_Des {
  display: grid;
  padding-top: 12px;
  /* margin-top: -2rem;
  */
  padding-left: 1rem;
}

.stock-styling .QVT_range span {
  padding-left: 4px;
  /* padding-right: 2px ;
  */
}

.stock-styling .Graph_Middle_Line {
  border-right: 1px solid #c0c3c2;
  height: 8rem;
  padding-left: 1rem;
}

.stock-styling .high_low {
  display: grid;
  padding-top: 12px;
  /* margin-top: -2rem;
  */
  padding-left: 0rem;
  padding-right: 0.5rem;
}

.stock-styling .high_low span:first-child {
  font-size: 1em;
  font-weight: 900;
}

.stock-styling .Graph_Sub_Details div:nth-child(2) {
  margin-left: 0px;
}

.stock-styling .week {
  margin-left: 5rem;
}

.stock-styling .week2 {
  margin-left: 12.5rem;
}

.stock-styling .Return_val {
  text-align: center;
  margin-top: 0rem;
  color: gray;
  font-size: 1em;
  font-weight: 700;
}

.stock-styling .overview_val {
  display: flex;
  margin-top: 5px;
  justify-content: space-between;
}

.stock-styling .Key_Metrics hr {
  margin: 0;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

.stock-styling hr {
  width: 854px;
  margin-top: 4rem;
}

.stock-styling .Range_chart {
  vertical-align: middle;
  padding-top: 3rem;
  height: 280px;
  border-radius: 20px;
  margin-top: 0rem !important;
  color: #000 !important;
  border: 2px solid #fff;
  background: #f7f7f7;
  background: linear-gradient(180deg,
      to top rgba(247, 247, 247, 1) 0%,
      rgba(230, 230, 229, 1) 19%);
  /* background: #d2d2d2;
  background: linear-gradient( 90deg, to bottom #d2d2d2 0%, #d4c1c1 100% );
  */
  /* background: #d2d2d2;
  background: linear-gradient( 90deg, to bottom #d2d2d2 0%, #d4c1c1 100% );
  */
}

.stock-styling .range {
  display: grid;
  /* place-items: center;
  */
}

.stock-styling input[type="range"] {
  outline: 0;
  border: 0;
  border-radius: 500px;
  width: 400px;
  max-width: 100%;
  margin-left: 1.5rem;
  /* margin: 24px 0 16px;
  */
  transition: box-shadow 0.2s ease-in-out;
}

.stock-styling .percent-text {
  color: #000 !important;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .stock-styling input[type="range"] {
    overflow: hidden;
    height: 17px;
    -webkit-appearance: none;
    appearance: none;
    background-color: #ddd;
  }

  .stock-styling input[type="range"]::-webkit-slider-runnable-track {
    height: 19px;
    -webkit-appearance: none;
    color: #444;
    transition: box-shadow 0.2s ease-in-out;
    background: #042b62;
  }

  .stock-styling input[type="range"]::-webkit-slider-thumb {
    width: 19px;
    -webkit-appearance: none;
    height: 19px;
    cursor: ew-resize;
    background: #fff !important;
    /* box-shadow: -340px 0 0 320px rgb(69, 180, 228), inset 0 0 0 40px rgb(69, 180, 228);
    */
    border-radius: 50%;
    transition: box-shadow 0.2s ease-in-out;
    position: relative;
  }

  .stock-styling input[type="range"]:active::-webkit-slider-thumb {
    background: #042b62;
    box-shadow: -340px 0 0 320px #042b62, inset 0 0 0 3px #042b62;
  }
}

.stock-styling input[type="range"]::-moz-range-progress {
  background-color: #9a905d;
}

.stock-styling input[type="range"]::-moz-range-track {
  background-color: #9a905d;
}

.stock-styling html input[type="range"]::-ms-fill-lower {
  background-color: #9a905d;
}

.stock-styling input[type="range"]::-ms-fill-upper {
  background-color: #9a905d !important;
}

.stock-styling input:not(:active)+#h4-container h4 {
  opacity: 0;
  margin-top: -50px;
  pointer-events: none;
  background-color: #fff;
}

.stock-styling .Compare_Stock {
  background-color: #fff;
  padding: 1rem;
  border-radius: 15px;
}

.stock-styling .Compare_Stock h4 {
  font-size: 1.2rem;
  color: gray;
  font-weight: 900;
  padding-left: 1rem !important;
}

.stock-styling table {
  border-collapse: collapse;
  width: 100%;
  border: none;
  margin-top: 1.8rem;
}

.stock-styling table td:first-child {
  text-align: left !important;
}

.stock-styling table td {
  padding: 11px;
  text-align: center;
  border-bottom: 1px solid #d8dbda;
  /* color: grey;
  */
}

.stock-styling table td:first-child a {
  color: #042b62;
  font-size: 1rem;
  font-weight: 500;
}

.stock-styling table th {
  padding: 1rem;
  text-align: center;
  background-color: #000;
  color: Gray;
  white-space: nowrap;
}

.stock-styling th img {
  width: 16px !important;
  height: 16px !important;
  margin-left: 0.5rem;
  cursor: pointer;
}

.stock-styling th:first-child {
  border-radius: 20px 0 0 20px;
  padding-left: 0.8rem !important;
}

.stock-styling th:last-child {
  border-radius: 0 20px 20px 0;
  padding-right: 2.3rem !important;
}

.stock-styling .Compare_Stock img {
  width: 30px;
  height: 20px;
}

.stock-styling .Fund_Buy_Now .Path {
  display: flex;
  /* justify-content: space-evenly;
  */
}

.stock-styling .Fund_Buy_Now .Path span {
  padding-right: 1rem;
}

.stock-styling .Fund_Buy_Now .Path span .homeIcon {
  margin-top: -0.4rem !important;
}

.stock-styling .Key_Metrics::before {
  background-color: #f0f4f3;
}

.stock-styling .Key_Metrics {
  background-color: #fff;
  padding: 1rem;
  border-radius: 15px;
}

.stock-styling .Key_Metrics h4 {
  font-size: 1rem;
  color: gray;
  font-weight: 900;
  padding-left: 1rem !important;
}

.stock-styling .Key_Metrics .Market_Diff .Flex_Diff span:nth-child(2) {
  font-size: 1.3rem;
  font-weight: 800;
  text-align: center;
}

.stock-styling .Change_Diff {
  /* margin-top: 1rem;
  */
  padding: 1rem;
  display: flex;
  justify-content: space-between;
}

.stock-styling .Key_Metrics .Price_Line {
  border-bottom: 1px solid #c0c3c2;
  /* padding-bottom: 2rem;
  */
  height: 3rem;
}

.stock-styling .Flex_Diff {
  display: grid;
  padding-right: 2rem;
}

.stock-styling .Flex_Diff span:nth-child(3) {
  font-size: 0.7rem;
}

.stock-styling .Flex_Diff img {
  width: 19px;
  height: 19px;
}

.stock-styling .Percent_Val img {
  width: 15px;
  height: 15px;
  margin-bottom: 12px;
}

.stock-styling .Hr {
  width: 800px;
  margin-top: 0rem;
  margin-left: 0.5rem;
  /* margin-right: 1rem;
  */
}

.stock-styling .Market_Diff {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  text-align: center;
  margin-top: 2rem;
}

.stock-styling .Market_Diff .Flex_Diff img {
  width: 20px;
  height: 20px;
}

.stock-styling .Market_Diff .Flex_Diff span:nth-child(2) {
  padding-left: 6px;
}

.stock-styling .Market_Diff .Flex_Diff span:nth-child(3) {
  padding-top: 0.6rem;
}

.stock-styling .Market_Diff .Price_Line {
  border-bottom: 1px solid #c0c3c2;
  /* padding-bottom: 2rem;
  */
  height: 5rem;
}

.stock-styling .ms {
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.stock-styling .Market_Diff .Flex_Diff {
  width: 18rem;
}

.stock-styling .ms .Market_Diff .Flex_Diff span:first-child {
  color: #000;
  font-weight: 900;
}

.stock-styling .Technicals {
  background-color: #fff;
  padding: 1rem;
  border-radius: 15px;
}

.stock-styling .Technicals h4 {
  font-size: 1.2rem;
  color: gray;
  font-weight: 900;
  padding-left: 1rem !important;
}

.stock-styling .Technicals_Graph {
  display: grid;
  place-items: center;
  margin-top: 2rem;
}

.stock-styling .Posi_Negi_Graph {
  width: 600px;
  height: 50px;
  border-radius: 25px;
  background-color: #89b23b;
}

.stock-styling .Technicals_Graph img {
  width: 60px;
  height: 60px;
  margin-left: 34%;
  vertical-align: middle;
  border: 3px solid gray;
  border-radius: 50%;
  margin-top: -0.2rem;
  overflow: visible;
  padding: 10px;
  background-color: #fff;
  /* padding-bottom: 1rem;
  */
}

.stock-styling .happyFace span {
  color: #fff !important;
}

.stock-styling .Technicals .Flex_Diff .Tech_Val {
  font-size: 1rem;
  font-weight: 900;
  padding-left: 2rem;
}

.stock-styling .Technicals .Flex_Diff .big_val {
  font-size: 1.4rem;
  font-weight: 900;
  color: #000;
}

.stock-styling .Posi_Negi_Graph span:first-child {
  padding-left: 0.8rem;
  padding-top: 0.8rem;
}

.stock-styling .Technicals_Graph p {
  font-size: 20px;
  font-weight: 800;
  color: gray;
}

.stock-styling .Posi_Negi_Graph span:last-child {
  padding-right: 0.8rem;
  float: right;
  padding-top: 0.8rem;
}

.stock-styling .Brokers_Summary {
  background-color: #fff;
  padding: 1rem;
  border-radius: 15px;
}

.stock-styling .mixed-chart p {
  padding-left: 1rem;
  padding-top: -1rem;
}

.stock-styling .Brokers_Summary h4 {
  font-size: 1.2rem;
  color: gray;
  font-weight: 900;
  padding-left: 1rem !important;
}

.stock-styling .Brokers_Summary_Graph {
  display: flex;
  /* justify-content: space-around;
  */
}

.stock-styling .Brokers_Summary .Market_Diff .Flex_Diff span:first-child {
  color: gray;
  font-size: 1.125rem;
  font-weight: 700;
}

.stock-styling .Brokers_Summary .Market_Diff .Flex_Diff span:nth-child(2) {
  font-size: 1.4rem;
  font-weight: 900;
}

.stock-styling .Brokers_Summary .Graph_Middle_Line {
  border-right: 1px solid #c0c3c2;
  height: 20rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.stock-styling .Brokers_Summary .Line {
  border-bottom: 1px solid #d8dbda;
  padding-bottom: 0.5rem;
  width: 28rem;
}

.stock-styling .Brokers_Summary_Graph .Left_Graph p {
  font-size: 1em;
  color: gray;
  /* padding-top: 1rem;
  */
}

.stock-styling .Recomm {
  display: flex;
  padding: 1rem;
  justify-content: space-between;
}

.stock-styling .Recomm div:first-child {
  background-color: #ececec;
  border-radius: 20px;
  /* padding: 5px;
  */
  padding-left: 10px;
  padding-right: 10px;
  color: gray;
  font-size: 0.875rem;
  /* height: 3rem;
  */
}

.stock-styling .Recomm div:last-child {
  /* background-color: #ececec;
  */
  border-radius: 20px;
  /* padding: 5px;
  */
  padding-left: 10px;
  padding-right: 10px;
  color: gray;
  font-size: 0.875rem;
  /* height: 3rem;
  */
}

.stock-styling .Table_Header {
  display: flex;
  justify-content: space-between;
}

.stock-styling .Tool {
  width: 500px;
  line-height: 1.3em;
  text-align: justify;
}

.stock-styling .Tool p {
  line-height: 21px;
}

.stock-styling .Tool:hover {
  display: none;
}

.stock-styling .tool_qvt:hover {
  position: relative;
}

.stock-styling .tool_qvt[title]:hover:after {
  content: attr(title);
  padding: 40px 8px;
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 1;
  background: red;
}

.stock-styling .modal1 {
  font-size: 12px;
  font-size: 12px;
  background-color: #fff;
  color: #000;
  border-radius: 20px;
  width: 100%;
}

.stock-styling .Click_Qvt {
  cursor: pointer !important;
}

.stock-styling .modal1>.header {
  width: 100%;
  border-bottom: 1px solid #d3d3d3;
  font-size: 20px;
  text-align: center;
  font-weight: 600;
  padding: 5px;
  color: #000;
}

.stock-styling .modal1 img {
  max-width: 194px;
  height: auto;
  margin-right: 0.1em;
}

.stock-styling .modal1>.content {
  width: 100%;
  color: #000;
  display: block !important;
}

.stock-styling .content .iDtsAC {
  display: none;
}

.stock-styling .content .rec-carousel-wrapper .rec-carousel button {
  display: none;
}

.stock-styling .modal1>.actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}

.stock-styling .modal1>.close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  margin-top: -0.4em;
  right: -10px;
  /* top: -187px;
  */
  font-size: 24px;
  background: #fff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}

.stock-styling .popup_Flex {
  display: flex;
  justify-content: space-around;
}

.stock-styling .popcontent {
  color: #000;
  font-size: 12px;
  display: table-cell;
  width: 50%;
  vertical-align: top;
  padding-left: 1rem;
}

.stock-styling .Table_Detail_Arrow {
  width: 12px !important;
  height: 12px !important;
}

.stock-styling .Bu_Now_Mobile {
  visibility: hidden;
}

.stock-styling .Bu_Now_Mobile .Card_Btn {
  margin-bottom: 0;
}

.stock-styling .stock-float-btn .Card_Btn button {
  background-color: #000;
  bottom: 14px;
  position: fixed;
  width: 200px;
  height: 2.3rem;
  color: #042b62;
  margin-top: 5rem;
  font-weight: 900;
  border-radius: 28px;
  margin-left: -100px;
  left: 50%;
  bottom: 10px;
}

@media screen and (max-width: 991px) {
  .stock-styling .Range_chart {
    display: none;
  }

  .stock-styling .Bu_Now_Mobile {
    visibility: visible;
  }
}

@media only screen and (min-width: 200px) and (max-width: 480px) {
  .stock-styling .Fund_Buy_Now .Card .card-bx {
    display: none;
  }

  .stock-styling .Tool {
    width: 206px;
    line-height: 1.3em;
    text-align: justify;
  }

  .stock-styling .Tool p {
    line-height: 21px;
  }

  .stock-styling .Range_chart {
    margin-top: -7rem !important;
    margin-bottom: 3rem;
    display: block;
  }

  .stock-styling .Range_chart #tab_default_5 {
    margin-top: 2.3rem;
  }

  .stock-styling .Fund_Details_All {
    width: 100% !important;
    /* width: 100%;
    */
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    overflow: hidden !important;
    margin-left: 10px;
  }

  .stock-styling .Fund_Details {
    margin-top: 0rem;
  }

  .stock-styling .Overview {
    background-color: #fff;
    padding: 1rem;
    border-radius: 15px;
  }

  .stock-styling .Graph_Sub_Details {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    /* justify-content: space-evenly;
    */
  }

  .stock-styling .Graph_Sub_Details .Right_Space {
    border: none !important;
    padding-left: 0rem;
  }

  .stock-styling .Graph_Overview {
    display: flex;
    justify-content: space-between;
    /* width: 30rem;
    */
  }

  .stock-styling input[type="range"] {
    overflow: hidden;
    height: 11px;
    -webkit-appearance: none;
    appearance: none;
    background-color: #ddd;
  }

  .stock-styling input[type="range"]::-webkit-slider-runnable-track {
    height: 11px;
    -webkit-appearance: none;
    color: #444;
    transition: box-shadow 0.2s ease-in-out;
    background: #042b62;
  }

  .stock-styling input[type="range"]::-webkit-slider-thumb {
    width: 12px;
    -webkit-appearance: none;
    height: 12px;
    cursor: ew-resize;
    background: #fff !important;
    /* box-shadow: -340px 0 0 320px rgb(69, 180, 228), inset 0 0 0 40px rgb(69, 180, 228);
    */
    border-radius: 50%;
    transition: box-shadow 0.2s ease-in-out;
    position: relative;
  }

  .stock-styling .toolbar button {
    font-size: 12px;
  }

  .stock-styling .Overview input[type="range"] {
    outline: 0;
    border: 0;
    border-radius: 500px;
    width: 325px;
    max-width: 100%;
    margin-left: 0.5rem;
    /* margin: 24px 0 16px;
    */
    transition: box-shadow 0.2s ease-in-out;
  }

  .stock-styling .Overview h4 {
    padding-left: 0;
  }

  .stock-styling .high_low {
    display: grid;
    padding-top: 12px;
    /* margin-top: -2rem;
    */
    padding-left: 0rem;
    padding-right: 0.5rem;
  }

  .stock-styling .Over_SubVal:first-child {
    margin-left: 0.5rem;
  }

  .stock-styling .Stock_nm {
    font-size: 22px;
    padding-left: 0rem;
  }

  .stock-styling .Funds_nm {
    display: block;
    justify-content: space-between;
    align-items: center;
  }

  .stock-styling .fixed_top {
    position: fixed;
    display: grid;
    /* justify-content: space-around;
    */
    top: 62px;
    /* left: 0;
    */
    width: 856px;
    z-index: 10000000;
    box-shadow: 0 2px 24px 0 #000;
    background-color: #f7f7f7 !important;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    padding: 1rem;
  }

  .stock-styling .Funds_icons img {
    width: 17px !important;
    height: 17px !important;
  }

  .stock-styling .Funds_icons #Copy {
    width: 35px !important;
    height: 35px !important;
  }

  .stock-styling .Funds_Sub_Details {
    margin-top: 2rem;
  }

  .stock-styling .tabbable-line>.nav-tabs {
    border: none;
    margin: 0px;
    height: 2rem;
    overflow-x: scroll;
  }

  .stock-styling .tab-pane {
    display: grid !important;
    justify-content: space-between;
  }

  .stock-styling #outerContainer {
    margin-left: 0rem;
    margin-right: 0rem;
  }

  .stock-styling .tab-content .Flex_Diff {
    display: grid;
    font-size: 14px;
  }

  .stock-styling .Price_Range {
    display: flex;
    padding-left: 0rem;
    justify-content: center;
    padding-top: 1rem;
  }

  .stock-styling .Price_Range .Price_Line {
    padding-left: 1rem;
  }

  .stock-styling .Price_Pos_Neg {
    padding-left: 1rem;
  }

  .stock-styling .Stock_Value {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }

  .stock-styling .Stock_Value .Price_Line {
    display: none;
  }

  .stock-styling .Middle_Line {
    display: none;
  }

  .stock-styling .Price_COV {
    display: grid;
    width: 7rem;
    padding-top: 12px;
  }

  .stock-styling .right_Pane {
    margin-top: 0rem;
    margin-left: 0rem;
  }

  .stock-styling .QVT {
    text-align: center;
  }

  .stock-styling .Graph_Details {
    display: grid;
    justify-content: space-between;
  }

  .stock-styling .Technicals {
    background-color: #fff;
    padding: 1rem;
    border-radius: 15px;
  }

  .stock-styling .Graph_Middle_Line {
    border-right: none;
    height: 2rem;
    padding-left: 1rem;
  }

  .stock-styling .Change_Diff {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 26px;
    grid-row-gap: 14px;
  }

  .stock-styling .Key_Metrics h4 {
    font-size: 16px;
    color: gray;
    font-weight: 900;
    padding-left: 0.5rem !important;
  }

  .stock-styling .Key_Metrics .Flex_Diff {
    border: 1px solid #d1d1d1;
    border-radius: 15px;
  }

  .stock-styling .Key_Metrics .Price_Line {
    display: none;
  }

  .stock-styling .Key_Metrics .Flex_Diff span:first-child {
    color: gray;
    font-size: 12px;
  }

  .stock-styling .Key_Metrics .Flex_Diff span:nth-child(2) {
    font-size: 0.6em;
    font-weight: 800;
    text-align: unset;
  }

  .stock-styling .Change_Diff .Flex_Diff {
    padding: 10px;
  }

  .stock-styling .Flex_Diff .upArrow img {
    float: right;
    margin-right: 0rem;
    margin-top: -1.8rem;
    padding-left: 0.5rem;
  }

  .stock-styling .Flex_Diff .downArrow img {
    float: right;
    margin-right: -0.3rem;
    margin-top: -1.8rem;
    padding-left: 0.5rem;
  }

  .stock-styling .Key_Metrics .Hr {
    width: 300px;
    margin-top: 1rem;
    margin-left: 0.5rem;
    /* margin-right: 1rem;
    */
  }

  .stock-styling .Fund_Details hr {
    margin: 1em 0 !important;
    color: inherit;
    background-color: currentColor;
    border: 0;
    opacity: 0.25;
  }

  .stock-styling .Market_Diff {
    padding: 1rem;
    display: grid;
    justify-content: space-between;
    text-align: center;
    margin-top: 0rem;
  }

  .stock-styling .ms .Flex_Diff {
    display: grid;
    padding-right: 2rem;
    margin-top: 2rem;
    padding: 10px;
  }

  .stock-styling .Brokers_Summary_Graph {
    display: grid;
  }

  .stock-styling .Brokers_Summary .Graph_Middle_Line {
    border-right: 1px solid #c0c3c2;
    height: 0rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .stock-styling .Brokers_Summary_Graph .Left_Graph p {
    font-size: 1em;
    color: gray;
    /* padding-top: 1rem;
    */
  }

  .stock-styling .Right_Graph #chart {
    width: 19rem !important;
  }

  .stock-styling .tabbable-line>.nav-tabs>li>a {
    border: 0;
    margin-right: 0;
    color: #a39696;
    font-size: 15.3px;
    font-weight: 600;
    white-space: nowrap;
  }

  .stock-styling .Market_Diff .Price_Line {
    border-bottom: 1px solid #c0c3c2;
    /* padding-bottom: 2rem;
    */
    /* height: 5rem;
    */
    display: none;
  }

  .stock-styling .Technicals h4 {
    padding-left: 0.5rem;
  }

  .stock-styling .Technicals .Market_Diff .Flex_Diff {
    border: 1px solid #d1d1d1;
    border-radius: 15px;
    padding: 10px;
    margin-top: 20px;
  }

  .stock-styling .Brokers_Summary .Market_Diff .Flex_Diff {
    border: 1px solid #d1d1d1;
    border-radius: 15px;
    padding: 10px;
    margin-top: 20px;
  }

  .stock-styling .styles_scroll-to-top__2A70v {
    display: none;
  }

  .stock-styling .Fund_Details hr {
    display: none;
  }

  .stock-styling .Recomm {
    display: flex;
    padding: 1rem;
    padding-left: 0rem;
    padding-right: 1em;
    white-space: no-wrap;
    /* justify-content: space-between;
    */
  }

  .stock-styling .Recomm div:first-child {
    background-color: #ececec;
    border-radius: 20px;
    /* padding: 5px;
    */
    padding-left: 10px;
    padding-right: 10px;
    color: gray;
    padding: 9px;
    font-size: 11px;
    vertical-align: middle;
    height: 2rem;
    white-space: nowrap;
  }

  .stock-styling .Recomm div:last-child {
    /* background-color: #ececec;
    */
    border-radius: 20px;
    white-space: nowrap;
    /* padding: 5px;
    */
    padding-left: 10px;
    padding: 7px;
    padding-right: 42px;
    color: gray;
    font-size: 11px;
    /* height: 3rem;
    */
  }

  .stock-styling .Compare_Stock {
    background-color: #fff;
    padding: 1rem;
    border-radius: 15px;
  }

  .stock-styling .Mobile_Table {
    position: relative;
    display: flex;
  }

  .stock-styling .Mobile_Table table td:first-child {
    color: #042b62;
    font-size: 13px;
    font-weight: 500;
  }

  .stock-styling .Mobile_Table table {
    margin-top: 1rem;
  }

  .stock-styling .Mobile_Table .table-left table th {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    background: #f3f3f3;
    font-size: 13px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    white-space: pre;
  }

  .stock-styling .tablr_Head th {
    background: #fff;
  }

  .stock-styling .Mobile_Table .tablr_Head th:last-child {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    height: 1rem;
  }

  .stock-styling .Mobile_Table .tablr_Head td {
    font-size: 13px;
  }

  .stock-styling .Table_Header div:nth-child(2) {
    display: none;
  }

  .stock-styling .Mobile_Table_Details {
    display: flex;
    /* float: right;
    */
    /* height: 2rem;
    */
    padding: 10px;
  }

  .stock-styling .content {
    background: #fff;
    padding: 30px;
    display: block;
    height: 100%;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  .stock-styling .popup_Flex {
    display: flow-root !important;
  }

  .stock-styling .modal1>.content {
    /* width: 100%;
    */
    padding: 10px 5px;
    color: #000;
    display: block !important;
  }

  .stock-styling #Stock_table1 {
    display: none;
  }

  .stock-styling .Card_Btn {
    display: block;
  }

  .stock-styling .stock-float-btn .Card_Btn button {
    display: block !important;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {

  .stock-styling .Range_chart {
    display: block;
  }

  .stock-styling .Funds_nm {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 710px;
  }

  .stock-styling .fixed_top {
    position: fixed;
    /* display: grid;
    */
    /* justify-content: space-around;
    */
    top: 80px;
    left: 0;
    width: 769px;
    z-index: 10000000;
    box-shadow: 0 2px 24px 0 #000;
    background-color: #f7f7f7 !important;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    /* padding: 1rem;
    */
  }

  .stock-styling .tabbable-line>.nav-tabs>li:first-child {
    margin-right: 2px;
    margin-left: 6px;
  }

  .stock-styling .Overview {
    width: 720px;
    background-color: #fff;
    padding: 1rem;
    border-radius: 15px;
  }

  .stock-styling .Graph_Overview {
    display: flex;
    justify-content: space-between;
    /* width: 30rem;
    */
  }

  .stock-styling .Graph_Middle_Line {
    border-right: 1px solid #c0c3c2;
    height: 8rem;
    padding-left: 1rem;
    display: none;
  }

  .stock-styling .Graph_Sub_Details {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }

  .stock-styling .Right_Space {
    padding-left: 0.5rem;
  }

  .stock-styling .range {
    display: grid;
    /* place-items: center;
    */
    margin-top: 1.5rem;
  }

  .stock-styling .range input[type="range"] {
    outline: 0;
    border: 0;
    border-radius: 500px;
    width: 672px;
    max-width: 100%;
    margin-left: 0rem;
    /* margin: 24px 0 16px;
    */
    transition: box-shadow 0.2s ease-in-out;
  }

  .stock-styling .Key_Metrics #chart {
    width: 41em;
  }

  .stock-styling .Key_Metrics {
    background-color: #fff;
    margin-top: 3rem;
    padding: 1rem;
    border-radius: 15px;
  }

  .stock-styling .Change_Diff {
    /* margin-top: 1rem;
    */
    padding: 1rem;
    display: flex;
    justify-content: space-between;
  }

  .stock-styling .ms {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }

  .stock-styling .Technicals {
    background-color: #fff;
    margin-top: 3rem;
    padding: 1rem;
    border-radius: 15px;
  }

  .stock-styling .Brokers_Summary_Graph {
    display: grid;
  }

  .stock-styling .Recomm {
    display: flex;
    padding: 1rem;
    /* width: 30rem;
    */
    justify-content: space-between;
  }

  .stock-styling .Brokers_Summary_Graph .Left_Graph p {
    font-size: 15px;
    color: gray;
    /* padding-top: 1rem;
    */
    width: 100%;
  }

  .stock-styling .Brokers_Summary .Right_Graph {
    width: 100%;
    margin-top: 3rem;
    height: 23rem;
  }

  .stock-styling .Market_Diff {
    /* padding: 1.4rem;
    */
    display: flex;
    /* justify-content: space-between;
    */
    text-align: center;
    margin-top: 2rem;
  }

  .stock-styling .Compare_Stock {
    background-color: #fff;
    padding: 1rem;
    border-radius: 15px;
  }

  .stock-styling #Stock_table1 {
    font-size: 13px;
  }

  .stock-styling .ms .Market_Diff .Flex_Diff span:first-child {
    color: #000;
    font-size: 11px;
    white-space: nowrap;
    font-weight: 900;
  }

  .stock-styling .Key_Metrics .Market_Diff .Flex_Diff span:nth-child(2) {
    font-size: 17px;
    font-weight: 800;
    text-align: center;
  }

  .stock-styling .Key_Metrics .Flex_Diff span:nth-child(2) {
    font-size: 15px;
    font-weight: 800;
    text-align: center;
  }

  .stock-styling .Key_Metrics hr {
    width: 658px;
  }

  .stock-styling #Stock_table1 th:last-child {
    border-radius: 0 20px 20px 0;
    padding-right: 0rem !important;
  }

  .stock-styling .Fund_Details hr {
    width: 716px;
  }

  .stock-styling table th {
    padding: 0.9rem;
    text-align: center;
    background-color: #000;
    color: Gray;
    white-space: nowrap;
  }

  .stock-styling .Slab_Chart #chart {
    width: 43rem !important;
  }

  .stock-styling .Fund_Details hr {
    width: 711px;
  }

  .stock-styling .nav-tabs {
    /* border: none;
    */
    margin: 0px;
    white-space: nowrap;
    height: 2rem;
    overflow-x: scroll;
    width: 690px;
    padding-left: 10px;
    border-bottom: 1px solid #c0c3c2;
  }

  .stock-styling ::-webkit-scrollbar {
    display: none;
  }

  .stock-styling .tabbable-line>.tab-content {
    margin-top: 0px;
    /* background-color: #fff;
    */
    border: 0;
    border: none;
    padding: 15px 0;
  }

  .stock-styling .QVT {
    margin-right: 0rem;
    margin-left: 2rem;
  }

  .stock-styling .Middle_Line {
    border-right: 1px solid #c0c3c2;
    height: 13rem;
    margin-top: 3rem;
    margin-left: 1rem;
  }

  .stock-styling .donut {
    margin-right: 3rem;
    /* margin-left: 3rem;
    */
    margin-top: -1rem;
  }

  .stock-styling .QVT_Diff {
    display: grid;
    justify-content: unset;
  }

  .stock-styling .QVT_range {
    background: #fff;
    border-radius: 26px;
    width: 214px;
    height: 2.5rem;
    text-align: center;
    padding: 7px;
    /* padding: 0rem;
    */
    font-size: 17px;
    font-weight: 500;
    margin-top: 1rem;
    color: #000;
  }

  .stock-styling .Qvt_Des {
    display: grid;
    padding-top: 0px;
    /* margin-top: -2rem;
    */
    font-size: 12px;
    padding-left: 1rem;
    padding-top: 5px;
  }

  .stock-styling .Qvt_Des .Strength {
    font-size: 13px;
    color: #89b23b;
    font-weight: 500;
  }

  .stock-styling .Qvt_Des .Valuation {
    font-size: 13px;
    color: #ff7f10;
    font-weight: 500;
  }

  .stock-styling .Qvt_Des .Neutral {
    font-size: 13px;
    color: #f7d81b;
    font-weight: 500;
  }

  .stock-styling .Line {
    border-bottom: 1px solid #d8dbda;
    /* padding-bottom: 0.5rem;
    */
    display: none;
  }

  .stock-styling .Qvt_Des .Neutral {
    font-size: 13px;
    color: #f7d81b;
    font-weight: 500;
  }

  .stock-styling .Chart_Details {
    margin-top: -4rem;
  }

  .stock-styling .Stock_nm {
    font-size: 25px;
    padding-left: 1rem;
  }

  .stock-styling .col-sm-8 {
    flex: 0 0 auto;
    width: 100%;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1399px) {
  .stock-styling .tabbable-line>.nav-tabs {
    border: none;
    margin: 0px;
    height: 2rem;
    white-space: nowrap;
  }

  .stock-styling .tab-content .Flex_Diff {
    display: grid;
    padding-top: 14px;
    color: gray;
  }

  .stock-styling .Graph_Details {
    display: flex;
    justify-content: center;
    /* align-items: center;
    */
  }

  .stock-styling .Compare_Stock {
    background-color: #fff;
    padding: 1rem;
    border-radius: 15px;
  }

  .stock-styling .fixed_top {
    position: fixed;
    display: flex;
    /* justify-content: space-around;
    */
    top: 80px;
    /* left: 0;
    */
    width: 800px;
    z-index: 10000000;
    box-shadow: 0 2px 24px 0 #000;
    background-color: #f7f7f7 !important;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    padding: 1rem;
  }

  .stock-styling .range input[type="range"] {
    outline: 0;
    border: 0;
    border-radius: 500px;
    width: 100%;
    max-width: 100%;
    margin-left: 0rem;
    /* margin: 24px 0 16px;
    */
    transition: box-shadow 0.2s ease-in-out;
  }

  .stock-styling .Key_Metrics #chart {
    width: 48rem;
  }

  .stock-styling .Key_Metrics .Flex_Diff span:nth-child(2) {
    font-size: 1em;
    font-weight: 800;
    text-align: center;
  }

  .stock-styling .Hr {
    width: 100%;
    margin-top: 0rem;
    margin-left: 0.5rem;
    /* margin-right: 1rem;
    */
  }

  .stock-styling .Fund_Details hr {
    width: 789px;
  }

  .stock-styling #Stock_table1 th:last-child {
    border-radius: 0 20px 20px 0;
    padding-right: 0.3rem !important;
  }

  .stock-styling #Stock_table1 table td:first-child {
    color: #042b62;
    font-size: 16px;
    font-weight: 500;
  }
}

@media only screen and (max-width: 1024px) {
  .stock-styling .sList {
    display: grid;
  }

  .stock-styling .right_Pane {
    margin-top: 0.2rem;
  }

  .stock-styling .right_Pane .QVT {
    text-align: center;
  }

  .stock-styling .VLine {
    border-right: none;
    border-bottom: 1px solid #d8dbda;
    padding: 1em;
  }

  .stock-styling .Range_chart {
    display: block !important;
  }
}

.stock-styling .Stock_List_Table {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.stock-styling .currencySymbol {
  font-weight: bold;
  padding: 0.3em !important;
}

.stock-styling .SoTList .Sample {
  flex-grow: 1;
  flex-basis: 0;
}

.stock-styling .SoTList .Sample {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.stock-styling .SoTList .Sample:first-child {
  padding-left: 0px;
}

.stock-styling .SoTList .Sample:last-child {
  padding-right: 0px;
}

.stock-styling .Stock_List_Table {
  margin-top: 1rem !important;
}

.stock-styling .mobile-explore a {
  float: right;
  margin-top: 1rem;
}

@media only screen and (max-width: 600px) {
  .stock-styling .Stock_List_Table_Item {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.stock-styling .mypagination {
  justify-content: flex-end;
  overflow: scroll;
}

.stock-styling .mypagination .page-item {
  outline: none !important;
  box-shadow: none !important;
}

.stock-styling .mypagination .page-item .page-link {
  background: none !important;
  outline: none !important;
  transition: none !important;
}

.stock-styling .mypagination .page-link {
  border: 0;
}

.stock-styling .mypagination .page-item.active {
  border-bottom: 2px solid #042b62;
  outline: none !important;
  box-shadow: none !important;
  transition: none !important;
}

.stock-styling .mypagination .page-link:focus {
  outline: none !important;
  box-shadow: none !important;
}

.stock-styling .mypagination .page-item a {
  color: #042b62;
}

.stock-styling .mypagination .page-item.active a {
  color: #042b62;
}

.stock-styling .Btn.Sort:focus,
.stock-styling .Btn.Sort:active {
  background: #fff;
  color: #042b62;
  border-top: 1px solid #042b62;
}

.stock-styling .data-loader img {
  width: 200px;
}

.stock-styling .data-loader {
  justify-content: center;
}

.stock-styling details {
  position: relative;
  width: 7rem;
  /* width: 200px;
  */
  margin-right: 1rem;
}

.stock-styling details[open] {
  z-index: 1;
}

.stock-styling summary {
  padding: 9px;
  height: 2.5rem;
  cursor: pointer;
  border-radius: 5px;
  background-color: #042b62;
  list-style: none;
  border-radius: 30px;
}

.stock-styling summary::-webkit-details-marker {
  display: none;
}

.stock-styling details[open] summary:before {
  content: "";
  display: block;
  width: 100vw;
  height: 100vh;
  background: transparent;
  position: fixed;
  top: 10px;
  left: 0;
}

.stock-styling summary:after {
  content: "";
  width: 10px;
  height: 10px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #fff;
  float: right;
  margin-top: 5px;
}

.stock-styling summary:focus {
  outline: none;
}

.stock-styling details[open] summary:after {
  transform: rotate(180deg) translate(0%, 0%);
}

.stock-styling details ul {
  width: 100%;
  background: #ddd;
  position: absolute;
  top: calc(100% + 0.5rem);
  left: 0;
  padding: 1rem;
  margin: 0;
  box-sizing: border-box;
  border-radius: 5px;
  max-height: 200px;
  overflow-y: auto;
}

.stock-styling details ul::-webkit-scrollbar {
  display: none;
}

.stock-styling details li {
  margin: 0;
  padding: 1rem 0;
  border-bottom: 1px solid #ccc;
}

.stock-styling details li:first-child {
  padding-top: 0;
}

.stock-styling details li:last-child {
  padding-bottom: 0;
  border-bottom: none;
}

.stock-styling summary.radios {
  counter-reset: radios;
  text-align: center;
}

.stock-styling summary.radios:before {
  content: var(--selection);
}

.stock-styling .radios input[type="radio"] {
  counter-increment: radios;
  appearance: none;
  display: none;
}

.stock-styling .FilterItem h4 {
  font-size: 16px;
}

.stock-styling .FilterItem {
  max-height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.stock-styling .mf_desktop_filters_form .category_filter {
  margin-left: 3rem;
}

.stock-styling .mf_desktop_filters_form input[type="radio"] {
  counter-increment: radios;
  appearance: auto !important;
  display: none;
}

.stock-styling input[type="radio"]:checked {
  display: inline;
  --display: block;
}

.stock-styling input[type="radio"]:after {
  content: attr(title);
  display: inline;
  font-size: 1rem;
  color: #fff;
}

.stock-styling details ul.list {
  counter-reset: labels;
}

.stock-styling details label {
  width: 100%;
  display: flex;
  cursor: pointer;
  justify-content: center;
}

.stock-styling details label span {
  --display: none;
  display: var(--display);
  width: 1rem;
  height: 1rem;
  border: 1px solid #727272;
  border-radius: 3px;
}

.stock-styling input[type="date"]:focus::before {
  content: none;
}

.stock-styling .QVT .popup-overlay {
  position: fixed;
  inset: 0px;
  display: flex;
  z-index: 999;
  pointer-events: auto;
  backdrop-filter: brightness(0.4) !important;
}

.stock-styling .Market_Diff .popup-overlay {
  position: fixed;
  inset: 0px;
  display: flex;
  z-index: 999;
  pointer-events: auto;
  backdrop-filter: none !important;
}

.stock-styling .Graph_Sub_Details .Right_Space {
  border-left: 1px solid #c0c3c2;
}

.stock-styling .Funds_nm {
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.stock-styling .Funds_nm .Stock_Header {
  font-size: 1.6em;
  line-height: 1.6em;
  font-weight: bold;
  color: #000;
}

.stock-styling .card-bx {
  border: 2px solid #fff;
  border-radius: 15px;
}

.stock-styling .sc-eCYdqJ.rec-carousel {
  margin-top: 1rem;
}

.stock-styling .Brokers_Summary_Graph .Left_Graph {
  width: 60%;
}

.stock-styling .Brokers_Summary_Graph .Right_Graph {
  width: 40%;
}

.stock-styling .Brokers_Summary_Graph .Right_Graph .apexcharts-legend {
  flex-wrap: nowrap;
}

.stock-styling .Brokers_Summary_Graph .Right_Graph .apexcharts-legend .apexcharts-legend-text {
  font-size: 0.8rem;
}

@media screen and (min-width: 1024px) and (max-width: 1399px) {
  .stock-styling .Chart_Details {
    font-size: 9px;
  }

  .stock-styling .Chart_Details .QVT_range {
    height: 1.9rem;
    padding: 5px;
  }

  .stock-styling .tab_default_1 .row {
    display: grid;
  }
}

.Stock_Details {
  min-height: 80vh;
}

.mylist-items .in-tr-item {
  position: relative;
}

.mylist-items .in-tr-item p {
  position: absolute;
  top: 0;
  right: 0;
}

.mylist-items .txi-item {
  white-space: nowrap;
}

.items-main-container .item-container {
  display: flex;
  flex-direction: column;
}

.my-dob-calendar {
  width: 100%;
}

.my-dob-calendar .react-date-picker__calendar {
  z-index: 9999 !important;
}

.my-dob-calendar .react-date-picker__wrapper {
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  padding: 5px 10px 5px 10px;
  height: 2.5rem;
}

.Bank-details .b-layout .bank-info .react-date-picker__wrapper {
  width: 100%;
  background-color: #fff;
  white-space: nowrap;
  border-radius: 0.375rem !important;
  /* padding: 4px 5px 0px 0px !important; */
  border: 1px solid #ced4da !important;
}

.nominee-calendar .react-date-picker__wrapper {
  border-color: #ced4da;
}

.nominee-calendar .react-date-picker__inputGroup__leadingZero {
  font-size: 1rem !important;
  display: inline-block;
}

.nominee-calendar input {
  font-size: 1rem !important;
}

.nominee-calendar input::placeholder {
  font-size: 1rem !important;
}

.dob8 .react-date-picker__clear-button {
  display: none;
}

/* .dob8.m_selected .react-date-picker__clear-button {
  display: block;
} */
.Header_Compare .addmore-fund-compare {
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.Header_Compare .addmore-fund-compare svg {
  color: #042b62;
}

.Header_Compare .Header_Name .Search {
  position: relative;
}

.Header_Compare .search-result-list {
  z-index: 999;
  max-height: 50vh;
  overflow-y: auto;
  position: absolute;
  top: 60px;
  left: 0;
  background-color: #e9ecef;
  padding: 1rem;
  border-radius: 10px;
  width: 100%;
}

.Header_Compare .search-result-list a {
  text-decoration: none;
}

.TopSectors .First_Div1 {
  padding-top: 1rem;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.icon-text-bxline {
  display: flex;
}

.icon-text-bxline p.Nm {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 85%;
}

.Other_Detail {
  display: flex;
  color: gray;
  margin-left: 30px;
  padding-bottom: 2em;
  display: flex;
  align-items: stretch;
}

.page-mutualfund-details .fixed_top_Nav {
  background: #f0f4f3;
}

.mycrop-bx {
  display: flex;
  justify-content: space-between;
}

.mycrop-bx .in-mycrop-1 {
  display: flex;
  align-items: flex-end;
}

.mycrop-bx p {
  margin: 0;
  padding: 0;
}

.mycrop-bx .mycrop-rotate-num {
  padding-bottom: 0.2rem;
  padding-left: 1rem;
}

.fintoo-blue {
  color: #042b62;
}

.btn-cross {
  position: absolute;
  right: 10px;
  top: 10px;
}

.digilocker {
  color: #6334fa;
  font-weight: 900;
}

.bank-tbl tr {
  border-top: 1px solid #d3d3d3 !important;
}

.bank-tbl tr td {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.bank-tbl tr td:first-child {
  width: 35%;
}

.RightPanel h4 {
  margin-bottom: 0.4rem;
}

.fintoo-bottom-border {
  border-bottom: 1px solid #d3d3d3;
}

.fintoo-top-border {
  border-top: 1px solid #d3d3d3;
}

.pricingSegmentControlContainer {
  width: 40%;
  margin: 0 auto;
}

.my-segment {
  border-radius: 1rem !important;
  height: 2.5rem;
  background: #fff;
}

.my-segment label {
  line-height: 2.5rem !important;
}

.my-segment>label:first-of-type:nth-last-of-type(3),
.my-segment>label:first-of-type:nth-last-of-type(3):after,
.my-segment>label:first-of-type:nth-last-of-type(3):before,
.my-segment>label:first-of-type:nth-last-of-type(3)~label,
.my-segment>label:first-of-type:nth-last-of-type(3)~label:after,
.my-segment>label:first-of-type:nth-last-of-type(3)~label:before {
  width: 33.333333% !important;
}

.my-segment-2 {
  border-radius: 8px !important;
  background: #F0F0F0;
  border: none !important;
  font-size: 1rem;
  font-weight: 600 !important;
  height: 40px !important;
}

.my-segment-2>label {
  height: 40px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.my-segment-2>label::before {
  border-radius: 8px !important;
  background-color: #042b62 !important;
  height: 40px !important;
}

.my-segment-2>label::after {
  color: white;
  font-weight: 600 !important;
  font-size: 1rem;
  height: 40px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dlc-bx-upload {
  background: #fff;
  border-radius: 15px;
  height: 300px;
  border: 1px solid #4b5763;
  box-shadow: 2px 2px 3px 0 #000;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.dlc-bx-upload .txt-opt-4 {
  color: #a2a2a2;
  padding-bottom: 2rem;
  margin: 0;
}

.dlc-bx {
  margin: auto;
  display: flex;
  flex-direction: row;
  background-image: none;
  justify-content: center;
  align-items: center;
}

.dlc-bx .fnt-seperator {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.dlc-bx .pnf-img-bx {
  text-align: center;
}

.dlc-bx .pnf-img-bx p {
  margin: 0;
  text-align: center;
}

.dlc-bx .pnf-img-bx img {
  width: 70px;
}

.dlc-bx .prf-divider div {
  position: relative;
  width: 1px;
  height: 70%;
  background: #000;
}

.dlc-bx .prf-divider div:after {
  border: 1px solid #000;
  border-radius: 1rem;
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 99;
  content: "OR";
}

.profile-addmore {
  padding-top: 0.5rem;
  color: #042b62;
  cursor: pointer;
}

.mylist-items {
  border: 1px solid #d3d3d3;
  border-radius: 1rem;
  padding: 1rem;
}

.mylist-items:hover {
  background: #eee;
}

.ftc-tbl tr td:last-child {
  padding-right: 0;
}

.ftc-tbl tr:last-child td {
  padding-top: 1rem;
}

.tbl-options {
  text-align: right;
}

.tbl-options span {
  font-size: 1.2rem;
  color: #042b62;
  cursor: pointer;
}

.tbl-options span:first-child {
  padding-right: 1rem;
}

.ftc-tbl td {
  vertical-align: top;
  width: 50%;
  padding-left: 1rem;
  padding-right: 1rem;
}

.ftc-tbl td p {
  margin: 0;
}

.ftc-tbl td .tbl-txt {
  font-weight: bold;
  font-size: 1rem;
}

.my-badge {
  font-size: 0.8rem;
  background: #042b62;
  height: 1.6rem;
  width: 1.6rem;
  display: inline-block;
  padding: 0;
  margin: 0;
  text-align: center;
  line-height: 1.6rem;
  color: #fff;
  border-radius: 1.6rem;
}

.ResiStatus {
  margin-bottom: 1.5rem;
}

.profile-space-1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.profile-space {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.profile-border {
  border-bottom: 1px solid #d3d3d3;
}

.profile-border-1 {
  border-top: 1px solid #d3d3d3;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.fn-video-record {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 370px;
}

.fn-video-record.PhotoVideo {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fn-video-record video {
  border-radius: 15px;
}

.fn-video-record .video-init-bx {
  cursor: pointer;
  text-align: center;
}

.fn-video-record .video-init-bx img {
  width: 60px;
  height: 60px;
}

.new-upload-bx {
  background: #fff;
  border: 1px solid #4b5763;
  border-radius: 15px;
  padding: 1rem;
}

.video-bx {
  position: relative;
}

.video-bx .video-retake {
  width: 5rem;
  height: 5rem;
  line-height: 1rem;
  position: absolute;
  top: calc(50% - 2.5rem);
  left: calc(50% - 2.5rem);
  background: rgba(0, 0, 0, 0.5);
  border: solid 1px #fff;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  cursor: pointer;
  text-align: center;
}

.video-bx .video-retake:hover {
  background: rgba(0, 0, 0, 0.7);
}

.video-bx .video-retake .video-retake-btn .vd-span-txt {
  padding-top: 7px;
  color: #fff;
}

.video-bx .video-retake .video-retake-btn img {
  width: 2.2rem;
  height: 2.2rem;
}

.video-bx .video-countdown {
  position: absolute;
  bottom: 0;
  background: #000;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 1rem;
  line-height: 0.9rem;
  justify-content: center;
}

.video-bx .video-countdown .vd-record-txt {
  color: #fff;
}

.video-bx .video-countdown .vd-record-icon-bx {
  padding-right: 5px;
}

.video-bx .video-countdown .vd-record-icon-bx .vd-record-icon {
  color: red;
}

.PhotoVideo {
  text-align: center;
}

.rs-type-ck img {
  width: 60px;
}

/* .profile-container {
  padding-right: 7rem;
  padding-left: 7rem;
} */
.profile-container .error {
  padding-top: 0.6em;
  color: #dc3545;
}

.btn-fintoo-back {
  font-size: 1.7rem;
  color: #042b62;
}

.profile-btn-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.ColorChange {
  position: relative;
}

.ColorChange img {
  width: 70px;
  height: 70px;
}

.ColorChange.active:after {
  background-image: url("./Assets/Images/check_01.svg");
  background-size: cover;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 99;
  content: " ";
}

.ColorChange.active img {
  opacity: 1;
}

.GenderSelect {
  flex-grow: 1;
}

.pn-rht-bx {
  display: flex;
}

.pn-rht-bx .pn-rht-bx-txt {
  padding-right: 10px;
  flex-grow: 1;
}

.gs-bx {
  display: flex;
  align-items: center;
}

.gs-bx .GenderSelect {
  flex-grow: 1;
  gap: 3.8rem;
}

.gs-bx .GenderSelect img {
  width: 4.6rem;
  height: 4.6rem;
}

.fi-list {
  padding-left: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  gap: 10px;
  gap: 2rem;
}

.fi-list .fc-title {
  font-weight: 900;
  color: #a4a4a4;
  font-size: 1.1rem;
}

#emptydiv-no-display {
  width: 11rem;
}

.line-2-seg {
  height: 4rem;
  display: flex !important;
  align-items: center !important;
}

.line-2-seg label {
  text-overflow: initial !important;
  white-space: unset !important;
  line-height: 2rem !important;
}

.line-2-seg label[data-value="Yes"]:after,
.line-2-seg label[data-value="No"]:after,
.line-2-seg label[data-value="Partially Exposed"]:after {
  top: auto !important;
}

.line-2-seg label:after {
  text-overflow: initial !important;
  white-space: unset !important;
}

.page-profile .mycrop-bx {
  flex-direction: column;
}

.page-profile .mycrop-bx .btn-fintoo {
  float: right;
  margin-top: 1.5rem;
}

.page-profile footer {
  margin-top: 15rem;
}

.panel-heading-bx {
  position: relative;
}

.panel-heading-bx .panel-back-btn {
  position: absolute;
  top: 0;
  left: -40px;
}

.uploadpan-section .panel-heading-bx .panel-back-btn {
  visibility: hidden;
}

.panel-heading-bx h4 {
  margin-bottom: 0.4rem;
}

/* .profile-container {
  padding-right: 7rem;
  padding-left: 7rem;
} */
.btn-fintoo-back {
  font-size: 1.7rem;
  color: #042b62;
}

.pop-hr {
  border-top: 1px solid #a3a3a3;
}

.fakeHide {
  height: 0px;
  width: 0px;
  overflow: hidden;
}

.new-bg-white {
  background-color: #fff;
  padding: 1rem;
  border-radius: 1rem;
}

.new-bg-white .crop-bx {
  margin: auto;
}

.my-profile-complete {
  margin-bottom: 0px;
  /* padding-left: 40px; */
}

.my-profile-complete p {
  margin-bottom: 0px;
  line-height: 1rem;
}

.fixedHeaders {
  position: sticky;
  top: 75px;
  background: #fff;
  z-index: 995;
  border-top: 1px solid #e9ecef;
}

@media only screen and (max-width: 850px) {
  .fixedHeaders {
    top: 58px;
  }
}

/* mobile css ------ important ----- */
@media only screen and (max-width: 768px) {
  .VLine {
    border: none;
  }

  .mylist-items .in-tr-item p {
    top: -23px;
    right: -10px;
  }

  .pn-rht-bx {
    flex-direction: column;
  }

  .pn-rht-bx .profile-btn-container {
    padding-top: 1rem;
  }

  .gs-bx .GenderSelect {
    gap: 0;
  }

  .fileContainer {
    height: 200px !important;
  }

  .dlc-bx .fnt-seperator {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .fn-video-record {
    min-height: 200px;
  }

  .rhl-inner {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .dlc-bx .pnf-img-bx img {
    width: 50px;
  }

  .Profile .RightPanel {
    margin-top: 1rem;
  }

  .panel-heading-bx .panel-back-btn {
    padding-bottom: 1rem;
    display: inline-block;
    margin-left: 0;
    position: relative;
    left: 0;
    top: 0;
  }

  .welcome-name {
    display: none;
  }

  .dlc-bx-upload {
    height: 200px;
  }

  .profile-container {
    padding-right: 0px;
    padding-left: 0px;
  }

  .stock-styling .Funds_icons .icon-d {
    display: flex;
    top: 130%;
    left: -45%;
  }
}

.cmp-box {
  width: 12%;
  border-right: 1px solid #d1d1d1;
}

.cmp-box h6 {
  padding-top: 2rem;
}

.fund-detail-grid {
  display: flex;
}

.fund-detail-grid .fund-detail-1 {
  display: flex;
  flex-grow: 1;
  width: 88%;
}

.fund-detail-grid .fund-detail-1 div {
  width: 33.33%;
  flex-grow: 1;
  border-right: 1px solid #d1d1d1;
  display: flex;
  justify-content: center;
}

.fund-detail-grid .fund-detail-1 div:last-child {
  border-right: 0px;
}

.fund-detail-grid .fund-detail-1 p {
  width: 80%;
  padding-top: 2rem;
  padding-right: 2rem;
}

.mybox {
  /* background: #fff; */
  border-radius: 15px;
  border: 1px solid #d8d8d8;
  /* overflow: hidden; */
}

.PortfolioDate {
  /* margin-top: 2rem; */
}

.myboxInner {
  padding: 1rem 3rem;
}

.valueBox {
  display: flex;
  align-items: center;
  margin-top: -14px;
}

.valueBox .valueBoxSymbol {
  font-size: 1.8rem;
  color: #000;
  padding-right: 0.5rem;
}

.valueBox p {
  margin-bottom: 0;
}

.valueBox .valueBoxPortolio {
  font-size: 2rem;
  font-weight: bold;
  color: #042b62;
}

.valueBox .valueBoxPercentage {
  color: #9ac449;
  padding-left: 10px;
  display: flex;
  align-items: center;
  font-weight: 600;
}

.valueBox .valueBoxPercentage svg {
  font-size: 0.9rem;
  padding-left: 0.5rem;
}

.smallCalendar {
  display: flex;
  align-items: center;
  line-height: 1rem;
  font-size: 0.9rem;
  color: #b3b3b3;
}

.smallCalendar span {
  line-height: 1rem;
  /* padding-left: 28px; */
}

.smallCalendar svg {
  font-size: 1.1rem;
  color: #042b62;
  margin-bottom: 5px;
}

.smallCalendar .DateLabel {
  font-weight: 500;
}

.insideTab {
  display: flex;
  /* border-right: 1px solid #d8d8d8; */
}

.insideTab div {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.insideTab div.active {
  background: #042b62;
}

.insideTab div p {
  line-height: 1rem;
  font-size: 1rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  margin-bottom: 0px;
  color: #a3a3a3;
}

.insideTab div:last-child p {
  border-right: 0px;
}

.insideTab div.active p {
  border-right: 0px;
  color: #fff;
}

.insideTabBox {
  background: #fff;
  overflow: hidden;
  border: 1px solid #d8d8d8;
  border-bottom: 0;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.insideTabContent {
  background: #fff;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  /* border-top: 1px solid #e9ecef; */
  border: 1px solid #d8d8d8;
  border-radius: 15px;
}

.cntResults {
  display: flex;
  padding-top: 1.5rem;
  padding-bottom: 0.5rem;
}

.cntResults .cntRItems {
  flex-grow: 1;
}


.stocksTabInfo {
  padding-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 2fr 2fr 2fr 2fr;
}

.stocksTabInfo .borderSpace {
  padding-left: 2rem;
  font-size: 0.9rem;
}

.stocksTabInfo .borderText {
  border-left: 2px #ccc solid;
  line-height: 1.2rem;
  font-size: 1.2rem;
  font-weight: 600;
}

.stocksTabInfo div:first-child .borderSpace {
  padding-left: 0px;
  border-left: 0px;
}

.stocksTabInfo .cntRItems {
  flex-grow: 1;
}

.cntResults .cntRItems {
  flex-grow: 1;
}

.resultOptions {
  display: flex;
  /* float: right; */
}

.resultOptionsBtn {
  background-color: #042b62;
  color: #fff;
  font-size: 0.8rem;
  padding: 5px 20px;
  display: flex;
  align-items: center;
  font-weight: 500;
  margin-left: 0.7rem;
  margin-right: 0.7rem;
  border-radius: 15px;
  min-width: 8rem;
  justify-content: center;
}

.resultOptionsBtn span {
  /* padding-left: 1rem; */
  white-space: nowrap;
}

.fundNameTd {
  width: 26rem;
}

.fundNameTd .fundName9 {
  display: flex;
  width: 100%;
  align-items: center;
}

.fundNameTd .fundName9 img {
  width: 40px;
  border: 1px solid #e9ecef;
  border-radius: 50%;
}

.cntResults .borderSpace {
  padding-left: 2rem;
  font-size: 0.9rem;
}

.cntResults .borderText {
  border-left: 2px #ccc solid;
  line-height: 1.2rem;
  font-size: 1.2rem;
  font-weight: 600;
}

.cntResults div:first-child .borderSpace {
  padding-left: 0px;
  border-left: 0px;
}

.resultOptionsBox {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: flex-end;
}

.resultOptionsBox .no-padding-btn span {
  padding-left: 0px;
}

.ptTableBx {
  padding-top: 1rem;
}

.ptTableBx .eq-22 {
  width: 12.4% !important;
}

.ptTableBx .eq-21 {
  width: 37% !important;
}

.ptTableBx .eq-20 {
  width: 9% !important;
}

.ptTable a {
  color: #212529;
  text-decoration: none;
}

.ptTable th {
  color: #8d8d8d;
  font-weight: 700;
  /* font-size: 0.9rem; */
  /* padding-left: 1rem; */
  /* padding-right: 1rem; */
  padding: 1rem 0rem;
}

/* .ptTable tbody {
  border-top: 1px solid #e9ecef !important;
} */
.ptTable thead {
  border-bottom: 1px solid #e9ecef !important;
}

.ptTable tbody td strong {
  font-weight: 600 !important;
}

.ptTable tbody td {
  padding: 1.5rem 0.5rem;
  /* font-size: 0.9rem; */
  padding-right: 1rem;
}

.ptTable tbody tr:first-child td {
  padding-top: 2rem !important;
}

.totalFundsTxt {
  border: 2px solid #bbb;
  font-size: 0.85rem;
  border-radius: 1rem;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 1rem;
}

.totalFundsTxt span {
  font-size: 1rem;
  margin-left: 10px;
  color: #bbb;
}

.cntRItems .borderSpace:first-child {
  color: #7f7f7f;
  padding-bottom: 0.8rem;
  font-weight: 500;
}

.fundNameCon {
  padding-left: 10px;
}

.fundNameCon .fnc-yy {
  width: 20rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fundNameCon .fn-inner-summary {
  font-size: 0.9rem;
  color: #747474;
}

.fundNameCon .fn-inner-summary div {
  line-height: 0.9rem;
  border-right: 1px solid #d1d1d1;
  padding-left: 1rem;
  padding-right: 1rem;
}

.fundNameCon .fn-inner-summary div:first-child {
  padding-left: 0px;
}

.fundNameCon .fn-inner-summary div:last-child {
  padding-right: 0px;
  border-right: 0px;
}

.topOptions {
  display: flex;
  float: right;
  gap: 0.1rem;
}

.topOptionsButton span {
  padding-left: 0.5rem;
}

.topOptionsButton:last-child {
  margin-left: 1px;
  border-top-right-radius: 15px;
}

.progressBar {
  display: flex;
  width: 100%;
  /* align-items: center; */
  height: 100%;
  padding-top: 2.3rem;
}

.progressBar div {
  height: 10px;
  margin-left: 1px;
  position: relative;
}

.progressBar div::after {
  content: attr(data-title);
  /* border: 1px solid;
  */
  position: absolute;
  top: 15px;
  left: 0;
  font-size: 0.9rem;
}

.progressBar div::before {
  content: attr(data-value);
  /* border: 1px solid;
  */
  position: absolute;
  top: -20px;
  left: 0;
  font-size: 0.7rem;
}

.pt-section-container {
  background: #fff;
  padding: 2rem;
  border-radius: 15px;
}

.pt-section-container .pt-se-innerpadding {
  padding-left: 3rem;
  padding-right: 3rem;
}

.pt-section-container .pt-note {
  color: red;
}

.pt-steps-section {
  font-size: 0.97rem;
}

.pt-steps-section li {
  position: relative;
  padding-top: 15px;
  padding-right: 20px;
  list-style: none;
  font-weight: 400;
}

.pt-steps-section li:before {
  position: absolute;
  content: " ";
  background: url("./Assets/Images/arrow-right_p08.png");
  width: 15px;
  height: 15px;
  background-size: cover;
  display: block;
  top: 18px;
  left: -30px;
}

.mybagde-bx {
  font-size: 1.2rem;
  font-weight: 700;
  padding-bottom: 1rem;
}

.mybagde-bx div:first-child {
  font-size: 1rem;
  line-height: 1.7rem;
  text-align: center;
  width: 1.7rem;
  height: 1.7rem;
  background: #042b62;
  color: #fff;
  border-radius: 50%;
}

.mybagde-bx div:last-child {
  margin-left: 5px;
}

.cams-upload-box {
  text-align: center;
  color: #a3a3a3;
  font-weight: 500;
  width: 20rem;
}

.cams-upload-box .cams-underline {
  border-bottom: 1px solid #e9ecef;
}

.cams-upload-box #pdf-password {
  width: 100%;
  text-align: center;
  border: 0;
}

.cams-upload-box #pdf-password::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #a3a3a3;
  font-weight: 500;
}

.cams-upload-box #pdf-password:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #a3a3a3;
  font-weight: 500;
}

.cams-upload-box #pdf-password::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #a3a3a3;
  font-weight: 500;
}

.cams-upload-box .upld-item {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}

.portfolio-back-panel {
  text-align: center;
  font-weight: bold;
  font-size: 1.2rem;
  position: relative;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px #e9ecef solid;
  margin-bottom: 2rem;
}

.portfolio-back-panel span {
  position: absolute;
  top: 1rem;
  left: 0;
}

.hover-dropdown-box {
  position: absolute;
  top: 28px;
  left: -110px;
  z-index: 111;
  color: #000;
  font-weight: 400;
  display: none;
}

.hover-dropdown-box .my-arrow {
  width: 10px;
  height: 10px;
  border-top: solid 1px #d1d1d1;
  border-left: solid 1px #d1d1d1;
  position: absolute;
  transform: rotate(45deg);
  right: 52px;
  top: 6px;
  background-color: #fff;
}

.hover-dropdown-box .hover-dropdown-content {
  background: #fff;
  border-radius: 10px;
  border: 1px solid #d1d1d1;
  padding: 10px;
  margin-top: 10px;
}

.hover-dropdown-box .download-report-1 {
  padding-top: 0px;
  padding-bottom: 0px;
}

.topOptionsButton {
  cursor: pointer;
  background-color: #042b62;
  color: #fff;
  font-size: 0.9rem;
  padding: 7px 20px;
  display: flex;
  align-items: center;
  font-weight: 500;
  position: relative;
}

.hover-dropdown:hover .download-report-box {
  display: block;
}

.resultOptionsBtn .hover-dropdown-content {
  padding: 0;
}

.resultOptionsBtn .download-report-box {
  padding: 0px;
  top: 25px !important;
  left: -26px !important;
  width: 10rem;
  z-index: 9999;
}

.resultOptionsBtn .custom-dropdown-9 div {
  font-weight: bold;
  font-size: 0.8rem;
  text-align: center;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  cursor: pointer;
}

.resultOptionsBtn .custom-dropdown-9 div:hover {
  background-color: #eee;
}

.opt-buttons {
  display: flex;
  justify-content: flex-end;
}

.opt-buttons .custom-buttons {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.custom-buttons {
  font-size: 0.9rem;
  background: #042b62;
  padding: 0.3rem 1.7rem 0.3rem 1.7rem;
  color: #fff;
  border-radius: 1rem;
  position: relative;
  cursor: pointer;
  text-decoration: none;
}

.custom-buttons:hover {
  color: #fff;
}

.custom-buttons .fn-btn-redeem {
  width: 300px;
  border: 1px solid #042b62;
  border-radius: 15px;
  overflow: hidden;
  display: flex;
}

.custom-buttons .fn-btn-redeem div {
  flex-grow: 1;
  background: #fff;
  color: #000;
  padding: 15px 30px;
  text-align: center;
}

.custom-buttons .fn-btn-redeem div:first-child {
  border-right: 1px solid #eee;
}

.custom-buttons .fn-btn-redeem div:hover {
  background: #042b62;
  color: #fff;
}

.custom-buttons .redeem-box-fn {
  display: none;
  position: absolute;
  left: -195px;
  top: 20px;
  padding-top: 15px;
  z-index: 900;
}

.custom-buttons:hover .redeem-box-fn {
  display: block;
}

.custom-buttons:hover .invest-more-popup {
  display: block;
}

.fund-name {
  padding-bottom: 1rem;
}

.fund-name a {
  text-decoration: none;
  color: #000;
  font-weight: 600;
}

.fintoo-portfolio-box {
  background-color: #fff;
  border-radius: 1rem;
  padding: 2rem;
}

.cutom-tbl-header {
  border-bottom: 1px solid #f0f0f0;
  font-size: 0.8rem;
  font-weight: 900;
  color: #737373;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.cutom-tbl-content {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.cutom-tbl-header,
.cutom-tbl-content {
  display: flex;
}

.cutom-tbl-header .custom-tbl-dt,
.cutom-tbl-content .custom-tbl-dt {
  width: 10rem;
}

.cutom-tbl-header .custom-tbl-amount,
.cutom-tbl-content .custom-tbl-amount {
  width: 10rem;
}

.cutom-tbl-header .custom-tbl-avg,
.cutom-tbl-content .custom-tbl-avg {
  width: 10rem;
}

.cutom-tbl-header .custom-tbl-tt,
.cutom-tbl-content .custom-tbl-tt {
  width: 10rem;
}

.cutom-tbl-header .custom-tbl-units,
.cutom-tbl-content .custom-tbl-units {
  width: 10rem;
}

.gain-tip-here {
  border-radius: 15px;
  background-color: #e9e9e9;
  color: #515151;
}

.gain-tip-here td {
  min-width: 5rem;
}

.page-portfolio .fund-dt-sep {
  color: #a6a6a6;
}

.page-portfolio .fund-logo {
  padding-right: 0.5rem;
}

.page-portfolio .fund-logo img {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
}

.page-portfolio .trnx-heading svg {
  color: #042b62;
}

.page-portfolio .fd-small-dt {
  font-size: 0.8rem;
  color: #696b6a;
}

.page-portfolio .fd-small-dt p {
  margin: 0;
  border-right: 1px solid #ccc;
  line-height: 1rem;
  font-size: 1rem;
  padding-right: 1rem;
  padding-left: 1rem;
}

.page-portfolio .fd-small-dt p:first-child {
  padding-left: 0;
}

.page-portfolio .fd-small-dt p:last-child {
  padding-right: 0;
  border-right: 0;
}

.download-report-1 {
  position: relative;
}

.download-report-1 button {
  background: transparent;
  border: 0px;
  font-size: 20px;
  color: #9e9e9e;
  padding-left: 0.7rem;
  padding-right: 0.7rem;
}

.download-report-1 button:hover {
  color: #042b62;
}

.download-report-1 .drp-1 {
  border-right: 1px solid #e1e5e4;
  padding-right: 1.5rem;
  font-weight: 500;
  white-space: nowrap;
  padding-left: 0.7rem;
}

.download-report-1 .drp-2 {
  padding-left: 1rem;
}

.download-report-1 td {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}

.white-modal {
  background: rgba(255, 255, 255, 1);
}

.white-modal .lbl-title {
  font-size: 1.2rem;
}

.white-modal .modal-dialog {
  max-width: 566px;
  /* margin-top: 4rem; */
}

.white-modal .modal-body {
  padding: 0;
}

.white-modal .modal-content {
  border: 0;
}

.white-modal .modal-header .modal-title {
  text-align: center;
  flex-grow: 1;
  font-size: 1.3rem;
  font-weight: 900;
}

.white-modal .white-modal-btn-box {
  cursor: pointer;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  overflow: hidden;
}

.white-modal .white-modal-btn-box div {
  text-align: center;
  font-weight: bold;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.white-modal .white-modal-btn-box div.cancel {
  background: #d4d4d4;
  color: #787878;
}

.white-modal .white-modal-btn-box div.yes {
  background: #042b62;
  color: #fff;
}

.white-modal .react-date-picker {
  width: 80%;
}

.white-modal .react-date-picker__wrapper {
  border: 0px;
  border-bottom: thin solid gray;
}

.modal-whitepopup-box-item {
  border-top: 1px solid #d1d1d1;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.modal-whitepopup-box-item p {
  margin-bottom: 0;
}

.fn-redeem-modal .switch-fund-btn {
  background: #042b62;
  text-align: center;
  font-weight: bold;
  color: #fff;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  cursor: pointer;
}

.bottom-border-input {
  border: 0px;
  border-bottom: 1px solid #d1d1d1;
}

.grey-color {
  color: #737373 !important;
}

.blue-color {
  color: #042b62 !important;
}

.blue-color {
  color: #042b62 !important;
}

.abs-22 {
  left: -95px !important;
}

.abs-22:before {
  right: 50% !important;
}

.grey-500 {
  color: #8d8d8d;
  font-weight: 500;
}

.pointer {
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.opt-flt-trnx div.active span {
  border-bottom: 2px solid #042b62;
}

.fintoo-small-btn {
  background-color: #042b62;
  color: #fff;
  font-size: 0.9rem;
  padding: 5px 20px;
  display: flex;
  align-items: center;
  font-weight: 400;
  margin-left: 0.7rem;
  margin-right: 0.7rem;
  border-radius: 15px;
}

.fn-right-border {
  border-right: 1px solid #8d8d8d;
}

.fn-right-border .TotalSIP {
  font-weight: 600;
  color: #000;
}

.TotalSIPAmount {
  font-weight: 600;
  color: #000;
}

.fn-tp-text-9 {
  /* line-height: 1rem; */
  /* height: 1rem; */
  font-size: 1rem;
}

.range-calendar {
  position: absolute;
  top: 40px;
  left: -140px;
}

.valueBoxPercentage {
  /* padding-left: 20px;
  */
  display: flex;
  align-items: center;
  font-weight: 600;
  margin: 0px;
}

.valueBoxPercentage svg {
  font-size: 0.9rem;
  padding-left: 0.5rem;
}

.valueBoxPercentage.green {
  color: #9ac449;
}

.valueBoxPercentage.red {
  color: red;
}

.valueBoxPercentage.red svg {
  padding-left: 0px;
  padding-right: 0.5rem;
  transform: rotate(180deg);
}

.ctdropdown svg {
  height: 20px;
}

.triangle-ct {
  position: relative;
}

.triangle-ct::before {
  position: absolute;
  content: " ";
  width: 0px;
  height: 0px;
  border-top: 5px solid black;
  border-right: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid transparent;
  right: -1rem;
  top: 0.4rem;
}

.ds-border-1 {
  border-right: 1px solid #e9ecef;
}

.trx-tbl8 tbody {
  border-top: 1px solid #e9ecef !important;
}

.trx-tbl8 th {
  color: #8d8d8d;
  font-weight: 500;
  font-size: 0.9rem;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.trx-tbl8 td {
  font-weight: 600;
  font-size: 1rem;
  padding: 2rem 2rem !important;
}

.trx-tbl8 tr:first-child td {
  padding-top: 4rem !important;
}

.trx-tbl8 .eq-23 {
  width: 13rem;
}

.trx-tbl8 .eq-24 {
  width: 14rem;
}

.rw-8-f .cl-8-f {
  border-right: 1px solid #e9ecef;
  padding-left: 2rem;
  padding-right: 2rem;
}

.rw-8-f .cl-8-f div {
  line-height: 1.2rem;
}

.rw-8-f .cl-8-f:first-child {
  padding-left: 0px;
}

.rw-8-f .cl-8-f:last-child {
  padding-right: 0px;
  border-right: 0px;
}

.fn-hover-popup {
  position: absolute;
  top: 1rem;
  z-index: 999;
}

.fn-hover-popup .fn-hover-content {
  width: 300px;
  margin-top: 1.5rem;
}

.white-bg {
  background: #fff;
  color: #000;
}

.blue-bg {
  background: #042b62;
}

.invest-more-popup {
  display: none;
  left: -95px;
}

.invest-more-popup .white-bg {
  margin: 1px;
  overflow: hidden;
  border-radius: 15px;
}

.invest-more-popup .fn-cs-tab {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.invest-more-popup .fn-custom-tabs {
  border-top: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
}

.invest-more-popup .fn-title {
  font-size: 1rem;
  font-weight: 600;
}

.invest-more-popup .fn-grey-tab {
  background-color: #ededed;
}

.invest-more-popup .fn-hover-content {
  border-radius: 15px;
  overflow: hidden;
}

.invest-more-popup .invest-big-text {
  font-size: 0.8rem;
  font-weight: 600;
  padding-left: 5px;
}

.invest-more-popup .fn-input {
  padding: 0.375rem 0.75rem;
  width: 100%;
  border: 0px;
}

.invest-more-popup .fn-input::placeholder {
  font-size: 1.1rem;
}

.invest-more-popup .fn-input-text {
  padding: 0.375rem 0.75rem;
  font-size: 1.5rem;
}

.invest-more-popup .fn-blue-btn {
  background-color: #042b62;
  color: #fff;
}

.bx-stopsip {
  top: 15px;
  left: -120px;
  display: none;
  padding-top: 2rem;
}

.bx-stopsip div {
  font-weight: 600;
  border-radius: 10px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  white-space: nowrap;
  background: #fff;
  font-size: 0.9rem;
  z-index: 11;
}

.bx-stopsip-18:hover .bx-stopsip {
  display: block;
}

.fn-h5 {
  font-size: 1.25rem;
}

.curr-amount-swp {
  font-size: 1.2rem;
}

.folio-swp {
  font-size: 1.2rem;
}

.rs-beside-textbox {
  font-size: 1.5rem;
}

.fn-fintootip {
  font-size: 1.2rem;
  font-weight: 900;
}

.flx-otp-bx .flx-item:first-child {
  border-right: 1px solid #737373;
  padding-right: 1.5rem;
}

.flx-otp-bx .flx-item:last-child {
  padding-left: 1.5rem;
}

.textfont {
  font-size: 1.2rem;
}

@media only screen and (max-width: 768px) {
  .myboxInner {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 2.5rem;
  }
}

.realEstateTd {
  width: 16.6%;
}

.FdBondTd {
  width: 11.1%;
}

.poTd {
  width: 11.1%;
}

.insuranceTd {
  width: 14.28%;
}

@media screen and (max-width: 600px) {

  .pricingSegmentControlContainer {
    width: 80%;
    padding-top: 4rem;
  }

  .realEstateTd,
  .FdBondTd,
  .poTd,
  .insuranceTd {
    width: unset;
  }

  .ptTableBx .eq-22 {
    width: 100% !important;
  }

  .ptTableBx .eq-20 {
    width: 100% !important;
  }

  .ptTableBx .mobileCalenderText {
    display: none;
  }

  .ptTableBx table {
    border: 0;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
  }

  .ptTableBx table tr {
    background-color: #f8f8f8;
    padding: 0.35em;
    border-radius: 15px;
    display: block;
    margin-bottom: 0.625em;
  }

  .ptTableBx table tr:first-child td {
    padding: 0.625em !important;
  }

  .ptTableBx table tr:first-child {
    margin-top: 2rem;
  }

  .ptTableBx table td {
    padding: 0.625em;
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right;
  }

  .ptTableBx table td .ProcessFundName {
    width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ptTableBx table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  .ptTableBx table td::before .insideTabContent {
    background: #fff;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    border-top: 1px solid #e9ecef;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .ptTableBx table td:last-child {
    border-bottom: 0;
  }

  .ptTableBx table .eq-23 {
    display: none;
  }

  .ptTableBx table .fundNameTd .fundName9 {
    width: calc(100vw - 64px);
    display: flex;
    justify-content: space-between;
    clear: both;
    overflow: hidden;
  }

  .ptTableBx table .fundNameTd .fundName9 .fundNameCon .fnc-yy {
    width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ptTableBx table .fundNameTd .fundName9 .fundNameCon .fn-inner-summary div {
    line-height: 0.9rem;
    border-right: 1px solid #d1d1d1;
    padding-left: 0rem;
    font-size: 12px;
    padding-right: 1rem;
  }

  .trx-tbl8 {
    border: 0;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
  }

  .trx-tbl8 thead {
    display: none;
  }

  .trx-tbl8 tr {
    background-color: #f8f8f8;
    padding: 0.35em;
    border-radius: 15px;
    display: block;
    margin-bottom: 0.625em;
  }

  .trx-tbl8 tr:first-child td {
    padding: 0.625em !important;
  }

  .trx-tbl8 tr:last-child {
    margin-bottom: 3rem;
  }

  .trx-tbl8 td {
    padding: 0.625em;
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right;
  }

  .trx-tbl8 td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  .trx-tbl8 td::before .insideTabContent {
    background: #fff;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    border-top: 1px solid #e9ecef;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .trx-tbl8 td:last-child {
    border-bottom: 0;
  }

  .trx-tbl8 .eq-23 {
    display: none;
  }

  .trx-tbl8 .fundNameTd .fundName9 {
    width: calc(100vw - 80px);
    width: calc(100vw - 130px);
    clear: both;
    overflow: hidden;
  }

  .trx-tbl8 .fundNameTd .fundName9 .fundNameCon .fnc-yy {
    width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .trx-tbl8 .fundNameTd .fundName9 .fundNameCon .fn-inner-summary div {
    line-height: 0.9rem;
    border-right: 1px solid #d1d1d1;
    padding-left: 0rem;
    font-size: 12px;
    padding-right: 1rem;
  }

  .ptTable {
    border: 0;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
  }

  .ptTable thead {
    display: none;
  }

  .ptTable tr {
    background-color: #f8f8f8;
    padding: 0.35em;
    border-radius: 15px;
    display: block;
    margin-bottom: 0.625em;
  }

  .ptTable tr:first-child td {
    padding: 0.625em !important;
  }

  .ptTable tr:last-child {
    margin-bottom: 3rem;
  }

  .ptTable td {
    padding: 0.625em;
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right;
  }

  .ptTable td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  .ptTable td::before .insideTabContent {
    background: #fff;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    border-top: 1px solid #e9ecef;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .ptTable td:last-child {
    border-bottom: 0;
  }

  .ptTable .eq-23 {
    display: none;
  }

  .ptTable .fundNameTd .fundName9 {
    width: calc(100vw - 80px);
    width: calc(100vw - 130px);
    clear: both;
    overflow: hidden;
  }

  .ptTable .fundNameTd .fundName9 .fundNameCon .fnc-yy {
    width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ptTable .fundNameTd .fundName9 .fundNameCon .fn-inner-summary div {
    line-height: 0.9rem;
    border-right: 1px solid #d1d1d1;
    padding-left: 0rem;
    font-size: 12px;
    padding-right: 1rem;
  }

  .ptTable .mobileTransfer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .ptTable .mobileTransfer div {
    width: 10rem;
  }

  .cntResults {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    overflow: auto;
    margin-left: .6rem;
  }

  .cntResults .borderText {
    border: none;
    padding-left: 0;
  }
}

@media screen and (max-width: 320px) {
  .stock-container .Sample .in-options-title {
    white-space: nowrap;
    font-size: 0.5em;
  }

  #outerContainer {
    margin-left: 0rem;
  }
}

.text-bold {
  font-weight: 900;
}

.progressBarMobile div {
  float: left;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  margin: 0.2rem;
  border-radius: 1rem;
  font-size: 0.7rem;
}

.progressBarMobile div span {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.td-btn-box .btn-fintoo {
  font-size: 0.7rem;
  min-width: 5rem;
}

.long-dropdown-main-box .btn-fintoo {
  font-size: 0.7rem;
}

.bottom-modal .modal-content {
  border-radius: 0px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border: 0px;
}

.bottom-modal .modal-dialog {
  transform: translate3d(0, 100vh, 0) !important;
  position: fixed;
  top: auto;
  right: auto;
  left: auto;
  bottom: 0;
  width: 100%;
  margin: 0px;
}

.bottom-modal.fade .modal-dialog {
  transform: translate3d(0, 0, 0) !important;
}

.returns-txt {
  min-width: 5.5rem;
}

.bb-0 {
  border-bottom: 0px !important;
}

/* mobile css ------ important ----- */
@media only screen and (max-width: 768px) {
  .TabList {
    margin-top: 0rem !important;
  }

  .blue-btn-box .blue-btn-box.active {
    background: #042b62;
    color: #fff;
  }

  .returns-txt {
    min-width: 5.5rem;
  }

  .resultOptionsBox {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    justify-content: center;
    padding-bottom: 2rem;
  }

  .fn-tip-box {
    justify-content: flex-end;
  }

  .mobile-bottom-button {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  .fn-fintootip {
    font-size: 0.8rem;
  }

  .rw-8-f .cl-8-f {
    padding-right: 0px;
  }

  .mobile-bottom-btns {
    display: none !important;
    position: fixed;
    width: 100vw;
    background: #fff;
    padding-top: 1rem;
    padding-bottom: 1rem;
    bottom: 0;
  }

  .mobile-bottom-btns .bottom-buttons-list {
    width: 100%;
    border-radius: 50px;
    overflow: hidden;
  }

  .mobile-bottom-btns div {
    float: left;
    width: 25%;
    border-right: 1px solid;
  }

  .mobile-bottom-btns div:last-child {
    border-right: 0;
  }

  .mobile-bottom-btns div button {
    background: #042b62;
    color: #fff;
    border: 0;
    box-shadow: 0;
    width: 100%;
    font-weight: 500;
    padding: 8px;
  }

  .white-modal .modal-dialog {
    transform: none !important;
  }

  .white-modal .modal-content {
    height: 100vh;
  }

  .white-modal .modal-header {
    padding: 0px;
  }

  .white-modal .modal-dialog {
    margin-top: 0px;
  }

  .trx-tbl8 tr td {
    padding: 0px !important;
  }

  .trx-tbl8 tr:first-child td {
    padding-top: 0px !important;
  }

  .mylist-items .in-tr-item p {
    top: -23px;
    right: -10px;
  }

  .pn-rht-bx {
    flex-direction: column;
  }

  .pn-rht-bx .profile-btn-container {
    padding-top: 1rem;
  }

  .gs-bx .GenderSelect {
    gap: 0;
  }

  .fileContainer {
    height: 200px !important;
  }

  .dlc-bx .fnt-seperator {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .fn-video-record {
    min-height: 200px;
  }

  .rhl-inner {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .dlc-bx .pnf-img-bx img {
    width: 50px;
  }

  .Profile .RightPanel {
    margin-top: 1rem;
  }

  .panel-heading-bx .panel-back-btn {
    padding-bottom: 1rem;
    display: inline-block;
    margin-left: 0;
    position: relative;
    left: 0;
    top: 0;
  }

  .welcome-name {
    display: none;
  }

  .dlc-bx-upload {
    height: 200px;
  }

  .profile-container {
    padding-right: 0px;
    padding-left: 0px;
  }

  .stock-styling .Funds_icons .icon-d {
    display: flex;
    top: 130%;
    left: -45%;
  }

  .insideTabBox {
    overflow-x: auto;
  }

  .insideTabBox .insideTab {
    width: fit-content;
    white-space: nowrap;
  }

  .insideTabBox .insideTab div {
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-align: center;
  }

  .insideTabBox .insideTab div.active {
    background: none;
  }

  .insideTabBox .insideTab div.active p {
    color: #737373;
  }

  .insideTabBox .insideTab div strong {
    clear: both;
    display: block;
  }

  .ptTableBx {
    padding-top: 0;
  }
}

.nav-div {
  border-radius: 2rem;
  overflow: hidden;
  margin: 0 auto 2rem;
  border: 1px solid #042b62;
}

.nav-div div.active {
  background: #042b62;
  color: #fff;
}

.nav-div div {
  padding: 0.5rem;
  text-align: center;
  font-weight: 700;
}

.whitebg-rounded {
  background: #fff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #000;
}

.fltr-items-li-w50 {
  width: 50% !important;
}

.fltr-items-li-w100 {
  width: 100% !important;
}

.section-heading {
  font-weight: 500;
  /* text-transform: uppercase; */
}

.page-Commondashboard .bar_box {
  background: #fff f;
  display: flex;
  /* animation: animateWidth 1s ease forwards; */
  border-radius: 40px;
  height: 3rem;
  margin-left: 1em;
  border: 1px solid #d3d3d3;
}

.page-Commondashboard .bar_box .bar {
  padding: 0rem;
  background: #ececec;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  height: 3rem;
  width: 50%;
  margin: 0em;
}

.page-Commondashboard .sensexnifty:first-child {
  padding-left: 1em;
  padding-right: 0.4em;
}

.page-Commondashboard .sensexnifty span {
  padding-left: 0.3em;
  padding-right: 0.3em;
  font-size: 0.8rem;
}

.page-Commondashboard .sensexnifty .valuetext {
  color: #000;
  font-weight: 700;
}

.page-Commondashboard .sensexnifty .negvaluetext {
  color: red;
  font-weight: 700;
}

.page-Commondashboard .sensexnifty .negvaluetext2 {
  color: red;
  font-size: 0.8em;
  font-weight: 700;
}

.page-Commondashboard .sensexnifty .posvaluetext {
  color: green;
  font-weight: 700;
}

.page-Commondashboard .sensexnifty .posvaluetext2 {
  color: green;
  font-size: 0.8em;
  font-weight: 700;
}

.page-Commondashboard .sensexLine {
  border-right: 1px solid #afafaf;
  height: 1em;
  margin-top: 0.3em;
}

.page-Commondashboard .Section {
  margin: 2em;
  margin-left: 1em;
  margin-top: 3rem;
}

.page-Commondashboard .Section .cardBox {
  border: 1px solid #eeee;
  border-radius: 15px;
  padding: 1rem;
  padding-left: 1rem;
  padding-top: 1rem;
  height: 289px;
}

.page-Commondashboard .Section .GraphImg {
  background-image: url(./Assets/Images/CommonDashboard/Graph.svg);
  background-size: 427px;
  background-position: bottom -13px right -9px;
  background-repeat: no-repeat;
}

.page-Commondashboard .FPlan {
  background-image: url(./Assets/Images/CommonDashboard/CiecleSection.svg);
  background-size: 463px;
  background-position: top -13px right -9px;
  background-repeat: no-repeat;
}

.page-Commondashboard .Section .cardBox .Imgbox {
  position: relative;
  margin-top: -1.3rem;
}

.page-Commondashboard .Section .cardBox .Imgbox img {
  position: absolute;
}

.page-Commondashboard .Section .cardBox .imgboxDataGathering {
  position: relative;
}

.page-Commondashboard .Section .cardBox .imgboxDataGathering img {
  float: right;
  margin: 0;
  position: absolute;
  right: -50px;
}

/* 
.page-Commondashboard .Section .cardBox .autoAdvisoryLabel {
  margin-top: 1rem;
} */

.page-Commondashboard .Section .autoAdvisory {
  border: 1px solid rgba(0, 0, 0, 0.10);
}

.page-Commondashboard .Section .autoAdvisory .normalText {
  font-size: 1.2em;
  color: #fff;
  font-weight: 700;
}

.page-Commondashboard .Section .autoAdvisory .secondText {
  font-size: 0.9em;
  font-weight: 500;
  color: #0f5c78;
  max-width: 900px;
}

.page-Commondashboard .Section .autoAdvisory .TextLabel {
  font-weight: 600;
}

.page-Commondashboard .Section .autoAdvisory .valueLabel {
  font-weight: 700;
  font-size: 2rem;
}

.page-Commondashboard .Section .autoAdvisory .valueLabel .bigBalue {
  font-weight: 700;
  font-size: 2rem;
  color: #161a34;
}

.page-Commondashboard .Section .autoAdvisory .borderRight {
  border-right: 1px solid #d7d7d7;
  /* margin: 0 2rem; */
}

.page-Commondashboard .Section .autoAdvisory .valueLabel span {
  color: #161a34 !important;
  font-weight: 700;
  font-size: 2rem;
}

.page-Commondashboard .Section .bgimage {
  background: linear-gradient(90deg,
      rgba(219, 230, 236, 1) 50%,
      rgba(255, 255, 255, 1) 99%) !important;
  border-radius: 12px;
}

.page-Commondashboard .Section .ExpertPlan {
  margin: 0rem;
  background-repeat: no-repeat;
  border: 1px solid #dce7eb;
  overflow: hidden;

  background-image: url("./Assets/Images/CommonDashboard/01_data_gathering.png");
  background-position: bottom 28px right 0px;
  background-size: 113px;
  background-repeat: no-repeat;
}

.page-Commondashboard .Section .ExpertPlan .normalText {
  font-size: 1.2em;
  color: #045f96;
  font-weight: 700;
}

.page-Commondashboard .Section .ExpertPlan .secondText {
  font-size: 0.9em;
  font-weight: 500;
  color: #9aa2a5;
  max-width: 900px;
}

.page-Commondashboard .Section .ExpertPlan .expertplanLabel {
  color: #185e9c;
  line-height: 26px;
  font-size: 1.2rem;
}

.page-Commondashboard .Section .ExpertPlan .expertplanLabel .Bigtextlabel {
  font-weight: 700;
}

.page-Commondashboard .Section .ExpertPlan .investBtn {
  margin-top: 1rem;
  border-radius: 20px;
  background-color: #015ea1;
  padding: 4px;
  border: none;
  font-weight: 700;
  width: 6.5rem;
  color: #fff;
  font-size: 0.7rem;
  outline: none;
  text-decoration: none;
}

.page-Commondashboard .Section .startInvest {
  background: #fff;
  background: linear-gradient(146deg,
      rgba(255, 255, 255, 1) 44%,
      rgba(255, 255, 255, 1) 78%);
  border: 1px solid #e2e2e2;
}

.page-Commondashboard .Section .startInvest .normalText {
  font-size: 1.2em;
  color: #0062ad;
  font-weight: 700;
}

.page-Commondashboard .Section .startInvest .secondText {
  font-size: 0.9em;
  font-weight: 500;
  color: #757575;
  max-width: 900px;
}

.page-Commondashboard .insideTabBoxd {
  margin-left: 0em;
  border-bottom: 1px solid #e6e6e6 !important;
  width: 100%;
}

.page-Commondashboard .insideTabBoxd .insideTabd {
  display: flex;
  /* justify-content: space-between; */
}

.page-Commondashboard .insideTabBoxd .insideTabd div {
  /* width: 100%; */
  width: 10rem;
  padding-left: 1rem;
  border-bottom: 3px solid transparent !important;
}

.page-Commondashboard .insideTabBoxd .insideTabd div .ps-2 {
  line-height: 1rem !important;
  border-right: 1px solid #e5e5e5;
  color: #8f8f8f;
  font-weight: 600;
  padding-top: 0.5rem;
}

.page-Commondashboard .insideTabBoxd .insideTabd .pointer:last-child .ps-2 {
  border-right: none;
}

.page-Commondashboard .insideTabBoxd .insideTabd .pointer:first-child p {
  text-align: left;
}

.page-Commondashboard .insideTabBoxd .insideTabd div:last-child p {
  border-right: none;
}

.page-Commondashboard .insideTabBoxd .insideTabd .active {
  border-bottom: 3px solid #042b62 !important;
  color: #000 !important;
  background-color: none !important;
}

.page-Commondashboard .insideTabBoxd .insideTabd .active p {
  line-height: 1rem !important;
  color: #000;
  font-weight: 600;
}

.page-Commondashboard .MFData {
  padding: 2rem;
}

.page-Commondashboard .MFData .Datatext p {
  font-size: 1em;
  font-weight: 600;
}

.page-Commondashboard .MFData button {
  background-color: #042b62;
  color: #fff;
  padding: 3px;
  padding-left: 5px;
  padding-right: 5px;
  width: 8rem;
  font-weight: 700;
  border-radius: 20px;
  border: 1px solid #042b62;
}

.page-Commondashboard .MFData .rec-dot {
  background-color: #92d3ef;
  box-shadow: 0 0 1px 3px #92d3ef;
  height: 6px;
  width: 6px;
  margin: 6px;
  border-radius: 50%;
}

.page-Commondashboard .MFData .rec.rec-arrow {
  visibility: hidden;
}

.page-Commondashboard .MFData .MFListItems {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  margin-left: 0rem;
}

.page-Commondashboard .MFData .MFListItems .DataItem {
  border: 1px solid #f0f0f0;
  width: 250px;
  margin: 1rem;
  margin-left: 0rem;
  border-radius: 20px;
}

/* .page-Commondashboard .MFData .MFListItems .DataItem:first-child {
  background-color: #ececec;
  padding: 0.2em;
} */
.page-Commondashboard .MFData .MFListItems .DataItem .imgBox {
  border-radius: 50%;
  padding: 1rem;
  padding-left: 1rem;
  border-radius: 50%;
}

.page-Commondashboard .MFData .MFListItems .DataItem .imgBox img {
  width: 40px;
  height: 40px;
}

.page-Commondashboard .MFData .MFListItems .DataItem .Funds {
  padding-left: 0.7em;
  padding-right: 0.7em;
  max-width: 315px;
  text-overflow: ellipsis;
}

.page-Commondashboard .MFData .MFListItems .DataItem .Funds .FundText {
  font-size: 0.9em;
  font-weight: 600;
  text-overflow: ellipsis;
  color: #000;
  /* width: 100%; */
  white-space: pre-wrap;
  overflow: hidden;
  max-width: 154px;
}

.page-Commondashboard .MFData .MFListItems .imgCenter {
  display: grid;
  place-items: center;
  align-items: center;
}

.page-Commondashboard .MFData .MFListItems .SliderText {
  font-size: 1em;
  font-weight: 600;
}

.page-Commondashboard .MFData .MFListItems .cateLine {
  border: 0.3px solid #d5d5d5;
  height: 3em;
  margin-top: 0.8em;
}

.page-Commondashboard .Addsection {
  /* margin-top: -2rem; */
  padding-left: 2rem;
}

.page-Commondashboard .Addsection .AddImg {
  background-color: #ececec;
  border-radius: 10px;
  width: 50px;
  height: 50px;
}

.page-Commondashboard .Addsection .AddImg img {
  width: 25px;
  height: 25px;
  margin: auto;
  margin-top: 0.7em;
  display: grid;
  align-items: center;
  place-items: center;
}

.page-Commondashboard .Addsection .newsData {
  margin-left: 2rem;
  padding-top: 0.3rem;
}

.page-Commondashboard .Addsection .newsData .Newstitle {
  color: #000;
  font-weight: 600;
}

.page-Commondashboard .Addsection .newsData .NewsBottomLine {
  color: #c6c6c6;
}

.page-Commondashboard .NewsBOx {
  width: 100%;
  background-color: #f5f5f5;
  border-radius: 20px;
  padding: 1.2rem;
  height: 260px;
}

.page-Commondashboard .BlogSecond {
  margin-top: 2rem;
}

.page-Commondashboard .NewsBOx .imgNews {
  border: 1px solid #fff f;
  border-radius: 15px;
  height: 100px;
  width: 296px;
}

.page-Commondashboard .NewsBOx .NewsTitle {
  font-size: 1em;
  padding-top: 1em;
  max-width: 200px;
  font-weight: 600;
}

.page-Commondashboard .NewsBOx .NewsText {
  padding-top: 0.4em;
  font-size: 0.8em;
  color: gray;
}

.page-Commondashboard .BannerBox {
  border: 1px solid #eee e;
  border-radius: 15px;
  width: 350px;
  background: linear-gradient(146deg,
      rgba(226, 235, 240, 1) 44%,
      rgba(226, 235, 240, 1) 78%);
}

.page-Commondashboard .Blogsimgbx {
  width: 50px;
  height: 50px;
  border-radius: 15px;
  border: 1px solid #fff;
}

.page-Commondashboard .Blogsimgbx img {
  width: 50px;
  border-radius: 12px;
}

.nil-steps .step-title {
  margin-top: 0.6rem;
  margin-bottom: 0;
  color: #a0a0a0;
}

.nil-steps .step-box {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.nil-steps .step-box.right::after {
  width: 50%;
  content: " ";
  background: #e6dddd;
  height: 1px;
  position: absolute;
  top: 7px;
  right: 0;
  z-index: 99;
}

.nil-steps .step-box.left::before {
  width: 50%;
  content: " ";
  background: #e6dddd;
  height: 1px;
  position: absolute;
  top: 7px;
  left: 0;
  z-index: 99;
}

.nil-steps .step-box div {
  position: relative;
  height: 1.2rem;
  width: 1.2rem;
}

.nil-steps .step-num {
  border-radius: 55%;
  background: #042b62;
  width: 1.2rem;
  height: 1.2rem;
  display: block;
  color: #fff;
  text-align: center;
  margin-bottom: 0;
  position: absolute;
  z-index: 100;
}

.nil-steps .red-step .step-num {
  background-color: #a0a0a0;
}

.w-88 {
  width: 88% !important;
}

.w-12 {
  width: 12% !important;
}

.srv-validation-message {
  font-size: 0.9rem;
  color: red;
  font-weight: 400;
}

.Right_Btn .AddCart:disabled {
  /* color: #fff; */
  cursor: not-allowed;
}

.Right_Btn .fin-cal-button:disabled {
  cursor: not-allowed;
}

.error {
  color: red;
}

.btn-add-fnds .btn-fintoo {
  min-width: 11rem;
}

.fin-p-txt p {
  font-size: 1rem;
  font-weight: 600;
  color: #919191;
}

.fintoo-small-btn-bx .fintoo-small-btn a {
  white-space: nowrap;
  color: #fff;
}

.Inactive img {
  opacity: 0.4;
}

@media only screen and (min-width: 768px) and (max-width: 1397px) {
  .fundNameTd {
    width: 20rem;
  }

  .font-size-8 {
    font-size: 0.8rem !important;
  }

  .fundDateYear {
    width: 10rem;
  }

  .fintoo-small-btn-bx .fintoo-small-btn {
    /* font-size: 0.8rem; */
    padding: 2.5px 10px;
  }

  .fintoo-small-btn-bx .fintoo-small-btn a {
    white-space: nowrap;
    color: #fff;
  }

  .pFundAmount {
    width: 10rem;
  }

  .pFundOptions {
    width: 18rem;
  }
}

@media only screen and (min-width: 500px) and (max-width: 856px) {
  .pFundOptions {
    width: 5rem;
  }

  .fnOptions7 {
    flex-direction: column;
  }

  .fnOptions7 .fintoo-small-btn {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.Section .rec-dot {
  background-color: #dde0e7;
  box-shadow: 0 0 1px 3px #dde0e7 !important;
  height: 6px;
  width: 6px;
}

.Section .rec-dot_active {
  background-color: #28bdf5;
  box-shadow: 0 0 1px 3px #28bdf5 !important;
  height: 6px;
  width: 6px;
}

/* .Section .rec-item-wrapper {
  width: 330.43px !important;
} */
.Section .CardBox {
  border: 1px solid #F1F9FD;
  background-color: #F1F9FD;
  padding: 1.4rem;
  border-radius: 12px;
  outline: none;
  height: 282px;
}

.Section .CardBox .TextLabel {
  color: #000;
  font-size: 1.4rem;
  font-weight: 500;
  margin-top: 1rem;
  line-height: 1.8rem;
}

/* .Section .CardBox .TextLabel span {
  color: #042b62c7;
  font-weight: 700;
  font-size: 1.4rem;
} */

.Section .CardBox .listLabel {
  color: #6a6a6a;
  max-width: 300px;
  margin-top: 0.7rem;
  font-size: 0.84rem;
  height: 4rem;
}

.Section .rec-item-wrapper .CardBox .SlideNextDiv1 {
  margin-top: 1.1rem;
}

.Section .CardBox .sildeBtn {
  padding: 5px 15px 5px 15px;
  background-color: #042b62;
  color: #fff;
  font-size: 0.8rem;
  font-weight: 700;
  border-radius: 20px;
  border: none;
  width: max-content;
  outline: none;
  margin: 0.7rem 0;
  position: absolute;
  bottom: 0.5rem;
}

.Section .CardBox .sildeBtn .slideRed {
  text-decoration: none;
  color: white;
}

/* .Section .CardBox .ImgBx {
  float: right;
  padding-top: 0rem;
  padding-bottom: 1.5rem;
} */

.Section .PlanBox {
  border: 1px solid #d8d8d8;
  padding: 0rem;
  border-radius: 12px;
  /* margin-top: 2.6rem; */
}

.Section .PlanBox .TradeImg {
  margin-top: 1rem;
  margin-left: -2rem;
  padding-right: 2rem;
}

.Section .PlanBox .TradeImg img {
  width: 200px;
}

.Section .PlanBox .TradeText {
  padding: 2.7rem 1rem;
}

.Section .PlanBox .TradeNextImg {
  padding: 7.7rem 4rem;
}

.Section .PlanBox .TradeNextImg img {
  width: 28px;
}

.Section .BlogBox {
  border: 1px solid #f1f3f4;
  /* background-color: #f1f3f4; */
  border-radius: 12px;
}

.Section .PlanBox .BigPlanText {
  /* color: #005b98; */
  color: #161A34;
  font-weight: 700;
  font-size: 1.8rem;
}

.Section .PlanBox .SmallPlanText {
  color: #161A34;
  font-size: 1rem;
  padding: 1.2rem 0;
}

.Section .PlanBox .ExploreherePlan button {
  padding: .4rem 1.2rem;
  background-color: #042b62;
  color: #fff;
  font-size: 0.8rem;
  font-weight: 700;
  border-radius: 20px;
  border: none;
  width: max-content;
  outline: none;
  margin: 1.2rem 0;
}

.page-Commondashboard .NewPlanbox {
  /* background-image: url(./Assets/Images/CommonDashboard/CiecleSection.svg); */
  background-size: 452px;
  background-position: top -13px right -9px;
  background-repeat: no-repeat;
}

.Section .FintooBlog .BlogBox .Newsbox {
  margin-left: 0rem !important;
}

.Section .BlogBox .Newsbox {
  border: 1px solid #f1f3f4;
  background-color: #f1f3f4;
  padding: 1.7rem 1rem;
  border-radius: 12px;
  outline: none;
  margin-right: 1rem;
  margin-left: 1rem;
}

.Section .NetworthBlog .Newsbox {
  padding: 2.3rem 1.5rem;
}

.Section .Newsbox {
  border: 1px solid #f1f3f4;
  background-color: #f1f3f4;
  padding: 1.25rem;
  border-radius: 12px;
  outline: none;
  margin-right: 1rem;
  margin-left: 1rem;
}

.Section .Newsbox .NewsImg {
  border-radius: 12px;
}

.Section .Newsbox .NewsImg img {
  width: 100%;
  height: 120px;
  /* object-fit: cover; */
  border-radius: 12px;
}

.Section .NewsHeading {
  font-size: 1.1rem;
  font-weight: 700;
  margin-top: 1rem;
  line-height: 19px;
  max-width: 22rem;
}

.Section .NewsHeading div {
  max-width: 400px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.Section .NewsText {
  color: #6a6a6a;
  font-size: 0.8rem;
  padding-top: 0.2rem;
  width: 300px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.ProfileHeader {
  padding: 3rem 3rem 0rem 0rem;
}

.ProfileHeader .profieDetails {
  line-height: 1.8rem;
}

.ProfileHeader .profile .welcomeInfo {
  color: #a1a1a1;
  color: #a1a1a1;
  font-size: 0.8rem;
  font-weight: 600;
}

.ProfileHeader .profile .ProfileName {
  color: #000;
  font-weight: 700;
  cursor: pointer;
  font-size: 1.2rem;
}

.ProfileHeader .hover-dropdown-box {
  position: absolute;
  top: 5.5rem;
  width: 290px;
  float: right;
  left: 77.7%;
  right: 7px;
  z-index: 111;
  color: #000;
  font-weight: 400;
  display: none;
}

.ProfileHeader .hover-dropdown-box::before {
  content: "";
  position: absolute;
  display: block;
  display: none;
  width: 0px;
  left: 91%;
  top: 5%;
  border: 15px solid transparent;
  border-top: 0;
  border-bottom: 15px solid #e5e5e5;
  transform: translate(-50%, calc(-100% - 5px));
}

.ProfileHeader .hover-dropdown-box .hover-dropdown-content {
  background: #fff;
  border-radius: 10px;
  border: 1px solid #d1d1d1;
  padding: 0px;
  margin-top: 15px;
}

.ProfileHeader .hover-dropdown-box .hover-dropdown-content .profile-nm-details .User-details {
  font-size: 0.9em;
  padding: 1px;
  font-weight: 500;
  /* padding: 0.5em 2em; */
  padding: 1rem 2rem;
}

.ProfileHeader .hover-dropdown-box .hover-dropdown-content .profile-nm-details {
  padding: 0em;
  background: #e5e5e5;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
}

.ProfileHeader .download-report-box .ProfileUserName {
  font-size: 1.4em;
  font-weight: 600;
  line-height: 16px;
}

.ProfileHeader .download-report-box .user-mail {
  color: #adadad;
  font-size: 1.1rem;
}

.ProfileHeader .hover-dropdown-box .hover-dropdown-content .profile-sub-details .text-label {
  font-size: 1em;
  color: #000;
  font-weight: 500;
  padding: 1em;
  padding-bottom: 0;
}

.ProfileHeader .hover-dropdown-box .hover-dropdown-content .profile-other-details .profiledata:first-child {
  padding-top: 1.4em;
}

.ProfileHeader.hover-dropdown-box .hover-dropdown-content .profile-other-details {
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 0em;
}

.ProfileHeader .hover-dropdown-box .hover-dropdown-content .profile-other-details .profiledata {
  display: flex;
  padding: 1em 0.6em 1em 0.6em;
  /* justify-content: space-between; */
  outline: none;
  border-bottom: 1px solid #e5e5e5;
}

.ProfileHeader .hover-dropdown-box .hover-dropdown-content .profile-other-details .profiledata:last-child {
  border-bottom: none;
}

.ProfileHeader .profiledata .textlabel {
  font-size: 1em;
  color: #000;
  font-weight: 600;
  padding: 0.2em 1em;
  padding-bottom: 0;
}

.ProfileHeader .profiledata a {
  text-decoration: none;
}

.ProfileHeader .hover-dropdown-box::before {
  content: "";
  position: absolute;
  display: block;
  width: 0px;
  right: 0%;
  top: 5%;
  border: 15px solid transparent;
  border-top: 0;
  border-bottom: 15px solid #e5e5e5;
  transform: translate(-50%, calc(-100% - 5px));
}

.Section .InvestmentType .MFData {
  padding: 0rem;
  padding-top: 2rem;
}

.Section .InvestmentType .rec-item-wrapper {
  width: 194.43px !important;
}

/* .page-Commondashboard .MFData .MFListItems .DataItem:first-child {
 
} */
.MFListItems .active {
  background-color: #ececec;
  padding: 1em 1em 0em;
}

.MFListItems .inactive {
  background-color: transparent;
  padding: 1em 1em 0em;
}

/* .page-Commondashboard .MFData .MFListItems .DataItem{
  background-color: #ececec;
  padding: 1em 1em 0em;
} */
.Section .InvestmentType .MFListItems .DataItem {
  border: 1px solid #f0f0f0;
  width: 185px;
  cursor: pointer;
  /* margin: 1rem; */
  margin-left: 1rem;
  border-radius: 20px;
}

.Section .InvestmentType .MFListItems {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  margin-left: 0rem;
}

.Section .InvestmentType .rec-slider-container {
  margin: 0px !important;
}

.Section .InvestmentType .rec-dot {
  background-color: #dde0e7;
  box-shadow: 0 0 1px 3px #dde0e7 !important;
  height: 6px;
  width: 6px;
  margin: 6px;
  border: none;
}

.Section .InvestmentType .rec-dot_active {
  background-color: #28bdf5;
  box-shadow: 0 0 1px 3px #28bdf5 !important;
  height: 6px;
  width: 6px;
  box-shadow: none;
  margin: 6px;
}

.Section .InvestmentType button {
  padding: 0 !important;
}

.Section .FintooBlog {
  margin-top: -2.5rem;
}

.Section .FintooBlog .BlogBox {
  margin-left: 2rem;
}

.Section .FintooBlog .Newsbox {
  margin-left: 2rem !important;
  margin-right: 0;
}

.Section .FintooBlog .NewsBOx {
  width: 100%;
  background-color: #f5f5f5;
  border-radius: 20px;
  padding: 1.5rem;
  line-height: 1.4rem;
}

.Section .BlogBox .NewsBOx .BlogSection {
  padding-left: 2rem;
  max-width: 229px;
}

.Section .BlogBox .NewsBOx .BlogSection .BlogTitle {
  font-size: 1.1rem;
  font-weight: 700;
  max-width: 200px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.Section .BlogBox .NewsBOx .BlogSection .BlogDec {
  font-size: 0.8rem;
  color: gray;
  padding-top: 0.2rem;
  max-width: 200px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.Section .GoalText {
  color: #042b62;
  font-weight: 700;
  font-size: 1.2rem;
  margin-left: 1.2rem;
}

.Section .GoalTabs {
  border-bottom: 1px solid #e7e7e7;
  margin-bottom: 2rem;
}

.Section .GoalTabs .AddGoalBtn {
  background-color: #042b62;
  color: #fff;
  padding: 3px;
  width: 8rem;
  font-weight: 700;
  float: right;
  border-radius: 20px;
  border: 1px solid #042b62;
}

.GoalTabs .insideTabBoxd {
  border-bottom: none !important;
}

.GoalTabs .insideTabBoxd .borderRight {
  border-right: 1px solid rgb(229, 229, 229);
  height: 1.4rem;
  margin-top: 0.6rem;
}

.GoalTabs .insideTabBoxd div p {
  text-align: left;
  line-height: 0.8rem;
  /* padding: 0rem; */
  color: #8f8f8f;
  font-weight: 600;
  padding: 0.7rem;
  margin-right: 1rem;
}

.GoalTabs .insideTabBoxd .active {
  border-bottom: none !important;
}

.GoalTabs .insideTabBoxd .active p {
  background-color: #f6f6f8;
  color: #000;
  font-weight: 600;
  border-radius: 7px;
}

.GoalTabs .insideTabBoxd .pointer .TotalGoal {
  color: #042b62;
  font-weight: 700;
  font-size: 1.6rem;
  display: inline-block;
  width: auto;
  vertical-align: sub;
  line-height: 1px;
  padding-left: 3px;
}

.Section .GoalTabs .lastTab {
  margin-top: -0.5rem;
}

.Section .pointer .bottomText {
  font-size: 10px;
  padding-top: 6px;
}

.TotalGoalBox {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(322px, 1fr));
  gap: 10px;
  margin: 0 1rem;
}

.totalGoals .Activebox {
  background-color: #f6f6f8;
  border-radius: 12px;
  /* overflow: auto; */
}

.totalGoals .GoalsBox {
  padding: 1.3rem 1rem 1.3rem 1rem;
}

.totalGoals .GoalsBox .GoalImg {
  margin-top: 0.3rem;
}

.totalGoals .GoalsBox .GoalImg img {
  background-color: rgb(236, 236, 236);
  border-radius: 10px;
  padding: 0.2rem;
  width: 35px;
  margin-right: 0.5rem;
  display: inline-block;
  vertical-align: middle;
}

.totalGoals .GoalsBox .GoalName {
  color: #000;
  font-size: 0.8rem;
  font-weight: 650;
  width: 140px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.totalGoals .GoalsBox .GoalSubTxt {
  color: #b7b7b9;
  font-size: 0.7rem;
  font-weight: 600;
}

.totalGoals .GoalsBox .GoalDate {
  color: #888;
  font-size: 0.7rem;
  font-weight: 600;
}

.totalGoals .GoalValue .RsSymbol {
  font-size: 1.2rem;
}

.totalGoals .GoalValue .goalAmt {
  color: #042b62;
  font-size: 1.2rem;
  font-weight: 650;
}

.totalGoals .RsSymbol2 {
  font-size: 0.8rem;
}

.totalGoals .goalNegAmt {
  color: #f90003;
  font-size: 0.8rem;
  font-weight: 650;
  white-space: nowrap;
}

.totalGoals .GoalType {
  color: green;
  font-style: italic;

  font-size: 0.7rem;
}

.totalGoals .GoalsBox .bar_box {
  background: #fe0000;
  display: flex;
  margin-top: 0.4rem;
  height: 0.3rem;
  /* animation: animateWidth 1s ease forwards; */
  margin-left: 0rem;
  border-radius: 0;
}

.totalGoals .GoalsBox .bar_box .bar {
  height: 0.2rem;
  border-radius: 0;
}

.Section .ScoreCardBox button {
  background-color: #042b62;
  color: #fff;
  padding: .4rem 2rem;
  font-size: 0.8rem;
  font-weight: 700;
  margin-right: 0.5rem;
  margin-top: 1rem;
  float: right;
  border-radius: 20px;
  border: 1px solid #042b62;
}

.Section .ScoreCardBox button:nth-child(2) {
  /* padding: 3px 13px; */
}

.Section .ScoreCardBox button a {
  text-decoration: none;
  color: white;
}

.Section .ScoreCard #meter-box {
  display: inline-block;
  /* border: 1px solid; */
  position: relative;
}

.Section .ScoreCard #meter-box #line {
  position: absolute;
  width: 25px;
  top: 0;
  left: 0;
  z-index: 999;
  transform: translate(84px, 2px);
  transition: transform 0.7s ease-in-out;
  display: inline-block;
  height: 264px;
}

.Section .ScoreCard #meter-box #line img {
  width: 100%;
  /* border: 1px solid; */
  position: absolute;
  top: 24px;
}

.Section .ScorecardValue {
  font-size: 1.5rem;
  vertical-align: middle;
}

.Section .ScorecardValue .Scoreval {
  color: #042b62;
  font-weight: 700;
}

.Section .ScorecardValue .borderLine {
  color: #959595;
  /* height: 1rem; */
}

.Section .ScorecardValue .ScoreTotal {
  color: #959595;
  font-weight: 700;
}

.profile-popups .modal-header {
  border-bottom: 0px;
}

.document-popup .img-box9 {
  border: 1px solid #000;
  padding: 1rem;
  border-radius: 1rem;
}

.document-popup .modal-body h3 {
  text-align: center;
  color: #a1a1a1;
}

.document-popup .modal-body .modal-body-box {
  padding-left: 10rem;
  padding-right: 10rem;
}

.sample-check-txt {
  color: #a1a1a1;
  display: flex;
  padding-top: 1rem;
}

.sample-check-txt img {
  width: 1.5rem;
  height: 1.5rem;
}

.sign-popup .inv-img-86 {
  width: 300px;
}

.sign-popup .inv-img-iqc {
  width: 200px;
}

.sign-popup .inv-sign-border {
  border-top: 1px solid #e0e0e0;
}

.sign-popup .btn-fintoo {
  padding-right: 1rem;
  padding-left: 1rem;
  margin: auto;
  display: block;
  min-width: 10rem;
}

.modal-medium {
  max-width: 600px;
}

.autoAdvisory .TodayDate {
  color: #d0d0d0;
  width: auto;
  font-size: 0.9rem;
  font-weight: 600;
  display: inline-block;
  vertical-align: sub;
}
.autoAdvisory button[disabled] {
  /* border: 1px solid black; */
  pointer-events: none; 
  opacity: .5;
  /* background-color: #eee; */
  /* color: #666; */
  cursor: not-allowed;
  float:  left;
}


.page-Commondashboard .sensexNifty {
  margin-top: 2rem;
  margin-bottom: 2rem;
  float: right;
  margin-right: 2rem;
}

.page-Commondashboard .Section .GoalBox .GraphImgGoal {
  /* background-image: url(./Assets/Images/CommonDashboard/04_graph.svg); */
  background-size: 250px;
  background-position: bottom right;
  background-repeat: no-repeat;
}

.page-Commondashboard .Section .GoalBox .lifeInsurance {
  /* background-image: url(./Assets/Images/CommonDashboard/02_Medical_insurance.png); */
  background-size: 150px;
  background-position: bottom right;
  margin: 0rem;
  background-repeat: no-repeat;
}

.page-Commondashboard .Section .card-box .lifeInsurance {
  /* background-image: url(./Assets/Images/CommonDashboard/LifeInsurance.svg); */
  background-size: cover;
  /* filter: blur(.8px); */
  /* -webkit-filter: blur(.8px); */
  /* background-position: bottom 28px right -5px; */
  margin: 0rem;
  background-repeat: no-repeat;
}

.page-Commondashboard .Section .card-box .CreditReportbox {
  /* background-image: url('./components/CommonDashboard/CreditScore/Creditperformance.svg'); */
  background-size: 163px;
  background-position: right 92px;
  margin: 0rem;
  background-repeat: no-repeat;
}

/* CommonDashboard Mobile CSS */

.form-control.is-valid,
.was-validated .form-control:valid {
  background-image: none;
  background-image: none;
}

.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size="1"],
.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size="1"] {
  /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e"); */
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.profiledata.active {
  background-color: #042b62;
}

.profile-img {
  border-radius: 50%;
}

@media (min-width: 1281px) {
  .Section .rec-item-wrapper {
    width: 330.43px !important;
  }
}

/* Renew Popup */
.react-responsive-modal-modal {
  border-radius: 20px !important;
  outline: none !important;
}

.HeaderText {
  font-weight: 700;
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 1rem;
}

.PopupContent {
  font-size: 1rem;
  font-weight: 500;
  color: gray;
  text-align: center;
  padding: 0rem 6rem;
}

.ButtonBx {
  display: grid;
  place-items: center;
  /* width: 30%; */
}

.ButtonBx button {
  margin: 0.7rem;
}

.ButtonBx .ReNew {
  padding: 0.6rem 2rem;
  background-color: #042b62;
  border: none;
  cursor: pointer;
  color: #fff;
  font-size: 1.1rem;
  font-weight: 800;
  border-radius: 30px;
}

.outlineBtn {
  padding: 0.6rem 2rem;
  background-color: white;
  border: 1px solid #042b62;
  cursor: pointer;
  color: #042b62;
  font-size: 1.1rem;
  font-weight: 800;
  border-radius: 30px;
}

.ButtonBx .SkipBtn {
  font-size: 1.2rem;
  color: gray;
  cursor: pointer;
  font-weight: 800;
}

.ExpirePopup {
  background-color: #f5f5f5;
  padding: 0.5rem 2rem 0.5rem 1rem;
  border-radius: 12px;
  margin-left: 1rem;
}

.RenewMsgbox {
  max-width: 56%;
}

.contentText {
  color: #9f9f9f;
  font-weight: 600;
}

.RenewBtn {
  border: none;
  padding: 0.3rem 1rem;
  white-space: nowrap;
  /* padding: .2rem 3rem; */
  background-color: #042b62;
  color: #fff;

  border-radius: 20px;
}

@media only screen and (max-width: 600px) {
  .ProfileHeader {
    padding: 1rem 0rem 0rem 0rem;
  }

  .ProfileHeader .hover-dropdown-box .hover-dropdown-content .profile-nm-details .User-details {
    padding: 1rem;
  }

  .RenewMsgbox {
    max-width: 100%;
  }

  .ExpirePopup {
    margin-left: 0rem;
    padding: 1rem;
  }

  .RenewBtn {
    padding: 0.2rem 2rem;
    font-size: 0.9rem;
  }

  .page-Commondashboard .sensexNifty {
    margin-top: 2.2rem;
    float: inherit;
    text-align: center;
    margin-right: 0rem;
  }

  .page-Commondashboard .SensexRow {
    display: grid;
    place-items: center;
    margin-right: 0rem;
    line-height: 29px;
  }

  .page-Commondashboard .sensexnifty:first-child {
    padding-left: 0rem;
    text-align: center;
    font-size: 1.1rem;
  }

  .page-Commondashboard .sensexnifty {
    font-size: 1.1rem;
  }

  .page-Commondashboard .sensexNifty .sensexLine {
    display: none;
  }

  .page-Commondashboard .sensexnifty .valuetext {
    color: #000;
    font-weight: 700;
  }


  .page-Commondashboard .FPlan {
    margin-top: 2rem;
  }

  .page-Commondashboard .Section .autoAdvisory .TextLabel {
    font-weight: 600;
    font-size: 0.9rem;
  }

  .page-Commondashboard .Section .ExpertPlan {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .page-Commondashboard .Section .autoAdvisory .valueLabel {
    font-weight: 700;
    font-size: 1.6rem;
  }

  .page-Commondashboard .Section .autoAdvisory .valueLabel span {
    color: #042b62;
    font-weight: 700;
    font-size: 1.7rem;
  }

  .page-Commondashboard .Section .autoAdvisory .valueLabel .bigBalue {
    font-weight: 700;
    font-size: 2.2rem;
  }

  .page-Commondashboard .Section {
    margin: 0em;
    margin-left: 0em;
    margin-top: 2rem;
  }

  .Newsbox {
    margin-top: 1rem;
  }

  .Section .NewsHeading {
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.6rem;
    margin-top: 1.3rem;
  }

  .Section .NewsText {
    color: #6a6a6a;
    font-size: 1rem;
  }

  /* .Section .BlogBox {
    margin-top: 2rem;
  } */
  .page-Commondashboard .insideTabBoxd .insideTabd {
    display: flex;
    justify-content: space-between;
    overflow: auto;
  }

  .page-Commondashboard .insideTabBoxd .insideTabd::-webkit-scrollbar {
    display: none;
  }

  .page-Commondashboard .insideTabBoxd .insideTabd div .ps-2 {
    line-height: 1rem !important;
    border-right: 1px solid #e5e5e5;
    color: #8f8f8f;
    font-weight: 600;
    padding-top: 0.5rem;
    white-space: nowrap;
    font-size: 0.8rem;
  }

  .Section .BlogBox .NewsBOx .BlogSection .BlogTitle {
    font-size: 1rem;
    font-weight: 700;
  }

  .Section .BlogBox .NewsBOx .BlogSection .BlogDec {
    font-size: 0.9rem;
    color: gray;
  }

  .page-Commondashboard .Section .GraphImg {
    /* background-image: url(./Assets/Images/CommonDashboard/Graph.svg); */
    background-size: 156px;
    background-position: bottom right;
    background-repeat: no-repeat;
    /* height: 166px; */
  }

  .Section .BlogBox .NewsBOx .BlogSection {
    padding-left: 1rem;
  }

  .NewsBOx p {
    font-size: 1.2rem;
  }

  .Section .text-bold {
    font-size: 1.2rem;
  }

  .page-Commondashboard .Section .card-box .col-md-4 {
    margin-top: 1rem;
  }

  .page-Commondashboard .Section .cardBox {
    /* padding: 2rem 3rem 2rem 0.9rem; */
    height: 305px;
  }

  .page-Commondashboard .FPlan {
    margin-top: 2rem;
  }

  .Section .rec-item-wrapper {
    /* width: 330.43px !important; */
    padding: 10px !important;
  }

  .Section .CardBox {
    width: 100%;
  }

  .ProfileHeader .hover-dropdown-box {
    position: absolute;
    top: 3.5rem;
    width: 290px;
    float: right;
    left: 21.7%;
    right: 7px;
    z-index: 111;
    color: #000;
    font-weight: 400;
    display: none;
  }

  .page-Commondashboard .Section .cardBox .Imgbox img {
    position: absolute;
    left: -1.9rem;
  }

  .ProfileHeader .download-report-box .ProfileUserName {
    font-size: 1em;
    font-weight: 600;
    line-height: 16px;
  }

  .ProfileHeader .download-report-box .user-mail {
    color: #adadad;
    font-size: 0.8rem;
  }

  .ProfileHeader .profiledata .textlabel {
    font-size: 0.8em;
    color: #000;
    font-weight: 600;
    padding: 0.2em 1em;
    padding-bottom: 0;
  }

  .Section .PlanBox .TradeImg {
    margin-top: 0rem;
    margin-left: 0rem;
  }

  .Section .PlanBox .BigPlanText {
    /* color: #005b98; */
    color: #161A34;
    font-weight: 700;
    font-size: 1.3rem;
  }

  .Section .PlanBox .SmallPlanText {
    color: #161A34;
    font-size: 0.9rem;
    line-height: 22px;
    padding-top: 0.6rem;
    width: 270px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .page-Commondashboard .NewPlanbox {
    background-size: 389px;
  }

  .Section .PlanBox .TradeImg img {
    width: 200px;
    display: grid;
    place-items: center;
    margin: auto;
    padding-top: 1rem;
  }

  .Section .PlanBox .TradeText {
    padding: 1.6rem 1rem;
  }

  .Section .PlanBox .TradeNextImg {
    padding: 0.7rem 1rem;
    padding: 0.7rem 1rem;
    position: absolute;
    right: 21px;
  }

  .Section .PlanBox .TradeNextImg img {
    width: 25px;
    position: relative;
    top: -4rem;
    right: 0rem;
  }

  .page-Commondashboard .NewsBOx {
    height: 100%;
  }

  .page-Commondashboard .Section .GoalBox .GraphImgGoal {
    /* background-image: url(./Assets/Images/CommonDashboard/Graph.svg); */
    background-size: 212px;
    background-position: bottom right;
    background-repeat: no-repeat;
  }

  .page-Commondashboard .ScoreCardBox {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .page-Commondashboard .Section .cardBox .Imgbox {
    position: relative;
    margin-top: -2.1rem;
  }

  .page-Commondashboard .Section .cardBox .Imgbox img {
    position: absolute;
    left: 0rem;
    width: 100px;
  }

  .GoalTabs .insideTabBoxd div p {
    white-space: nowrap;
    font-weight: 600;
  }

  .Section .GoalTabs .AddGoalBtn {
    background-color: #042b62;
    color: #fff;
    padding: 3px;
    width: 7rem;
    font-weight: 700;
    font-size: 0.8rem;
    margin-bottom: 1rem;
    float: right;
    border-radius: 20px;
    border: 1px solid #042b62;
  }

  .page-Commondashboard .insideTabBoxd .overflow-auto::-webkit-scrollbar {
    display: none;
  }

  .page-Commondashboard .insideTabBoxd .insideTabd div {
    padding: 0rem 1rem 0rem 0rem;
  }

  .page-Commondashboard .insideTabBoxd .insideTabd div p {
    white-space: nowrap;
    padding: 0rem 1rem 0rem 1rem;
  }

  .Section .InvestmentType .MFListItems .DataItem {
    border: 1px solid #f0f0f0;
    width: 252px;
    cursor: pointer;
    margin: 0rem;
    margin-left: 0rem;
    border-radius: 20px;
    padding: 1rem;
  }

  .Section .InvestmentType .rec-item-wrapper {
    width: 348px !important;
  }

  .Section .FintooBlog .BlogBox {
    margin-left: 0rem;
  }

  .Section .FintooBlog {
    margin-top: 0rem;
  }

  .Section .FintooBlog .Newsbox {
    margin-left: 0rem !important;
  }

  .Section .Newsbox {
    margin-top: 2rem !important;
  }

  /* .page-Commondashboard .Section .card-box .lifeInsurance {
    background-image: url(./Assets/Images/CommonDashboard/02_Medical_insurance.png);
    background-size: 95px;
    background-position: bottom right;
    margin: 0rem;
    background-repeat: no-repeat;
  } */

  .Section .CardBox .SlideNextDiv1 {
    margin-top: 1.1rem;
  }

  .Section .CardBox .SlideNextDiv1:last-child {
    margin-top: 1.1rem;
  }

  /* .Section .PlanBox {
    height: 100%;
  } */
  .contentText {
    font-size: 0.8rem;
  }
}

.page-Commondashboard .react-responsive-modal-root {
  z-index: 100000;
}

.page-Commondashboard .react-responsive-modal-overlay {
  background-color: rgb(193 189 189 / 20%);
  backdrop-filter: blur(1px);
}

.page-Commondashboard .fade,
.page-Commondashboard .show,
.page-Commondashboard .modal {
  /* z-index: 100000; */
  background-color: rgb(193 189 189 / 20%);
  backdrop-filter: blur(1px);
}

#calendly {
  /* padding: 2rem; */
  height: 100%;
  position: relative;
  width: 100%;
  border-radius: 20px;
}

.page-Commondashboard #calendly {
  padding: 2rem;
}

.Calendlymodal {
  min-width: 700px;
}

.BooKLabel {
  color: #114c5d;
  font-weight: 700;
  border-bottom: 0.3px solid #e3e3e3;
  padding-bottom: 0.4rem;
}

.bookIntro {
  font-size: 1rem;
  color: #c4c4c4;
}

.CloseIcon {
  width: 100%;
  height: 100%;
  float: right;
}

.CloseIcon img {
  position: absolute;
  width: 27px;
  height: 27px;
  right: -1rem;
  top: -1rem;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .page-Commondashboard .react-responsive-modal-modal {
    height: fit-content;
    margin: 2rem;
  }

  .page-Commondashboard .BlogSectionBox {
    margin-top: 2rem;
  }

  .page-Commondashboard .react-responsive-modal-closeButton {
    padding-top: 1rem;
  }

  .page-Commondashboard .react-responsive-modal-closeButton svg {
    width: 40px;
    height: 40px;
  }

  .HeaderText {
    font-weight: 600;
    font-size: 1.3rem;
    /* margin-top: 1rem; */
  }

  .PopupImg img {
    width: 324px;
  }

  .PopupContent {
    font-size: 1rem;
    padding: 0rem;
  }

  .ButtonBx .ReNew {
    padding: 0.4rem 2rem;
  }

  .ButtonBx .SkipBtn {
    font-size: 1rem;
  }

  .page-Commondashboard .CloseIcon img {
    position: absolute;
    width: 38px;
    height: 37px;
    right: -0.8rem;
    top: -1.4rem;
    cursor: pointer;
  }

  .page-Commondashboard .react-responsive-modal-root {
    padding: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .RenewMsgbox {
    max-width: 100%;
  }

  .sensexNifty {
    justify-content: center;
  }

  .Expert-bg .slick-dots {
    display: none !important;
  }
}

@media screen and (max-width: 1024px) {
  .RenewMsgbox {
    max-width: 100%;
  }

  .page-Commondashboard .sensexNifty {
    margin-top: 1.2rem;
    display: grid !important;
    justify-content: center;
    margin-left: 1.2rem;
  }

  .page-Commondashboard .sensexLine {
    display: none;
  }

  .page-Commondashboard .sensexnifty:first-child {
    padding-left: 0rem;
  }
}

.remove-height .fakeSpace_rn_k9 {
  height: 0px;
}

.remove-height .DesktopView {
  padding-top: 7.5rem;
  padding: 2rem;
}

.checkredirect header {
  display: none;
}

.NDA-Page header {
  display: none;
}

.ProfileDetails-Page .NDA-Space {
  height: 0rem;
  display: none;
}

.Expert-bg .NDA-Space {
  height: 0rem;
  display: none;
}

.Expert-bg .slick-dots li {
  width: 10px;
}

.Expert-bg .slick-dots li button::before {
  font-size: 11px !important;
  width: 10px !important;
  height: 10px !important;
  margin: 5px 2px !important;
  display: block;
  backface-visibility: visible;
  transition: opacity 0.2s ease 0s;
  border-radius: 30px;
  box-shadow: none !important;
}

.Expert-bg .slick-active .slick-dots button {
  background-color: #042b62 !important;
}

.Expert-bg .d-md-block .slick-dots {
  position: relative !important;
  width: 100px !important;
  text-align: center;
  left: 45%;
  top: 0rem !important;
}

.Expert-bg #RMDATA1 .slick-dots {
  bottom: 0px !important;
}

.Expert-bg .slider-control-centerleft {
  display: none;
}

.Expert-bg .slider-control-centerright {
  display: none;
}

.Expert-bg .slider-control-bottomcenter ul {
  top: 10px !important;
}

.bgImagClass .react-responsive-modal-root {
  z-index: 10000 !important;
}

@media screen and (max-width: 768px) {
  .Expert-bg .slider-control-bottomcenter ul {
    top: 12px !important;
  }
}

.page-portfolio .sortSelect__control {
  min-height: 2rem !important;
  border-radius: 0px;
  border: none !important;
  border-bottom: 1px solid #dadada !important;
  height: auto !important;
  outline: none !important;
  box-shadow: none !important;
}

.page-portfolio .sortSelect__value-container {
  padding: 2px 0px;
}

.page-portfolio .sortSelect__single-value {
  margin-left: 0px;
}

.page-portfolio .sortSelect__indicator-separator {
  display: none;
}

.Client-Slider .slick-slide {
  margin: 0rem -2rem !important;
}

.page-portfolio .Border {
  border: 1px solid #d8d8d8;
  border-radius: 15px;
  padding: 2rem 1rem;
}

.calendly-inline-widget .calendly-inline-widget {
  position: relative;
}

.Billingpopup,
.coupon_modal .modal-content {
  outline: none !important;
  border: none !important;
  border-radius: 20px !important;
}

.Billingpopup .modal-dialog {
  width: 85%;
}

.coupon_modal .modal-dialog {
  width: 90% !important;
  margin: auto;
}

@media (min-width: 768px) {
  .coupon_modal .modal-dialog {
    width: 60% !important;
  }
}

.billing_Modal .modal-content {
  border-radius: 20px !important;
  overflow: hidden !important;
  outline: none !important;
  border: none !important;
}

.coupon_modal .modal-content {
  border-radius: 20px !important;
  overflow: hidden !important;
  outline: none !important;
  border: none !important;
}

.page-portfolio .CloseIcon img {
  cursor: pointer;
  height: 27px;
  position: absolute;
  right: -1rem;
  top: -1rem;
  width: 27px;
}

.otp-error {
  color: red;
  font-size: medium;
}

.page-portfolio #calendly {
  border-radius: 20px;
  height: 100%;
  padding: 2rem;
  position: relative;
  width: 100%;
}

.page-portfolio .BooKLabel {
  border-bottom: 0.3px solid #e3e3e3;
  color: #114c5d;
  font-weight: 700;
  padding-bottom: 0.4rem;
}

.page-portfolio .bookIntro {
  color: #c4c4c4;
  font-size: 1rem;
}

.temp-hide {
  display: none !important;
}

div[disabled] {
  pointer-events: none;
}

.data-loader select {
  width: 87% !important;
}

.sticky .FundName {
  display: none !important;
}

@media (min-width: 768px) {
  .SIP_Lumpsum.RightSide {
    position: sticky;
    top: 7rem;
    z-index: 888;
  }

  .sticky .FundName {
    color: #000;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-left: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: none !important;
  }

  .fixed_top_Nav .FundName {
    display: block !important;
  }
}

body.hide-header .main-header,
body.hide-header .NDA-Space,
body.hide-footer .main-footer {
  display: none !important;
}

.new-itr-page-footer p {
  margin: 0px;
  padding: 0px;
  color: #adadad;

  font-size: 14px;
  font-weight: 300;
  line-height: 19px;
}

/* Pricing-Page */
.s-head {
  padding: 4rem;
}

.s-head .upperText {
  width: 55%;
  margin: auto;
  font-size: 2rem;
}

.s-head .BottomText {
  width: 70%;
  margin: auto;
}

.Price-type h4 {
  color: #042b62 !important;
  font-size: 1.6rem;
  font-weight: 500;
  margin: 0;
}

.PricingBox {
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
}

.PricingBox .amount h4 {
  font-size: 2.5rem !important;
  font-weight: bold;
  margin: 0;
}

.price-table .item {
  background-color: #fff !important;
  border-radius: 15px;
  padding: 2rem !important;
  max-width: 350px !important;
  margin: 0 1rem;
  border: 1px solid #e1e1e1;
}

.PricingBox .item-inactive {
  margin-top: 2rem;
  transition: 0.3s;
}

.PricingBox .feat {
  padding: 2rem 1rem !important;
  margin-top: 2rem;
}

.app-price .item .amount h3 {
  color: #000 !important;
  font-weight: 700 !important;
  font-size: 2.6rem !important;
}

.PricingBox .feat li {
  padding-bottom: 1rem;
}

.PricingBox .feat .text-black {
  font-size: 1rem !important;
}

.PricingBox .feat .text-white {
  font-size: 1rem !important;
}

.ExtraTxt {
  color: gray;
  font-size: 0.6rem;
  font-style: italic;
}

.Extrainfo {
  color: gray;
  font-size: 0.7rem;
  font-style: italic;
}

.BtnBox {
  display: grid;
  place-items: center;
  margin-top: 0rem;
}

.BtnBox button {
  padding: 0.5rem 2rem;
  background-color: #042b62;
  color: #fff;
  outline: none;
  border: none;
  font-weight: bold;
  border-radius: 30px;
}

.PricingBox .item-active {
  background-color: #161a34 !important;
  color: #fff;
  border-radius: 15px !important;
  padding: 0rem !important;
  /* min-height: 50rem; */
  border: none !important;
  transition: 0.3s;
}

.PricingBox .TextWhite {
  color: #ffffff;
  font-size: 0.6rem;
}

.PricingBox .amount2 h4 {
  font-size: 2rem !important;
  font-weight: bold;
  margin: 0;
}

.item-active .Price-type {
  padding: 1rem;
  background-color: #042b62;
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
}

.item-active .Price-type h6 {
  color: #fff !important;
}

.item-active .Customtype {
  color: #ffffff !important;
  font-weight: 700 !important;
  font-size: 2.5rem;
  padding: 1rem;
  padding-bottom: 0rem;
}

.item-active .Customtype2 {
  color: #ffffff !important;
  font-weight: 700 !important;
  font-size: 2rem;
  padding: 1rem;
  padding-bottom: 0rem;
}

.item-active .custombtmText {
  padding: 0 3rem;
  text-align: center;
  font-size: 0.9rem;
  color: gray;
}

.item-active .amount h3 {
  color: #ffffff !important;
  font-weight: 700 !important;
  font-size: 2.6rem !important;
}

.item-active .Extrainfo,
.ExtraTxt {
  color: #ffffff;
}

.item-active:hover .ExtraTxt {
  color: #ffffff !important;
}

.item-active .feat {
  padding: 0 3rem !important;
}

.item-active .BtnBox {
  margin-top: 3rem;
}

.PricingBox .item-inactive:hover {
  transform: scale(1.15);
  margin-right: 3rem;
  transition: 0.3s;
  cursor: pointer;
  /* margin-top: 3rem; */
}

.PricingBox .item-active {
  background-color: #fff !important;
  border: 1px solid #042b62 !important;
  color: #000 !important;
  position: relative;
}

.PricingBox .item-active:hover {
  transform: scale(1.05);
  margin-right: 2rem;
  transition: 0.3s;
  cursor: pointer;
  background-color: #161a34 !important;
  color: #fff !important;
  border: none !important;
}

.PricingBox .feat {
  margin-bottom: 70px;
}

.PricingBox .BtnBox {
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}

/* .item-active:hover {
  transform: scale(1.14);
  transition: .3s;
  margin-left: 3rem;
} */
@media screen and (max-width: 480px) {
  .sliderimg img {
    width: 100%;
  }

  .moblileImg img {
    width: 200px;
    margin: auto;
    display: grid;
    place-items: center;
  }

  .s-head {
    padding: 2rem 0rem;
  }

  .s-head .upperText {
    font-size: 0.9rem;
    width: 100%;
  }

  .s-head .BottomText {
    width: 100%;
  }

  .PricingBox {
    display: grid;
  }

  .PricingFirst {
    margin-top: 0rem;
  }

  .PricingFirst .feat {
    margin-top: 1rem;
    padding: 0rem 1rem !important;
    margin-bottom: 1rem;
  }

  .PricingBox .item-active {
    margin-top: 3rem;
  }

  .PricingBox .item-active {
    height: 100%;
  }

  .PricingBox .item-inactive:hover {
    transform: none;
    margin-right: 1rem;
    transition: none;
  }

  .PricingBox .item-active:hover {
    transform: none;
    margin-right: 1rem;
    transition: none;
  }
}

/* added by Nil, for DMF SIP Lump buy now form */
.frm-buy-mobile .calc-tablist {
  display: none;
}

.frm-buy-mobile .calc-buttons-d {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.frm-buy-mobile .Tabs {
  padding: 0px;
}

.frm-buy-mobile .modal-title {
  padding-left: 35px;
}

.mobile-purchase-btns {
  position: fixed;
  bottom: 0px;
  width: 100vw;
  background: #fff;
  padding: 10px;
  left: 0;
}

.mobile-purchase-btns div {
  width: 50%;
  padding: 10px;
}

.mobile-purchase-btns div button {
  width: 100%;
  height: 2.5rem;
  border: 0px;
  border-radius: 20px;
  background-color: #042b62;
  color: #fff;
}

.custom-spline-chart .toolbar {
  flex-direction: column;
}

.sticky.fixed_top_Nav {
  margin-left: calc(var(--bs-gutter-x) * -0.5);
  width: 100vw;
}

.page-mutualfund-details .fixed_top_Nav {
  background: #fff;
  padding: 1rem;
  padding-bottom: 0rem;
}

.page-mutualfund-details .fixed_top_Nav .FundName {
  color: #000;
}

.sticky.fixed_top_Nav .FundName {
  display: block !important;
}

.mobileFund .info {
  position: absolute;
}

.__react_component_tooltip::before,
.__react_component_tooltip::after {
  display: none;
}

@media (min-width: 768px) {

  .__react_component_tooltip::before,
  .__react_component_tooltip::after {
    display: block;
  }

  .mobileFund .info {
    position: relative;
  }

  .page-mutualfund-details .fixed_top_Nav {
    background: #f0f4f3;
    padding-left: 0px;
    padding-right: 0px;
  }

  .custom-spline-chart .toolbar {
    flex-direction: row;
  }

  .sticky.fixed_top_Nav {
    margin-left: 0px;
    width: 100%;
  }
}

@media screen and (min-width: 1800px) {
  .PricingBox .item-active {
    min-height: 54rem !important;
  }
}

.donutchartI74y div.apexcharts-legend {
  display: none !important;
}

.ptTable td .shine {
  width: 100%;
  height: 2rem;
}

.shine {
  background: #dfe2e6;
  background-image: linear-gradient(to right,
      #f6f7f8 0%,
      #edeef1 20%,
      #f6f7f8 40%,
      #f6f7f8 100%);
  background-repeat: no-repeat;
  background-size: 800px 104px;
  display: inline-block;
  position: relative;

  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
  animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

@-webkit-keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.user-select-none {
  user-select: none !important;
}

.iti {
  width: 100% !important;
}

.iti .iti__flag-container {
  /* position: relative !important; */
  border-bottom: 1px solid rgba(0, 82, 99, 0.31);
}

.iti--allow-dropdown input {
  padding-right: 6px !important;
  padding-left: 52px !important;
  margin-left: 0 !important;
}

.iti__selected-flag {
  padding: 0px 5px !important;
  outline: none !important;
}

.iti--allow-dropdown input {
  padding-left: 90px !important;
}

.blur-bg {
  filter: blur(8px);
}

.link-your-holding .portfolio-navbar-sidebar {
  display: none;
}

body.link-your-holding {
  background-color: #f0f4f3 !important;
  background-image: url("http://localhost:3001/web/static/media/01_background_png.b884a584e11c0cf7995a.png") !important;
}

.holdings-form-box {
  background: #fff;
  border-radius: 20px;
}

.holdings-form-box p {
  flex-grow: 1;
}

.link-holdings-otp input {
  border: 1px solid #cecece;
  border-radius: 5px;
}

.btn-primary {
  background: #042b62;
  padding: 10px 50px;
  border-radius: 20px;
}

.btn-outline-primary {
  /* background: #042b62; */
  padding: 10px 50px;
  border-radius: 20px;
  border: 1px solid #042b62;
  color: #042b62;
}

.fnto-dropdown-react .sortSelect__control {
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-radius: 0px;
  outline: none !important;
  box-shadow: none !important;
}

.fnto-dropdown-react .sortSelect__indicator-separator {
  display: none;
}

.fnto-dropdown-react .sortSelect__value-container {
  padding-left: 0px;
}

.fnto-dropdown-react .sortSelect__placeholder {
  font-weight: bold;
}

.fnto-dropdown-react .sortSelect__single-value {
  font-weight: bold !important;
  color: #000 !important;
}

.fntoo-textbox-react {
  border: 0px;
  border-bottom: 1px solid hsl(0, 0%, 80%);
  line-height: 2.5rem;
  font-weight: bold;
  color: gray;
}

.fntoo-textbox-react::placeholder {
  font-weight: normal;
}

.bonds-datepicker .fintoo-datepicker {
  width: 100% !important;
}

.bonds-datepicker .fintoo-datepicker input {
  border-left: 0px !important;
  border-right: 0px !important;
  border-top: 0px !important;
  border-radius: 0px !important;
  font-weight: 500 !important;
}

S.IP-datepicker .fintoo-datepicker {
  width: 100% !important;
}

.SIP-datepicker .fintoo-datepicker input {
  border-left: 0px !important;
  border-right: 0px !important;
  border-top: 0px !important;
  border-radius: 0px !important;
}

.SIP-datepicker .fintoo-datepicker .react-datepicker__input-container input {
  padding: 5.3px 7px !important;
}

.SIP-datepicker .react-datepicker-wrapper {
  width: 100%;
}

.slider-newbonds {
  height: 2px !important;
}

.lbl-newbond {
  font-weight: 600 !important;
  color: #000;
}

.fnto-bonds-tags {
  display: flex;
  margin-top: 7px;
}

.fnto-bonds-tags div {
  width: 25%;
  padding: 5px;
}

.fnto-dropdown-react .sortSelect__single-value {
  font-weight: 500 !important;
  color: gray !important;
}

.fnto-bonds-tags p {
  font-size: 12px;
  margin-bottom: 0px;
  text-align: center;
  background-color: #fff;
  border-radius: 10px;
  color: #042b62;
  cursor: pointer;
  border: 1px solid #042b62;
}

.fnto-bonds-tags div.active p {
  color: #fff;
  background-color: #042b62;
}

.anchor-primary {
  background: #042b62 !important;
  border-radius: 20px;
  padding: 10px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  text-align: center;
  color: #fff !important;
  margin: auto;
  display: block;
  width: fit-content;
}

.bonds-datepicker .react-datepicker-wrapper {
  width: 100%;
}

.insurance-switch-container {
  display: flex;
  align-items: center;
  /* gap: 1rem; */
  padding-top: 0.8rem;
  line-height: 2.5rem;
  font-weight: bold;
  color: rgb(117, 117, 117);
  width: 100%;
}

.css-mo4r8n:not([disabled]):focus+div,
[data-css-mo4r8n]:not([disabled]):focus+div {
  box-shadow: none !important;
}

.css-mo4r8n:checked+div,
[data-css-mo4r8n]:checked+div {
  background-color: #042b62 !important;
}

.Rupee-icon {
  padding-left: 25px !important;
  background: url("./components/Assets/rupee-indian.png") no-repeat left;
  background-size: 10px;

}

.Modalpopup2 {
  padding: 0px !important;
  width: 50%;
}

.Modalpopup2_heading {
  background-color: #24A5E4;
  padding: 0.5rem;
  font-size: 1.7rem;
  font-weight: bold;
  display: flex;
}

.popupHeaderimg {
  position: absolute;
  width: 100%;
  top: -50px;
}

.popupHeaderimg .popup-img {
  width: 80px;
}

.payment_success_img {
  width: 200px;
  margin-top: 2rem;
}

.pink-link {
  color: #f0806d;
}

.popup-green-btn {
  margin: 20px auto;
  padding: 8px 40px;
  border: 1px solid #042b62;
  color: #042b62 !important;
  transition: 0.2s all ease-out;
  text-align: center;
  cursor: pointer;
  background: 0 0;
  color: #272727;
  min-width: 120px;
  border-radius: 30px;
  font-weight: 600;
}

.popup-green-btn:hover {
  color: #fff !important;
  background: #042b62;
}

.popup .popup-container .payment-popupbody {
  padding: 1rem 9rem !important;
}

.white-modal .payment-popup {
  background: grey !important;
}

.ModalpopupXs {
  width: 30% !important;
  height: unset !important;
}

.ModalpopupContentWidth {
  height: fit-content !important;
  width: fit-content !important;
}

.ModalpopupXs .PopupContent {
  padding: 0 !important;
}

@media only screen and (max-width: 900px) {
  .Modalpopup {
    height: fit-content !important;
    width: 80% !important;
    padding: 0px !important;
  }

  .ModalpopupXs {
    width: 55% !important;
  }

  .ModalpopupXs .PopupContent {
    padding: 0 !important;
  }

  .Modalpopup2 {
    height: fit-content;
    width: 80% !important;
  }

  .fnto-bonds-tags p {
    font-size: 10px;
  }
}


@media only screen and (max-width: 500px) {
  .Modalpopup {
    position: relative !important;
    width: 95% !important;
  }

  .ModalpopupXs {
    width: 90% !important;
  }

  .ModalpopupContentWidth {
    width: 95% !important;
  }

  .ModalpopupXs .PopupContent {
    padding: 0rem 6rem;
  }

  .ModalpopupContentContainer {
    position: relative !important;
  }

  .aadharPopUpFooter {
    position: sticky;
    bottom: 0;
    background-color: white;
  }

  .Modalpopup2 {
    height: fit-content;
    width: 90% !important;
  }

  .Modalpopup2_heading {
    font-size: 1rem;
  }

  .assetForm .my-md-4 {
    margin-bottom: 2rem !important;
  }

  .white-modal .payment-popupbody h2 {
    font-size: 1.6rem;
  }

  .white-modal .popup .popup-container .payment-popupbody {
    padding: 1rem 3.6rem !important;
  }

  .white-modal .popup .popup-container .paymentfail-popupbody {
    padding: 1rem 5rem !important;
  }

}

.ptTable td {
  padding: 5px;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

/* Networth Report Advisory */
.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
  display: none;
}

.Popupcenter .react-responsive-modal-root {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.Popupcenter .react-responsive-modal-container {
  height: auto !important;
}

/* For Asset Equity Refresh Modal */
.RefreshModalpopup_Heading {
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  border: 0px solid #F1F1F2;
  background: #042b62;
  display: flex;
  color: #fff;
  font-weight: 600;
  font-size: 1.8rem;
  width: 100%;
  padding: 1.4rem 1rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.ButtonBx .Cancel,
.Unlink {
  padding: 0.6rem 2rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #042b62;
  margin-top: 3rem;
  padding: .6rem 2rem;
  font-size: 1.1rem;
  font-weight: 800;
  border-radius: 30px;
  border: 0.744px solid #042b62;
}

.ButtonBx .Cancel:hover {
  background-color: #042b62;
  color: #fff;
}

.ButtonBx .Unlink {
  background-color: #042b62;
  color: #fff;
  margin-top: 3rem;
}

.Nsdlcsdl-modal-width {
  max-width: 60%;
  width: 100%;
  margin: 0 auto;
}

.Nsdlcsdl-modal-width .RefreshModalpopup_Heading {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.Nsdlcsdl-modal-width .modalBody {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.magic-dots.slick-dots ul {
  padding: 0;
  display: flex;
  transition: all 0.2s;
  position: relative;
  margin: 0;
}

.magic-dots {
  overflow: visible;
}

.magic-dots.slick-dots li.slick-active button {
  width: 20px;
  height: 20px;
}

.magic-dots.slick-dots li.slick-active button:before {
  color: #042b62;
  width: 2rem;
  background-color: #042b62 !important;
  height: 6px;
  border-radius: 4px;
  font-size: 0px !important;
  top: 7px;
}

.magic-dots.slick-dots li button:before {
  transition: font-size 0.35s;
  content: "\2022";
  transition: none !important;
  color: #D9D9D9;
  opacity: 1;
}

.magic-dots.slick-dots li.small button:before {
  font-size: 8px;
  line-height: 20px;
}

/* .slick-dots li {
  width: 12px;
  height: 20px;
} */
@media only screen and (max-width: 768px) {
  .ButtonBx {
    width: 100%;
  }

  .ButtonBx .Unlink {
    /* margin-top: 0rem; */
    font-size: .9rem;
  }

  .ButtonBx .Cancel {
    /* margin-top: 1rem; */
    font-size: .9rem;
  }

  .ButtonBx .Cancel,
  .Unlink {
    padding: .6rem 3rem;
  }

  .RefreshModalpopup_Heading {
    font-size: 1.3rem;
  }

  .Nsdlcsdl-modal-width {
    max-width: 96%;
  }
}

/* Advisory fintoo loader Css */
.dg-layout .fintoo-loader {
  background: rgb(255 255 255 / 50%) !important;
  z-index: 1500 !important;
}

.dg-layout .fintoo-loader p {
  display: none;
}

/* For advisory input field */

.dg-layout .custom-input {
  position: relative;
}

.custom-input input {
  display: block;
  width: 100%;
  font-size: 1rem;
  padding: 12px 0px;
  padding-left: 0px;
  padding-bottom: 2px;
  border: none;
  border-bottom: 1px solid #dadada;
  background: none;
  padding-right: 35px;
}

.custom-input input:focus {
  outline: none;
}

.custom-input label {
  position: relative;
  top: -24px;
  left: 0px;
  color: #000;
  font-size: 12px;
  font-weight: normal;
  pointer-events: none;
  transition: all 0.2s ease;
}

.custom-input .inputData label {
  top: -45px;
  color: #000;
  font-size: 12px;
  font-weight: bold;
}

.custom-input input:focus~label {
  top: -45px;
  font-size: 12px;
  color: #000;
  font-weight: bold;
}

.custom-input .inputData input:focus~label,
.custom-input .inputData input:valid~label {
  top: -45px;
  font-size: 12px;
  color: #000;
  font-weight: bold;
}

.dg-layout .custom-input .info-hover-box {
  position: absolute;
  right: 13px;
  top: 12px;
  cursor: pointer;
  float: right;
}

.custom-input .bar {
  display: block;
  position: relative;
  width: 100%;
}

.custom-input .bar:before,
.custom-input .bar:after {
  content: "";
  height: 1px;
  width: 0;
  bottom: .5px;
  border-bottom: 1px soild #042b62;
  position: absolute;
  background: #042b62;
  transition: all 0.2s ease;
}

.dg-layout .inputData input::placeholder {
  display: none;
}

.custom-input .bar:before {
  left: 50%;
}

.custom-input .bar:after {
  right: 50%;
}

.custom-input input:focus~.bar:before,
.custom-input input:focus~.bar:after {
  width: 50%;
}


.slide-enter {
  transform: translateX(0%);
}

.slide-enter-active {
  transform: translateX(0%);
  transition: transform 500ms ease-in-out;
}

.slide-exit {
  transform: translatex(-100%);
}

.slide-exit-active {
  transform: translate(100%, -100%);
  transition: transform 500ms ease-in-out;
}

.move {
  transition: transform 0.5s ease-in-out;
}

[data-title]:hover:after {
  /* [data-title]:after { */
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  display: unset;
}

[data-title]:after {
  width: 350px;
  content: attr(data-title);
  background: white;
  position: absolute;
  padding: 0.5rem;
  top: -200%;
  left: -210%;
  z-index: 99999;
  color: #031117;
  border: 1px solid rgba(0, 0, 0, 0.086);
  border-radius: 3px;
  font-size: 0.9rem;
  font-weight: normal;
  display: none;
  opacity: 0;
}

[data-title] {
  position: relative;
}

@media only screen and (max-width: 550px) {
  [data-title]:after {
    width: 100%;
    left: unset
  }

  .page-Commondashboard .Section .card-box .CreditReportbox {
    background-image: none;
    padding: 14px;
  }
}

a.custom-buttons,
a.custom-buttons:hover {
  color: #fff;
  text-decoration: none;
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}

#swal2-title {
  font-size: 1rem;
}

.chk-self * {
  pointer-events: none;
}

.mobile-search-modal {
  border-radius: 0px !important;
}

@media screen {
  .page-Commondashboard .Section .card-box .CreditReportbox {
    /* background: url('./components/CommonDashboard/CreditScore/CreditReport.png'), #EEF9FF; */
    background-size: 120px;
    background-position: bottom 0px right 20px;
    margin: 0rem;
    background-repeat: no-repeat;
  }

  .page-Commondashboard .mfReportbox {
    /* background: url('./components/CommonDashboard/CreditScore/MFReport.svg'), #EEF9FF; */
    background-size: 140px;
    background-position: bottom -2px right 20px;
    margin: 0rem;
    background-repeat: no-repeat;
  }

  .page-Commondashboard .ParReportbox {
    /* background: url('./components/CommonDashboard/CreditScore/PARReport.svg'), #EEF9FF; */
    background-size: 140px;
    background-position: bottom -2px right 20px;
    margin: 0rem;
    background-repeat: no-repeat;
  }

}

@media only screen and (max-width: 600px) {
  .page-Commondashboard .mfReportbox {
    background-size: 116px;
  }

  .page-Commondashboard .ParReportbox {
    background-size: 116px;
  }
}

.__react_component_tooltip.place-top::before {
  visibility: hidden !important;
}

.dashboard-slick-dots li {
  margin: 0 0px !important;
}

.tooltipDemo .tooltip-inner {
  max-width: 600px;
  text-align: justify;
  background-color: gray !important;
  padding: .6rem !important;
}

.tooltipDemo .tooltip.show {
  opacity: 1 !important;
}

.tooltipDemo .bs-tooltip-top .tooltip-arrow::before {
  border-top-color: gray !important;
}

.tooltipDemo .show,
.tooltipDemo .fade,
.tooltipDemo .modal {
  /* backdrop-filter: unset !important; */
  background-color: unset !important;
}

.tooltipDemo .CreditReportbox img {
  display: inline !important;
}

.closepopupmodal .slick-initialized {
  display: block !important;
}

/* For finacial-health-checkup Page CSS */
.fhcpage .fhcSection {
  margin: 2rem 10rem
}

@media only screen and (max-width: 600px) {
  .tooltipDemo .tooltip-inner {
    max-width: 400px;
  }

  .fhcpage .fhcSection {
    margin: 1rem;
  }
}

.opt-buttons .custom-buttons {
  padding: 0.3rem 1rem 0.3rem 1rem !important;
}

.login-demo button:disabled {
  background-color: #eee !important;
  color: #666 !important;
}


.InvestSelectBank.disabled-bank {
  background: #eee;
  opacity: .3;
  user-select: none;
  pointer-events: none;
}

.InvestSelectBank.disabled-bank .mandate-support-error p {
  color: red;
  font-size: 1rem;
}

/* For Creditscorecard Tooltip */
.CreditScoreTooltip {
  position: absolute;
  display: inline-block;
  border-bottom: 1px dotted #042b62;
  top: 2rem;
}

.CreditScoreTooltip .CreditScoreTooltipnumber {
  visibility: visible;
  width: 53px;
  background-color: #042b62;
  color: #fff;
  text-align: center;
  border-radius: 8px;
  /* padding: .5rem 0; */
  padding: 2px 0;
  position: absolute;
  z-index: 1;
  top: 150%;
  left: 100%;
  margin-left: 0px;
}

.CreditScoreTooltip .CreditScoreTooltipnumber::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -9px;
  border-width: 9px;
  border-style: solid;
  border-color: transparent transparent #042b62 transparent;
}

/* For Creditscorecard Tooltip */
.CreditScoreTooltip {
  position: absolute;
  display: inline-block;
  border-bottom: 1px dotted #042b62;
  top: 2rem;
}

.CreditScoreTooltip .CreditScoreTooltipnumber {
  visibility: visible;
  width: 53px;
  background-color: #042b62;
  color: #fff;
  text-align: center;
  border-radius: 8px;
  /* padding: .5rem 0; */
  padding: 2px 0;
  position: absolute;
  z-index: 1;
  top: 150%;
  left: 100%;
  margin-left: 0px;
}

.CreditScoreTooltip .CreditScoreTooltipnumber::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -9px;
  border-width: 9px;
  border-style: solid;
  border-color: transparent transparent #042b62 transparent;
}

/* New Login Page Css */
body.login-demo {
  background-color: #cce4ff !important;
  height: 100vh !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background-repeat: no-repeat !important;
  background-position: unset !important;
  background: linear-gradient(to right,
      #e6f2ff 0%,
      #e6f2ff 50%,
      #161a34 50%,
      #161a34 100%) !important;
}

.login-demo .iti__selected-flag {
  padding: 0px !important;
}

.login-demo .iti--separate-dial-code .iti__selected-flag {
  background-color: transparent;
}

.login-demo .iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
  background-color: transparent !important;
}
.login-demo .iti__dropdown-content {
  margin-left: -1rem;
  /* width: 373px; */
}
.login-demo .iti__country-list {
  /* margin-left: -1.05rem; */
  width: 367px;
}

/* For finacial-health-checkup Page CSS */
.fhcpage .fhcSection {
  margin: 2rem 10rem
}

.newSignup .iti {
  position: relative;
  /* top: 10px; */
}

@media only screen and (max-width: 600px) {
  .tooltipDemo .tooltip-inner {
    max-width: 400px;
  }

  .fhcpage .fhcSection {
    margin: 1rem;
  }

  .selectedGoals {
    width: 126px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  body.login-demo {
    height: max-content;
    overflow: visible;
    display: block !important;
    background: none !important;
  }

  .Calendlymodal {
    min-width: 300px;
  }

}

.small-para {
  font-size: .8rem;
}

.page-Commondashboard .finvestGoal {
  display: grid;
  place-items: center;
  align-items: center;
  gap: .4rem;
}

.finvestGoal .texttitle {
  color: rgba(26, 50, 99, 0.80);
  text-align: center;
  font-family: "Red Hat Text";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.finvestGoal .textDesc {
  color: #1A326380;
  text-align: center;
  font-family: "Red Hat Text";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.finvestGoal .AddGoalBtn {
  background-color: #042b62;
  color: #fff;
  padding: 0.4rem 2rem;
  font-size: .9rem;
  font-weight: 700;
  margin-right: 0.5rem;
  margin-top: 1rem;
  float: right;
  border-radius: 20px;
  border: 1px solid #042b62;
  text-decoration: none;
}

.page-item:not(:first-child) .page-link{
  background: #042b62 !important;
  color: #fff !important;
  border: 1px solid #fff !important;
}
.page-item:first-child .page-link {
  color: #042b62 !important;
}
.sweet-alert button.cancel{
  background-color: #fff !important;
  color: #042b62;
  border: 1px solid #042b62;
}
.sa-confirm-button-container button{
  background-color: #042b62 !important;
}

.dg-layout .fintoo_loader .fintoo-loader {
  position: relative;
  height: auto;
  width: auto;
  z-index: auto;
}


.page-portfolio .bgStyleTable {
  position: relative;
  text-align: left;
  width: 100%;
  font-size: 14px;
  border: solid 1px #1c94ad;
}

.page-portfolio .bgStyleTable th {
  border-right: 1px solid #a3a3a3;
  font-weight: bold;
  background-color: #042b62;
  color: #fff;
  border-bottom: 1px solid #a3a3a3;
  border-top: 1px solid #a3a3a3;
  padding: 15px 15px;
  border-right: 1px solid #a3a3a3;
}

.page-portfolio .bgStyleTable tr:nth-child(even) td {
  background: #f8f8f8;
}

.page-portfolio .bgStyleTable tr td {
  padding: 12px 12px;
  border-right: 1px solid #a3a3a3;
  /* width: 50%; */
}
