.section {
    padding: 2rem;
}


@media (min-width: 768px) {
    .cardBox {
        width: 85%;
        margin: auto;
    }
    .title {
        font-size: 4rem;
        font-weight: 700;
    }
    .section {
        padding: 4rem;
    }
    .subtitle {
        font-weight: 600;
        font-size: 2rem;
    }
}