.aboutuspage {
    width: 100%;
    background: #fff;
    padding-bottom: 2rem;
    /* margin-bottom: 2rem; */
}
.aboutuspage .aboutusText{
    color: #fff;
    text-align: center;
    font-size: 2.2rem;
    font-weight: bold;
}
.primargBg {
    position: relative;
    background: url("https://fintooinvest.in/assets/img/inner-bg.svg") bottom right/450px no-repeat #02003c;
    padding: 120px 0;
}

.primargBg::after {
    content: "";
    background: url("https://fintooinvest.in/assets/img/inner-wave.svg") center top/cover no-repeat;
    width: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
    height: 130px;
}

.aboutuspage .teamSection {
    width: 60%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background: #f6f6f9; */
    border: 1px solid #eee;
    box-shadow: -1px 1px 11px 0px rgb(0 0 0 / 9%);
    border-radius: 22px;
    padding: 2rem;
}

.aboutuspage .teamImg img {
    width: 500px;
}

.aboutuspage .aboutintrocontent {
    padding: 2rem;
    line-height: 1.8rem;
}
.aboutuspage .teamBody{
    margin-top: 5rem;
}
.aboutuspage .teamvisionmission{
    width: 60%;
    margin: auto;
}
.aboutuspage .teamvmsection{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.aboutuspage .teamvmsection img{
    width: 500px;
}
.aboutuspage .teamvmsectionDesc{
    margin-left: 6rem;
}

@media screen and (max-width: 650px) {
    .primargBg {
        padding: 50px 0;
        background-size: contain;
    }
    .aboutuspage .aboutusText{
        text-align: left;
        padding-left: 1rem;
    }
    .aboutuspage .teamSection{
        width: 100%;
        display: grid;
        padding: 1rem;
        /* margin: 0 1rem; */
    }
    .aboutuspage .aboutintrocontent{
        padding: 1.5rem;
        text-align: justify;
    }
    .aboutuspage .teamImg img {
        width: 100%;
    }
    .aboutuspage .teamvisionmission{
        width: 100%;
        padding: 1rem;
    }
    .aboutuspage .teamvmsection{
        display: block;
    }
    .aboutuspage .teamvmsectionDesc{
        margin-left: 0;
        padding-top: 1rem;
    }
}
.animated {
    animation-name: bounceInUp;
    animation-duration: 1s;
}
@keyframes bounceInUp {
    0% {
        opacity: 0;
        transform: translateY(2000px);
    }
    100% {
        transform: translateY(0);
    }
}