.GlobalScorecard{
    background-color: #042b62;
    margin: 3rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 0;
    color: #fff;
    text-align: center;
}
.GlobalScorecard .ScoreBox{
    padding: 0 10rem;
}
.GlobalScorecard .counternumber{
    font-size: 2rem;
    font-weight: 700;
}

@media only screen and (max-width: 600px){
    .GlobalScorecard{
        display: grid;
        gap: 1rem;
        place-items: center;
    }
}