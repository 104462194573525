.header-section {
  position: relative;
  padding: 1rem;
  /* background-image: url(https://www.fintoo.in/wealthmanagement/wp-content/uploads/2022/09/nasa-45066-unsplash.jpg); */
  background-size: cover;
}
.header-section-image-container img {
  width: 600px;
}
.header-section-background-overlay {
  background-color: #ffffff;
  opacity: 0.94;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}
.padding-class .section-title {
  color: #060606;
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 6.5px;
}
.header-section-title {
  font-size: 4.7rem !important;
  font-weight: 700 !important;
}
.DiffText {
  color: #042b62;
}
.padding-class .section-sub-title {
  font-size: 2.7rem;
  font-weight: 500;
  line-height: 3.6rem;
}
.header-section-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width: 80%; */
  margin: auto;
  position: relative;
  z-index: 10;
}
.header-section-content {
  width: 49%;
}
.header-section-content .sectionsubText {
  color: #060606;
  font-size: 1.1rem;
}
.liveItrLink {
  background-color: #eef5ff;
  padding: 5px;
  border: .6px solid #042b62;
  outline: none;
  border-radius: 25px;
  width: max-content;
  font-weight: 600;
  color: #093065;
  vertical-align: middle;
}
.liveItrLink:hover {
  color: #093065!important;
}
.liveItrLink .liveItrLinkicon{
  color: #fff;
  background-color: #042b62;
  padding: 7px;
  font-size: 12px;
  text-align: center;
  border-radius: 50%;
  margin: 0px 3px;
  vertical-align: middle;
}
.ItrFiletxt{
  margin: 1rem 0;
  display: grid;
}
.ItrFiletxt .text{
  padding: 5px 0;
  font-size: 1.2rem;
  color: #b2b3be;
  vertical-align: middle;
  display: inline-block;
}
.ItrFiletxt .text svg{
  color: #042b62;
  margin: 0 4px;
  font-weight: 700;
}
/* / ---------------------------------------------------------------animations------------------------------------------------------------- / */
@keyframes fadeInUp {
  from {
    transform: translate3d(0, 300px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@-webkit-keyframes fadeInUp {
  from {
    transform: translate3d(0, 100vh, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.animated {
  animation-duration: 2s;
  animation-fill-mode: both;
  -webkit-animation-duration: 2s;
  -webkit-animation-fill-mode: both;
}

.animatedFadeInUp {
  opacity: 0;
}

.fadeInUp {
  opacity: 0;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
}

/* / -----------------------------------------------------------------------bounceInUp-------------------------------------------------------------- / */
.animatedBouncInUp {
  animation-duration: 1s;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: both;
}

@-webkit-keyframes bounceInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateY(-30px);
  }

  80% {
    -webkit-transform: translateY(10px);
  }

  100% {
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes bounceInUp {
  0% {
    opacity: 0;
    -moz-transform: translateY(2000px);
  }

  60% {
    opacity: 1;
    -moz-transform: translateY(-30px);
  }

  80% {
    -moz-transform: translateY(10px);
  }

  100% {
    -moz-transform: translateY(0);
  }
}

@-ms-keyframes bounceInUp {
  0% {
    opacity: 0;
    -ms-transform: translateY(2000px);
  }

  60% {
    opacity: 1;
    -ms-transform: translateY(-30px);
  }

  80% {
    -ms-transform: translateY(10px);
  }

  100% {
    -ms-transform: translateY(0);
  }
}

@-o-keyframes bounceInUp {
  0% {
    opacity: 0;
    -o-transform: translateY(2000px);
  }

  60% {
    opacity: 1;
    -o-transform: translateY(-30px);
  }

  80% {
    -o-transform: translateY(10px);
  }

  100% {
    -o-transform: translateY(0);
  }
}

@keyframes bounceInUp {
  0% {
    opacity: 0;
    transform: translateY(2000px);
  }

  60% {
    opacity: 1;
    transform: translateY(-30px);
  }

  80% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0);
  }
}

.bounceInUp {
  -webkit-animation-name: bounceInUp;
  -moz-animation-name: bounceInUp;
  -ms-animation-name: bounceInUp;
  -o-animation-name: bounceInUp;
  animation-name: bounceInUp;
}
@media only screen and (max-width: 768px) {
  
  .header-section-title{
    font-size: 2rem !important;
    line-height: 3rem;
  }
  .liveItrLink{
    font-size: .8rem;
    font-weight: 500;
  }
  .ItrFiletxt{
    text-align: left;
  }
  .ItrFiletxt .text{
    font-size: 1rem;
  }
  .header-section-container {
    display: inline-flex;
    flex-direction: column;
    width: 100%;
  }
  .header-section-image-container img {
    width: 343px;
  }
  .header-section-content {
    width: 100%;
    margin-top: 0rem;
    /* display: grid; */
    /* padding: 1rem; */
  }
  .padding-class .section-sub-title {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 2.6rem;
  }
  .header-section-content .sectionsubText {
    color: #060606;
    font-size: 1rem;
    text-align: center;
  }
  .header-section-content-btn {
    font-size: 0.8rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    fill: #ffffff;
    color: #ffffff;
    background-color: #042b62;
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-color: #fcfcfc;
    border-radius: 30px;
    transition: all 0.3s;
    padding: 1rem 3rem;
    text-align: center;
  }
}
@media only screen and (max-width: 850px) {
  .header-section-container {
    width: 100%;
    display: inline-flex;
    flex-direction: column-reverse;
  }
}

@media (min-width: 768px) {
  
  .liveItrLink {
    padding: 10px 15px;
  }
  .header-section {
    padding: 4rem 4rem 0rem 4rem;
  }
}
