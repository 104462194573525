.ie-advantages-section{
    height: 100%;
    margin-top: 3rem;
    margin-bottom: 6rem;
}
.ie-advantages-section-header {
  width: 100%;
  font-size: 38px;
  font-weight: 600;
  color: #000000;
  text-align: center;
  height: 100%;

  /* margin: 2rem 0; */
}
.img img{
  width: 80px;
  margin: 1rem;
}

.cardBox {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: 4rem;
}
.card {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border: 1px solid #f3e9e9;
  border-radius: 20px;
  margin-right: 6px;
  cursor: pointer;
  display: grid;
  place-items: center;
  margin: 2rem;
  margin-bottom: 4rem;
}
.txtHead {
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 0rem;
  /* line-height: 110%; */
  color: #162542;
}
.txt {
  font-size: 1.1rem;
  font-weight: 500;
  color: #000000;
  padding: 1rem 2rem;
}
/* ------------------------------------------------------------------------media query----------------------------------------------------------------- */
@media only screen and (max-width: 600px) {
  .ie-advantages-items-container {
    grid-template-columns: 1fr;
    grid-gap: 0.5rem;
  }
  .ie-advantages-section-header{
    font-size: 1.3rem;
    margin-bottom: 1rem;
  }
  .cardBox{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 1rem;
    grid-row-gap: 2rem;
  }
  .card{
    margin: 0rem;
    margin-top: 1rem;
  }
}
