
.headerbx .amc_logo {
    position: absolute;
    left: -50px;
}
@media (min-width: 576px) {
    .headerbx .amc_logo {
        position: absolute;
        left: -50px;
    }
}
@media (min-width: 768px) {
    .headerbx .amc_logo {
        position: absolute;
        left: -30px;
    }
}
@media (min-width: 992px) {
    .headerbx .amc_logo {
        position: absolute;
        left: -50px;
    }
}
@media (min-width: 1200px) {
    .headerbx .amc_logo {
        position: absolute;
        left: -50px;
    }
}
@media (min-width: 1400px) {
    .headerbx .amc_logo {
        position: absolute;
        left: -50px;
    }
}