/* For Index.js */

html {
	scroll-behavior: auto !important;
}

body {
	margin: 0;
	font-family: "Red Hat Text", "Arial", "Helvetica", "sans-serif";
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

}

.Toastify__toast-container {
	z-index: 11000 !important;
}

body.bgImagClass {
	background-image: url("./components/Assets/01_background_png.png");
	background-color: #f0f4f3;
	background-repeat: no-repeat !important;
	background-position: -400px -220px !important;
	background-attachment: fixed;
}
body.Bg-white{
	background-color: #fff;
	background-image: none;
}
body.page-portfolio {
	background-image: none !important;
	background-color: #fff;
}

.item-list .MarketDesktop {
	display: block;
}

.item-list .MarketMobile {
	display: none;
}

.checkredirect header {
	display: none;
}

@media only screen and (min-width: 768px) and (max-width: 1280px) {
	html {
		font-size: 12px;
	}
}

@media only screen and (min-width: 1281px) and (max-width: 1680px) {
	html {
		font-size: 14px;
	}
}

.NavList {
	float: right !important;
}

.classList {
	background-image: url("./components/Assets/01_background_png.png") !important;
	background-repeat: no-repeat !important;
	background-position: 1000px 680px !important;
	background-attachment: fixed;
}

.page-stock-details .popup-overlay {
	backdrop-filter: brightness(0.4) !important;
}

.explore-for-desktop {
	margin-top: 1em;
}

[hidden] {
	display: block !important;
}

.Bu_Now_Mobile {
	display: none;
}

.Shubh {
	color: #000;
}

.ColorChange {
	cursor: pointer;
}

.red-color {
	color: #FF0000;
}

.ColorChange img {
	opacity: 0.5;
	content: "\2713";
}

.ColorChangeDark {
	opacity: 1;
	position: relative;
}

.ColorChangeDark:after {
	background-image: url("./Assets/Images/check_01.svg");
	background-size: cover;
	width: 20px;
	height: 20px;
	position: absolute;
	top: 0px;
	right: 0px;
	z-index: 99;
	content: " ";
}

/* For Header */
/* For Header */
.Header {
	font-family: "Red Hat Display", sans-serif;
	position: fixed;
	z-index: 999 !important;
	top: 0;
	max-height: 100px;
	width: 100vw;
	display: grid;
	justify-content: space-between;
	grid-template-areas: "logo nav";
	/* Cosmetics */
	background-color: #fff;
	box-shadow: 2px 2px 6px 0 #000;
	height: 80px;
}

.fintooheader .navbar-toggler-icon {
	width: 1em;
	height: 1.1em;
}

.fintooheader .navbar-toggler {
	margin-right: 1em;
}

.Logo {
	grid-area: logo;
	margin-top: 0rem;
	margin-left: 4rem;
	/* height: 70px;
	 */
}

.navbar .brand-text {
	margin-left: 4rem;
	margin-top: -3.2rem;
	font-weight: 700;
}

.navbar .nav-link {
	margin: 20px;
	text-align: center;
	border: 2px solid transparent;
	border-radius: 1rem;
}

.navbar .nav-link:hover,
.navbar .nav-link:focus {
	background-color: #fefefe;
	color: #212529 !important;
	border-radius: 1rem;
}

.navbar-nav {
	font-size: 1.2rem;
}

.NavList .navbar-nav .dropdown-menu {
	margin-left: 0rem;
	margin-right: 0rem;
}

.navbar .dropdown-toggle::after {
	display: none;
}

.info {
	line-height: 1.7;
}

.TabHeader {
	display: flex;
	justify-content: space-between;
	font-weight: bold;
}

.fixed_top .Mf_Name {
	margin-left: 0rem;
}

.Percent_Val {
	color: green;
	font-size: 14px;
}

.navbar {
	background-color: #fff;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
	position: fixed !important;
	z-index: 1000000000000000000;
	top: 0;
	left: 0;
	width: 100%;
	height: 80px;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	font-size: 1.2rem;
	z-index: 999;
}

/* For Mutual_Fund.Jsx */
.LeftSection {
	margin-top: 3rem !important;
}

.FundName {
	font-size: 1.6em;
	font-weight: bold;
}

.ValueFund {
	display: flex;
	justify-content: space-around;
	text-align: right;
	margin-left: 3rem;
}

.ValueFund .FundPrice {
	font-size: 1.25rem;
	font-weight: 600;
}

.ValueFund .DiffLine {
	border-right: 1px solid #000;
	height: 1em;
	margin-top: 6px;
}

#chart .DiffLine {
	border-right: 1px solid #000;
	height: 8px !important;
	padding-left: 10px;
	margin-right: 10px;
	margin-top: 6px;
}

.ValueFund .FundPlusminusVal {
	font-size: 0.8rem;
	font-weight: 600;
	color: green;
	padding-top: 0.4rem;
	float: right;
}

.LatestNavText {
	text-align: center;
	font-size: 1.2em;
	color: gray;
	margin-right: 5px;
	font-weight: 400;
}

.FundIcon {
	display: flex;
	margin-top: 1rem;
	margin-left: 4rem;
	cursor: pointer;
	justify-content: flex-end;
	position: relative;
}

.swal2-container.swal2-bottom,
.swal2-container.swal2-center,
.swal2-container.swal2-top {
	z-index: 1000000000000 !important;
}

.swal2-styled.swal2-confirm {
	border: 0;
	border-radius: 0.25em;
	background: initial;
	background-color: #042b62 !important;
	color: #fff;
	outline: none !important;
	box-shadow: none !important;
	font-size: 1em;
}

.cont3 {
	color: #042b62;
	fill: #042b62;
}

.cont2 {
	color: #042b62;
}

.fundComp::hover {
	color: #000;
}

.VideoShoot {
	position: relative;
	width: 100%;
}

.FundIcon img {
	width: 20px;
	height: 20px;
}

.FundDownText {
	display: flex;
	justify-content: space-around;
	/* margin-left: 3.3rem;
	 */
	margin-top: 0;
}

.FundDownText p {
	font-size: 16px;
	color: #a0a0a0;
	font-weight: 200;
	letter-spacing: 1px;
}

.FundDownText .DiffLine {
	border-right: 1px solid gray !important;
	height: 15px;
	margin-top: 3px;
}

.fundCrVal {
	font-size: 15px;
	font-weight: 600;
}

.FunSubDetails .Text {
	font-size: 12px;
	color: gray;
	font-weight: 500;
}

.FlexBox {
	display: flex;
	justify-content: space-around;
}

.CenterDiv {
	display: flex;
	justify-content: center;
}

.sticky {
	position: sticky !important;
	left: 0;
	right: 0;
	z-index: 10;
	/* background: #000;
	 */
	color: #fff;
}

.sticky .Stock_nm {
	color: #000;
}

.header_link {
	padding: 15px;
	color: gray;
	font-weight: bold;
	background: transparent;
	border: none;
	border-bottom: none;
	cursor: pointer;
	outline: none;
	border-bottom: 4px transparent;
}

.sticky .header_link:first-child {
	padding-left: 0rem !important;
}

.sticky .header_link {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}

.header_link:hover {
	color: #000;
}

.selected {
	border-bottom: 4px solid #042b62;
	color: #000;
	/* border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	font-weight: 600; */
}

/* .section {
	 height: 100%;
	 padding: 10px;
	 width: 58rem;
}
 */
.section .MidLine {
	padding: 0 !important;
	height: 8.1rem;
	margin-top: -1.3rem;
	border-right: 1px solid gray;
}

/* #Overview {
	 height: 40rem;
	 width: 58rem;
}
 */
.section .overviewDetails {
	background: #fff;
	margin-top: 1rem;
	/* height: 8rem;
	 */
	border-radius: 14px;
	/* padding: 20px;
	 */
	/* display: flex;
	 */
	/* justify-content: space-between;
	 */
}

.Accordian_Box hr {
	margin: 0.5rem 0;
}

.acc-section {
	padding: 2rem;
}

.NavTrendList {
	display: flex;
	justify-content: space-between;
}

.Cagr {
	display: flex;
	gap: 20px;
}

.Cagr div {
	flex-grow: 1;
}

.Cagr div p {
	margin-bottom: 0px;
}

/* .NavVal p {
	 margin-bottom: 5px!important;
}
 */
/* .Cagr .NavVal {
	 display: grid;
	 padding-right: 3rem;
	 line-height: 1.5rem;
}
 */
.Cagr .NavVal span:first-child {
	color: gray;
}

.Cagr .MidLine {
	margin-right: 1rem !important;
}

.Cagr .NavVal:last-child {
	display: grid;
	padding-right: 1rem;
}

.Cagr .NavVal img {
	width: 12px;
	height: 12px;
	vertical-align: text-top;
	margin-top: 4px;
}

.NavSecond {
	margin-left: 12px;
}

.OverGraph {
	/* margin-top: 3rem;
	 */
}

.RsVal {
	font-weight: bold !important;
}

#FundDetails h4 {
	color: gray;
	font-weight: 900;
	/* padding-left: 1rem !important;
	 */
}

.BottomLine {
	width: 100%;
	height: 1px;
	border-bottom: 1px solid #d3d3d3;
}

#FundDetails .FundDetail {
	display: flex;
	gap: 2rem;
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;
}

.FundDetail .FundNames {
	font-weight: 600;
	white-space: inherit;
	word-wrap: break-word;
	letter-spacing: 0.6px;
	padding-top: 10px;
	overflow: hidden;
	max-width: 90%;
	text-overflow: ellipsis;
}

.TabData {
	width: 100% !important;
	background: none !important;
}

.fixed_top .header_link {
	border-bottom: none !important;
}

/* .Table_Compare {
	 margin-top: 2rem !important;
}
 */
.Table_Compare .header_NM {
	color: gray;
	font-weight: 900;
	padding-left: 1rem !important;
}

.Table_Compare h4 {
	color: gray;
	font-size: 20px;
}

.Table_Compare button {
	width: 7rem;
	height: 2rem;
	background-color: #042b62;
	color: #fff;
	font-size: 0.9em;
	border-radius: 15px;
	font-weight: 900;
	border: 2px solid #fff;
	cursor: pointer;
}

.Table_Compare .header_NM {
	/* font-size: 13px;
	 */
	color: gray;
	font-weight: 900;
	padding-left: 0rem !important;
}

#DMF_table {
	/* margin-top: 2rem;
	 */
	width: 100%;
}

#DMF_table td:first-child {
	text-align: left !important;
	color: #042b62;
	font-size: 1em;
	font-weight: 900;
}

.Table_Hold {
	/* margin-top: 1rem;
	 */
	width: 100%;
}

.Table_Hold th {
	border-bottom: 1px solid #000;
}

.Table_Compare h4 {
	color: gray;
	font-size: 20px;
}

#DMF_table td {
	padding: 1rem;
	text-align: center;
	padding-bottom: 1rem !important;
	border-bottom: 1px solid #d8dbda !important;
	/* color: grey;
	 */
}

#DMF_table td:first-child {
	color: #042b62;
	font-size: 1em;
	font-weight: 900;
	padding: 2rem;
	padding-left: 0;
}

#DMF_table th {
	padding: 1rem;
	text-align: center;
	background-color: #fff;
	color: Gray;
}

#DMF_table th img {
	width: 16px !important;
	height: 16px !important;
	margin-left: 0.5rem;
	cursor: pointer;
}

input:checked label img {
	display: none;
	/* border: 1px solid #042b62 !important;
	 */
}

#Male,
#FeMale,
#other {
	visibility: visible;
	float: right;
}

#Calc_Amt_Range .ExpectedR {
	width: 100%;
}

.css-3g5hux-Slider {
	background-color: #042b62 !important;
}

.css-c5m0sj {
	background-color: #042b62 !important;
}

.BackProfile {
	width: 25px;
	height: 25px;
	margin-top: 3px;
}

#DMF_table th:first-child {
	border-radius: 20px 0 0 20px;
	padding-left: 0rem !important;
}

#DMF_table th:last-child {
	border-radius: 0 20px 20px 0;
	padding-right: 2.3rem !important;
}

.Mobile_Compare_Opt .Mobile_Table {
	position: relative;
	display: none;
}

.Mobile_Table {
	display: none;
}

.fixed_top .ValueFund {
	display: flex;
	justify-content: space-around;
	text-align: right;
	margin-left: 0rem;
}

.section .TabData {
	width: 458px;
	height: none;
	border-radius: 20px;
	/* border: 2px solid #fff;
	 */
	/* background: rgb(247, 247, 247);
	 */
}

.section .TabData .tabs {
	padding: 15px;
	text-align: center;
	width: 46%;
	background: rgba(128, 128, 128, 0.075);
	border-top-left-radius: 20px;
	font-size: 20px;
	font-weight: 900;
	color: #969696;
	cursor: pointer;
	border-bottom: 1px solid #9dd0e7;
	box-sizing: content-box;
	position: relative;
	outline: none;
}

.section .TabHeader {
	height: 3rem;
}

.section .tabs:not(:last-child) {
	border-right: 2px solid #d0e6ef;
	border-bottom: 1px solid #9dd0e7;
}

.section .tabs:last-child {
	border-top-right-radius: 20px;
	border-top-left-radius: 0px;
	border-bottom: 1px solid #9dd0e7;
}

.section .tabs:hover {
	color: #042b62;
}

.section.active-tabs {
	background: #042b62;
	border-bottom: 1px solid transparent;
	color: #fff;
}

.section .active-tabs::before {
	content: "";
	display: block;
	position: absolute;
	top: -5px;
	left: 50%;
	transform: translateX(-50%);
	width: calc(100% + 2px);
	/* height: 5px;
	 */
	background: #5893f1;
}

.Allocation .TabData .bloc-tabs button:first-child {
	border: 1px solid #d1d1d1;
	border-bottom-left-radius: 30px;
	border-top-left-radius: 30px;
	color: #969696;
}

.Allocation .TabData .bloc-tabs .active-tabs {
	background: #042b62;
	color: #fff !important;
}

button.shake {
	transition-duration: 1s;
	opacity: 0.6;
}

.button:hover {
	transition-duration: 0.1s;
	background-color: #3a3a3a;
}

.button:after {
	content: "";
	display: block;
	position: absolute;
	border-radius: 4em;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	transition: all 0.5s;
	box-shadow: 0 0 10px 40px white;
}

.button:active:after {
	box-shadow: 0 0 0 0 white;
	position: absolute;
	border-radius: 4em;
	left: 0;
	top: 0;
	opacity: 1;
	transition: 0s;
}

.button:active {
	top: 1px;
}

.TabData .tabs {
	background: rgba(128, 128, 128, 0.075);
	border-bottom: 1px solid #9dd0e7;
	border-top-left-radius: 20px;
	box-sizing: initial;
	color: #969696;
	cursor: pointer;
	font-size: 20px;
	font-weight: 900;
	outline: none;
	padding: 15px;
	position: relative;
	text-align: center;
	width: 50%;
}

.active-tabs {
	background: #042b62;
	border-bottom: 1px solid transparent;
	color: #fff;
}

.Allocation .TabData .bloc-tabs button:last-child {
	border: 1px solid #d1d1d1;
	border-bottom-right-radius: 30px;
	border-top-right-radius: 30px;
	color: #969696;
}

.Allocation .content {
	display: none;
}

.Risk_Measure {
	border-collapse: collapse;
	width: 100%;
	border: none;
	/* margin-top: 1.8rem;
	 */
}

.Risk_Measure_List {
	margin-top: 1rem !important;
}

.Risk_Measure_List h4 {
	font-weight: 600;
	color: gray;
	font-size: 17px;
}

.Checkall {
	cursor: pointer;
	margin-top: 10px;
}

.Risk_Measure_List table {
	border: none !important;
	margin-top: 0rem;
}

.Risk_Measure table,
.Risk_Measure tr,
.Risk_Measure td,
.Risk_Measure th {
	border: none !important;
}

.Risk_Measure th:first-child {
	font-size: 16px;
	color: gray;
	font-weight: 900;
	text-align: left;
}

.Risk_Measure th {
	border-bottom: 1px solid #d1d1d1;
	padding-bottom: 1rem;
	font-size: 16px;
}

.Risk_Measure td:first-child {
	color: #042b62;
	font-size: 16px;
	font-weight: bold;
	/* padding: 2rem;
	 */
	text-align: left;
}

.Risk_Measure td {
	padding: 0.5rem;
	padding-left: 0rem;
	font-size: 16px;
	/* text-align: center;
	 */
	height: 20px;
	/* border-bottom: 1px solid #d8dbda;
	 */
	/* color: grey;
	 */
}

.Stock_Header1 {
	/* display: flex;
	 justify-content: space-between;
	 */
	background-color: #042b62;
	padding: 2rem;
	padding-top: 2rem !important;
	height: 8rem;
	border-radius: 15px;
	color: #000;
	outline: none;
	border: none;
}

.react-tabs__tab {
	display: inline-block;
	border: 1px solid transparent;
	border-bottom: none;
	bottom: -1px;
	position: initial !important;
	list-style: none;
	padding: 6px 12px;
	/* font-weight: 900;
	 */
	cursor: pointer;
}

/* .react-tabs__tab:hover {
	 border-bottom: 1px solid #fff !important;
}
 */
.react-tabs__tab--selected {
	background: none !important;
	border: None !important;
	font-size: 19px !important;
	border-bottom: 1px solid #fff !important;
	border-radius: 0px 0px 0 0 !important;
	color: white !important;
	/* border-radius: 5px 5px 0 0;
	 */
}

.Stock_Header1 div {
	cursor: pointer;
	font-size: 18px;
	outline: none;
	font-weight: 900;
}

.Stock_Header1 .active {
	color: #fff;
}

.search-box-container {
	display: flex;
	width: 100%;
	border-radius: 20px;
	padding: 1rem;
	border: 2px solid #a4a4a4;
	/* margin-left: 7rem;
	 */
	padding-top: 0.4em;
	padding-bottom: 0.4em;
}

.search-box-container input {
	border: none;

}

.Stock_Search input.autocomplete-text {
	outline: none;
	flex-grow: 1;
	border: 0;
	color: #000;
}

.Stock_Search input::placeholder {
	color: #afafaf;
}

.Stock_Search_Header {
	/* display: flex;
	 justify-content: space-between;
	 */
	margin-top: 1rem;
}

.filter {
	color: #000 !important;
	font-size: 19px;
	font-weight: 900;
}

.filter span {
	padding-left: 18px;
}

.filter img {
	width: 26px;
	height: 26px;
	text-align: right;
}

.Filter_Btn {
	/* margin-left: 2rem;
	 */
	color: #000 !important;
	/* padding: 1rem;
	 */
}

.searchbar-desktop button.btn-filter {
	width: 7rem;
	height: 2rem;
	/* padding-right: 3rem;
	 */
	border-radius: 20px;
	text-align: center;
	background-color: #fff;
	border: 1px solid #042b62;
	color: #042b62;
	/* float: right;
	 */
	margin-left: 2.2rem;
}

.Filter_Btn .filter {
	background-color: #fff;
	color: grey;
	float: right;
}

.Filter_Btn .active {
	color: #fff;
}

/* .All_Stocks {
	 margin-top: 1.5rem;
	 display: grid;
	 grid-template-columns: repeat(3, 1fr);
	 grid-template-rows: 1fr;
	 grid-column-gap: 0px;
	 grid-row-gap: 0px;
	 padding-bottom: 2rem;
}
 */
.MobileView {
	display: none;
}

.Stock_List {
	padding: 0rem;
	display: grid;
	grid-template-columns: repeat(7, 1fr);
	grid-template-rows: 1fr;
	grid-column-gap: 0px;
	grid-row-gap: 0px;
	margin-top: 2rem;
	font-size: 16px;
	font-weight: bold;
}

/* For MFList.jsx */
.Stock_List_Table_selected {
	background-color: #fff;
	margin-top: 1rem;
	padding: 2rem;
	display: block;
	border-radius: 15px;
}

.Stock_List_Table_selected button {
	width: 9rem;
	height: 2rem;
	margin-top: 0.5rem;
	border-radius: 15px;
	text-align: center;
	background-color: #042b62;
	border: 1px solid #042b62;
	color: #fff;
}

.user {
	display: flex;
	justify-content: space-evenly;
}

.Stock_Details {
	/* width: 100%;
	 */
	overflow: hidden !important;
	margin-top: 0rem;
	/* display: grid;
	 */
	/* place-items: center;
	 */
	/* margin-top: 8rem; */
	/* margin-left: 0rem;
	 */
}

.Stock_Details a {
	text-decoration: none;
}

.Stock_Header1 {
	/* display: flex;
	 justify-content: space-between;
	 */
	background-color: #042b62;
	padding: 2rem;
	padding-top: 2.5rem !important;
	height: 8rem;
	border-radius: 15px;
	color: #000;
	outline: none;
	border: none;
	width: 100% !important;
}

.RightSide .react-tabs__tab {
	display: inline-block;
	border: 1px solid transparent;
	border-bottom: none;
	bottom: -1px;
	position: initial !important;
	list-style: none;
	padding: 0px 0px !important;
	/* font-weight: 900;
	 */
	cursor: pointer;
}

.RightSide .react-tabs__tab--selected {
	background: #f4f4f4 !important;
	border: None !important;
	font-size: 1em !important;
	color: gray !important;
}

.RightSide .react-tabs__tab--selected span {
	font-weight: bold;
}

.RightSide .react-tabs__tab:first-child {
	display: inline-block;
	border-right: 1px solid #fff !important;
	border-bottom: none;
	bottom: -1px;
	/* width: 14rem;
	 */
	position: relative;
	list-style: none;
	/* padding: 0px 0px !important;
	 */
	cursor: pointer;
}

.Stock_Header1 div {
	cursor: pointer;
	font-size: 18px;
	outline: none;
	font-weight: 500;
}

.Stock_Header1 .active {
	color: #fff;
}

.Stock_Search {
	background-color: #fff;
	border-radius: 10px;
	/* height: 20vh;
	 */
	margin-top: -1.5rem;
	padding: 3rem 2rem;
	/* height: 8rem;
	 */
}

.Stock_Search_Header {
	/* display: flex;
	 justify-content: space-between;
	 */
	margin-top: 1rem;
}

.filter {
	color: #000 !important;
	font-size: 19px;
	font-weight: 900;
}

.filter span {
	padding-left: 18px;
}

.filter img {
	width: 26px;
	height: 26px;
	text-align: right;
}

.Filter_Btn {
	/* margin-left: 2rem;
	 */
	color: #000 !important;
	/* padding: 1rem;
	 */
}

.Filter_Btn button {
	width: 10rem;
	height: 2.5rem;
	/* padding-right: 3rem;
	 */
	border-radius: 20px;
	text-align: center;
	background-color: #042b62;
	border: 1px solid #042b62;
}

.Filter_Btn .active {
	color: #fff;
}

/* .All_Stocks {
	 margin-top: 1.5rem;
	 display: grid;
	 grid-template-columns: repeat(3, 1fr);
	 grid-template-rows: 1fr;
	 grid-column-gap: 0px;
	 grid-row-gap: 0px;
	 padding-bottom: 2rem;
}
 */
.Stock_List {
	padding: 0rem;
	display: grid;
	grid-template-columns: repeat(7, 1fr);
	grid-template-rows: 1fr;
	grid-column-gap: 0px;
	grid-row-gap: 0px;
	margin-top: 2rem;
	font-size: 16px;
	font-weight: bold;
}

.CompareFundata {
	display: flex;
	justify-content: space-between;
}

.CompareBtn {
	display: grid;
	float: right;
}

.Seleted_Funds {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: 1fr;
	grid-column-gap: 50px;
	grid-row-gap: 0px;
}

.compareSmallBox {
	width: 18rem;
	border: 1px solid #d1d1d1;
	border-radius: 15px;
	padding: 8px;
	margin-top: 0rem;
}

.compareSmallBox img {
	margin-top: 10px;
	width: 60px;
	height: 60px;
}

.compareSmallBox p {
	margin-left: 2rem;
	text-align: left;
	padding-top: 10px;
}

.MobileTopsectors {
	display: none;
}

.Mobile_Sector_Allocation_List {
	display: none;
}

.lineSpace {
	border-right: 1px solid #c0c3c2;
	margin-top: 0.2rem !important;
	height: 0.8rem;
}

/* For Transaction */
.Transaction {
	/* margin-top: -1rem; */
	background: #fff;
	min-height: 100vh;
	padding-top: 2rem;
}

.Transaction h4 {
	font-weight: 500;
	padding-bottom: 1rem;
}

.Transaction .MainPanel {
	margin-top: 1rem;
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
	width: 100%;
	/* padding-bottom: 1rem; */
}

.MyCart {
	background: #f0f4f3;
}

.Transaction .path {
	display: flex;
	justify-content: space-between;
	white-space: nowrap !important;
}

.Transaction .path .home {
	width: 18px;
	height: 18px;
	margin-top: -0.2rem;
}

.Transaction .path .Arrow {
	width: 11px;
	height: 11px;
	margin-top: 0px;
}

.CartAmtBox a {
	text-decoration: none;
	color: Gray;
	font-weight: 500;
}

.CartAmtBox .path a:hover {
	color: #042b62;
	font-weight: 500;
}

.CartSummary {
	background: #042b62;
    border: 2px solid #fff;
    border-radius: 15px;
    color: #fff;
    margin-top: 3rem;
    width: 100%;
}

.CartCenter {
	padding-top: 1rem;
	letter-spacing: 2px;
	line-height: 19px;
}

.CartHeading {
	color: #000100;
	text-align: center;
	font-size: 1.2em;
	padding: 0.5em;
	/* padding-top: 0.8rem;
	 */
	font-weight: 500;
}

.InvestFundbox {
	margin-top: 1rem;
	/* height: 13rem;
	 */
	padding: 5px;
	background: #fff;
	border-radius: 10px;
}

.MyCartBox {
	height: 17rem;
}

.InvestFundbox .FundName {
	padding: 0.5rem;
	margin-top: 0.5em;
	display: flex;
}

.InvestFundbox .FundName img {
	width: 40px;
	height: 40px;
	border: 1px solid #d1d1d1;
	border-radius: 50%;
}

.InvestFundbox .FundHead {
	margin-left: 0.6rem;
	margin-top: 0.7rem;
}

.InvestFundbox .FundHead h4 {
	font-size: 1rem;
	font-weight: 900;
	margin-top: 0px;
}

.InvestFundbox .Layer {
	line-height: 10px;
}

.InvestFundbox .InvestType p {
	color: #000;
	font-size: 17px;
	height: 1.5rem;
	width: 9rem;
	display: flex;
	font-weight: 600;
	border-bottom: 1px solid #ddd;
	padding-bottom: 10px;
	/* clear: both;
	 */
}

.InvestFundbox .InvestType input[type="text"] {
	color: #000;
	padding-left: 0;
	font-size: 17px;
	white-space: nowrap;
	height: 3rem;
	overflow: visible;
	z-index: 1000000;
	font-weight: 600;
	padding-bottom: 2rem;
}

.InvestType #react-select-3-listbox {
	font-size: 12px !important;
	font-size: 500;
}

.InvestType .css-qc6sy-singleValue {
	height: 1rem;
}

.InvestType .css-1okebmr-indicatorSeparator {
	display: none !important;
}

.InvestFundbox .InvestOptions {
	padding: 1.2rem;
}

.InvestFundbox .InvestHeading p {
	color: #a3a3a3;
	font-size: 15px;
	font-weight: 500;
	letter-spacing: 1px;
}

.desktopspace {
	margin-left: -1em;
}

.AddLumsum p {
	color: #a3a3a3;
	font-size: 90%;
	font-weight: 700;
}

.AddLumsum p span {
	cursor: pointer;
}

.InvestSelectBank {
	margin-top: 1rem;
	width: 100%;
	background: #fff;
	border-radius: 10px;
	border: 1px solid rgba(0, 0, 0, 0.10);
}

.InvestSelectBank .Layer {
	width: 10rem;
}

.InvestSelectBank .SelectBankData {
	display: flex;
	padding-left: 1em;
	/* margin-left:rem;
	 */
}

.InvestSelectBank .SelectBankData .BankData {
	padding-left: 2rem;
}

.InvestSelectBank .BankLogo img {
	width: 30px;
	height: 30px;
	border: 1px solid #a3a3a3;
	border-radius: 50%;
}

.InvestSelectBank .NextArrow {
	margin-top: 1rem;
	margin-left: 1rem;
	float: right;
}

.InvestSelectBank .NextArrow img {
	width: 20px;
	height: 20px;
	float: right;
}

.InvestSelectBank {
	line-height: 10px;
	padding: 0.8em;
}

.InvestSelectBank .mobile-next {
	padding-top: 0.2em;
	padding-right: 1em;
}

.InvestSelectBank .InvestfundHeading {
	margin-top: 0.7rem;
}

.InvestSelectBank .InvestfundHeading div {
	color: #a3a3a3;
	font-size: 90%;
	font-weight: 500;
}

.InvestSelectBank .Heading p {
	color: #a3a3a3;
	font-size: 15px;
	font-weight: 500;
}

.BankName h4,
.AccountNo h4,
.BranchName h4 {
	font-size: 0.9em;
	white-space: nowrap;
	font-weight: 600;
}

.AccountNo {
	font-weight: 600;
	font-size: 1em;
}

.branchnm p {
	text-transform: lowercase;
}

.branchnm p::first-letter {
	text-transform: capitalize;
}

.SIPInstallment {
	border-right: 1px solid #000;
}

.HRLine {
	border-bottom: 1px solid #cddee6;
	width: 100%;
	padding: 0rem !important;
}

.CartCenter .AmtPayText {
	color: #000100;
	font-size: 0.9em;
	font-weight: 500;
	text-align: center;
	letter-spacing: normal;
}

.Rupeees {
	font-size: 1.4em;
	font-weight: 600;
}

.Rupeees .BackBtn {
	font-size: 1.4em;
	font-weight: 600;
	margin-left: 5.5em;
}

.Cart .BackBtn {
	font-size: 1.4em;
	font-weight: 600;
	margin-left: 0em;
}

.CartCenter .Rupeees img {
	width: 18px;
	height: 18px;
	vertical-align: text-top;
}

.CartCenter .Cart_Amt {
	font-size: 1.4em;
	font-weight: 600;
}

.SecureShield {
	display: flex;
	justify-content: center;
	align-items: center;
}

.SecureShield img {
	width: 40px;
	height: 40px;
	margin-top: 1rem;
}

.CartCenter .Safe {
	color: #fff;
	font-size: 0.7em;
	letter-spacing: normal;
	font-weight: 500;
	text-align: center;
	padding-top: 0.5em;
}

.CartBtn {
	background: #042b62;
	border-bottom-left-radius: 15px;
	border-bottom-right-radius: 15px;
	padding: 0.7rem;
	text-align: center;
	font-size: 0.9em;
	display: flex;
	justify-content: center;
	align-items: center;
}

.CartBtn a:hover {
	color: #fff;
	font-weight: 900;
}

.CartBtn button {
	background: none;
	text-align: center;
	color: #fff;
	font-size: 1em;
	border: none;
	font-weight: 600;
}

.CartBtn a {
	text-decoration: none;
	color: #fff;
}

.Rupeees .BackBtn {
	width: 25px;
	height: 25px;
	vertical-align: text-top !important;
}

.CartText {
	font-size: 25px;
	font-weight: 600;
	letter-spacing: 2px;
	padding-left: 0.5rem;
}

.AllTrans {
	max-width: 100%;
	/* border-bottom: 1px solid gray;
	 */
}

.AllTrans .tranText {
	font-size: 19px;
	font-weight: 700;
	display: inline-block;
	border-bottom: 3px solid #042b62;
	/* max-width: 151px;
	 */
}

.AllTrans .GreenLine {
	height: 5px;
	width: 10rem;
	border-radius: 15px;
	border: 1px solid transparent;
	background-color: #042b62;
}

.TwoSpace {
	margin-top: 1rem;
	display: flex;
	justify-content: space-between;
}

.TwoSpace .InvestFund {
	font-weight: 500;
}

.TwoSpace .AddFund {
	width: 8rem;
	height: 2rem;
	background: #042b62;
	border-radius: 15px;
	border: none;
	font-weight: 600;
	color: white;
	font-size: 90%;
	line-height: 2rem;
	text-align: center;
	display: block;
}

.CartFundList {
	margin-top: 1rem;
	height: 13rem;
	width: 55rem;
	background: #fff;
	border-radius: 15px;
	padding: 1.4rem;
}

.CartFundList .SelectdFundList img {
	width: 40px;
	height: 40px;
	border: 1px solid gray;
	border-radius: 50%;
}

.CartFundList .CartFundName {
	padding-left: 0.3rem;
	font-size: 18px;
	font-weight: 600;
	padding-top: 2rem;
}

.CartFun {
	margin-left: 1rem;
	margin-top: 1.4rem;
}

.CartFundOPt {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
	gap: 0px 41px;
}

.CartFundOPt .SameD {
	color: #c1c1c1;
	font-size: 14px;
	font-weight: 600;
	letter-spacing: 1px;
}

.CartFun .form-control {
	border: none;
	border-bottom: 1px solid #c1c1c1 !important;
	padding: 5px 0px;
	outline: none;
	width: 7rem;
	border-radius: 0;
}

.CartFun .form-select {
	border: none;
	border-bottom: 1px solid #c1c1c1 !important;
	padding: 5px 0px;
	outline: none;
	width: 7rem;
	border-radius: 0;
	color: #000 !important;
}

.CartFun [placeholder]:focus::-webkit-input-placeholder {
	transition: text-indent 0.4s 0.4s ease;
	text-indent: -100%;
	opacity: 1;
}

.Close_Data {
	cursor: pointer;
	margin-top: 1rem;
}

.CartFunS {
	margin-left: 1rem;
	color: #9c9c9c;
	cursor: pointer;
	font-size: 16px !important;
	font-weight: 600 !important;
	/* margin-top: 1.4rem */
}

.CartSelectBank .trans-head {
	text-align: center;
}

.SelectedBank {
	display: grid;
	place-items: center;
}

.BankLayer {
	width: 80%;
	height: 8rem;
	margin-top: 1rem;
	background-color: transparent;
	border: 1px solid #000 !important;
	border-radius: 10px;
	padding: 3rem;
	padding-top: 2.7rem;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: 1fr;
	grid-column-gap: 0px;
	grid-row-gap: 0px;
}

.BankLayer .BankName {
	display: flex;
}

.BankLayer .BankName img {
	width: 50px;
	height: 50px;
	border: 1px solid Gray;
	border-radius: 50%;
}

.BankLayer .BankName .BankText {
	padding-left: 1rem;
}

.BankLayer .Bank {
	color: Gray;
	font-size: 13px;
}

.BankLayer .BankDet {
	color: #000;
	font-size: 19px;
	font-weight: 600;
	white-space: nowrap;
}

.BankPayLayer {
	width: 80%;
	height: 9rem;
	margin-top: 1rem;
	background-color: transparent;
	border: 1px solid #000 !important;
	border-radius: 10px;
	padding: 3rem;
	padding-top: 1.5rem;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: 1fr;
	grid-column-gap: 0px;
	grid-row-gap: 0px;
}

.BankLayer .PayNext img {
	width: 20px !important;
	height: 20px !important;
	margin-left: 1rem;
	margin-top: -1rem;
}

.Payopt {
	display: flex;
}

.BankPayLayer .Payopt img {
	width: 80px;
	height: 80px;
}

.BankPayLayer .PayText {
	width: 40rem;
	margin-left: 1rem;
}

.BankPayLayer .PayText div:first-child {
	color: #000;
	font-size: 19px;
	font-weight: 600;
	white-space: nowrap;
}

.BankPayLayer .PayText div:nth-child(2) {
	color: gray;
	font-size: 16px;
}

.BankPayLayer .PayAutoNext {
	width: 20px;
	height: 20px;
	margin-top: 2rem;
	margin-left: 3rem;
}

.InvestSelectBank .PayOption {
	padding: 10px;
	margin-left: 1em;
	line-height: 1px;
	margin-top: 0.7em;
}

.PayOption .PayType p {
	color: #000;
	font-size: 1.1em;
	font-weight: 600;
}

.PayOption .NextArrow {
	margin-left: 0rem;
}

.InvestSelectBank .PayOptionInfo {
	line-height: 1.2rem;
	max-width: 100%;
	/* line-height: 10px;
	 */
	font-size: 0.9em;
	font-weight: 400;
	color: #a0a0a0;
}

.InvestSelectBank .AutoPayOPtion img {
	height: 50px;
	margin-top: 0.6em;
}

.CartSelectSIP {
	margin-top: 0rem;
	margin-bottom: 2rem;
}

.CartSelectSIP .SIP_Install {
	display: flex;
	justify-content: space-between;
	width: 50%;
}

.SIP_Install .line {
	height: 14rem;
}

.CartSelectSIP .SIP_Install div:first-child {
	text-align: center;
}

.CartSelectSIP .SIP_Install img {
	width: 180px;
	height: 180px;
}

.CartSelectSIP .SIP_Install .Active {
	background: #f2f2f2;
	border-radius: 50%;
	padding: 2rem;
}

.CartSelectSIP .SIP_Install .INActive {
	background: #fff;
	/* border-radius: 50%;
	 */
	padding: 3rem;
}

.CartSelectSIP .SIP_Install div:nth-child(3) {
	margin-left: 2rem;
	text-align: center;
}

.Transaction .PaymentLayout {
	margin-top: 4rem;
}

.text-label h4 {
	font-size: 1.4em;
}

.NEFTMobile {
	display: none;
}

.CartSelectSIP .paymentgatewayoption {
	border: 1px solid #d6d6d6;
	padding: 1.2rem;
	width: 100%;
	margin-top: 1.1rem;
	border-radius: 10px;
}

.CartSelectSIP .paymentgatewayoption .select-bank .fintoo-checkbox img {
	width: 16px;
	height: 15px;
	margin-top: 1em;
}

.CartSelectSIP .UPIBox .paymentgatewayoption:first-child {
	margin-top: 1.5rem;
}

.CartSelectSIP .PaymentType {
	font-size: 18px;
	margin-top: 1rem;
}

.CartSelectSIP .paymentgatewayoption .NextArrow {
	margin-top: 1rem;
}

.CartSelectSIP .paymentgatewayoption .PayImg img {
	width: 40px;
	height: 40px;
	margin-top: 0.5em;
}

.CartSelectSIP .paymentgatewayoption .UPI {
	height: 50px !important;
	width: 50px !important;
	margin-top: 0.7rem;
}

.boxContent .AccountDetails {
	line-height: 5px !important;
	display: grid;
}

.boxContent .AccountDetails .AccountLabel {
	white-space: nowrap;
}

.boxContent .UPIID input[type="text"] {
	outline: 0;
	/* border-width: 0 0 1px;
	 */
	/* border-color: gray;
	 */
	border: none;
	margin-top: 1rem;
	height: 2.4rem;
	width: 100%;
	background: #f1f1f1;
	font-size: 17px;
	text-align: center;
	font-weight: 500;
	color: #042b62;
	padding-left: 1rem;
	border-radius: 11px;
}

.SelectBank .NeftBox .paymentgatewayoption {
	width: 100%;
	border: 1px solid #d6d6d6;
	padding-left: 1rem;
}

.boxContent .PayNow {
	background-color: #042b62;
	color: #fff;
	border-radius: 20px;
	width: 7rem;
	font-size: 0.8em;
	font-weight: 700;
	border: 1px solid #fff;
	padding: 8px;
	margin-top: 1rem;
}

input[type="checkbox"]:checked {
	border: #1c4cbd;
	background-color: #042b62 !important;
}

.boxContent .AccountDetails h4 {
	white-space: nowrap;
	font-size: 0.9em;
}

.PaymentLayout .CartSummary {
	margin-top: 0rem;
}

.CartSelectSIP .paymentgatewayoption .AccountDetails {
	margin-top: 0.7em;
}

.paymentgatewayoption .BankName {
	margin-top: 0.7em;
}

.paymentgatewayoption .BankName .AccountLabel {
	color: #a0a0a0;
	font-size: 0.8em;
	font-weight: 500;
	white-space: nowrap;
}

.NeftBox img {
	margin-top: 0.7em;
}

.CartSelectSIP .paymentgatewayoption .H-line .AccountDetails {
	border-right: 1px solid #7f7f7f;
}

.CartSelectSIP .paymentgatewayoption .AccountDetails .AccountLabel {
	color: #a0a0a0;
	font-size: 0.8em;
	font-weight: 500;
	white-space: nowrap;
}

.form-check-input:checked {
	background-color: #042b62 !important;
	border-color: #042b62 !important;
}

.CartSelectSIP .paymentgatewayoption .next-process {
	float: right;
	white-space: nowrap;
}

.Transaction .TotalPayText {
	display: flex;
	justify-content: flex-start;
	margin-left: 6rem;
}

.Transaction .TotalPayText p {
	text-align: left;
}

.Transaction .PayAmount {
	font-size: 40px;
	color: #042b62;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 20rem;
	font-weight: 600;
	border-bottom: 1px solid #a3a3a3;
}

.Transaction .PayAmount span:nth-child(2) {
	padding-left: 0.7rem;
}

.SIP_Install .SIPText {
	font-size: 20px;
	font-weight: bold;
	margin-top: 2rem;
}

.PaymentText {
	text-align: center;
	font-size: 0.9em;
	color: #a0a0a0;
	font-weight: 500;
}

.CartSelectSIP .PaymentMode {
	margin-top: 3rem;
	width: 60rem;
}

.PaymentOpt .OptPay {
	border: 1px solid black;
	width: 500px;
	height: 6rem;
	margin-top: 1rem;
	border-radius: 10px;
	padding: 2rem;
	display: flex;
	justify-content: space-between;
}

.PaymentOpt .OptPay:first-child {
	margin-top: 0rem;
}

.OptPay .NextPay {
	width: 20px;
	cursor: pointer;
}

.PayText {
	font-size: 20px;
	font-weight: bold;
	letter-spacing: 1.3px;
}

.AmountBox {
	display: grid;
	place-items: center;
}

.AmountBox #currencyInput {
	border: none;
	background: none;
	color: #042b62;
	font-size: 29px;
	padding-left: 0.7rem;
	font-weight: 900;
	text-align: center;
}

.AmountBox p {
	font-size: 24px;
	font-weight: bold;
	letter-spacing: 1.3px;
}

.AmountBox .Input_Rupee {
	color: #042b62;
	font-size: 29px;
	font-weight: 900;
	padding-left: 1rem;
	/* text-align: center;
	 */
}

.PaymentMode hr {
	width: 24rem;
}

@media screen and (min-width: 768px) {
	.Stock_List_Table {
		background-color: #fff;
		margin-top: 1rem;
		display: flex;
		align-items: flex-start;
		/* width: 100%;
		 */
		justify-content: center;
		/* flex-direction: column;
		 */
		padding: 2rem;
		border-radius: 15px;
	}

	.fList {
		width: 100%;
	}

	.sList {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-start;
		padding-bottom: 24px;
		padding-top: 16px;
		/* border-bottom: 1px solid #eee;
		 */
		width: 100%;
	}

	.SOList {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		/* align-items: flex-start;
		 */
		width: 100%;
		/* margin-right: 40px;
		 */
	}

	.StList {
		cursor: pointer;
	}

	.SooList {
		width: 300px !important;
	}

	.SoTList {
		display: flex;
		/* flex-direction: row;
		 */
		justify-content: flex-start;
		align-items: center;
	}

	.SottLoist {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-end;
		/* width: 142px;
		 */
	}

	.item-side-options .Sample {
		flex: 1 1 auto;
	}
}

@media (max-width: 600px) {
	.SoTList {
		display: flex;
		justify-content: flex-start;
	}

	.Stock_List_Table {
		background-color: #fff;
		margin-top: 1rem;
		display: flex;
		align-items: flex-start;
		width: 100%;
		justify-content: center;
		flex-direction: column;
		padding: 2rem;
		border-radius: 15px;
	}
}

.Stock_List div:first-child {
	text-align: left;
}

.Stock_List div {
	text-align: center;
}

/* .Stock_List div:first-child {
	 width: 21em;
}
 */
/* .Stock_List_Table div:first-child {
	 width: 18em;
	 font-size: 18px;
	 font-weight: 900;
}
 */
.Stock_List_Table a {
	color: #000;
}

.Mobile_Fund {
	display: none;
}

.mobileTopHoldings {
	display: none;
}

.Stock_List_Table .Data_td input[type="checkbox"] {
	width: 2rem;
	height: 1rem;
	border: 1px solid #d1d1d1;
	background: #000;
	border-radius: 15px !important;
	outline: none;
}

.bottom_text {
	display: flex;
	font-size: 15px;
	font-weight: 400;
	margin-left: 0.5rem;
	color: #878a89;
}

.bottom_text span:nth-child(2) {
	padding-left: 1rem;
}

.Explore {
	color: #fff !important;
}

.Data_td {
	text-align: center;
	color: black;
	font-weight: bold;
}

.Stock_List_Table button {
	width: 9rem;
	height: 2rem;
	margin-top: 0.5rem;
	border-radius: 15px;
	text-align: center;
	background-color: #042b62;
	border: 1px solid #042b62;
	color: #fff;
}

/* SidePanel */
.panel-content {
	z-index: 10000000000000;
}

.panel-content .filter_text {
	font-size: 1.5rem;
	font-weight: 500;
}

.panel-content .remove-border-li {
	border-bottom: none;
}

.sliding-panel-container {
	position: fixed;
	top: 0;
	font-size: 15px;
	left: 0;
	/* background-color: #fff;
	 */
}

.SideBar_Filter {
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid #d3d3d3;
}

.SideBar_Filter button {
	outline: none !important;
	width: 40px;
	height: 40px;
	margin-top: -1em;
	border-radius: 50% !important;
	border: 0;
	background: none;
}

.SideBar_Filter button img {
	outline: none !important;
	width: 26px;
	height: 26px;
}

.sliding-panel-container.active {
	position: fixed;
	z-index: 100000;
	background-color: rgba(0, 0, 0, 0.3);
	will-change: transform;
}

.sliding-panel-container.active.click-through {
	pointer-events: none;
	background-color: transparent;
}

.sliding-panel-container.click-through>div {
	pointer-events: none;
}

.panel-container-left-enter {
	opacity: 0;
	transform: translateX(-100%);
}

.panel-container-left-enter-active {
	opacity: 1;
	transform: translateX(0);
	transition: transform 500ms, opacity 500ms;
}

.panel-container-left-exit {
	opacity: 1;
	transform: translateX(0);
}

.panel-container-left-exit-active {
	opacity: 0;
	transform: translateX(-100%);
	transition: transform 500ms, opacity 500ms;
}

.panel-container-right-enter {
	opacity: 0;
	transform: translateX(100%);
}

.panel-container-right-enter-active {
	opacity: 1;
	transform: translateX(0);
	transition: transform 500ms, opacity 500ms;
}

.panel-container-right-exit {
	opacity: 1;
	transform: translateX(0);
}

.panel-container-right-exit-active {
	opacity: 0;
	transform: translateX(100%);
	transition: transform 500ms, opacity 500ms;
}

.panel-container-top-enter {
	opacity: 0;
	transform: translateY(-100%);
}

.panel-container-top-enter-active {
	opacity: 1;
	transform: translateX(0);
	transition: transform 500ms, opacity 500ms;
}

.panel-container-top-exit {
	opacity: 1;
	transform: translateX(0);
}

.panel-container-top-exit-active {
	opacity: 0;
	transform: translateY(-100%);
	transition: transform 500ms, opacity 500ms;
}

.panel-container-bottom-enter {
	opacity: 0;
	transform: translateY(100%);
}

.panel-container-bottom-enter-active {
	opacity: 1;
	transform: translateX(0);
	transition: transform 500ms, opacity 500ms;
}

.panel-container-bottom-exit {
	opacity: 1;
	transform: translateX(0);
}

.panel-container-bottom-exit-active {
	opacity: 0;
	transform: translateY(100%);
	transition: transform 500ms, opacity 500ms;
}

h4 {
	margin-top: 1rem;
}

.Filter_Btn_panel {
	bottom: 0;
	background: #fff;
	width: 100%;
}

.Filter_Btn_panel button {
	width: 8rem;
	height: 2.5rem;
	/* padding-right: 3rem;
	 */
	border-radius: 20px;
	text-align: center;
	background-color: #042b62;
	border: 1px solid #042b62;
}

.Filter_Btn_panel button:first-child {
	color: #fff;
	font-weight: 900;
}

.Filter_Btn_panel .reset_btn button {
	background-color: #c0c3c2 !important;
	border: 1px solid #c0c3c2;
	color: gray;
}

.Filter_Btn_panel .reset_btn {
	padding-left: 1rem;
}

/* Search List */
.searchbar-desktop .user-list {
	position: absolute;
	margin-top: 30px;
	width: 100%;
	display: grid;
	background: #e9ecef;
	border-radius: 15px;
	margin-top: 0.2rem;
	padding: 1rem;
	color: #000 !important;
	z-index: 999;
}

/* .Search_tab{
	 display: none;
}
 */
.Mobile_Stock_search {
	display: none;
}

.user {
	list-style: none;
	margin: 10px 0;
	padding: 1rem;
	background: #fff;
	display: flex;
	justify-content: space-between;
	border-radius: 18px;
	color: #000 !important;
}

.Search_tab ul {
	display: flex !important;
	color: #000;
	width: 100%;
	justify-content: space-between;
	list-style-type: none;
}

.Search_tab ul li:first-child {
	text-align: left;
	margin-left: -1.5rem;
	/* border-bottom: 2px solid #042b62;
	 */
}

.Search_tab ul li:first-child a {
	color: #000;
}

.Search_tab ul li:hover {
	border-bottom: 2px solid #042b62;
}

.Search_tab ul li:hover a {
	/* border-bottom: 2px solid #042b62;
	 */
	color: #000;
}

.Search_tab a {
	color: gray;
}

.user:nth-child(even) {
	background: #c0c3c2;
}

.user-id {
	/* color: red;
	 */
	margin-right: 20px;
}

.user-age {
	margin-left: auto;
}

/* For Pagination*/
.sweetPagination {
	display: flex !important;
	justify-content: flex-end !important;
}

.sweetPagination .paginationUL {
	margin: 0;
	padding: 0;
	display: flex;
	justify-content: center;
}

.sweetPagination .paginationUL .pageItem {
	list-style: none;
}

.sweetPagination .paginationUL .pageItem .pageButton {
	font-size: 16px;
	font-weight: 600;
	border-radius: 5rem;
	width: 40px;
	height: 40px;
	cursor: pointer;
	margin: 5px;
	background-color: #ddd;
}

.sweetPagination .paginationUL .pageItem .pageButton:hover {
	color: #f1f2f6;
	background-color: #fd7238;
	fill: #f1f2f6;
}

.sweetPagination .paginationUL .pageItem .activeButton {
	color: #f1f2f6;
	background-color: #fd7238;
}

/* .pageButton svg{
	 width: 1.5rem;
}
 */
.navigationIcon {
	width: 12px;
}

.dottedIcon {
	width: 20px;
}

/* common css style for all button */
.sweetPagination .pageItem .commonButtonStyle {
	outline: none;
	border: none;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: transparent;
}

/* Button Style 1 */
.style-1 .paginationUL .pageItem .pageButton {
	margin-left: 5px;
	padding: 0;
	width: 40px;
	height: 40px;
	line-height: 30px;
	background-color: transparent;
	-moz-border-radius: 100%;
	-webkit-border-radius: 100%;
	border-radius: 100%;
}

.style-1 .paginationUL .pageItem .pageButton:hover {
	background-color: #042b62 !important;
	color: #f1f2f6;
	fill: #f1f2f6;
}

.style-1 .paginationUL .pageItem .activeButton {
	background-color: #042b62 !important;
	color: #f1f2f6;
}

/* Button Style 2 */
.style-2 .paginationUL .pageItem .pageButton {
	font-size: 14px;
	margin: -1px;
	margin-top: 5px;
	border: 1px solid transparent;
	line-height: 1.5;
	border-color: #ddd;
	color: #4285f4;
	fill: #4285f4;
	background: #fff;
	border-radius: 0;
	height: 40px;
	width: 40px;
}

.style-2 .paginationUL .pageItem .activeButton {
	background-color: #4285f4;
	color: #eee;
}

.style-2 .paginationUL .pageItem .pageButton:hover {
	background-color: #eee;
	color: #4285f4;
	fill: #4285f4;
}

.style-2 .paginationUL .pageItem .leftNavigation {
	-moz-border-radius: 6px 0 0 6px;
	-webkit-border-radius: 6px;
	border-radius: 6px 0 0 6px;
}

.style-2 .paginationUL .pageItem .rightNavigation {
	-moz-border-radius: 0 6px 6px 0;
	-webkit-border-radius: 0;
	border-radius: 0 6px 6px 0;
}

/* Media Quary */
@media only screen and (max-width: 576px) {
	.sweetPagination .paginationUL .pageItem .pageButton {
		width: 25px;
		height: 25px;
		margin: 3px;
	}

	.style-2 .paginationUL .pageItem .pageButton {
		margin: -1px;
		margin-top: 5px;
	}
}

/* */
.rwt__tabs[data-rwt-vertical="true"] {
	display: flex;
	outline: none;
}

.rwt__tablist:not([aria-orientation="vertical"]) {
	border-bottom: 1px solid #ddd;
	outline: none;
}

.rwt__tablist[aria-orientation="vertical"] {
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
	flex-grow: 0;
	border-right: 1px solid #ddd;
	margin-right: 1rem;
}

.Path {
	width: 100%;
	height: 100%;
	/* margin-top: 7rem;
	 */
	margin-right: 10rem;
	display: flex;
	justify-content: flex-end;
	padding-right: 8rem;
}

.rwt__tab {
	background: transparent;
	border: 0;
	height: 5rem;
	font-family: inherit;
	font-size: inherit;
	padding: 1rem 2rem;
	transition: background 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.Stock_Details .Path {
	width: 100%;
	height: 100%;
	margin-top: 0rem;
	margin-right: 10rem;
	display: flex;
	justify-content: flex-end;
	padding-right: 8rem;
}

.Stock_Details .Path div {
	padding-right: 1rem;
}

.imgC img {
	width: 24px;
	height: 24px;
	/* padding-bottom:10px ;
	 */
	/* opacity: 0.5; */
	vertical-align: top !important;
	margin-top: -3px;
}

.All_Stocks .imgC {
	width: 30px !important;
	height: 30px !important;
	margin-top: -0.9rem;
	padding-right: 4px;
}

.rwt__tab[aria-selected="false"]:hover,
.rwt__tab:focus {
	outline: 0;
	background-color: #f4f4f4;
	background-color: rgba(0, 0, 0, 0.05);
}

.rwt__tab[aria-selected="true"] {
	position: relative;
}

.rwt__tab[aria-selected="true"]:after {
	content: "";
	position: absolute;
}

.rwt__tablist:not([aria-orientation="vertical"]) .rwt__tab[aria-selected="true"]:after {
	bottom: -1px;
	left: 0;
	width: 100%;
	border-bottom: 3px solid #00d8ff;
}

.rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]:after {
	right: -1px;
	top: 0;
	height: 100%;
	border-right: 3px solid #00d8ff;
}

/* For Compare.jsx */
.Compare_Details {
	overflow: hidden !important;
	margin-top: 0rem;
	place-items: center;
	margin-top: 7rem;
}

.Header_Compare {
	background-color: #fcfcfc;
	/* width: 80%;
	 */
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
}

.Header_Compare .Header_Name {
	/* display: flex;
	 */
	justify-content: space-between;
	padding: 2rem;
	border-bottom: 1px solid #d1d1d1 !important;
}

.Header_Compare .Header_Name input[type="search"] {
	outline: none;
	width: 50rem;
	padding: 0.5rem;
	border-radius: 15px;
	font-size: 20px;
	border: 1px solid #d1d1d1;
}

.Header_Compare .Header_Name input[type="search"]::placeholder {
	font-size: 16px;
}

.Header_Compare .line {
	width: 100%;
	padding: 0 !important;
}

.Stock_Compare_List {
	display: flex;
	/* padding: 1rem;
	 */
	/* justify-content: space-between;
	 */
}

.Stock_Compare_List .item-container {
	border-right: 1px solid #d3d3d3;
}

.Stock_Compare_List .compare_dec {
	width: 12%;
	border-right: 1px solid #d3d3d3;
	/* padding-top: 2rem !important;
	 */
	/* padding-left: 0rem !important;
	 */
	padding: 3rem 1em;
}

.Stock_Compare_List .items-main-container {
	border: none;
	width: 88%;
}

.Stock_Compare_List .items-main-container .item-container:last-child {
	border-right: none;
}

.Stock_Compare_List h6 {
	font-size: 18px;
	font-weight: 800;
}

.Stock_Compare_List p {
	/* color: gray;
	 */
	font-size: 1em;
	/* padding-top: 1rem;
	 */
	font-weight: 400;
}

.Stock_Compare_List .Funds_Name {
	border: none;
	display: flex;
	/* margin-top: -2rem;
	 */
	/* height: 7rem;
	 */
	color: #000;
	font-weight: 600;
	font-size: 1em;
	line-height: 1.4em;
}

.Stock_Compare_List .Fund_Options {
	border: none !important;
	/* display: flex;
	 */
	/* justify-content: space-between;
	 */
	/* margin-left: -3rem;
	 */
	color: gray;
	/* padding-left: 60px;
	 */
	/* font-weight: 600;
	 */
}

.Stock_Compare_List .Fund_Options .Line {
	border-right: 1px solid #c0c3c2;
	margin-top: 0.2rem !important;
	height: 1.4rem;
}

.Stock_Compare_List .Invest_Btn {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: auto;
	padding-top: 1em;
	flex-direction: column;
}

.Stock_Compare_List .Invest_Btn button {
	width: 10rem;
	height: 2.3rem;
	font-size: 1rem;
	border-radius: 25px;
	padding: 1px;
	font-weight: 900;
	border: 2px solid #fff;
	color: #fff;
	background-color: #042b62;
}

/* Style the accordion.jsx section */
.AllComapareDetails {
	background-color: #fff;
	/* width: 80%;
	 */
	/* margin-top: -2rem;
	 */
	/* padding: 2rem;
	 */
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
	overflow: hidden;
}

[pointer-events="bounding-box"] {
	display: none;
}

.FundssDetails .Fund_Sub h6 {
	color: #000;
}

.Fund_Sub div {
	border-right: 1px solid #d1d1d1;
	padding-bottom: 2rem;
	float: left;
	padding-right: 2rem;
	padding-left: 4rem;
	color: gray;
}

.FundssDetails .Fund_Sub .First_Div {
	padding-top: 1rem;
}

.FundssDetails .Fund_Sub .Last_div {
	border: none;
}

#AssetAllo h4 {
	color: gray;
	font-weight: 900;
}

/* .AllocationTabs {
	 margin-top: 2rem;
}
 */
.AllocationTabs ul.nav {
	width: 100%;
	margin: 0 auto 2rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border: 1px solid #042b62;
	border-radius: 2rem;
	padding-left: 0px;
}

@media (max-width: 768px) {
	.AllocationTabs ul.nav {
		width: 90%;
	}
}

.AllocationTabs ul.nav li {
	width: 50%;
	font-weight: 700;
	font-size: 1em;
	padding: 1rem;
	list-style: none;
	text-align: center;
	cursor: pointer;
	transition: all 0.7s;
	border-bottom-left-radius: 2rem;
	border-top-left-radius: 2rem;
}

.AllocationTabs ul.nav li:nth-child(2) {
	border-radius: 0;
	border-bottom-right-radius: 2rem;
	border-top-right-radius: 2rem;
}

.AllocationTabs ul.nav li:hover {
	background: rgba(179, 182, 181, 0.15);
}

.AllocationTabs ul.nav li.active {
	background: #042b62;
	color: #fff;
}

.Sector_All {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-column-gap: 0px;
	grid-row-gap: 0px;
	/* margin-top: 2rem;
	 */
	gap: 3rem 5rem;
}

/* .Sector_All div {
	 padding: 2rem;
	 padding-top: 1rem;
	 width: 19.6rem;
}
 */
.Sector_All h5 {
	margin-top: 2rem;
	margin-bottom: 2rem;
}

.Sector_All .Sector_Allocation_List {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-template-rows: repeat(9, 1fr);
	grid-column-gap: 0px;
	grid-row-gap: 0px;
}

.Sector_All .Sector_Allocation_List div {
	border-right: 1px solid #d1d1d1;
	width: 15rem;
	padding-bottom: 2rem;
	padding-top: 1rem;
	font-size: 14px;
}

.Sector_All .Sector_Allocation_List .Last_div {
	border: none;
}

.Sector_All .First_Div {
	border-right: 1px solid #d1d1d1;
	width: 15rem !important;
	padding-top: 2rem;
	padding-bottom: 2rem;
	float: left;
	padding-right: 4rem;
	color: gray;
}

.Sector_All input[type="range"] {
	-webkit-appearance: none;
	overflow: visible !important;
	/* width: 15rem;
	 */
	width: 100%;
	margin-top: 1rem;
	height: 10px;
	background: grey;
	border-radius: 14px;
	background-repeat: no-repeat;
	/* background-image: linear-gradient(#042b62, #042b62);
	 */
}

.Sector_All input[type="range"]::-webkit-slider-thumb {
	-webkit-appearance: none;
	/* display: none;
	 */
}

.Sector_All input[type="range"]::-webkit-slider-runnable-track {
	-webkit-appearance: none;
	box-shadow: none;
	border: none;
	background: transparent;
}

.Sector_All input[type="range"]::-webkit-slider-thumb:hover {
	box-shadow: #fff 0px 0px 0px 8px;
}

.Sector_All input[type="range"]::-webkit-slider-thumb:active {
	box-shadow: #fff 0px 0px 0px 11px;
	transition: box-shadow 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, left 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, bottom 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.Holdings_table {
	margin-top: 1rem;
}

.Holdings_table a,
.Holdings_table a:hover {
	color: #042b62;
	text-decoration: none;
}

.Holdings_table h4 {
	color: gray;
	font-weight: 900;
	padding-bottom: 1em;
}

.TopHoldings .Holding_Details {
	display: flex;
}

.TopHoldings .Holding_Details .Two_Details {
	display: flex;
	justify-content: space-between;
	width: 19rem;
	margin-left: -4rem;
	padding-right: 1rem;
	border-right: 1px solid #d1d1d1;
}

.TopHoldings .Holding_Details div {
	/* padding-bottom: 1rem;
	 */
	font-size: 14px;
}

.Two_Details .Comp_Name {
	background-color: #e8e8e8;
	height: 2rem;
	padding: 5px;
	width: 11rem;
	text-align: center;
}

.Two_Details .Comp_Holding {
	background-color: #e8e8e8;
	height: 2rem;
	padding: 5px;
}

.Close_Fund {
	cursor: pointer;
}

.TopHoldings .Holding_Details .Two_Details:last-child {
	border: none;
}

.Holding_Details .First_Div {
	border-right: 1px solid #d1d1d1;
	width: 15rem !important;
	padding-top: 3rem;
	padding-bottom: 2rem;
	float: left;
	padding-right: 4rem;
	color: gray;
}

/* .Sample .Value {
	 padding-top: 10px;
}
 */
.Value img {
	width: 13px;
	height: 13px;
	vertical-align: inherit;
}

.Path_Home {
	width: 18px;
	height: 18px;
}

.Path_Arrow {
	width: 16px;
	height: 16px;
}

.Stock_Img img {
	width: 35px;
	height: 35px;
	margin-top: 0px !important;
	border-radius: 50px;
}

.TabHeader {
	display: flex;
	/* justify-content: space-between;
	 */
	align-items: center;
}

.Stock_Img .imgCExplore {
	display: none !important;
}

.Holdings_table table th {
	/* padding: 1rem;
	 */
	text-align: center;
	background-color: none !important;
	color: Gray;
	padding-bottom: 0.4rem;
	border-bottom: 1px solid #d1d1d1 !important;
}

.Holdings_table table th:first-child {
	/* padding-left: 0.4rem;
	 */
	text-align: left;
}

.Holdings_table .Nm {
	margin-top: 0rem;
	padding-left: 10px;
	max-width: 20rem;
	overflow: hidden;
}

.Holdings_table td {
	/* padding: 1rem;
	 */
	width: 12rem;
	text-align: center;
	height: 20px;
	padding-bottom: 0.5rem;
}

.Holdings_table td:first-child {
	color: #042b62;
	font-size: 1em;
	font-weight: bold;
	/* padding: 2rem;
	 */
	padding-top: 20px;
	text-align: left;
	max-width: 20rem;
}

.Holdings_table td img {
	width: 1.5rem;
	height: 1.5rem;
	vertical-align: top !important;
}

/* for Risk measures table */
.Risk_mesures {
	margin-top: 0rem !important;
}

.Risk_mesures table th {
	text-align: center;
	background-color: none !important;
	color: Gray;
	padding-bottom: 1rem;
	border-bottom: 1px solid #d1d1d1 !important;
}

.Risk_mesures table th:first-child {
	/* padding-left: 0.4rem;
	 */
	text-align: left;
	font-size: 20px;
	/* font-weight: 600; */
	width: 24rem;
}

.Risk_mesures .Nm {
	position: absolute;
	margin-top: 0rem;
	padding-left: 10px;
}

.Risk_mesures td {
	/* padding: 1rem;
	 */
	width: 12rem;
	text-align: center;
	height: 20px;
	padding-bottom: 0.5rem;
}

.Risk_mesures td:first-child {
	color: #042b62;
	font-size: 1em;
	font-weight: bold;
	/* padding: 2rem;
	 */
	padding-top: 20px;
	text-align: left;
	width: 26rem;
}

#Comapre {
	margin-top: 2rem;
}

/* For others */
/* .OthersPanel {
	 margin-top: 2rem !important;
}
 */
.OthersPanel h4 {
	font-size: 20px;
	color: gray;
	font-weight: 900;
}

.OthersPanel img {
	width: 30px;
	height: 30px;
}

.OthersPanel .Nm {
	margin-top: 0rem;
	padding-left: 10px;
	color: #000;
	font-size: 1.2em;
	font-weight: 900;
}

.OthersPanel .Other_First span:nth-child(2) {
	font-size: 1.2em;
	font-weight: 900;
}

.Other_Detail .Other_First {
	padding-left: 10px;
}

.Vertical_Line {
	border-right: 1px solid #d1d1d1;
	width: 10px;
	margin-top: 10px;
	margin-left: 10px;
	margin-right: 10px;
}

.UserNM {
	color: #000;
	font-size: 1.2em;
	font-weight: 900;
}

.bottom-spacer {
	height: 10vh;
}

.RegName {
	margin-top: 2rem;
}

.RegName p {
	font-weight: 500;
}

.FundMangerName {
	margin-top: 3rem;
}

.FundMangerName p {
	font-weight: 500;
}

/* For Right side Panel */
.RightSide {
	margin-top: -2rem;
}

.RightSide .path span {
	padding-left: 10px;
	color: gray;
	font-weight: 500;
}

.RightSide .path img {
	width: 15px !important;
	height: 15px !important;
}

.RightSide .path .AllFundPath:hover {
	color: #042b62 !important;
}

/* Tab Container */
.Tabs {
	/* width: 28rem;
	 */
	/* height: auto;
	 min-height: 400px;
	 */
	background: #fdfdfd 9c;
	margin: 2.5rem auto 1.5rem;
	border: 2px solid #fff;
	padding: 0rem 0rem;
	color: #000;
}

@media (max-width: 769px) {
	.Tabs {
		padding: 2rem 0;
	}
}

.SIPLum {
	padding: 1rem;
	text-align: center;
	/* font-weight: 500;
	 */
	/* width: 14rem !important;
	 */
}

.Lump {
	text-align: left !important;
	padding-left: 0rem;
	font-weight: 500;
}

.RightSide .react-tabs__tab-panel {
	padding: 2rem !important;
}

.Input_Rupee {
	font-size: 35px;
	color: #042b62;
	font-weight: bold;
}

.bloc-tabs button:hover {
	color: #042b62;
}

input:focus {
	border-color: green;
}

.Input_Rupee {
	font-size: 35px;
	color: #042b62;
	font-weight: bold;
}

.calc-tablist-panel .Plan {
	display: flex;
	padding-top: 2rem;
	justify-content: space-between;
}

.calc-tablist-panel .react-datepicker__header {
	/* display: none; */
}

.Plan span:first-child {
	color: #9a9a9a;
	font-size: 1em;
	font-weight: 500;
}

.Plan .Plan_SIP span:nth-child(2) {
	padding-top: 0rem;
}

.Total_amnt .Value {
	color: #9a9a9a;
	font-weight: 400;
	font-size: 24px;
}

.years {
	padding-top: 2rem;
}

.years input[type="checkbox"] {
	width: 1.3rem;
	height: 1.3rem;
	background-color: white;
	border-radius: 50%;
	vertical-align: middle;
	border: 1px solid #ddd;
	appearance: none;
	-webkit-appearance: none;
	outline: none;
	cursor: pointer;
}

.years input[type="checkbox"]:checked {
	background-color: #042b62;
}

.form-check-input:focus {
	box-shadow: none !important;
}

.form-check label {
	display: inline-block;
	padding-left: 0.4rem;
	padding-top: 2px;
	font-size: 0.9rem;
	color: gray;
}

.years span:nth-child(2) {
	font-size: 18px;
	font-weight: 500;
	color: #9a9a9a;
}

.Time {
	width: 30px;
	height: 30px;
	position: relative;
	/* top: -0.1rem;
	 */
	left: 0rem;
}

.Right_Btn {
	display: flex;
	margin-top: -0.6rem;
}

.Right_Btn button:first-child {
	display: grid;
	place-content: center;
	margin-top: 0rem;
	cursor: pointer;
	width: 50%;
	bottom: 0;
	background-color: #042b62;
	color: #fff;
	height: 3.35rem;
	border: none;
	vertical-align: middle;
	text-align: center;
	font-weight: 900;
}

.Right_Btn button:nth-child(2) {
	display: grid;
	place-content: center;
	cursor: pointer;
	margin-top: 0rem;
	float: right;
	/* justify-content: space-between;
	 */
	width: 50%;
	bottom: 0;
	background-color: #fff;
	color: #a2a2a2;
	height: 3.35rem;
	text-align: center;
	/* border-bottom-left-radius: 15px;
	 */
	border: none;
	font-weight: 900;
	/* margin-top: 10.2rem;
	 */
}

.LumpSumData {
	height: 11.3rem;
}

#Calc_Amt_Range {
	color: gray;
	margin-top: 5rem;
	/* width: 77%;
	 */
}

#Calc_Amt_Range p {
	font-weight: 600;
	font-size: 0.9375rem;
}

#Calc_Amt_Range .return {
	margin-top: 3rem;
	width: 100%;
}

#Calc_Amt_Range .invest {
	margin-top: 3rem;
}

#Calc_Amt_Range input[type="range"] {
	-webkit-appearance: none;
	overflow: visible !important;
	width: 28.5rem;
	margin-top: 1rem;
	height: 10px;
	background: grey;
	border-radius: 14px;
	background-image: linear-gradient(#042b62, #042b62);
	background-repeat: no-repeat;
}

#Calc_Amt_Range input[type="range"]::-webkit-slider-thumb {
	-webkit-appearance: none;
	height: 16px;
	margin-top: 0px;
	width: 16px;
	border-radius: 50%;
	background: #d1d1d1;
	cursor: pointer;
	/* box-shadow: #fff 0px 0px 0px 11px;
	 */
	transition: background 0.3s ease-in-out;
}

#Calc_Amt_Range input[type="range"]::-webkit-slider-runnable-track {
	-webkit-appearance: none;
	box-shadow: none;
	border: none;
	background: transparent;
}

#Calc_Amt_Range input[type="range"]::-webkit-slider-thumb:hover {
	box-shadow: #fff 0px 0px 0px 8px;
}

#Calc_Amt_Range input[type="range"]::-webkit-slider-thumb:active {
	box-shadow: #fff 0px 0px 0px 11px;
	transition: box-shadow 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, left 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, bottom 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.Amtreturn {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.Amtreturn div:first-child {
	color: gray;
	font-weight: 500;
}

.Amtreturn div:nth-child(2) {
	color: #000;
	font-weight: 500;
}

.amnt img,
svg {
	vertical-align: middle !important;
}

.Box_Value {
	margin-top: 5rem;
	display: flex;
	place-items: center;
	width: 100%;
}

.Box_Value .IM {
	background-color: #042b62;
	width: 50%;
	text-align: left;
	vertical-align: middle;
	border-top-left-radius: 15px;
	border-bottom-left-radius: 15px;
	padding: 15px;
	display: grid;
}

.Box_Value .IM span:first-child {
	color: #fff;
}

.TextNam {
	font-weight: 600;
}

.Box_Value .Est_Re {
	background-color: #042b62;
	width: 50%;
	text-align: center;
	vertical-align: middle;
	border-top-right-radius: 15px;
	border-bottom-right-radius: 15px;
	padding: 15px;
	display: grid;
}

.Box_Value .Est_Re span:first-child {
	color: #fff;
}

.amnt .Rupeee {
	width: 15px !important;
	height: 15px !important;
	position: relative;
	top: -0.1rem;
	left: 0rem;
}

.Total_Val {
	margin-top: 2rem;
	display: grid;
	place-items: center;
}

.Right_Btn .AddCart:hover {
	color: #042b62;
}

.reset_btn .Reset:hover {
	color: #fff !important;
}

.Total_Val .Total_amnt .Rupeee {
	width: 20px !important;
	height: 20px !important;
	position: relative;
}

.Total_amnt {
	display: flex;
}

.Total_amnt input {
	border-bottom: 1px solid #d1d1d1;
	width: 90%;
}

.Total_amnt .DatePick input {
	border-bottom: 1px solid #d1d1d1;
	width: 90%;
	font-size: 18px;
	font-weight: 400;
	font-size: 20px;
}

.CompareBtn .Com {
	color: #fff;
}

.CompareBtn .Com:hover {
	color: #000;
}

.Total_amnt {
	color: #000;
	font-size: 26px;
	font-weight: 900;
}

.Total_amnt img {
	width: 20px;
	height: 20px;
}

.Total_Val span:first-child {
	color: gray;
	/* padding-left: 2.5rem;
	 */
	font-size: 18px;
	font-weight: 700;
}

.Total_amnt .Value {
	font-weight: 600;
}

.riskGraph {
	margin-top: 4rem;
}

.riskGraph h5 {
	color: gray;
	font-weight: 700;
	font-size: 23px;
}

.risk_Meter {
	display: grid;
	place-items: center;
	margin-top: 2rem;
	/* padding-top: 3rem;
	 */
	height: 280px;
	border-radius: 20px;
	/* margin-top: -1rem !important;
	 */
	color: #000 !important;
	font-weight: 600;
	border: 2px solid #fff;
	background: #f7f7f7;
	background: linear-gradient(180deg, to top rgba(247, 247, 247, 1) 0%, rgba(230, 230, 229, 1) 19%);
}

.risk_Meter h5 {
	font-weight: 600;
	font-size: 20px;
}

.Asset_Allocation .GraphAllocation {
	margin-top: 2rem;
}

.Asset_Allocation .GraphAllocation .DivGraph {
	border-right: 1px solid #d1d1d1;
}

.Asset_Allocation .GraphAllocation .borderGraph {
	border-right: 1px solid #d1d1d1;
	width: 33.33%;
}

.Asset_Allocation .GraphAllocation .borderGraph:last-child {
	border: none;
}

.Asset_Allocation .GraphAllocation .bar {
	display: grid;
	place-items: center;
	margin-top: 2rem;
	margin-bottom: 2rem;
	/* padding-left: 5rem;
	 */
}

/* .PortFolio h5 {
	 margin-top: 2rem;
	 margin-bottom: 2rem;
}
 */
.PortFolio .TopSectors .Sector_List {
	border-right: 1px solid #d1d1d1;
}

.PortFolio .TopSectors div:last-child {
	border: none;
}

.PortFolio .Sector_List {
	padding-top: 1em;
}

.Sector_Head {
	padding-top: 1em;
}

.Sector_Allocation .Sector_Allocation_List {
	/* display: grid;
	 grid-template-columns: repeat(4, 1fr);
	 grid-template-rows: repeat(9, 1fr);
	 grid-column-gap: 0px;
	 grid-row-gap: 0px;
	 */
	display: flex;
}

.Sector_Allocation .Sector_Allocation_List .Input_Div {
	border-right: 1px solid #d1d1d1;
	width: 15rem;
	padding-bottom: 2rem;
	padding-top: 1rem;
	font-size: 14px;
}

.Sector_Allocation .Sector_Allocation_List .Last_div {
	border: none;
}

.Sector_Allocation .First_Div {
	border-right: 1px solid #d1d1d1;
	width: 15rem !important;
	padding-top: 2rem;
	padding-bottom: 2rem;
	float: left;
	padding-right: 4rem;
	color: gray;
}

.Mobile_Sector_Allocation_List .SpaceData {
	display: flex;
	/* grid-template-columns: repeat(3, 1fr);
	 grid-template-rows: 1fr;
	 grid-column-gap: 20px;
	 grid-row-gap: 0px;
	 margin-top: 1rem;
	 */
	margin-top: 0px;
}

.AllocationFlex {
	display: flex;
	justify-content: space-between;
	font-size: 11px;
	border-right: 1px solid #d3d3d3;
	padding: 7px;
}

.fund-count-3 .Mobile_Sector_Allocation_List .AllocationFlex {
	width: 33.33%;
}

.fund-count-2 .Mobile_Sector_Allocation_List .AllocationFlex {
	width: 50%;
}

.fund-count-1 .Mobile_Sector_Allocation_List .AllocationFlex {
	width: 100%;
}

.Mobile_Sector_Allocation_List .SpaceData .AllocationFlex:last-child {
	border-right: 0;
}

.HoldingsFlex {
	display: flex;
	justify-content: space-between;
	font-size: 12px;
}

.Sector_Allocation .Input_Div input[type="range"] {
	overflow: hidden;
	width: 12rem !important;
	-webkit-appearance: none;
	background-color: #d1d1d1;
	border-radius: 15px;
}

.holding-item-list table {
	width: 100%;
}

.holding-item-list table td {
	padding-top: 1em;
	padding-bottom: 1em;
}

.holding-item-list table tr:first-child td {
	padding-top: 0;
}

.holding-item-list table .holding-cl-1,
.holding-item-list table .holding-cl-2 {
	background-color: #d3d3d3;
}

.holding-item-list table .holding-cl-1,
.holding-item-list table .holding-data-1 {
	padding-left: 2em;
}

.holding-item-list table .holding-cl-2 {
	text-align: center;
	white-space: nowrap;
}

.TopHoldings .acc-section-bx {
	padding-bottom: 0.5em;
}

.holding-item-list table .holding-data-2 {
	text-align: center;
}

.TopHoldings .Holding_Details .Two_Details {
	display: flex;
	justify-content: space-between;
	width: 19rem;
	margin-left: -4rem;
	padding-right: 1rem;
	border-right: 1px solid #d1d1d1;
}

.TopHoldings .Holding_Details div {
	/* padding-bottom: 1rem;
	 */
	font-size: 14px;
}

.Two_Details .Comp_Name {
	background-color: #e8e8e8;
	height: 2rem;
	padding: 5px;
	width: 11rem;
	text-align: center;
}

.Two_Details .Comp_Holding {
	background-color: #e8e8e8;
	height: 2rem;
	padding: 5px;
}

.Close_Fund {
	cursor: pointer;
}

.TopHoldings .Holding_Details .Two_Details:last-child {
	border: none;
}

.Holding_Details .First_Div {
	border-right: 1px solid #d1d1d1;
	width: 15rem !important;
	padding-top: 3rem;
	padding-bottom: 2rem;
	float: left;
	padding-right: 4rem;
	color: gray;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
	.Input_Div input[type="range"] {
		overflow: hidden;
		width: 15rem;
		-webkit-appearance: none;
		background-color: #d1d1d1;
		border-radius: 15px;
	}

	.Input_Div input[type="range"]::-webkit-slider-runnable-track {
		height: 10px;
		-webkit-appearance: none;
		color: #042b62;
		margin-top: -1px;
	}

	.Input_Div input[type="range"]::-webkit-slider-thumb {
		width: 0px;
		-webkit-appearance: none;
		height: 0px;
		cursor: ew-resize;
		background: #434343;
		box-shadow: -80px 0 0 80px #042b62;
	}
}

/** FF*/
.Input_Div i input[type="range"]::-moz-range-progress {
	background-color: #042b62;
}

.Input_Div i input[type="range"]::-moz-range-track {
	background-color: #9a905d;
}

/* IE*/
.Input_Div i input[type="range"]::-ms-fill-lower {
	background-color: #042b62;
}

.Input_Div input[type="range"]::-ms-fill-upper {
	background-color: #9a905d;
}

.header_NM {
	font-size: 20px;
	color: gray;
	font-weight: 900;
	padding-left: 1rem !important;
}

.fund-bx-title {
	margin-bottom: 0.5em;
}

.other-detail-main-box {
	padding-top: 1em;
}

/* For Responsive Mobile and Tabs */
@media only screen and (min-width: 992px) and (max-width: 1399px) {
	.All_Stocks {
		display: none;
	}

	.sList {
		display: grid;
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-start;
		padding-bottom: 24px;
		padding-top: 16px;
		/* border-bottom: 1px solid #eee;
		 */
		width: 100%;
	}

	.SOList {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		/* align-items: flex-start;
		 */
		width: 100%;
		margin-right: 40px;
	}

	.StList {
		cursor: pointer;
		margin-top: 1rem;
		display: grid;
		place-items: stretch;
	}

	.StList {
		cursor: pointer;
		margin-top: 1rem;
		display: grid;
		place-items: stretch;
	}
}

@media (min-width: 991px) {
	.InvestSelectBank .col-9 {
		width: 85%;
	}

	.InvestSelectBank .PayOption .NextArrow img {
		margin-top: 0rem;
	}

	.InvestSelectBank .NextArrow img {
		width: 20px;
		height: 20px;
		margin-top: 1rem;
		float: right;
		padding-left: 0rem;
	}
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {

	.Stock_Details {
		margin-top: 4rem;
	}

	/* For Modal */
	/* Used to trick the browser to center the modal content properly */
	/* For Mutual Fund Deatils */
	.navbar-nav {
		background: #fff;
	}

	.desktop-view-none {
		display: block !important;
	}

	.desktop-view-none .fltr-items {
		display: flex;
		justify-content: space-between;
	}

	.desktop-view-none .fltr-items li {
		white-space: nowrap;
	}

	.InvestFundbox .InvestType p {
		color: #000;
		font-size: 13px;
		height: 1.5rem;
		width: 6rem;
		display: flex;
		font-weight: 600;
		border-bottom: 1px solid #ddd;
		padding-bottom: 10px;
	}

	.searchbar-desktop {
		display: none;
	}

	.Mobile_Stock_search {
		display: block;
	}

	.InvestFundbox .InvestType p input[type="text"] {
		color: #000;
		padding-left: 0;
		font-size: 13px;
		white-space: nowrap;
		height: 3rem;
		overflow: visible;
		z-index: 1000000;
		font-weight: 600;
		padding-bottom: 2rem;
	}

	.Compare_Details {
		/* width: 95%;
		 */
		/* overflow: hidden !important;
		 */
		margin-top: 0rem;
		/* display: grid;
		 */
		/* place-items: center;
		 */
		/* margin-left: 10px;
		 */
		margin-top: 7rem;
	}

	.Compare_Details h4,
	h5 {
		font-size: 14px !important;
	}

	.Header_Compare {
		background-color: #fcfcfc;
		width: 100%;
		border-top-left-radius: 20px;
		border-top-right-radius: 20px;
	}

	.Header_Compare .Header_Name {
		/* display: grid;
		 */
		padding: 1rem;
		border-bottom: 1px solid #d3d3d3 !important;
	}

	.Header_Name h4 {
		font-size: 14px;
	}

	.Header_Compare .Header_Name input[type="search"] {
		outline: none;
		/* width: 21rem;
		 */
		height: 2rem;
		padding: 0.8rem;
		padding-top: 0.8rem;
		border-radius: 15px;
		font-size: 14px;
		border: 1px solid #d1d1d1;
	}

	.Header_Compare .Header_Name input[type="search"]::placeholder {
		font-size: 10px;
		padding-top: 0.5rem;
	}

	.Stock_Compare_List {
		display: grid;
		padding-left: 0rem;
		justify-content: space-between;
	}

	.Stock_Compare_List h4 {
		font-size: 14px;
	}

	.Stock_Compare_List div {
		border: none;
	}

	.Stock_Compare_List .compare_dec {
		width: 23rem !important;
		padding-top: 2rem !important;
		padding-left: 1rem !important;
	}

	.Stock_Compare_List p {
		font-size: 12px;
		color: gray;
		font-size: 12px;
		padding-top: 0rem;
		font-weight: 400;
	}

	.Stock_Compare_List div {
		border: none;
		/* padding: 1rem;
		 */
		/* border: none;
		 */
		/* width: 20rem !important;
		 */
	}

	.Stock_Compare_List .Funds_Name {
		border: none;
		/* display: flex;
		 */
		/* margin-top: -2rem;
		 */
		/* height: 6rem;
		 */
		color: #000;
		font-weight: 800;
	}

	.Stock_Compare_List .Fund_Options {
		border: none !important;
		/* display: flex;
		 */
		/* justify-content: space-between;
		 */
		/* margin-left: -3rem;
		 */
		color: gray;
		/* padding-left: 60px;
		 */
		/* font-weight: 600;
		 */
		font-size: 12px;
	}

	.Stock_Compare_List .Fund_Options .Line {
		border-right: 1px solid #c0c3c2;
		margin-top: 0.2rem !important;
		height: 0.8rem;
	}

	.Close_Fund {
		cursor: pointer;
		font-size: 10px;
	}

	.Stock_Compare_List .Invest_Btn button {
		width: 8rem;
		height: 2rem;
		font-size: 12px;
		border-radius: 25px;
		padding: 1px;
		font-weight: 900;
		border: 2px solid #fff;
		color: #fff;
		background-color: #042b62;
	}

	.AllComapareDetails {
		background-color: #fff;
		width: 100%;
		/* margin-top: -1rem;
		 */
		/* padding: 2rem;
		 */
		border-top-left-radius: 20px;
		border-top-right-radius: 20px;
	}

	.Funds_Name div span:nth-child(2) {
		font-size: 12px;
	}

	.Funds_Name img {
		width: 40px;
		height: 40px;
	}

	.NavTrend #chart .toolbar {
		display: flex !important;
		justify-content: flex-end;
	}

	.Asset_Allocation .GraphAllocation {
		display: grid;
	}

	.Asset_Allocation p {
		font-size: 14px !important;
	}

	.PortFolio p {
		font-size: 14px !important;
	}

	.Sector_Allocation p {
		font-size: 14px !important;
	}

	.TopHoldings p {
		font-size: 14px !important;
	}

	.Asset_Allocation .GraphAllocation .DivGraph {
		border: none;
	}

	.Asset_Allocation .GraphAllocation .borderGraph {
		border: none;
		margin-left: 0rem;
	}

	.sList {
		display: grid;
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-start;
		padding-bottom: 24px;
		padding-top: 16px;
		/* border-bottom: 1px solid #eee;
		 */
		width: 100%;
	}

	.SOList {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		/* align-items: flex-start;
		 */
		width: 100%;
		margin-right: 40px;
	}

	.StList {
		cursor: pointer;
		margin-top: 1rem;
		display: grid;
		place-items: stretch;
	}

	.Stock_Img img {
		width: 40px;
		height: 40px;
	}

	.Stock_Header1 {
		font-size: 11px !important;
	}

	.imgC img {
		width: 14px;
		height: 14px;
		margin-top: 0px;
	}

	.RightSide .react-tabs__tab--selected {
		background: gray !important;
		border: None !important;
		font-size: 11px !important;
		color: gray !important;
		/* border-radius: 5px 5px 0 0;
		 */
	}

	.All_Stocks div:first-child {
		display: none;
	}

	.Stock_Details .Path {
		width: 100%;
		height: 100%;
		margin-top: 0rem;
		margin-right: 10rem;
		display: flex;
		justify-content: flex-end;
		padding-right: 0rem;
	}

	.All_Stocks {
		display: none;
	}

	.Search_Filter_Mobile {
		display: flex;
		justify-content: space-between;
	}

	.Mobile_Stock_search h4 {
		font-size: 15px;
		font-weight: 800;
	}

	.Search_Filter_Mobile {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: 1fr;
		grid-column-gap: 0px;
		grid-row-gap: 0px;
	}

	.Search_Filter_Mobile .Search_Field img {
		float: left;
	}

	.Search_Filter_Mobile .Filter_Field img {
		float: right;
		right: 0;
	}

	.Search_Modal {
		width: 100%;
		height: 100%;
	}

	.Search_Modal {
		width: 100%;
		height: 100%;
	}

	.react-responsive-modal-root {
		position: fixed;
		top: 0px;
		width: 100%;
		height: 100%;
		/* bottom: 0;
		 */
		/* left: 0;
		 */
		/* right: 0;
		 */
		z-index: 10000000000;
	}

	.react-responsive-modal-overlay {
		background: rgba(0, 0, 0, 0.5);
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: -1;
	}

	.react-responsive-modal-container {
		height: 100%;
		outline: 0;
		overflow-x: hidden;
		overflow-y: auto;
		text-align: center;
	}

	.Stock_Header1 div {
		cursor: pointer;
		font-size: 12px;
		outline: none;
		font-weight: 500;
	}

	.react-responsive-modal-containerCenter:after {
		width: 0;
		height: 100%;
		content: "";
		display: inline-block;
		vertical-align: middle;
	}

	.react-responsive-modal-modal {
		max-width: 100%;
		height: 100%;
		display: inline-block;
		text-align: left;
		vertical-align: middle;
		background: #fff;
		box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
		/* margin: 1.2rem;
		 */
		padding: 3rem;
		position: relative;
		overflow-y: auto;
		/* border-radius: 15px;
		 */
		width: 100%;
	}

	.react-responsive-modal-closeButton {
		position: absolute;
		top: 4px !important;
		right: 14px;
		height: 14px;
		width: 14px;
		border: none;
		padding: 0;
		cursor: pointer;
		background-color: transparent;
		display: flex;
	}

	@keyframes react-responsive-modal-overlay-in {
		0% {
			opacity: 0;
		}

		100% {
			opacity: 1;
		}
	}

	@keyframes react-responsive-modal-overlay-out {
		0% {
			opacity: 1;
		}

		100% {
			opacity: 0;
		}
	}

	@keyframes react-responsive-modal-modal-in {
		0% {
			transform: scale(0.96);
			opacity: 0;
		}

		100% {
			transform: scale(100%);
			opacity: 1;
		}
	}

	@keyframes react-responsive-modal-modal-out {
		0% {
			transform: scale(100%);
			opacity: 1;
		}

		100% {
			transform: scale(0.96);
			opacity: 0;
		}
	}

	.react-responsive-modal-modal input[type="search"] {
		outline: none;
		border: hidden;
		border-bottom: 1px solid #d1d1d1;
		width: 100%;
		font-size: 13px;
		height: 3rem;
	}

	.react-responsive-modal-modal input[type="search"]::placeholder {
		font-size: 13px;
		padding: 5px;
	}

	.react-responsive-modal-modal .mostpop {
		font-size: 13px;
		color: #abb2ba;
		padding-bottom: 0px;
	}

	.react-responsive-modal-modal .Top_Stock_List {
		width: 100%;
		position: relative;
	}

	.Top_Stock_List div:first-child {
		width: 75%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		color: #aab1b9;
		font-size: 15px;
	}

	.react-responsive-modal-modal .Top_Stock_List p {
		font-size: 15px;
		/* width: 75%;
		 */
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		color: #aab1b9;
		padding-bottom: 16px;
	}

	.Top_Stock_List {
		display: flex;
		justify-content: space-between;
	}

	.Fund_Opt {
		display: flex;
		justify-content: space-between;
		color: #aab1b9;
		font-size: 15px;
	}

	.Filter_Field .Fund_Opt input[type="checkbox"] {
		width: 10px !important;
		height: 10px !important;
		outline: none;
	}

	.Fund_Opt_SubC {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: repeat(3, 1fr);
		grid-column-gap: 10px;
		grid-row-gap: 0px;
		color: #aab1b9;
		font-size: 15px;
	}

	.Filter_Button {
		display: flex;
	}

	.Filter_Button button:first-child {
		width: 6rem;
		height: 2rem;
		/* margin-left: 1rem;
		 */
		margin-top: 0.5rem;
		border-radius: 15px;
		text-align: center;
		background-color: #042b62;
		border: 1px solid #042b62;
		color: #fff;
		font-weight: bold;
	}

	.Filter_Button button:nth-child(2) {
		width: 6rem;
		height: 2rem;
		margin-left: 1rem;
		font-weight: bold;
		margin-top: 0.5rem;
		border-radius: 15px;
		text-align: center;
		background-color: #c0c3c2 !important;
		border: 1px solid #c0c3c2;
		color: gray;
	}

	.Stock_Search {
		background-color: #fff;
		border-radius: 15px;
		/* height: 20vh;
		 */
		margin-top: -1.5rem;
		padding: 2rem;
		height: 5rem;
	}

	.SOList {
		display: grid;
		flex-direction: row;
		justify-content: space-between;
		/* align-items: flex-start;
		 */
		width: 100%;
		margin-right: 40px;
	}

	.SoTList {
		display: flex;
		/* flex-direction: row;
		 */
		justify-content: flex-start;
		align-items: center;
		margin-top: 2rem;
	}

	.Sample .Value {
		padding-top: 10px;
	}

	.Sample {
		/* width: 142px !important;
		 */
		font-size: 12px;
	}

	.Value img {
		width: 15px;
		height: 15px;
	}

	.TabHeader {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.fixed_top {
		position: fixed;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 65rem;
		/* justify-content: space-around;
		 */
		top: 80px;
		/* left: 0;
		 */
		/* width: 1000px;
		 */
		z-index: 10000;
		left: 0rem;
		box-shadow: 0 2px 24px 0 #000;
		background-color: #f7f7f7 !important;
		animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
		padding: 1rem;
	}

	.navbar {
		background-color: #fff;
		box-shadow: 0 4px 8px 0 #000;
		position: fixed !important;
		z-index: 1000000000000000000;
		top: 0;
		left: 0;
		width: 100%;
		height: 80px;
		display: flex;
		justify-content: unset;
		align-items: center;
		font-size: 1.2rem;
		z-index: 0;
	}

	.RightSide {
		display: block;
	}

	.Bu_Now_Mobile .Card_Btn button {
		background-color: #000;
		bottom: 14px;
		position: fixed;
		width: 200px;
		height: 2.3rem;
		color: #042b62;
		margin-top: 5rem;
		font-weight: 900;
		border-radius: 28px;
		margin-left: -100px;
		left: 50%;
		bottom: 10px;
	}

	.VideoShoot {
		position: relative;
		width: 291px;
		height: 297px;
		border-radius: 28px;
	}
}

.form-control {
	display: block;
	width: 100%;
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #212529;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	appearance: none;
	border-radius: 0.375rem;
	-webkit-transition-property: none !important;
	-moz-transition-property: none !important;
	-o-transition-property: none !important;
	transition-property: none !important;
}

/* For Progress Profile.jsx */
/* For CropImg */
.PR {
	position: relative;
}

.zoom {
	position: absolute;
	left: 14%;
	color: black;
	z-index: 1000;
	display: flex;
	align-items: center;
}

.zoom span {
	cursor: pointer;
	font-size: 30px;
	margin: 0 20px;
}

.disabled {
	opacity: 0.5;
	pointer-events: none;
}

.tui-image-editor-container .tui-image-editor-header-buttons,
.tui-image-editor-container .tui-image-editor-controls-buttons {
	float: left !important;
	margin: 8px;
}

.tui-image-editor-container .tui-image-editor-help-menu.top {
	white-space: nowrap;
	width: 506px;
	height: 40px;
	top: 8px;
	display: none;
	/* left: 50%;
	 */
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
}

.tui-image-editor {
	height: 800px;
	/* width: 435px;
	 */
	top: 0px;
	left: 0px;
}

/* For Profile.jsx */
.eUPUwZ {
	display: none !important;
}

.eUPUwZ {
	width: 100%;
	height: 60px;
	margin-bottom: 100px;
}

.sc-eCYdqJ {
	display: flex;
	justify-content: flex-end;
	margin-top: -4.3rem;
}

.cpdnlP {
	width: 31px !important;
	height: 31px !important;
	padding: 0px !important;
	border: none;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-left: 1rem;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	position: relative;
	-webkit-transition: 0.6s;
	transition: 0.6s;
	background: #f2f2f2;
}

.ctDUnc {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	height: inherit;
	text-transform: uppercase;
	font-size: 14px;
	color: #fff;
	-webkit-transition: 0.6s;
	transition: 0.6s;
}

.gnjafM {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: none !important;
	-webkit-transition: 0.6s;
	transition: 0.6s;
}

.egbpdO {
	width: 31px !important;
	height: 31px !important;
	padding: 0px !important;
	border: none;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	margin-left: 1rem;
	display: flex;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	position: relative;
	-webkit-transition: 0.6s;
	transition: 0.6s;
	background: #042b62 !important;
}

.iwMfiZ {
	width: 32px !important;
	/* position: relative;
	 */
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	/* display: flex;
	 */
	-webkit-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	/* align-items: center;
	 */
	/* -webkit-box-pack: center;
	 */
	/* -webkit-justify-content: center;
	 */
	-ms-flex-pack: center;
	justify-content: center;
	height: inherit;
	text-transform: uppercase;
	font-size: 16px;
	color: white !important;
	-webkit-transition: 0.6s;
	transition: 0.6s;
	/* height: 39px;
	 */
}

.buOpDx {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 1px solid black !important;
}

.ub-w_240px {
	width: 477.1px !important;
	border-radius: 15px !important;
}

.css-12b25td:not([disabled]):active,
[data-css-12b25td]:not([disabled]):active,
.css-12b25td:not([disabled])[aria-expanded="true"],
[data-css-12b25td]:not([disabled])[aria-expanded="true"],
.css-12b25td:not([disabled])[data-active],
[data-css-12b25td]:not([disabled])[data-active] {
	background-color: #042b62 !important;
	color: white;
	border: none !important;
	outline: none !important;
	box-shadow: none !important;
}

input[type="date"]:required:invalid::-webkit-datetime-edit {
	color: transparent;
}

textarea:focus,
input:focus {
	outline: none !important;
}

.form-control {
	display: block;
	width: 100%;
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #212529;
	background-color: #fff;
	background-clip: padding-box;
	/* border: none; */
	appearance: none;
	border-radius: 0.375rem;
	transition: none;
}

input[type="date"]:focus::-webkit-datetime-edit {
	color: black !important;
}

.roundedBorder input[type="tel"] {
	border: none !important;
	border-bottom: 2px solid #eee !important;
	font-weight: 700;
}

.Profile {
	margin-top: 4rem;
	/* background: #fff;
	 */
}

.ProgressPan {
	height: 6px !important;
	background: #e0e0de;
	border-radius: 50px;
	width: 100%;
	margin-top: 3rem;
}

.ProgressPan div:first-child {
	height: 5px !important;
	background: #e0e0de;
	border-radius: 50px;
	width: 100%;
}

.ProgressPan div div:first-child {
	width: 20%;
	background: #042b62 !important;
	transition: width 1s ease-in-out 0s;
	border-radius: inherit;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.ProgressPan div div:first-child span {
	padding: 5px;
	color: #cbcbcb !important;
	font-weight: normal !important;
	font-size: 15px;
	padding-top: 3rem !important;
	display: initial;
}

.Profile .ProfileImg img {
	width: 500px;
	height: 500px;
}

.Profile h2 {
	text-align: center;
	color: #042b62;
	font-size: 30px;
	font-weight: 900;
}

.Profile .RightPanel {
	margin-top: 3rem;
}

.line {
	height: 6rem;
	width: 1px;
	background: #cdc3b7;
}

.FamilyDetails {
	display: flex;
}

.Profile .RightPanel h4 {
	color: #585858;
	font-weight: 900;
}

.Profile .RightPanel .InputTextDate {
	width: 16rem;
	border-radius: 10px;
	outline: none;
	height: 2.5rem;
	border: 1px solid #ced4da;
}

.DobNext .NextBtn {
	margin-left: 2.5rem;
}

.Profile .RightPanel .InputText {
	width: 20rem;
	border-radius: 10px;
	outline: none;
	height: 2.5rem;
	border: 1px solid #c1c1c1;
}

.Profile .RightPanel input[type="text"] {
	background: #fff;
	font-weight: 100;
	color: gray;
	padding-left: 1rem;
	border: 1px solid #c1c1c1 !important;
}

.Profile .RightPanel .InputText::placeholder {
	color: #a4a4a4;
	font-size: 18px;
}

.Profile .RightPanel .VerifyBtn {
	text-align: center;
	background: #042b62;
	width: 9rem;
	height: 39px;
	text-decoration: none;
	font-weight: 900;
	color: #fff;
	/* vertical-align: middle;
	 */
	padding: 5px;
	border-radius: 20px;
	border: none;
	font-size: 18px;
}

.Profile .RightPanel .NextBtn {
	text-align: center;
	background: #042b62;
	width: 9rem;
	height: 39px;
	text-decoration: none;
	font-weight: 900;
	color: #fff;
	/* vertical-align: middle;
	 */
	padding: 5px;
	border-radius: 20px;
	border: none;
	font-size: 18px;
}

.VerifyDetails {
	margin-top: 2rem;
}

.Profile .RightPanel .PanName {
	padding-left: 0.5rem;
	color: #575757;
	font-weight: 900;
}

.Profile .RightPanel .ChangePan {
	color: #042b62;
	cursor: pointer;
	font-weight: 500;
	line-height: 1rem;
}

.InputdATE {
	width: 14rem !important;
}

.NotCorrect {
	font-size: 16px;
	font-weight: 500;
	color: #bebbbb;
}

.GenderSelect,
.Residential,
.Holding,
.AddressAadhar {
	display: flex;
}

.rs-type-bx.active span {

	color: #000;
}

.ResidentType span {
	white-space: nowrap;
}

.RightPanel input {
	font-size: 1rem !important;
}

.RightPanel .form-control {
	font-weight: 400 !important;
}

.Residential img {
	width: 60px;
}

.Holding img {
	width: 60px;
}

.AddressAadhar img {
	width: 60px;
}

.Residential #RESResident,
#NRO,
#NRI {
	margin-top: -3rem;
}

.Holding #Single,
#Joint,
#Anyone {
	margin-top: -3rem;
}

.deleteFund .deleteFundWish:hover {
	color: red;
}

.AddressAadhar #Aadhar,
#Passport,
#Driving {
	margin-top: -3rem;
}

.GenderSelect .GenderName {
	text-align: center;
}

.genderNxt {
	margin-top: 1.5rem;
}

.Marital_Status {
	color: #a4a4a4;
	font-size: 18px;
	font-weight: 600;
}

.Marital_StatusNxt {
	margin-left: 2rem;
	/* height: 3rem;
	 */
	vertical-align: middle;
	padding: 0;
}

.FatcaBtn .NextBtn {
	margin-left: 14rem;
}

.Fatca .Nominee_Identity {
	margin-top: 1rem;
}

.Fatca .Option {
	margin-top: 2rem;
}

.css-l6zppx> :first-child:not(:last-child),
[data-css-l6zppx]> :first-child:not(:last-child) {
	border-top-left-radius: 15px;
	border-bottom-left-radius: 15px;
}

.css-l6zppx> :last-child:not(:first-child),
[data-css-l6zppx]> :last-child:not(:first-child) {
	border-top-right-radius: 15px;
	border-bottom-right-radius: 15px;
}

.LabelName {
	font-size: 16px;
	color: #575757;
	font-weight: 900;
}

.Nominee_Option {
	display: flex;
	justify-content: space-between;
	margin-top: 1em;
}

.Nominee_Identity {
	margin-top: 2rem;
}

.Nominee_Identity_Last {
	display: flex;
	justify-content: space-between;
	margin-top: 2rem;
}

.Nominee_Identity_Last .AddressAadhar {
	font-size: 20px;
	font-weight: 600;
	color: #575757;
}

.PhotoClickUpload {
	margin-top: 2rem;
	height: 300px !important;
	font-size: 22px;
	border-radius: 15px;
	background-color: #fff;
	border: 1px solid #4b5763;
	color: #b0b1b3;
	cursor: pointer;
	background: #fff;
	/* position: relative;
	 */
	border-radius: 10px;
	display: flex;
	justify-content: space-around;
	align-items: center;
}

.PhotoClickUpload img {
	width: 60px;
}

.PhotoVideo img {
	width: 60px;
}

.ResidentType {
	text-align: center;
	margin-top: 0.8rem;
	color: #a4a4a4;
	font-size: 0.7em;
	font-weight: 600;
}

.Option {
	margin-top: 1rem;
}

.switch-block {
	width: 30.1rem;
	height: 2.5rem;
	border: 1px solid black;
	border-radius: 15px !important;
	cursor: pointer;
}

.switch-toggle input {
	width: 0px;
	visibility: hidden;
	border: none !important;
	cursor: pointer;
}

.switch-toggle a,
.switch-light span span {
	display: none;
}

/* We can't test for a specific feature, * so we only target browsers with support for media queries. */
@media only screen {

	/* Checkbox */
	.switch-light {
		position: relative;
		display: block;
		border: none;
		cursor: pointer;
		/* simulate default browser focus outlines on the switch, * when the inputs are focused. */
	}

	.switch-light::after {
		clear: both;
		content: "";
		display: table;
	}

	.switch-light *,
	.switch-light *:before,
	.switch-light *:after {
		box-sizing: border-box;
	}

	.switch-light a {
		display: block;
		transition: all 0.2s ease-out;
	}

	.switch-light label,
	.switch-light>span {
		/* breathing room for bootstrap/foundation classes. */
		line-height: 2em;
	}

	.switch-light input:focus~span a,
	.switch-light input:focus+label {
		outline-width: 2px;
		outline-style: solid;
		outline-color: Highlight;
		/* Chrome/Opera gets its native focus styles. */
	}
}

@media only screen and (-webkit-min-device-pixel-ratio: 0) {

	.switch-light input:focus~span a,
	.switch-light input:focus+label {
		outline-color: -webkit-focus-ring-color;
		outline-style: auto;
	}
}

@media only screen {

	/* don't hide the input from screen-readers and keyboard access */
	/* inherit from label */
	/* bootstrap 4 tweaks */
	/* Radio Switch */
	.switch-light input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		z-index: 3;
	}

	.switch-light input:checked~span a {
		right: 0%;
	}

	.switch-light strong {
		font-weight: inherit;
	}

	.switch-light>span {
		position: relative;
		overflow: hidden;
		display: block;
		min-height: 2em;
		/* overwrite 3rd party classes padding * eg. bootstrap .alert */
		padding: 0;
		text-align: left;
	}

	.switch-light span span {
		position: relative;
		z-index: 2;
		display: block;
		float: left;
		width: 50%;
		text-align: center;
		user-select: none;
	}

	.switch-light a {
		position: absolute;
		right: 50%;
		top: 0;
		z-index: 1;
		display: block;
		width: 50%;
		height: 100%;
		padding: 0;
	}

	.switch-light.row {
		display: flex;
	}

	.switch-light .alert-light {
		color: #333;
	}

	.switch-toggle {
		position: relative;
		display: block;
		/* simulate default browser focus outlines on the switch, * when the inputs are focused. */
		/* For callout panels in foundation */
		padding: 0 !important;
		/* 2 items */
		/* 3 items */
		/* 4 items */
		/* 5 items */
		/* 6 items */
	}

	.switch-toggle::after {
		clear: both;
		content: "";
		display: table;
	}

	.switch-toggle *,
	.switch-toggle *:before,
	.switch-toggle *:after {
		box-sizing: border-box;
	}

	.switch-toggle a {
		display: block;
		transition: all 0.2s ease-out;
	}

	.switch-toggle label,
	.switch-toggle>span {
		/* breathing room for bootstrap/foundation classes. */
		line-height: 2em;
	}

	.switch-toggle input:focus~span a,
	.switch-toggle input:focus+label {
		outline-width: 2px;
		outline-style: solid;
		outline-color: Highlight;
		/* Chrome/Opera gets its native focus styles. */
	}
}

@media only screen and (-webkit-min-device-pixel-ratio: 0) {

	.switch-toggle input:focus~span a,
	.switch-toggle input:focus+label {
		outline-color: -webkit-focus-ring-color;
		outline-style: auto;
	}
}

@media only screen {

	/* iOS Theme */
	/* Holo Theme */
	/* Selected ON switch-light */
	/* Material Theme */
	/* switch-light */
	/* switch-toggle */
	/* ripple */
	/* trick to prevent the default checked ripple animation from showing * when the page loads. * the ripples are hidden by default, and shown only when the input is focused. */
	.switch-toggle input {
		position: absolute;
		cursor: pointer;
		left: 0;
		opacity: 0;
	}

	.switch-toggle input+label {
		position: relative;
		z-index: 2;
		display: block;
		float: left;
		padding: 0.2rem 0.5em;
		margin: 0;
		text-align: center;
	}

	.switch-toggle a {
		position: absolute;
		top: 0;
		left: 0;
		padding: 0;
		z-index: 1;
		width: 10px;
		height: 100%;
	}

	.switch-toggle label:nth-child(2):nth-last-child(4),
	.switch-toggle label:nth-child(2):nth-last-child(4)~label,
	.switch-toggle label:nth-child(2):nth-last-child(4)~a {
		width: 50%;
	}

	.switch-toggle label:nth-child(2):nth-last-child(4)~input:checked:nth-child(3)+label~a {
		left: 50%;
	}

	.switch-toggle label:nth-child(2):nth-last-child(6),
	.switch-toggle label:nth-child(2):nth-last-child(6)~label,
	.switch-toggle label:nth-child(2):nth-last-child(6)~a {
		width: 33.33%;
		border: none;
		border-top-left-radius: 15px;
		border-bottom-left-radius: 15px;
	}

	.switch-toggle label:nth-child(2):nth-last-child(6)~input:checked:nth-child(3)+label~a {
		left: 33.33%;
		border-radius: 0 !important;
	}

	.switch-toggle label:nth-child(2):nth-last-child(6)~input:checked:nth-child(5)+label~a {
		left: 66.66%;
		border-top-left-radius: 0px;
		border-bottom-left-radius: 0px;
		border-top-right-radius: 15px;
		border-bottom-right-radius: 15px;
	}

	.switch-toggle label:nth-child(2):nth-last-child(8),
	.switch-toggle label:nth-child(2):nth-last-child(8)~label,
	.switch-toggle label:nth-child(2):nth-last-child(8)~a {
		width: 25%;
	}

	.switch-toggle label:nth-child(2):nth-last-child(8)~input:checked:nth-child(3)+label~a {
		left: 25%;
	}

	.switch-toggle label:nth-child(2):nth-last-child(8)~input:checked:nth-child(5)+label~a {
		left: 50%;
	}

	.switch-toggle label:nth-child(2):nth-last-child(8)~input:checked:nth-child(7)+label~a {
		left: 75%;
	}

	.switch-toggle label:nth-child(2):nth-last-child(10),
	.switch-toggle label:nth-child(2):nth-last-child(10)~label,
	.switch-toggle label:nth-child(2):nth-last-child(10)~a {
		width: 20%;
	}

	.switch-toggle label:nth-child(2):nth-last-child(10)~input:checked:nth-child(3)+label~a {
		left: 20%;
	}

	.switch-toggle label:nth-child(2):nth-last-child(10)~input:checked:nth-child(5)+label~a {
		left: 40%;
	}

	.switch-toggle label:nth-child(2):nth-last-child(10)~input:checked:nth-child(7)+label~a {
		left: 60%;
	}

	.switch-toggle label:nth-child(2):nth-last-child(10)~input:checked:nth-child(9)+label~a {
		left: 80%;
	}

	.switch-toggle label:nth-child(2):nth-last-child(12),
	.switch-toggle label:nth-child(2):nth-last-child(12)~label,
	.switch-toggle label:nth-child(2):nth-last-child(12)~a {
		width: 16.6%;
	}

	.switch-toggle label:nth-child(2):nth-last-child(12)~input:checked:nth-child(3)+label~a {
		left: 16.6%;
	}

	.switch-toggle label:nth-child(2):nth-last-child(12)~input:checked:nth-child(5)+label~a {
		left: 33.2%;
	}

	.switch-toggle label:nth-child(2):nth-last-child(12)~input:checked:nth-child(7)+label~a {
		left: 49.8%;
	}

	.switch-toggle label:nth-child(2):nth-last-child(12)~input:checked:nth-child(9)+label~a {
		left: 66.4%;
	}

	.switch-toggle label:nth-child(2):nth-last-child(12)~input:checked:nth-child(11)+label~a {
		left: 83%;
	}

	.switch-toggle.switch-candy,
	.switch-light.switch-candy>span {
		background-color: none;
		border-radius: 3px;
		color: #fff !important;
	}

	.switch-light.switch-candy span span,
	.switch-light.switch-candy input:checked~span span:first-child,
	.switch-toggle.switch-candy label {
		color: #000 !important;
	}

	.switch-light.switch-candy input~span span:first-child,
	.switch-light.switch-candy input:checked~span span:nth-child(2),
	.switch-candy input:checked+label {
		color: #333;
		text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
	}

	.switch-candy a {
		border: 1px solid #333;
		border-radius: 3px;
		box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2), inset 0 1px 1px rgba(255, 255, 255, 0.45);
		background-color: #042b62;
		background-image: linear-gradient(rgba(255, 255, 255, 0.2), transparent);
	}

	.switch-candy-blue a {
		background-color: #38a3d4;
	}

	.switch-candy-yellow a {
		background-color: #f5e560;
	}

	.switch-ios.switch-light span span {
		color: #888b92;
	}

	.switch-ios.switch-light a {
		left: 0;
		top: 0;
		color: #000;
		width: 2em;
		height: 2em;
		background-color: #fff;
		border-radius: 100%;
		border: 0.25em solid #d8d9db;
		transition: all 0.2s ease-out;
	}

	.switch-ios.switch-light>span {
		display: block;
		width: 100%;
		height: 2em;
		background-color: #d8d9db;
		border-radius: 1.75em;
		transition: all 0.4s ease-out;
	}

	.switch-ios.switch-light>span span {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		opacity: 0;
		line-height: 1.875em;
		vertical-align: middle;
		transition: all 0.2s ease-out;
	}

	.switch-ios.switch-light>span span:first-of-type {
		opacity: 1;
		padding-left: 1.875em;
	}

	.switch-ios.switch-light>span span:last-of-type {
		padding-right: 1.875em;
	}

	.switch-ios.switch-light input:checked~span a {
		left: 100%;
		border-color: #042b62;
		margin-left: -2em;
	}

	.switch-ios.switch-light input:checked~span {
		border-color: #042b62;
		box-shadow: inset 0 0 0 30px #042b62;
	}

	.switch-ios.switch-light input:checked~span span:first-of-type {
		opacity: 0;
	}

	.switch-ios.switch-light input:checked~span span:last-of-type {
		opacity: 1;
		color: #fff;
	}

	.switch-ios.switch-toggle {
		background-color: #042b62;
		border-radius: 30px;
		box-shadow: inset rgba(0, 0, 0, 0.1) 0 1px 0;
	}

	.switch-ios.switch-toggle a {
		background-color: #042b62;
		border: 0.125em solid #d8d9db;
		border-radius: 1.75em;
		transition: all 0.12s ease-out;
	}

	.switch-ios.switch-toggle label {
		height: 2.4em;
		color: #888b92;
		line-height: 2.4em;
		vertical-align: middle;
	}

	.switch-ios input:checked+label {
		color: #3e4043;
	}

	.switch-toggle.switch-holo,
	.switch-light.switch-holo>span {
		background-color: #464747;
		border-radius: 1px;
		box-shadow: inset rgba(0, 0, 0, 0.1) 0 1px 0;
		color: #fff;
		text-transform: uppercase;
	}

	.switch-holo label {
		color: #fff;
	}

	.switch-holo>span span {
		opacity: 0;
		transition: all 0.1s;
	}

	.switch-holo>span span:first-of-type {
		opacity: 1;
	}

	.switch-holo>span span,
	.switch-holo label {
		font-size: 85%;
		line-height: 2.15625em;
	}

	.switch-holo a {
		background-color: #666;
		border-radius: 1px;
		box-shadow: inset rgba(255, 255, 255, 0.2) 0 1px 0, inset rgba(0, 0, 0, 0.3) 0 -1px 0;
	}

	.switch-holo.switch-light input:checked~span a {
		background-color: #0e88b1;
	}

	.switch-holo.switch-light input:checked~span span:first-of-type {
		opacity: 0;
	}

	.switch-holo.switch-light input:checked~span span:last-of-type {
		opacity: 1;
	}

	.switch-light.switch-material a {
		top: -0.1875em;
		width: 1.75em;
		height: 1.75em;
		border-radius: 50%;
		background: #fafafa;
		box-shadow: 0 0.125em 0.125em 0 rgba(0, 0, 0, 0.14), 0 0.1875em 0.125em -0.125em rgba(0, 0, 0, 0.2), 0 0.125em 0.25em 0 rgba(0, 0, 0, 0.12);
		transition: right 0.28s cubic-bezier(0.4, 0, 0.2, 1);
	}

	.switch-material.switch-light {
		overflow: visible;
	}

	.switch-material.switch-light::after {
		clear: both;
		content: "";
		display: table;
	}

	.switch-material.switch-light>span {
		overflow: visible;
		position: relative;
		top: 0.1875em;
		width: 3.25em;
		height: 1.5em;
		min-height: auto;
		border-radius: 1em;
		background: rgba(0, 0, 0, 0.26);
	}

	.switch-material.switch-light span span {
		position: absolute;
		clip: rect(0 0 0 0);
	}

	.switch-material.switch-light input:checked~span a {
		right: 0;
		background: #3f51b5;
		box-shadow: 0 0.1875em 0.25em 0 rgba(0, 0, 0, 0.14), 0 0.1875em 0.1875em -0.125em rgba(0, 0, 0, 0.2), 0 0.0625em 0.375em 0 rgba(0, 0, 0, 0.12);
	}

	.switch-material.switch-light input:checked~span {
		background: rgba(63, 81, 181, 0.5);
	}

	.switch-toggle.switch-material {
		overflow: visible;
	}

	.switch-toggle.switch-material::after {
		clear: both;
		content: "";
		display: table;
	}

	.switch-toggle.switch-material a {
		top: 48%;
		width: 0.375em !important;
		height: 0.375em;
		margin-left: 0.25em;
		background: #3f51b5;
		border-radius: 100%;
		transform: translateY(-50%);
		transition: transform 0.4s ease-in;
	}

	.switch-toggle.switch-material label {
		color: rgba(0, 0, 0, 0.54);
		font-size: 1em;
	}

	.switch-toggle.switch-material label:before {
		content: "";
		position: absolute;
		top: 48%;
		left: 0;
		display: block;
		width: 0.875em;
		height: 0.875em;
		border-radius: 100%;
		border: 0.125em solid rgba(0, 0, 0, 0.54);
		transform: translateY(-50%);
	}

	.switch-toggle.switch-material input:checked+label:before {
		border-color: #3f51b5;
	}

	.switch-light.switch-material>span:before,
	.switch-light.switch-material>span:after,
	.switch-toggle.switch-material label:after {
		content: "";
		position: absolute;
		top: 0;
		color: #000;
		left: 0;
		z-index: 3;
		display: block;
		width: 4em;
		height: 4em;
		border-radius: 100%;
		background: #3f51b5;
		opacity: 0.4;
		margin-left: -1.25em;
		margin-top: -1.25em;
		transform: scale(0);
		transition: opacity 0.4s ease-in;
	}

	.switch-light.switch-material>span:after {
		left: auto;
		right: 0;
		margin-left: 0;
		margin-right: -1.25em;
	}

	.switch-toggle.switch-material label:after {
		width: 3.25em;
		height: 3.25em;
		margin-top: -0.75em;
	}

	@keyframes materialRipple {
		0% {
			transform: scale(0);
		}

		20% {
			transform: scale(1);
		}

		100% {
			opacity: 0;
			transform: scale(1);
		}
	}

	.switch-material.switch-light input:not(:checked)~span:after,
	.switch-material.switch-light input:checked~span:before,
	.switch-toggle.switch-material input:checked+label:after {
		animation: materialRipple 0.4s ease-in;
	}

	.switch-light.switch-material.switch-light input~span:before,
	.switch-light.switch-material.switch-light input~span:after,
	.switch-material.switch-toggle input+label:after {
		visibility: hidden;
	}

	.switch-light.switch-material.switch-light input:focus:checked~span:before,
	.switch-light.switch-material.switch-light input:focus:not(:checked)~span:after,
	.switch-material.switch-toggle input:focus:checked+label:after {
		visibility: visible;
	}
}

/* Bugfix for older Webkit, including mobile Webkit. Adapted from * http://css-tricks.com/webkit-sibling-bug/ */
@media only screen and (-webkit-max-device-pixel-ratio: 2) and (max-device-width: 80em) {

	.switch-light,
	.switch-toggle {
		-webkit-animation: webkitSiblingBugfix infinite 1s;
	}
}

@-webkit-keyframes webkitSiblingBugfix {
	from {
		-webkit-transform: translate3d(0, 0, 0);
	}

	to {
		-webkit-transform: translate3d(0, 0, 0);
	}
}


.BankConfrmDetails {
	width: 100%;
	background: #e0e0e0;
	border-radius: 15px;
	padding: 2rem;
	display: flex;
}

.Percent input::placeholder {
	text-align: right;
	font-size: 19px;
}

.BankConfrmDetails .BankCnfmName {
	font-size: 19px;
	font-weight: 600;
}

.CofmAccountNM {
	font-size: 17px;
	font-weight: 600;
}

.CofmIfscCode {
	font-size: 17px;
	font-weight: 600;
}

.BankDetails {
	display: flex;
	justify-content: space-between;
}

.BankDetails span:nth-child(2) {
	margin-right: 8rem;
}

.BankConfrmDetails img {
	width: 40px;
	height: 40px;
}

.ReactCrop {
	position: relative;
	display: inline-block;
	cursor: crosshair;
	overflow: hidden;
	max-width: 100%;
}

.ReactCrop *,
.ReactCrop *::before,
.ReactCrop *::after {
	box-sizing: border-box;
}

.ReactCrop--disabled,
.ReactCrop--locked {
	cursor: inherit;
}

.ReactCrop__child-wrapper {
	max-height: inherit;
}

.ReactCrop__child-wrapper>img,
.ReactCrop__child-wrapper>video {
	display: block;
	max-width: 100%;
	max-height: inherit;
	touch-action: none;
}

.ReactCrop__crop-selection {
	position: absolute;
	top: 0;
	left: 0;
	transform: translate3d(0, 0, 0);
	cursor: move;
	box-shadow: 0 0 0 9999em rgba(0, 0, 0, 0.5);
	touch-action: none;
	border: 1px dashed #fff;
}

.ReactCrop--disabled .ReactCrop__crop-selection {
	cursor: inherit;
}

.ReactCrop--circular-crop .ReactCrop__crop-selection {
	border-radius: 50%;
	box-shadow: 0px 0px 1px 1px #fff, 0 0 0 9999em rgba(0, 0, 0, 0.5);
}

.ReactCrop__crop-selection:focus {
	outline: none;
	border-color: blue;
	border-style: solid;
}

.ReactCrop--invisible-crop .ReactCrop__crop-selection {
	display: none;
}

.ReactCrop__rule-of-thirds-vt::before,
.ReactCrop__rule-of-thirds-vt::after,
.ReactCrop__rule-of-thirds-hz::before,
.ReactCrop__rule-of-thirds-hz::after {
	content: "";
	display: block;
	position: absolute;
	background-color: rgba(255, 255, 255, 0.4);
}

.ReactCrop__rule-of-thirds-vt::before,
.ReactCrop__rule-of-thirds-vt::after {
	width: 1px;
	height: 100%;
}

.ReactCrop__rule-of-thirds-vt::before {
	left: 33.3333%;
	left: 33.3333333333%;
}

.ReactCrop__rule-of-thirds-vt::after {
	left: 66.6666%;
	left: 66.6666666667%;
}

.ReactCrop__rule-of-thirds-hz::before,
.ReactCrop__rule-of-thirds-hz::after {
	width: 100%;
	height: 1px;
}

.ReactCrop__rule-of-thirds-hz::before {
	top: 33.3333%;
	top: 33.3333333333%;
}

.ReactCrop__rule-of-thirds-hz::after {
	top: 66.6666%;
	top: 66.6666666667%;
}

.ReactCrop__drag-handle {
	position: absolute;
}

.ReactCrop__drag-handle::after {
	position: absolute;
	content: "";
	display: block;
	width: 10px;
	height: 10px;
	background-color: rgba(0, 0, 0, 0.2);
	border: 1px solid rgba(255, 255, 255, 0.7);
	outline: 1px solid rgba(0, 0, 0, 0);
}

.ReactCrop__drag-handle:focus::after {
	border-color: blue;
	background: #2dbfff;
}

.ReactCrop .ord-nw {
	top: 0;
	left: 0;
	margin-top: -5px;
	margin-left: -5px;
	cursor: nw-resize;
}

.ReactCrop .ord-nw::after {
	top: 0;
	left: 0;
}

.ReactCrop .ord-n {
	top: 0;
	left: 50%;
	margin-top: -5px;
	margin-left: -5px;
	cursor: n-resize;
}

.ReactCrop .ord-n::after {
	top: 0;
}

.ReactCrop .ord-ne {
	top: 0;
	right: 0;
	margin-top: -5px;
	margin-right: -5px;
	cursor: ne-resize;
}

.ReactCrop .ord-ne::after {
	top: 0;
	right: 0;
}

.ReactCrop .ord-e {
	top: 50%;
	right: 0;
	margin-top: -5px;
	margin-right: -5px;
	cursor: e-resize;
}

.ReactCrop .ord-e::after {
	right: 0;
}

.ReactCrop .ord-se {
	bottom: 0;
	right: 0;
	margin-bottom: -5px;
	margin-right: -5px;
	cursor: se-resize;
}

.ReactCrop .ord-se::after {
	bottom: 0;
	right: 0;
}

.ReactCrop .ord-s {
	bottom: 0;
	left: 50%;
	margin-bottom: -5px;
	margin-left: -5px;
	cursor: s-resize;
}

.ReactCrop .ord-s::after {
	bottom: 0;
}

.ReactCrop .ord-sw {
	bottom: 0;
	left: 0;
	margin-bottom: -5px;
	margin-left: -5px;
	cursor: sw-resize;
}

.ReactCrop .ord-sw::after {
	bottom: 0;
	left: 0;
}

.ReactCrop .ord-w {
	top: 50%;
	left: 0;
	margin-top: -5px;
	margin-left: -5px;
	cursor: w-resize;
}

.ReactCrop .ord-w::after {
	left: 0;
}

.ReactCrop__disabled .ReactCrop__drag-handle {
	cursor: inherit;
}

.ReactCrop__drag-bar {
	position: absolute;
}

.ReactCrop__drag-bar.ord-n {
	top: 0;
	left: 0;
	width: 100%;
	height: 6px;
	margin-top: -3px;
}

.ReactCrop__drag-bar.ord-e {
	right: 0;
	top: 0;
	width: 6px;
	height: 100%;
	margin-right: -3px;
}

.ReactCrop__drag-bar.ord-s {
	bottom: 0;
	left: 0;
	width: 100%;
	height: 6px;
	margin-bottom: -3px;
}

.ReactCrop__drag-bar.ord-w {
	top: 0;
	left: 0;
	width: 6px;
	height: 100%;
	margin-left: -3px;
}

.ReactCrop--new-crop .ReactCrop__drag-bar,
.ReactCrop--new-crop .ReactCrop__drag-handle,
.ReactCrop--fixed-aspect .ReactCrop__drag-bar {
	display: none;
}

.ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-n,
.ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-e,
.ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-s,
.ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-w {
	display: none;
}

@media (pointer: coarse) {

	.ReactCrop .ord-n,
	.ReactCrop .ord-e,
	.ReactCrop .ord-s,
	.ReactCrop .ord-w {
		display: none;
	}

	.ReactCrop__drag-handle {
		width: 24px;
		height: 24px;
	}
}

/* Aadhar */
.UploadBtn {
	height: 300px;
	width: 464px;
	font-size: 22px;
	border-radius: 15px;
	background-color: #fff;
	border: 1px solid #4b5763;
	color: #b0b1b3;
	/* text-transform: uppercase;
	 */
	cursor: pointer;
	margin-bottom: 4px;
}

.AadharAddress {
	height: 290px;
	width: 450px;
	font-size: 22px;
	border-radius: 15px;
	background-color: #fff;
	border: 1px solid #4b5763;
	color: #b0b1b3;
	display: grid;
	place-items: center;
	/* text-transform: uppercase;
	 */
	cursor: pointer;
	margin-bottom: 4px;
}

.AadharAddress img {
	width: 400px;
	height: 200px;
}

.ConfirmAadharAddress {
	margin-top: 0rem;
}

.YNuuG {
	margin-top: -24rem !important;
	height: 23rem !important;
	width: 681px !important;
	margin-left: 1rem;
	border-radius: 15px;
	background-image: url("./components/Assets/Adhaar_Back.jpg");
}

.ipDuCr:first-child {
	display: none;
}

.ipDuCr:nth-child(2) {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 5rem;
}

.ipDuCr:nth-child(3) {
	display: none;
}

.ipDuCr:nth-child(4) {
	display: none;
}

.ipDuCr:nth-child(5) {
	display: none;
}

.ipDuCr:nth-child(6) {
	display: none;
}

.ipDuCr:nth-child(7) {
	display: none;
}

.ipDuCr:nth-child(8) {
	display: none;
}

.PhotoVideo {
	font-size: 22px;
	border-radius: 15px;
	background-color: #fff;
	border: 1px solid #4b5763;
	color: #b0b1b3;
	/* text-transform: uppercase;
	 */
	cursor: pointer;
	margin-bottom: 4px;
	padding: 1rem;
}

.TwoOption {
	display: flex;
	justify-content: center;
	align-items: center;
	align-items: center;
	margin-top: 5rem;
}

.TwoOption div {
	margin-left: 1rem;
	margin-right: 1rem;
}

.NomineeBtn {
	display: flex;
	justify-content: space-between;
	margin-top: 2rem;
	float: none;
}

.NomiName {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: repeat(2, 1fr);
	grid-column-gap: 0px;
	grid-row-gap: 0px;
}

.NomiName h5 {
	color: #585858;
	font-size: 21px;
	font-weight: 900;
}

.NomiName span {
	color: #a5a5a5;
	font-size: 24px;
	font-weight: 400;
}

.ResiStatus {
	color: #585858;
	font-size: 1.2rem;
	font-weight: 900;
}

.VerifyDetails {
	margin-top: 0rem;
}

.MarriedStatus .NextBtn {
	margin-left: 1rem;
}

.MarriedStatus {
	display: flex;
}

.Width {
	width: 76%;
}

.DobNext .NextBtn {
	margin-top: 2rem;
}

.Upload {
	margin-left: 20.4rem;
}

.Percent {
	width: 11rem;
}

.mobileUI {
	display: none;
}

.MobilePanUI {
	display: block;
}

.Profile .RightPanel .MobilePanUI .NextBtn {
	margin-left: 21.5rem !important;
}

@media screen and (max-width: 768px) {
	.CartSelectBank h4 {
		font-size: 1.1em;
	}

	.PaymentLayout .CartSummary {
		margin-top: 2rem;
	}

	.InvestSelectBank .NextArrow {
		margin-left: 4rem;
		margin-top: 1.5rem;
	}

	.InvestSelectBank .Auto-Pay .NextArrow {
		margin-top: -4rem;
	}

	.SIPInstallment {
		border-right: none;
	}

	.CartSelectSIP {
		margin-top: 1rem;
		line-height: 10px;
	}

	.CartSelectSIP .h4 {
		font-weight: 600;
		font-size: 13px;
	}

	.CartSelectSIP .paymentgatewayoption {
		margin-top: 1rem;
		width: 100%;
		border: 1px solid #d6d6d6;
		border-radius: 7px;
	}

	.CartSelectSIP .UPIOption {
		padding: 1.6em;
	}

	.SelectBank .NeftBox .paymentgatewayoption {
		width: 100%;
		border: 1px solid #d6d6d6;
		/* height: 7rem;
		 */
		padding: 1.2rem;
		padding-left: 0rem;
		width: 21.3rem;
		margin-top: 1rem;
		border-radius: 12px;
		/* margin-left: ;
		 */
	}

	.SelectBank .paymentgatewayoption {
		padding: 1rem;
	}

	.boxContent .AccountDetails h4 {
		white-space: nowrap;
		font-size: 0.8em;
	}

	.CartSelectSIP .paymentgatewayoption .AccountDetails .AccountLabel {
		color: #bebebe;
		font-size: 0.8em;
	}

	.AddManadateLabel {
		margin-top: 1em;
	}

	.boxContent .UPIID input[type="text"] {
		width: 18rem;
	}

	.boxContent .PayNow {
		width: 18rem;
	}

	.boxContent .mb-grid {
		display: grid;
	}

	.CartSelectSIP .TotalPayText h4 {
		font-weight: 600;
		font-size: 16px;
	}

	.Transaction .TotalPayText {
		display: flex;
		justify-content: center;
		margin-left: 0rem;
		/* text-align: center;
		 */
		width: 100%;
	}

	.Transaction .PayAmount {
		font-size: 29px;
		color: #042b62;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 21rem;
		font-weight: 600;
		border-bottom: 1px solid #a3a3a3;
	}

	.CartSelectSIP .PaymentType {
		font-size: 16px;
	}

	.CartSelectSIP .paymentgatewayoption .NextArrow {
		width: 13px;
		height: 13px;
		margin-top: 1rem;
	}

	.CartSelectSIP .SIP_Install img {
		width: 84px;
		height: 84px;
	}

	.CartSelectSIP .SIP_Install .Active {
		background: #f2f2f2;
		border-radius: 50%;
		padding: 20px;
	}

	.CartSelectSIP .SIP_Install .INActive {
		background: none;
		/* border-radius: 50%;
		 */
		padding: 14px;
	}

	.SIP_Install .SIPText {
		font-size: 13px;
		font-weight: 600;
		margin-top: 1rem;
	}

	.SIP_Install .MobileSpace {
		margin-top: 2rem;
	}

	.Transaction {
		margin-top: 0rem;
	}

	.InvestSelectBank .AutoPayOPtion img {
		width: 40px;
		height: 40px;
	}

	.PayOption .PayType p {
		color: #000;
		font-size: 1em;
		font-weight: 600;
	}

	.InvestSelectBank .PayOptionInfo {
		max-width: 20em;
		line-height: 16px;
		font-size: 11px;
		color: #a0a0a0;
	}

	.PayOption .NextArrow {
		margin-top: 2rem;
		float: right;
	}

	.SelectedBank {
		margin-top: 0rem;
	}

	.InvestSelectBank {
		padding: 1rem;
	}

	.InvestSelectBank .BankLogo img {
		width: 3em;
		height: 3em;
		margin-top: 1rem;
		border: 1px solid #a3a3a3;
		border-radius: 50%;
	}

	.InvestSelectBank .InvestfundHeading p {
		color: #a3a3a3;
		font-size: 13px;
		font-weight: 500;
	}

	.InvestSelectBank .BankName,
	.AccountNo,
	.BranchName p {
		white-space: nowrap;
		color: #000;
		font-size: 13px;
		font-weight: 700;
	}

	.CartText {
		font-size: 15px;
		font-weight: 600;
		letter-spacing: 2px;
		padding-left: 0.5rem;
	}

	.Rupeees .BackBtn {
		width: 19px;
		height: 19px;
		vertical-align: text-bottom !important;
		margin-left: 0em;
	}

	.Cart .BackBtn {
		font-size: 1.4em;
		font-weight: 600;
		margin-left: 0em;
	}

	.TransDetails {
		padding-top: 1em;
	}

	.AllTrans .tranText {
		font-size: 15px;
		font-weight: 700;
		letter-spacing: 1px;
		line-height: 1.6em;
		max-width: 127px;
	}

	.AllTrans .GreenLine {
		height: 4px;
		width: 8rem;
		border-radius: 15px;
		border: 1px solid transparent;
		background-color: #042b62;
	}

	.InvestFundbox {
		margin-top: 1rem;
		padding: 5px;
		background: #fff;
		border-radius: 10px;
	}

	.InvestFundbox .FundHead h4 {
		font-size: 0.7em;
		font-weight: 600;
	}

	.InvestFundbox .InvestHeading p {
		color: #a3a3a3;
		font-size: 11px;
		font-weight: 500;
		letter-spacing: 1px;
	}

	.InvestFundbox .InvestType p {
		color: #000;
		font-size: 12px;
		height: 1.5rem;
		width: 8rem;
		display: flex;
		font-weight: 600;
		border-bottom: 1px solid #ddd;
		padding-bottom: 10px;
		/* clear: both;
		 */
	}

	.TwoSpace .AddFund {
		width: 8rem;
		background: #042b62;
		border-radius: 15px;
		border: 2px solid #fff !important;
		font-weight: 600;
		color: #fff f;
		font-size: 12px;
	}

	.TwoSpace .InvestFund {
		font-size: 12px;
		font-weight: bolder;
		padding-top: 0.4em;
	}

	.InvestOptions .AddLumsum p {
		padding-top: 1rem;
		color: #a3a3a3;
		font-size: 12px;
		font-weight: 700;
		cursor: pointer;
	}

	.InvestFundbox .InvestType p input[type="text"] {
		color: #000;
		padding-left: 0;
		font-size: 12px;
		white-space: nowrap;
		height: 3rem;
		overflow: visible;
		z-index: 1000000;
		font-weight: 600;
		padding-bottom: 2rem;
	}

	.CartAmtBox {
		display: none;
	}

	.reverse {
		/* display: flex;
		 flex-wrap: wrap-reverse !important;
		 */
		display: none;
	}

	.Bu_Now_Mobile {
		display: block;
	}

	.Bu_Now_Mobile .Card_Btn button {
		width: 7rem;
		height: 2rem;
		font-weight: bold;
		border-radius: 15px;
		border: 1px solid #fff;
		background-color: #042b62;
		color: #fff;
	}

	.BankDetails span:nth-child(2) {
		margin-right: 3.1rem;
		font-size: 12px;
	}

	.InputOpt {
		width: 19.5rem;
	}

	.Nominee_Identity {
		margin-top: 1rem;
	}

	.Space {
		margin-top: 0.5rem;
	}

	.ProfileImg {
		display: none;
	}

	.FamilyDetails {
		display: grid;
	}

	.Nominee_Option {
		display: grid;
	}

	.Profile .RightPanel h4 {
		color: #585858;
		font-weight: 900;
	}

	.MarriedStatus .NextBtn {
		margin-left: 1rem;
		margin-top: 1.2rem;
	}

	.Profile .RightPanel p {
		/* margin-top: 1.5em;
		 */
		line-height: 1.5rem;
	}

	.Profile .RightPanel .InputText {
		width: 19.5rem;
		border-radius: 10px;
		outline: none;
		height: 2.5rem;
		/* border: 2px solid #c1c1c1;
		 */
	}

	.MobilePanNm {
		display: grid !important;
		margin-top: 0.5rem !important;
	}

	.MarriedStatus {
		display: grid !important;
	}

	.ProfileHr {
		/* margin-top: 1rem !important;
		 */
	}

	.CofmIfscCode {
		font-size: 13px;
		font-weight: 600;
	}

	.Profile .RightPanel .PanName {
		padding-left: 0.5rem;
		color: #575757;
		font-weight: 900;
	}

	.MobilePanNm img {
		width: 21px !important;
	}

	.Profile .RightPanel .ChangePan {
		color: #042b62;
		text-decoration: none;
		font-weight: 500;
	}

	.Profile .RightPanel .VerifyBtn {
		margin-left: 10.5rem;
	}

	.VerifyDetails {
		margin-top: 0rem;
	}

	.Profile .styles_scroll-to-top__2A70v {
		display: none;
	}

	input[type="radio"].toggle.toggle-right+label {
		margin-left: -5px;
		width: 7rem;
		border-bottom-right-radius: 15px;
		border-top-right-radius: 15px;
		overflow: hidden;
	}

	input[type="radio"].toggle.toggle-left+label {
		border-right: 0;
		width: 7rem;
		border-left: 1px solid #1a1a1a;
		border-bottom-left-radius: 15px;
		border-top-left-radius: 15px;
		overflow: hidden;
	}

	.Profile .RightPanel .NextBtn {
		margin-left: 11.6rem !important;
		outline: none;
		box-shadow: none !important;
	}

	.Nominee_Option .Default {
		border-radius: 10px;
		height: 2.5rem;
		width: 23.5rem;
		outline: none;
	}

	.Width {
		width: 20.5rem;
	}

	.DobNext .NextBtn {
		margin-top: -1rem;
	}

	.BankConfrmDetails {
		width: 100%;
		background: #e0e0e0;
		border-radius: 15px;
		padding: 1rem;
		display: flex;
	}

	.UploadBtn {
		height: 242px;
		width: 300px;
		border-radius: 15px;
		background-color: #fff;
		border: 1px solid #4b5763;
		color: #b0b1b3;
		/* text-transform: uppercase;
		 */
		cursor: pointer;
		margin-bottom: 4px;
	}

	.Upload {
		margin-left: 0rem;
	}

	.BankConfrmDetails .BankCnfmName {
		font-size: 13px;
		font-weight: 600;
	}

	.BankConfrmDetails img {
		width: 30px;
		height: 30px;
	}

	.TwoOption {
		display: flex;
		justify-content: center;
		align-items: center;
		align-items: center;
		margin-top: 3rem;
	}

	.CofmAccountNM {
		font-size: 13px;
		font-weight: 600;
	}

	.GenderSelect {
		display: flex;
	}

	.GenderSelect .ResidentType {
		white-space: nowrap;
	}

	.PhotoVideo {
		height: 300px;
		width: 307px;
		font-size: 14px;
		border-radius: 15px;
		background-color: #fff;
		border: 1px solid #4b5763;
		color: #b0b1b3;
		/* text-transform: uppercase;
		 */
		cursor: pointer;
		margin-bottom: 4px;
	}

	input[type="radio"].toggle.toggle-right+label {
		margin-left: -5px;
		width: 6rem !important;
		border-bottom-right-radius: 15px;
		border-top-right-radius: 15px;
		overflow: hidden;
	}

	input[type="radio"].toggle.toggle-left+label {
		border-right: 0;
		width: 6rem !important;
		border-left: 1px solid #1a1a1a;
		border-bottom-left-radius: 15px;
		border-top-left-radius: 15px;
		overflow: hidden;
	}

	.Percent {
		margin-top: 1rem;
		width: 19.5rem;
	}

	.Nominee_Option .NomineeName {
		width: 19.5rem;
	}

	.NomineeBtn {
		display: flex;
		margin-top: 2rem;
		float: left !important;
		/* margin-right: 10rem;
		 */
		margin-left: -6.5rem !important;
	}

	.NomineeBtn .NomiNext {
		margin-top: 1rem;
	}

	.YNuuG {
		margin-top: -18rem !important;
		height: 17rem !important;
		width: 491px !important;
		margin-left: 0.3rem;
		border-radius: 15px;
	}

	.DobNext .GenderSelect,
	.Residential img {
		width: 60px;
	}

	.ResidentType span {
		white-space: nowrap;
	}

	.LabelName {
		font-size: 16px;
		color: #575757;
		font-weight: 900;
		white-space: nowrap;
	}

	.NotCorrect {
		font-size: 12px;
		font-weight: 500;
		color: #bebbbb;
	}

	.NomineeBtn .NextBtn {
		margin-left: 14rem !important;
		margin-top: 0rem;
	}

	.Fatca .ResiStatus {
		color: #585858;
		font-size: 11px;
		font-weight: 600;
	}

	.Fatca .GenderSelect {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: repeat(2, 1fr);
		grid-column-gap: 39px;
		grid-row-gap: 0px;
	}

	.Fatca .ResidentType {
		text-align: center;
		margin-top: 0.8rem;
		color: #a4a4a4;
		font-size: 10px;
		font-weight: 600;
	}

	.ConfirmAadharAddress .TaxRes {
		width: 12.6rem !important;
	}

	.ub-w_240px {
		width: 19rem !important;
		border-radius: 15px !important;
		font-size: 12px !important;
	}

	.MobilePanUI {
		display: block;
	}

	.Profile .RightPanel .MobilePanUI .NextBtn {
		margin-left: 10rem !important;
	}

	.mobileUI {
		display: none;
	}

	.NomineeBtn {
		display: flex;
		margin-top: 1rem;
		float: left !important;
		/* margin-right: 10rem;
		 */
		margin-left: -11.5rem !important;
	}

	.NomineeBtn .NewNominee {
		font-size: 14px !important;
		margin-left: 0rem;
		width: 10rem;
	}

	.NomineeBtn .NewNominee h5 {
		font-size: 14px !important;
	}

	.NewNominee .NextBtn {
		width: 10rem !important;
	}

	.Profile .RightPanel .NomiNext {
		margin-left: 13.6rem !important;
	}

	.LeftSide .tab [hidden] {
		display: none !important;
	}

	.Compare_Details {
		/* width: 95%;
		 */
		/* overflow: hidden !important;
		 */
		margin-top: 0rem;
		/* display: grid;
		 */
		/* place-items: center;
		 */
		/* margin-left: 10px;
		 */
		margin-top: 7rem;
	}

	.Compare_Details h4,
	h5 {
		font-size: 14px !important;
	}

	.Header_Compare {
		background-color: #fcfcfc;
		width: 100%;
		border-top-left-radius: 20px;
		border-top-right-radius: 20px;
	}

	.Header_Compare .Header_Name {
		/* display: grid;
		 */
		padding: 1rem;
		border-bottom: 1px solid #d1d1d1 !important;
	}

	.Header_Name h4 {
		font-size: 14px;
	}

	.Header_Compare .Header_Name input[type="search"] {
		outline: none;
		/* width: 21rem;
		 */
		width: 100%;
		height: 2rem;
		padding: 0.8rem;
		padding-top: 0.8rem;
		border-radius: 15px;
		font-size: 14px;
		border: 1px solid #d1d1d1;
	}

	.Header_Compare .Header_Name input[type="search"]::placeholder {
		font-size: 10px;
		padding-top: 0.5rem;
	}

	.Stock_Compare_List {
		display: flex;
		padding-left: 0rem;
		justify-content: space-between;
	}

	.Stock_Compare_List h4 {
		font-size: 14px;
	}

	.Stock_Compare_List div {
		border: none;
	}

	.Stock_Compare_List .compare_dec {
		width: 23rem !important;
		padding-top: 2rem !important;
		padding-left: 1rem !important;
	}

	.Stock_Compare_List p {
		font-size: 12px;
		color: gray;
		font-size: 12px;
		padding-top: 0rem;
		font-weight: 400;
	}

	.Stock_Compare_List div {
		border: none;
		/* padding: 1rem;
		 */
		/* border: none;
		 */
		/* width: 20rem !important;
		 */
	}

	.Stock_Compare_List .Funds_Name {
		border: none;
		/* display: flex;
		 */
		/* margin-top: -2rem;
		 */
		/* height: 6rem;
		 */
		color: #000;
		font-weight: 800;
	}

	.Stock_Compare_List .Fund_Options {
		border: none !important;
		/* display: flex;
		 */
		/* justify-content: space-between;
		 */
		/* margin-left: -3rem;
		 */
		color: gray;
		/* padding-left: 60px;
		 */
		/* font-weight: 600;
		 */
		font-size: 12px;
	}

	.Stock_Compare_List .Fund_Options .Line {
		border-right: 1px solid #c0c3c2;
		margin-top: 0.2rem !important;
		height: 0.8rem;
	}

	.Close_Fund {
		cursor: pointer;
		font-size: 10px;
	}

	.Stock_Compare_List .Invest_Btn button {
		width: 8rem;
		height: 2rem;
		font-size: 12px;
		border-radius: 25px;
		padding: 1px;
		font-weight: 900;
		border: 2px solid #fff;
		color: #fff;
		background-color: #042b62;
	}

	.AllComapareDetails {
		background-color: #fff;
		width: 100%;
		/* margin-top: -1rem;
		 */
		/* padding: 2rem;
		 */
		/* border-top-left-radius: 20px;
		 border-top-right-radius: 20px;
		 */
	}

	.Funds_Name div span:nth-child(2) {
		font-size: 12px;
	}

	.Funds_Name img {
		width: 40px;
		height: 40px;
	}

	.NavTrend #chart .toolbar {
		display: flex !important;
		justify-content: flex-end;
	}

	.Asset_Allocation .GraphAllocation {
		display: block;
	}

	.Asset_Allocation p {
		font-size: 14px !important;
	}

	.PortFolio p {
		font-size: 14px !important;
	}

	.Sector_Allocation p {
		font-size: 14px !important;
	}

	.TopHoldings p {
		font-size: 14px !important;
	}

	.Asset_Allocation .GraphAllocation .DivGraph {
		border: none;
		display: grid;
		grid-template-columns: repeat(0, 1fr) !important;
		grid-template-rows: 1fr;
		grid-column-gap: 0px;
		grid-row-gap: 0px;
		margin-top: 0rem !important;
	}

	.Asset_Allocation .GraphAllocation .borderGraph {
		border: none;
		margin-left: 0rem;
		width: 19rem;
	}
}

.TabHeader {
	outline: none;
}

.Table_Compare .fundComp:disabled {
	background-color: #d3d3d3;
	cursor: not-allowed;
}

.fund-checkbx {
	padding-right: 1rem;
}

.fund_amc {
	cursor: pointer;
}

.stckymenu {
	display: flex;
	padding-bottom: 0rem;
	padding-top: 1rem;
	margin-left: .4rem;
}

.stckymenu button {
	flex-grow: 1;
}

.overviewDetails .colleft1 {
	border-right: 1px solid #d3d3d3;
	padding: 1rem;
}

.overviewDetails .colright1 {
	padding: 1rem;
}

/* .LeftSection {
	 padding-right: 1rem;
}
 */
.RightSection {
	padding-left: 1rem;
}

.headerbx {
	position: relative;
}

.changeColor {
	padding-right: 1rem;
	padding-left: 1rem;
}

.LatestNavText p {
	margin-bottom: 0;
}

.bxheader-name {
	color: #bebebe;
	font-size: 1rem;
	font-weight: bold;
	margin-top: 0.5em;
	margin-bottom: 0.5em;
}

.fileUploader {
	border: none;
}

.fileContainer {
	height: 300px !important;
	width: 100% !important;
	font-size: 22px;
	border-radius: 15px;
	background-color: #fff;
	border: 1px solid #4b5763;
	color: #b0b1b3;
	/* text-transform: uppercase;
	 */
	cursor: pointer;
	margin-bottom: 4px;
	background: #fff;
	box-shadow: none;
	position: relative;
	border-radius: 10px;
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	transition: all 0.3s ease-in;
	height: 275px;
}

.img-preview-n {
	position: relative;
}

.img-preview-n span {
	position: absolute;
	z-index: 999;
	cursor: pointer;
	top: 5px;
	right: 10px;
	color: #042b62;
}

.fileContainer p:empty {
	margin: 0;
}

.fileContainer .uploadPicturesWrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	position: relative;
	top: -51px;
	width: 300px;
	height: auto;
	/* overflow: hidden !important;
	 */
}

.uploadPicturesWrapper>div:empty {
	height: 1px;
}

.fileContainer .uploadPictureContainer {
	height: 297px !important;
	margin: 0% !important;
	border-radius: 12px !important;
	padding: 5px;
	/* background: none !important;
	 */
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	/* overflow: visible !important;
	 */
}

fileContainer .deleteImage {
	position: absolute;
	right: -7px !important;
	top: -11px !important;
	border-radius: 0;
	text-align: center;
	cursor: pointer;
	font-size: 13px !important;
	font-weight: bold;
	line-height: 24px !important;
	padding-bottom: 1rem !important;
	width: 20px !important;
	height: 20px !important;
	background: #042b62 !important;
	/* background-image: url(./components/Assets/Delete.png) !important;
	 */
	background-position: left 4px top 1px;
	background-repeat: no-repeat;
	background-size: 17px 23px;
	background-color: transparent;
	color: black;
	/* background: url('./components/Assets/Delete.png') !important;
	 */
	/* overflow: hidden;
	 */
	/* text-indent: -156px;
	 */
}

.fileContainer .deleteImage:after {
	content: "";
	position: absolute;
	right: -45px;
	font-size: 16px;
	overflow: hidden !important;
}

.fileContainer .uploadPictureContainer img.uploadPicture {
	height: 200px;
	/* height: 200px;
	 */
}

.fileContainer .errorsContainer {
	max-width: 300px;
	display: flex;
	justify-content: center;
	font-size: 19px;
	font-weight: 500;
	font-family: "Red Hat Display", sans-serif !important;
	position: absolute;
	background: #000 !important;
	z-index: 10;
	color: red !important;
	text-align: center;
	top: 20%;
	background: rgba(255, 0, 0, 0.7);
	padding: 10px;
	text-align: center;
	/* color: white;
	 */
	margin: 0 auto;
	width: 100%;
	border-radius: 6px;
}

.fileContainer .errorsContainer:empty {
	opacity: 0;
}

.fileContainer .chooseFileButton {
	padding: 6px 45px !important;
	margin-top: 5rem !important;
	background: none !important;
	background-color: none !important;
	background-repeat: no-repeat;
	background-position: 50% 23%;
	background-size: 25%;
	border-radius: 0;
	color: #b0b1b3 !important;
	font-weight: 300;
	font-size: 24px !important;
	margin: 10px 0;
	transition: all 0.2s ease-in;
	cursor: pointer;
	outline: none;
	border: none;
	width: 95%;
	height: 100%;
}

.fileContainer .chooseFileButton:hover {
	background-color: #c1c1c1;
	background-repeat: no-repeat;
	background-position: 50% 23%;
	background-size: 25%;
}

/* .fileUploader {
	 border: 1px solid #e1e1e1;
}
 .fileUploader .fileContainer {
	 display: flex;
	 flex-direction: row-reverse;
	 padding:10px 10px;
	 box-shadow: none;
	 margin: 0 0 0 0;
}} */


/* Penny Drop */
@media screen and (max-width: 768px) {

	/* Photo Click and Upload */
	.PhotoClickUpload {
		margin-top: 2rem;
		height: 300px !important;
		width: 464px !important;
		font-size: 22px;
		border-radius: 15px;
		background-color: #fff;
		border: 1px solid #4b5763;
		color: #b0b1b3;
		cursor: pointer;
		background: #fff;
		/* position: relative;
	 */
		border-radius: 10px;
		display: flex;
		justify-content: space-around;
		align-items: center;
	}

	.PhotoClickUpload img {
		width: 60px;
		height: 60px;
	}

	.PhotoUpload div:nth-child(2) {
		margin-top: -9rem;
	}

	.PhotoUpload div:nth-child(2) img {
		margin-top: -9.2rem;
		width: 376px;
		border-radius: 20px;
		margin-bottom: 1.6rem;
		height: 282px;
		margin-left: 3rem;
		/* margin-top: -31rem;
	 */
	}

	.PhotoUpload video {
		width: 376px;
		border-radius: 20px;
		margin-bottom: 1.6rem;
		height: 282px;
		margin-left: 3rem;
		margin-top: -31rem;
	}

	.fileContainer {
		height: 300px !important;
	}

	.fileContainer .uploadPictureContainer {
		width: 322px !important;
		height: 297px !important;
		margin: 0% !important;
		border-radius: 12px !important;
	}


}

.apexchartsarea-datetime {
	background: #fff;
	border-radius: 0.7rem;

}

.OverGraph #chart .toolbar {
	padding-top: 2rem;
	padding-bottom: 1.5rem;

}

.FundDetail .inner-item-details {
	flex-grow: 1;
	width: 25%;
	overflow: hidden;

}

.FundDetail .inner-item-details p.FundNames {
	margin-bottom: 0;

}

.FundDetail .inner-item-details .inner-item-details-top-bx img {
	width: 1.9rem;
	/* background-color: #fff;
 */

}

.inner-item-details-top-bx {
	display: flex;
	align-items: flex-end;

}

.inner-item-details-top-bx .mobileFund {
	line-height: 1em;
	margin-left: 5px;
	color: #888;

}

.range-outer {
	background: #d3d3d3;
	border-radius: 10px;
	overflow: hidden;
	margin-top: 1rem;

}

.range-outer .range-inner {
	height: 10px;
	width: 72%;
	background: #042b62;

}

.calc-tablist {
	border-radius: 0px 0px 0px 0px;
	display: flex;
	padding-left: 0;
	margin-bottom: 0px;
	border-top-right-radius: 2rem;
	overflow: hidden;
	border-top-left-radius: 2rem;
	border: 2px solid #fff;
	border-bottom: 0px;

}

.calc-panel-box {
	border: 2px solid #fff;
	border-top: 0px;
	border-bottom: 0px;

}

.calc-buttons-d {
	border-bottom-left-radius: 2rem;
	border-bottom-right-radius: 2rem;
	overflow: hidden;
	border: 2px solid #fff;
	border-top: 0px;

}

.calc-tab-box {
	background: none !important;
	border: 0px !important;

}

.calc-tablist li {
	width: 50%;

}

.stock-details .fixed_top_Nav {
	background-color: #f8f9fa;
	top: 57px !important;

}

.details-section .section {
	padding-top: 1rem;
	padding-bottom: 1rem;

}

.chart-button-st {
	font-size: 0.9em;
	outline: none;
	height: 1.6rem;
	cursor: pointer;
	border-radius: 8px;
	border: 1px solid #042b62;
	font-weight: bold;
	color: grey;
	border: 0;
	padding-left: 0.7em;
	padding-right: 0.7em;
	margin-left: 0.5em;
	margin-right: 0.5em;
	background: none;

}

.chart-button-st.active {
	background: #042b62;
	color: #fff;

}

.details-section .section-heading {
	/* background: #000;
 */
	padding-bottom: 1rem;
	padding-top: 1rem;
	margin-bottom: 0;

}

.react-tabs__tab-list.top-nav-menu {
	display: flex;
	gap: 20px;
	border-bottom: none;

}

.react-tabs__tab-list.top-nav-menu li {
	flex-grow: 1;

}

.top-nav-menu .TabHeader {
	border-bottom: 0px !important;

}

.top-nav-menu .TabHeader .top-menu-li-item {
	display: inline-flex;

}



/* .top-nav-menu .TabHeader.react-tabs__tab--selected .top-menu-li-item {
 border-bottom: 1px solid #fff;} */
.txt-gray-colored {
	color: #6d6d6d;

}

#chart-timeline {
	border-radius: 14px;
	padding: 1rem;
	background: #fff;
	min-height: 315px;

}

.item-continer-bx {
	background: #fff;
	margin-top: 1rem;
	margin-bottom: 1rem;
	border-radius: 10px;
	padding: 2rem 2rem;

}

.explore-now {
	background-color: #042b62;
	border: 1px solid #042b62;
	border-radius: 15px;
	color: #fff;
	height: 1.7em;
	padding-top: 0.3em;
	line-height: 1em;
	text-align: center;
	width: 7rem;
	font-size: 0.9em;
	/* float: right;
 */
	font-weight: 600;
	display: inline-block;

}

.explore-now:hover {
	color: #fff;

}

.explore-for-mobile {
	display: none;

}

.mf-autocomplete .user-name {
	width: 80%;

}

.mf-autocomplete .mf-nv-rt {
	padding-right: 5px;
	border-right: 1px solid #d3d3d3;

}

.mf-autocomplete .mf-nv-lt {
	padding-left: 5px;

}

.mf-autocomplete .user-Price {
	white-space: nowrap;
	font-size: 0.8rem;
	display: flex;
	align-items: flex-start;

}

.mf-autocomplete p {
	margin-bottom: 0;

}

.clear-input {
	cursor: pointer;

}

#gauge-chart1 .text-group {
	display: none;

}

.ellipsis {
	text-overflow: ellipsis;
	/* Required for text-overflow to do anything */
	white-space: nowrap;
	overflow: hidden;

}

.Nm-bx {
	display: flex;
	align-items: center;

}

.Nm-bx p {
	margin: 0;

}

.w-33 {
	width: 33.3333%;

}

.rs-type-bx img {
	opacity: 0.5;

}

.rs-type-bx .rs-type-ck {
	display: inline-block;
	-webkit-user-select: none;
	/* Safari */
	-ms-user-select: none;
	/* IE 10 and IE 11 */
	user-select: none;
	/* Standard syntax */

}

.rs-type-bx.active img {
	opacity: 1;

}

.rs-type-bx.active label {
	position: relative;

}

.rs-type-bx.active label:after {
	background-image: url("./Assets/Images/check_01.svg");
	background-size: cover;
	width: 20px;
	height: 20px;
	position: absolute;
	top: 0px;
	right: 0px;
	z-index: 99;
	content: " ";

}

.SubCategory_Filter .fintoo-checkbox {
	padding-top: 10px;
	padding-bottom: 10px;

}

.SubCategory_Filter {
	padding-top: 1rem;

}

.modal {
	z-index: 10001 !important;
}

.xrr-returns.red {
	color: red;
}

.xrr-returns.green {
	color: green;
}

.investor-name.orange {
	color: #f68522;
}

/* mobile css*/


/* @media only screen and (max-width: 600px) {
 .overviewDetails .colleft1 {
	 border-right: 0px;
	 padding: 0px;
}
 .overviewDetails .colright1 {
	 padding: 0px;
}} */
.child-section-asset {
	padding-top: 1.5rem;

}

.calc-tablist-panel {
	background: #f4f4f4;

}

.calc-tablist-panel-item {
	background: #f4f4f4;
	padding: 1.5rem;

}

.calc-tablist li.react-tabs__tab {
	background: #d1d1d1;

}

.calc-tablist .react-tabs__tab.react-tabs__tab--selected::after {
	display: none;

}

.fin-cal-button {
	font-size: 1rem;

}

.title-bottom {
	display: flex;
	flex-grow: 1;

}

.title-bottom p {
	line-height: 1em;
	font-size: 1em;
	margin-bottom: 0;
	padding-right: 7px;

}

.bottom-starrating-container {
	display: flex;

}

.title-bottom-bx {
	display: flex;
	padding-top: 0.7rem;

}

.FunSubDetails p {
	line-height: 1em;
	margin-bottom: 0.5em;

}

.FunSubDetails p:last-child {
	margin-bottom: 0;

}

.row-fake-margin {
	margin-top: -2em !important;

}

.overviewDetails .ow-subtitle {
	font-size: 1em;
	color: #808080;

}



/* for customBar */
.bar-box-container .bar-bottom-sheet .bar-bottom-sheet-item {
	flex-grow: 1;

}

.bar-box-container .bar-scheme-container {
	display: flex;
	align-items: center;

}

.bar-box-container .bar-scheme-container span {
	padding-left: 5px;
	padding-right: 5px;

}

.bar-box-container .bar-color-box {
	width: 1rem;
	height: 1rem;
	display: inline-block;

}

.bar-bottom-sheet {
	display: flex;
	flex-grow: 1;

}

.bar-box-ontainer {
	width: 100%;

}

.bar_box {
	background: #d3d3d3;
	display: flex;
	/* animation: animateWidth 1s ease forwards; */

}

.bar_box .bar {
	height: 25px;
	transition: 10s;

}

.bar-bottom-sheet {
	padding-top: 1em;

}

@keyframes animateWidth {
	from {
		width: 0%;
	}

	to {
		width: 100%;
	}


}

.item-side-options {
	display: flex;

}

.item-side-options .ExploreBtn {
	font-size: 1.7em;
	color: #042b62;
	margin-top: 0.2em;
	margin-left: 0.3em;

}



/* .searchbar-desktop {
 display: none;} */
.item-title-container {
	display: flex;
	align-items: center;
	overflow: hidden;
}

.stock-container .item-title-parent {
	width: 85%;
	line-height: 1.2rem;

}

.item-title {
	text-overflow: ellipsis;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	max-width: 18rem;
	/* flex-grow: 1;
 display: flex;
 flex-direction: column;
 */

}

.item-title a {
	color: #000;
	font-size: 1em;
	font-weight: bold;

}

.stock-item-list span {
	color: #888;
	font-size: 0.8em;

}

.item-title-container .Stock_Img {
	padding-right: 10px;

}

.item-side-options .Sample {
	width: calc(100% / 6);

}

.item-side-options .Sample:first-child {
	width: 20%;

}

.items-main-container {
	display: flex;

}

.items-main-container .item-container {
	width: 33.33%;
	position: relative;
	padding: 3rem 0;

}

.Filter_Btn_panel {
	padding-bottom: 0.3em;
	display: flex;
	background: #fff;
	width: 100%;

}

.items-main-container .item-container .comapre-remove-button {
	position: absolute;
	right: 2em;
	cursor: pointer;
	background: #fff;
	border-radius: 50%;
	border: none;
	top: 3em;

}

.Funds_Name img {
	width: 35px;

}

.acc-section-bx {
	border-top: #f1f1f1 solid 1px;
	border-bottom: #f1f1f1 solid 1px;

}

.Asset_Allocation .GraphAllocation {
	margin-top: 0;

}

.emptypara {
	display: flex;
	align-items: center;
	justify-content: center;

}

.emptypara p {
	text-align: center;

}

#chart-timeline .apexcharts-legend {
	padding-top: 1rem;
	flex-direction: column;

}

#bargraph-compare .apexcharts-legend {
	padding-top: 1rem;
	flex-direction: column;

}

.yearbuttons {
	background-color: transparent;
	border: transparent 1px;
	padding-left: 10px;
	padding-right: 10px;
	font-size: 0.9rem;

}

.yearbuttons.active {
	background-color: #042b62;
	border: #042b62 1px solid;
	color: #fff;
	outline: none;
	border-radius: 8px;

}

@media only screen and (min-width: 768px) {
	.Compare_Details .Header_Name {
		display: flex;
	}


}

.Sample .in-options-title {
	font-weight: bold;
	font-size: 0.8em;
	text-align: left;

}

.inSideComp {
	display: inline-block;
	text-align: center;
}

.inSideComp input {
	height: 1.2rem;
	width: 1.2rem;
	cursor: pointer;
}

.Sample .in-options-title .Value {
	font-size: 0.9em !important;
	text-align: left;

}

.item-continer-row {
	align-items: center;
	padding-top: 0.5em;
	padding-bottom: 0.5em;

}

.explore-for-desktop {
	display: table;

}

.explore-for-desktop .explore-now-bx {
	display: table-cell;
	vertical-align: middle;

}

.searchbar-desktop .row {
	align-items: center;

}

.Stock_Header1 .react-tabs__tab--selected img {
	opacity: 1;

}

.Stock_Header1 .header-tab-title {
	color: #fff;
	padding-bottom: 0.2em;
	border-bottom: 3px solid transparent;

}

.Stock_Header1 .react-tabs__tab--selected .header-tab-title {
	border-bottom: 3px solid #fff;
	color: #fff;

}

.searchbar-desktop h4 span:first-child {
	margin-right: 0.5em;

}

.searchbar-desktop .Second {
	position: relative;

}

.tab-header-bx h4 {
	display: flex;
	align-items: flex-end;

}

.in-options-title .formobile {
	display: none;

}

.item-title-parent .navtext {
	font-weight: bold;

}

.Stock_Compare_List .compare_dec .compare-title-head {
	font-size: 1em;
	font-weight: bold;

}

.Stock_Compare_List .compare_dec .compare-text {
	font-size: 0.8em;

}

.items-main-container .item-container .item-inside-bx {
	padding: 0 5.5rem;

}

.fund-opt-spacing {
	font-weight: 500;
	font-size: 0.9em;
	line-height: 0.9em;
	text-align: center;
	padding-top: 1em;
	padding-bottom: 1em;

}

.fund-opt-spacing .in-invest {
	padding-left: 10px;
	padding-right: 10px;

}

.Fund_Options.show-in-mobile .in-invest {
	flex-grow: 1;

}

.fund-opt-spacing .in-invest {
	flex-grow: 1;
	border-right: 1px solid #d3d3d3;

}

.fund-opt-spacing span:last-child {
	border-right: none;

}

.funds-title-bx {
	padding-left: 5px;

}

.toolbar-all-buttons button {
	font-size: 0.8em;
	border-radius: 8px;
	margin-right: 0.5rem;
	background: #042b62;
	color: #fff;
	border: 1px solid #042b62;

}

.empty-space {
	width: 15rem;

}

.sector-item-list {
	width: 33.333%;
	padding-right: 4em;
	padding-left: 4em;
	border-left: 1px solid #d3d3d3;

}

.sector-item-title {
	display: flex;
	justify-content: space-between;

}

.sector-item {
	padding-top: 2em;
	padding-bottom: 2em;

}

#stocks-header .react-tabs__tab-list {
	display: flex !important;
	justify-content: space-between !important;

}

div.cs-container {
	position: relative;

}

div.cs-container ul {
	width: 100%;
	background: #ddd;
	position: absolute;
	top: calc(100% + 0.5rem);
	left: 0;
	padding: 1rem;
	margin: 0;
	box-sizing: border-box;
	border-radius: 5px;
	max-height: 200px;
	overflow-y: auto;

}

::marker {
	display: none;

}

@media only screen and (min-width: 768px) {
	.item-title-container .item-title-parent .formobile {
		display: none;
	}

	.show-in-mobile {
		display: none;
	}


}

.fintoo-filter-buttons .css-1s2u09g-control {

	border-color: #042b62;

}

.fintoo-filter-buttons .css-1s2u09g-control .css-1okebmr-indicatorSeparator {
	background-color: #042b62 !important;

}

.fintoo-filter-buttons .css-1s2u09g-control .css-tj5bde-Svg {
	color: #042b62;

}

.fintoo-filter-buttons .css-1s2u09g-control .css-14el2xx-placeholder {
	color: #000;

}

.fintoo-filter-buttons .css-1pahdxg-control {
	border-radius: 20px;
	border-color: #042b62;
	box-shadow: none !important;
}

.fintoo-filter-buttons .css-6j8wv5-Input {
	margin: 0 !important;
	padding: 0 !important;

}

.fintoo-filter-buttons .sortSelect__indicator {
	color: #042b62 !important;
}

.fintoo-filter-buttons .sortSelect__single-value:first-child {
	margin-left: 19px !important;

}

.page-stock-list .Btn.filter {
	font-size: 1em;

}

.holding-item-list {
	width: 33.33%;
	border-left: 1px solid #d3d3d3;

}

.fintoo-loader {
	text-align: center;
	height: 100vh;
	width: 100vw;
	position: fixed;
	background: #fff;
	top: 0;
	left: 0;
	z-index: 99999;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.fintoo-loader img {
	width: 100px;
	height: 100px;

}

.user-list {
	max-height: 50vh;
	overflow-y: auto;

}

.fintoo-filter-buttons .css-b62m3t-container,
.fintoo-filter-buttons .Btn.btn-filter {
	width: 90%;
	margin-left: 0;

}

.fintoo-filter-buttons .sortSelect__value-container {
	height: '100%';
}

.fintoo-filter-buttons .sortSelect__dropdown-indicator {
	padding-top: 0px !important;
	padding-bottom: 0px !important;

}

.fintoo-filter-buttons .sortSelect__indicator-separator {
	background-color: #042b62 !important;
}

.fintoo-filter-buttons .sortSelect__control {
	min-height: 2rem !important;
	height: 2rem !important;
	border-radius: 20px;
	border: 1px solid #042b62 !important;
}

.fintoo-filter-buttons .sortSelect__placeholder {
	color: #042b62 !important;

}

#FilterData {
	position: relative;
	height: 100%;
	overflow-y: auto;
}

#FilterData input[type="checkbox"] {
	cursor: pointer;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	outline: 0;
	background: lightgray;
	height: 16px;
	width: 16px;
	border: 1px solid white;

}

#FilterData input[type="checkbox"]:checked {
	background: url("./Assets/Images/check.svg"), lightgray;
	background-size: 100%;
	background-repeat: no-repeat;
	background-position: center;

}

.panel-content {
	height: 100%;
	width: 100%;
	background: #fff;
	pointer-events: all;
	overflow: hidden;

}

.FilterItem {
	/* height: 400px;
 */
	max-height: 85vh;
	overflow-y: auto;
	padding-bottom: 6rem;
	padding-top: 1.5rem;

}

.page-stock-details .notification {
	padding: 0px 0px;
	position: relative;
	display: inline-block;
	border-radius: 2px;

}

.page-stock-details .notification .badge {
	position: absolute;
	top: -7px;
	font-size: 10px;
	right: 23px;
	padding: 5px 8px;
	border-radius: 55%;
	background-color: #042b62;
	color: white;

}

.page-stock-details .share-btn {
	position: relative;

}

.page-stock-details .ssb143TwClick {
	transform: translate(-8px, 54px);

}

.stk-section {
	margin-top: 2rem;
	margin-bottom: 2rem;

}

.fin-cal-tab input[type="text"] {
	background: none;
	border: none;
	color: #888;
	font-size: 2.1875rem;
	font-weight: 100;
	outline: 0;
	padding-left: 1rem;
	width: 100%;
	font-weight: 900;

}

.fin-cal-tab .Total_amnt input {
	border-bottom: 1px solid #d1d1d1;
	width: 90%;

}

.fin-cal-tab .Total_amnt .Value {
	color: #888;
	font-size: 1.2rem;
	font-weight: 700;

}

.fin-cal-tab .Total_amnt .DatePick input {
	border-bottom: 1px solid #d1d1d1;
	font-size: 1.2rem;
	font-weight: 700;
	width: 90%;

}

.item-title-parent {
	width: 100%;

}

footer {
	background: #042b62;
	color: #fff;

}

.footer .footer-bg {
	width: 100%;
	margin-top: -50px;
	margin-left: -17px;
	position: relative;
	z-index: 3;

}

.footer .footer-heading {
	font-size: 16px;

}

.footer .footer-navigation {
	color: #0e8aa4;

}

.footer .footer-navigation li a {
	color: inherit;
	transition: 0.2s all;
	font-size: 16px;

}

.footer .footer-navigation li a:hover {
	color: #10d0f8;

}

.footer .social-media-links {
	display: flex;
	align-items: center;
	justify-content: center;

}

.footer .logo {
	text-align: center;
	display: block;

}

.footer .footer-bg {
	width: 100%;
	margin-top: -50px;
	margin-left: -17px;
	position: relative;
	z-index: 3;

}

.footer .copyright {
	padding: 10px;
	border-top: 1px solid #fff;

}

.footer .copyright p {
	font-size: 12px;
	line-height: 20px;
	letter-spacing: 0.2px;
	text-align: justify;

}

footer ul {
	list-style-type: none;
	padding: 0;
	margin: 0;

}

footer a {
	color: #000;
	text-decoration: none;
	cursor: pointer;

}

.footer .top-footer {
	padding: 20px 0;

}

.social-media-links li {
	padding: 0px 10px !important;

}

.Header .dropdown-toggle::after {
	display: none;

}

.cr-pointer {
	cursor: pointer;

}

.fltr-section {
	padding-top: 10px;
	padding-bottom: 10px;
	border-bottom: 1px #e5e5e5 solid;

}

.fltr-section h4 {
	font-size: 1rem;
	font-weight: 500;

}

.fltr-section .fltr-items {
	padding: 0;
	margin: 0;
	list-style: none;
	width: 100%;
	max-height: 400px;
	overflow-y: auto;

}

.fltr-items-li-w100 {
	width: 100% !important;
}


.fltr-section .fltr-items .fltr-items-li {
	width: 25%;
	display: inline-block;
	font-weight: 500;
	font-size: 0.9rem;

}

.fltr-section .fltr-items .fltr-items-li-amc {
	width: 100%;
	display: inline-block;
	font-weight: 500;
	font-size: 0.9rem;
	padding-top: 15px;
}

.fltr-section .fltr-items .fltr-items-li .fintoo-checkbox {
	padding-top: 10px;
	padding-bottom: 10px;

}

.calc-tablist-panel-item .amount-input-box::placeholder {
	color: #c0c0c0;

}

.Mobile_Holdings {
	display: none;

}

.st-filter-list {
	padding-top: 1rem;
	padding-bottom: 4.5rem;
	max-height: 65vh;
	overflow: auto;

}



/* width */
.st-filter-list::-webkit-scrollbar {
	width: 5px;

}



/* Track */
.st-filter-list::-webkit-scrollbar-track {
	background: #f1f1f1;

}



/* Handle */
.st-filter-list::-webkit-scrollbar-thumb {
	background: #888;

}



/* Handle on hover */
.st-filter-list::-webkit-scrollbar-thumb:hover {
	background: #555;

}

.InvestFundbox .delete-icon {
	color: #042b62;
	font-size: 18px;
	cursor: pointer;
	margin-top: 2.3em;
	width: 14px;

}

.InvestFundbox .cart-details {
	line-height: 2rem;
	margin-top: 0.5em;
	margin-bottom: 1rem;
	display: flex;
	justify-content: space-between;

}

.InvestFundbox .cart-details .cart-data {
	display: flex;
	justify-content: space-between;

}

.InvestFundbox .cart-details .cart-data .cart-data-demo {
	width: 100%;
	padding-left: 1rem;
	line-height: 1.8em;

}

.InvestFundbox .cart-details .cart-data .cart-data-demo-mode {
	width: 6rem;
	line-height: 1.8em;

}

.InvestFundbox .cart-title {
	color: #a3a3a3;
	font-weight: 500;
	font-size: 90%;

}

.InvestFundbox .cart-ietm-title {
	font-size: 85%;
	font-weight: 600;
	border-bottom: 1px solid #ddd;

}

.InvestFundbox .cart-ietm-title .react-date-picker__button {
	padding: 0px 1.5rem;
	flex-grow: 1;

}

.InvestFundbox .cart-ietm-title .react-date-picker__wrapper {
	background: none;
	border: none;
	padding: 0;

}

.InvestFundbox .cart-ietm-title .my-dob-calendar .react-date-picker__wrapper {
	width: 12em;

}

.InvestFundbox .cart-ietm-title .react-date-picker__inputGroup__input {
	width: none;

}

.InvestFundbox .cart-ietm-title .react-date-picker__inputGroup__input--hasLeadingZero {
	margin-left: 0;

}

.InvestFundbox .cart-ietm-title input {
	border: none;
	font-weight: 500;
	padding: 0px;

}

.InvestFundbox .cart-ietm-title .form-select {
	font-size: 100%;
	padding: 0.26rem;
	font-weight: 600;

}

.InvestFundbox .SIP_cart {
	border-bottom: none !important;

}

.InvestFundbox input[type="text"] {
	-webkit-appearance: none;
	letter-spacing: 2px;
	font-weight: 600;

}

.InvestFundbox input[type="number"] {
	-webkit-appearance: none;
	letter-spacing: 2px;
	font-weight: 600;

}

.InvestFundbox .Rupee-icon {
	padding-left: 25px !important;
	background: url("./components/Assets/rupee-indian.png") no-repeat left;
	background-size: 10px;

}

.InvestFundbox input[type="search"]::-webkit-search-cancel-button {
	-webkit-appearance: none;
	height: 25px;
	width: 25px;
	cursor: pointer;
	background: url("./components/Assets/cart_Close.png") no-repeat left;
	background-size: 20px;

}

input[type="date"] {
	-webkit-appearance: none;
	background-size: 15px;
	width: 100%;
	text-transform: uppercase;

}

.InvestSelectBank .bank-details {
	/* display: flex;
	justify-content: space-between; */

}

.InvestSelectBank .bank-details .Phone-next {
	margin-top: 1em;
	margin-right: 1em;

}

.InvestSelectBank .bank-details .bank-data {
	display: flex;

}

.InvestSelectBank .bank-details .bank-data .bank-data-title {
	padding-left: 2rem;
	line-height: 19px;

}

.InvestSelectBank .bank-details .bank-data .BankName {
	padding-left: 1em;

}

.InvestSelectBank .bank-details .bank-data .BankLogo {
	margin-top: 1em;

}

.InvestSelectBank .bank-details .next-page {
	width: 15px;
	height: 15px;
	margin-top: 1.5rem;
	margin-left: 2rem;

}

.InvestSelectBank .SelectBankData .BankData {
	padding-left: 2rem;
	line-height: 19px;
	margin-top: 0.5em;

}

.payment-box {
	padding-right: 10rem;
	padding-left: 10rem;

}

.payment-box p:first-child {
	font-weight: 600;
	font-size: 1.2em;

}

.payment-box .PaymentGatewayTYpe {
	margin-top: -1.3em;

}

.Neft-rtgs-txt {
	display: flex;
	justify-content: space-between;

}

.Neft-rtgs-txt .add-bank-new label {
	cursor: pointer;
	color: #042b62;
	font-size: 16px;
	font-weight: 600;

}

.NeftBox {
	margin-top: 1rem;

}

.NeftBox .BankLogo img {
	width: 40px;
	height: 40px;
	border: 1px solid #a3a3a3;
	border-radius: 50%;
	margin-top: -0.5em;

}

.NeftBox .bank-title {
	margin-left: 0.5rem;

}

.continue-btn {
	background-color: #042b62;
	margin-top: 2rem;
	color: #fff;
	width: 8rem;
	height: 2rem;
	border: none;
	border-radius: 30px;
	font-weight: 500;
	padding: 0.1rem;

}

.continue-btn a {
	text-decoration: none;
	color: #fff;

}

.continue-btn a:hover {
	color: #fff;
	font-weight: 600;

}

.bank-nm {
	color: #042b62;
	cursor: auto;


}

.neft-title {
	font-size: 0.8em;
	font-weight: 600;

}

.neftdetailsinfo {
	font-size: 0.8em;
	line-height: 18px;

}

.benificiary-details {
	border: 1px solid #a3a3a3;
	padding: 1.3rem;
	border-radius: 8px;
	margin-top: 1rem;

}

.benificiary-details .benificiary-data {
	width: 100%;

}

.benificiary-details .benificiary-data .benificiary-title {
	padding: 0.5em;
	color: #a3a3a3;
	font-size: 0.9em;
	font-weight: 500;
	width: 50%;
	line-height: 32px;

}

.benificiary-details .benificiary-data .benificiary-data-text {
	color: #000;
	font-size: 0.9em;
	font-weight: 500;
	text-align: left;

}

.payment-instruction ol {
	padding-left: 1rem;

}

.payment-instruction ol li {
	line-height: 25px;
	padding-top: 0.8em;
	color: #808080;
	font-weight: 100;
	font-size: 0.8em;
	padding-left: 0.6em;

}

.add-nominee {
	color: #042b62;
	font-weight: 500;
	text-decoration: none;
	text-align: left;
	cursor: pointer;

}

.add-nominee:hover {
	color: #042b62;
	font-weight: 600;

}

.UPIBox .SelectBank .paymentgatewayoption {
	width: 100%;
	border: 1px solid #a3a3a3;
	/* height: 7rem;
 */
	padding-left: 1rem;
	border-radius: 12px;

}

.totalPayable .CartHeading {
	color: #fff;
	text-align: center;
	font-size: 1.2em;
	padding: 0.7em;
	/* padding-top: 0.8rem;
 */
	font-weight: 500;

}

.totalPayable .CartCenter {
	padding-top: 1rem;
	letter-spacing: 1px;
	line-height: 19px;

}

.totalPayable .CartCenter .Cart_Amt {
	font-size: 1.4em;
	font-weight: 600;

}

.totalPayable .CartCenter .Cart_Amt .safe {
	color: #000100;
	font-size: 0.7em;
	letter-spacing: normal;
	font-weight: 500;
	text-align: center;
	padding-top: 0.5em;

}

.totalPayable .CartCenter .AmtPayText {
	color: #fff;
	font-size: 0.9em;
	font-weight: 500;
	text-align: center;
	letter-spacing: normal;

}

.totalPayable .SecureShield img {
	width: 45px;
	height: 45px;
	margin-top: 2rem;

}

.PaymentSuccess {
	margin: auto;
	margin-top: 9rem;
	width: 50%;
	padding: 10px;
	margin-bottom: 5rem;
	background-color: #fff;
	border-radius: 12px;
	max-width: 1000px;

}

.Res-modal {
	display: grid;
	justify-content: center;
	align-items: center;
	padding: 2em;

}

.Res-modal .SucessImg {
	width: 170px;

}

.Res-modal .ErrorBtn {
	display: flex;
	justify-content: center;

}

.Res-modal .continue-btn {
	width: 12rem;
	height: 2.3rem;
	border-radius: 30px;
	margin-left: 1rem;

}

.Res-modal .continue-btn a {
	text-decoration: none;
	color: #fff;

}

.Res-modal .continue-btn a :hover {
	font-weight: 700;

}

.desktop-view-none {
	display: none;

}

.UPIBox .paymentgatewayoption .boxContent .fintoo-checkbox {
	font-size: 0.8rem;

}

.UPIBox .paymentgatewayoption .boxContent .fintoo-checkbox img {
	width: 17px;
	height: 17px;

}

.page-profile .was-validated .form-control:valid {
	padding: 0.375rem 0.75rem;
	border-color: #ced4da;
	background-image: none;

}

.page-profile .was-validated .form-select:valid:not([multiple]):not([size]) {
	background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e');
	padding: 0.375rem 0.75rem;
	border-color: #ced4da;

}

.page-profile .was-validated .form-control:invalid {
	background-image: none;

}

.search-fund-list a {
	background-color: #042b62;
	color: #fff;
	font-weight: 500;
	padding: 5px 20px;
	border-radius: 15px;
}

.addtowish-btn {
	padding-top: 1rem;
}

/* mobile css ------ important ----- */
@media only screen and (max-width: 768px) {

	/* Comapre Mobile */
	/* #Overview {
	 height: 55rem;
	 width: 22rem;
}
 */
	/* #AssetAllo {
	 width: 22rem;
	 margin-top: 2rem;
}
 */
	/* .Sector_All div {
	 padding: 2rem;
	 padding-top: 1rem;
	 width: 21rem !important;
}
 */
	/* .CenterDiv{
	 overflow: hidden !important;
}
 */
	/* .Mobile_Holdings .RangeData {
	 display: flex;
	 margin-top: 10px;
	 justify-content: space-between;
}
 */
	/** FF*/
	/* IE*/
	/* */
	/* */
	/* For Modal */
	/* Used to trick the browser to center the modal content properly */
	/* .Value input {
	 margin-top: 3px;
	 margin-left: 1rem;
}
 */
	/* For MF Detials */
	/* .Table_Compare{
	 display: none;
}
 */
	/* .Sector_All div {
	 padding: 2rem;
	 padding-top: 1rem;
	 width: 19rem;
}
 */
	/* .Mobile_Holdings .RangeData {
	 display: flex;
	 margin-top: 10px;
	 justify-content: space-between;
}
 */
	/* For compare Responsive */


	ul.pagination.justify-content-center {
		font-size: 0.9rem;
	}

	.overviewDetails .colleft1 {
		border-right: 0px;
	}

	.SubCategory_Filter {

		padding-bottom: 1.5rem;
	}

	.SubCategory_Filter .fintoo-checkbox {
		padding-top: 5px;
		padding-bottom: 5px;
	}

	.MenuTop {
		margin-top: 2rem;
	}

	.desktop-view-none {
		display: block !important;
	}

	.stock-details .fixed_top_Nav {
		background-color: #f8f9fa;
		top: 0px !important;
	}

	.profile-container {
		padding-right: 0px;
		padding-left: 0px;
	}

	.LeftSection {
		margin-top: 0 !important;
	}

	.mf-container .Sample .Value {
		font-size: 0.8em;
	}

	.mf-container .item-title-parent {
		width: 80%;
	}

	.mf-container .item-title-parent span {
		font-size: 0.7rem;
	}

	.mf-container .item-title a {
		font-size: 1rem;
	}

	.mf-container .item-title a:hover {
		color: #000;
	}

	.fltr-section .fltr-items .fltr-items-li {
		width: 33.33333%;
	}

	.NavList .navbar-nav {
		position: fixed;
		font-size: 1rem;
		top: 80px;
		right: 0px;
		width: 100%;
		height: 100%;
		background: #fff;
	}

	.NavList .navbar .nav-link {
		margin: 14px;
		text-align: center;
		border: 2px solid transparent;
		border-radius: 1rem;
	}

	.navbar-toggler:focus {
		box-shadow: 0 0 0 0em !important;
		outline: none !important;
	}

	.NavList .navbar-nav .dropdown-menu {
		position: static;
		text-align: center;
		margin-left: 3rem;
		margin-right: 3rem;
	}

	.acc-section {
		padding: 1rem 0;
	}

	.Filter_Btn_panel {
		background: #fff;
		width: 100%;
	}

	#tab_default_5 {
		margin-top: 1.3rem !important;
	}



	.FilterItem {
		max-height: 49vh;
	}

	.Stock_Header1 .react-tabs__tab--selected .header-tab-title {
		border: none;
	}

	.react-responsive-modal-root {
		top: 0px !important;
	}

	.Logo {
		grid-area: logo;
		margin-top: 0rem;
		margin-left: 1rem;
		width: 6em;
	}

	.Header .navbar-nav {
		background: #fff;
	}

	.item-container {
		text-align: center;
	}

	.show-in-desktop {
		display: none;
	}

	.Stock_Compare_List .Funds_Name {
		display: block;
	}

	.items-main-container .item-container .item-inside-bx {
		padding: 0;
	}

	.item-side-options .Sample.fordesktop {
		display: none;
	}

	.in-options-title .formobile {
		display: block;
	}

	.in-options-title .fordesktop {
		display: none;
	}

	.item-side-options .Sample {
		flex-grow: 1;
		padding-left: 5px;
		text-align: left;
	}

	.item-side-options .Sample:first-child {
		width: 30%;
	}

	.item-side-options .MarketDesktop {
		display: none !important;
	}

	.item-list .MarketMobile {
		display: block;
		margin-right: 2.1rem !important;
		margin-top: 0.9em !important;
	}

	.stock-container .item-title-parent span {
		font-size: 0.7em;
	}

	.explore-for-mobile {
		padding-top: 1rem;
	}

	.explore-for-mobile .explore-now {
		float: right;
		font-size: 0.9rem;
		width: 6rem;
	}

	.item-side-options {
		padding-top: 1em;
		padding-bottom: 1em;
		display: flex;
		grid-template-columns: repeat(5, 1fr);
	}

	.stock-container .Sample .in-options-title {
		white-space: nowrap;
		font-size: 0.7em;
	}

	.stock-container .Sample .Value {
		font-size: 0.65em;
		white-space: nowrap;
	}

	.stock-container .item-title a {
		font-size: 0.9em;
	}

	.item-continer-bx {
		padding: 1rem;
	}

	.items-main-container .item-container .comapre-remove-button {
		right: 0;
	}

	.Accordian_Box hr {
		margin: 0;
		margin-bottom: 1em;
	}

	.Stock_Compare_List .Invest_Btn {
		padding-top: 0.5em;
	}

	.Compare_Details #chart button {
		font-size: 0.8em;
	}

	.Funds_Name span {
		font-size: 0.8em;
	}

	.Stock_Compare_List .item-container {
		width: 33.33%;
		display: flex;
		flex-direction: column;
		padding: 2px;
		padding-top: 1rem;
	}

	.compare_dec {
		display: none;
	}

	.Mobile_Stock_search {
		display: block;
	}

	.searchbar-desktop {
		display: none;
	}

	.explore-for-desktop {
		display: none;
	}

	.explore-for-mobile {
		display: block;
	}

	.row-fake-margin {
		margin-top: 0 !important;
	}

	.apexcharts-pie-label,
	.apexcharts-datalabels,
	.apexcharts-datalabel,
	.apexcharts-datalabel-label,
	.apexcharts-datalabel-value {
		cursor: default;
		pointer-events: none;
	}

	.Bu_Now_Mobile {
		display: block;
	}

	.VideoShoot {
		position: relative;
	}

	.Bu_Now_Mobile .Card_Btn {
		background-color: #000;
		bottom: 20px;
		position: fixed;
		width: 100vw;
		height: 3rem;
		color: #042b62;
		padding: 8px;
		font-weight: 900;
		border-radius: 28px;
		/* 
	/* bottom: 10px;
	 */
	}

	.Bu_Now_Mobile .Card_Btn div {
		width: 50%;
		padding-left: 5px;
		padding-right: 5px;
	}

	.Bu_Now_Mobile .Card_Btn button {
		width: 7rem;
		height: 2rem;
		font-weight: bold;
		border-radius: 15px;
		border: 1px solid #fff;
		background-color: #042b62;
		color: #fff;
		width: 100%;
		padding-left: 5px;
		padding-right: 5px;
	}

	.BackProfile {
		width: 19px;
		height: 19px;
		margin-top: -6px;
		margin-left: 11px;
	}

	.classList {
		overflow: clip;
		margin-top: 0rem;
	}

	.TopHoldings .Holding_Details {
		display: none;
	}

	.TopHoldings .MobileTopSectors {
		display: block;
	}

	.Fund_Sub {
		display: none;
	}

	.Mobile_Fund {
		display: block;
	}

	.FundssDetails .Fund_Sub {
		display: none;
	}

	.FundssDetails .Mobile_Fund .fundSub {
		/* display: flex;
	 justify-content: center;
	 align-items: center;
	 place-items: center;
	 */
		background: #f5f5f5;
		border-radius: 15px;
		margin-top: 1rem;
	}

	.FundssDetails .Mobile_Fund .FundType {
		display: grid;
		place-items: center;
	}

	.FundssDetails .Mobile_Fund .FundType p {
		display: flex;
		/* place-items: center;
	 */
		justify-content: center;
		align-items: center;
		width: 8rem;
		margin-top: 1rem;
		background: #d1d1d1;
		border-radius: 15px;
		font-size: 12px;
		font-weight: 500;
		white-space: nowrap;
		text-align: left;
	}

	.FundssDetails .Mobile_Fund .FlexBoxFund {
		display: grid;
		place-items: center;
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: 1fr;
		grid-column-gap: 23px;
		grid-row-gap: 0px;
		font-size: 13px;
		padding: 10px;
		/* margin-left: 13px;
	 */
	}

	.TopSectors {
		display: none !important;
	}

	.MobileTopsectors {
		display: block !important;
	}

	.MobileTopsectors {
		display: block;
	}

	.Potfoliosector {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-column-gap: 23px;
		font-size: 12px;
	}

	.Potfoliosector .portdetail p {
		width: 7rem;
		font-size: 9px !important;
	}

	.Sector_Allocation_List {
		display: none;
	}

	.CompareFundata {
		display: grid;
	}

	.CompareBtn {
		display: grid;
		justify-content: center !important;
		margin-top: 1rem;
	}

	.Seleted_Funds {
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		grid-template-rows: 1fr;
		grid-column-gap: 50px;
		grid-row-gap: 30px;
	}

	.CenterDiv {
		display: flex;
		justify-content: flex-start;
		/* overflow-x: hidden;
	 */
	}

	.HeaderFix img {
		width: 30px !important;
		height: 30px !important;
	}

	.DesktopView {
		display: none;
		margin-right: 100%;
	}

	.MobileView {
		display: block;
		position: fixed;
		bottom: 1rem;
	}

	.mb-float-button {
		margin-top: 2rem;
		display: grid;
		place-items: center;
	}

	.mb-float-button button {
		width: 17rem;
		height: 3rem;
		border: #042b62;
		font-weight: 600;
		color: #fff;
		background-color: #042b62;
		border-radius: 13px;
	}

	.UPIIDSaveText span {
		font-size: 12px;
	}

	.f-Amt .MobileView .mb-float-button {
		margin-top: 0rem !important;
		display: grid;
		place-items: center;
	}

	.FundName {
		margin-top: 0rem;
		margin-left: 0px;
		font-size: 1.2em;
	}

	.fixed_top .FundName {
		margin-top: 0rem;
		margin-left: 10px;
		font-size: 16px;
		width: 19rem;
		letter-spacing: 1.4px;
		/* font-weight: 600;
	 */
	}

	.FundDownText {
		display: flex;
		justify-content: flex-start;
		margin-left: 2.4rem;
		margin-top: 0.5rem;
	}

	.FundDownText .DiffLine {
		padding-left: 10px;
	}

	.FundDownText p {
		font-size: 12px;
		color: gray;
		font-weight: 200;
		letter-spacing: 1px;
	}

	.FundDownText .mobilespace {
		margin-left: 10px;
	}

	.FundDownText img {
		width: 12px !important;
		height: 12px !important;
		margin-left: 10px;
	}

	.FlexBox {
		display: flex;
		justify-content: flex-start;
	}

	.FunSubDetails {
		line-height: 5px;
		margin-top: 0rem;
		margin-left: 0;
	}

	.mobileNav {
		columns: 2 12em;
		/* two columns, each 12em width */
		column-gap: 0em;
		/* spacing between columns */
		-moz-columns: 2 12em;
		/* for Gecko */
		-moz-column-gap: 1em;
		-webkit-columns: 2 12em;
		/* for WebKit */
		-webkit-column-gap: 0em;
		padding-top: 1em;
	}

	.LatestNavText p {
		text-align: left !important;
		margin-left: 0 !important;
		font-size: 16px;
	}

	.ValueFund {
		display: flex;
		justify-content: flex-start;
		text-align: right;
		margin-left: 0;
	}

	.ValueFund img {
		width: 15px !important;
		height: 15px !important;
	}

	.ValueFund .FundPrice {
		font-size: 1.2em;
		font-weight: 600;
	}

	.ValueFund .DiffLine {
		border-right: 1px solid #000;
		height: 16px;
		margin-top: 6px;
		margin: 6px;
	}

	.ValueFund .DiffLine {
		border-right: 1px solid #000;
		height: 16px;
		margin-top: 6px;
		margin: 6px;
	}

	.FundIcon div:first-child {
		padding-right: 10px;
	}

	.FundIcon img {
		width: 15px !important;
		height: 15px !important;
	}

	.sticky .header {
		border-bottom: 1px solid gray;
		/* width: 20.7rem;
	 */
		overflow-x: scroll;
		margin-left: 0;
	}

	.section .MidLine {
		padding: 0 !important;
		height: 8.1rem;
		display: none;
		margin-top: -1.3rem;
		border-right: 1px solid gray;
	}

	.section .overviewDetails {
		background: #fff;
		margin-top: 2rem;
		/* height: 22rem;
	 */
		border-radius: 14px;
		padding: 20px;
		display: grid;
		justify-content: space-between;
	}

	.Cagr .NavVal img {
		width: 9px;
		height: 9px;
		vertical-align: text-top;
		margin-top: 4px;
	}

	.NavSecond {
		margin-left: 0px;
	}

	#chart .toolbar {
		display: flex !important;
	}

	.toolbar div span:first-child {
		font-size: 12px;
	}

	#FundDetails h4 {
		color: gray;
		font-size: 16px;
		font-weight: 600;
		padding-left: 0rem !important;
	}

	.BottomLine {
		/* width: 21rem;
	 */
		height: 1px;
		border-bottom: 1px solid gray;
	}

	#FundDetails .FundDetail {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		/* grid-template-rows: ;
	 */
		grid-column-gap: 0px;
		grid-row-gap: 0px;
		padding: 0px;
	}

	.header .header_link {
		font-size: 12px;
	}

	.header_link {
		padding-bottom: 10px;
	}

	.FundDetail .FundNames {
		font-weight: 600;
		white-space: inherit;
		word-wrap: break-word;
		letter-spacing: 0.6px;
		font-size: 12px;
	}

	.FundDetail img {
		width: 25px !important;
		height: 25px !important;
	}

	.FundDetail .inner-item-details {
		padding-top: 1em;
		padding-bottom: 1em;
		width: 100%;
		overflow: hidden;
	}

	.FundDetail .mobileFund {
		font-size: 14px;
		max-width: 65%;
		text-overflow: ellipsis;
	}

	.FundDetail .FundNames img {
		width: 11px !important;
		height: 11px !important;
	}

	#FundDetails i {
		font-size: 13px !important;
	}

	.Table_compare_Opt {
		display: flex;
		justify-content: flex-start !important;
	}

	.AllocationTabs ul.nav li {
		width: 50%;
		font-weight: 700;
		font-size: 1em;
		padding: 8px;
		list-style: none;
		text-align: center;
		cursor: pointer;
		transition: all 0.7s;
		border-bottom-left-radius: 2rem;
		border-top-left-radius: 2rem;
	}

	.Sector_All input[type="range"] {
		-webkit-appearance: none;
		overflow: hidden !important;
		width: 17rem;
		margin-top: 1rem;
		height: 10px;
		background: grey;
		border-radius: 14px;
		background-repeat: no-repeat;
		background-image: linear-gradient(#042b62, #042b62);
	}

	.Other_Detail {
		display: flex;
		justify-content: flex-start !important;
	}

	.Vertical_Line {
		padding-left: 4px;
	}

	.Other_First {
		padding-left: 10px;
	}

	.Mobile_Holdings {
		/* border-top: 1px solid #d1d1d1;
	 margin-top: -1rem;
	 */
		display: block;
	}

	.Mobile_Holdings input[type="range"] {
		-webkit-appearance: none;
		overflow: hidden !important;
		/* width: 17rem;
	 */
		margin-top: 1rem;
		height: 10px;
		background: grey;
		border-radius: 14px;
		background-repeat: no-repeat;
		background-image: none;
	}

	.Mobile_Holdings input[type="range"]::-webkit-slider-thumb {
		-webkit-appearance: none;
		height: 13px;
		margin-top: -2px;
		width: 13px;
		border-radius: 50%;
		background: #d1d1d1;
		cursor: pointer;
		/* box-shadow: #fff 0px 0px 0px 11px;
	 */
		transition: background 0.3s ease-in-out;
	}

	.fixed_top {
		height: 19.5rem;
	}

	.sticky .header {
		border-bottom: none !important;
	}

	.fixed_top .ValueFund {
		display: flex;
		/* justify-content: space-around;
	 */
		text-align: right;
		margin-left: 2.5rem;
	}

	.fixed_top .FundIcon {
		display: flex !important;
		justify-content: flex-end !important;
		margin-left: 6rem;
		padding-left: 10px;
	}

	.fixed_top .FundIcon div:first-child {
		padding-right: 10px;
	}

	.sticky {
		/* position: sticky !important;
	 top: 19.5rem !important;
	 */
		left: 0;
		right: 0;
		z-index: 10;
		/* background: #000;
	 */
		color: #fff;
	}

	input[type="range"]::-moz-range-progress {
		background-color: #042b62;
	}

	input[type="range"]::-moz-range-track {
		background-color: #9a905d;
	}

	input[type="range"]::-ms-fill-lower {
		background-color: #042b62;
	}

	input[type="range"]::-ms-fill-upper {
		background-color: #9a905d;
	}

	.mf_details {
		display: block;
		grid-template-columns: 1fr;
		grid-template-rows: repeat(2, 1fr);
		grid-column-gap: 0px;
		grid-row-gap: 0px;
	}

	modal {
		animation-fill-mode: forwards !important;
	}

	.Stock_Header1 {
		background-color: #042b62;
		padding: 1rem;
		padding-top: 27px !important;
		height: 6rem;
		border-radius: 15px;
		color: #000;
		/* margin-top: 3rem; */
	}

	.Stock_Details {
		margin-top: 2rem;
	}

	.Stock_Img .imgCExplore {
		display: flex !important;
	}

	.Stock_Header1 .react-tabs__tab-list {
		overflow-x: scroll;
	}

	.Stock_Header1 .react-tabs__tab--selected {
		border: none !important;
	}

	.Stock_Header1 .TabHeader:first-child {
		padding-left: 0rem !important;
		width: 5rem;
	}

	.Stock_Details .Path {
		width: 100%;
		height: 100%;
		margin-top: 0rem;
		margin-right: 0rem;
		font-size: 13px;
		display: flex;
		justify-content: flex-end;
		padding-right: 1rem;
	}

	.Stock_List_Table {
		background-color: #fff;
		margin-top: 1rem;
		display: flex;
		align-items: flex-start;
		width: 100%;
		justify-content: center;
		flex-direction: column;
		padding: 1rem;
		border-radius: 15px;
	}

	.bottom_text {
		font-size: 15px;
		font-weight: 400;
		margin-top: -0.5rem;
		margin-left: 0rem;
		color: #878a89;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: 1fr;
		grid-column-gap: 20px;
		grid-row-gap: 0px;
	}

	.bottom_text span:first-child {
		width: 10rem;
	}

	.bottom_text img {
		vertical-align: top;
		padding-top: 0.1rem;
	}

	.Stock_Search {
		background-color: #fff;
		border-radius: 15px;
		/* margin-top: -1.5rem;
	 */
		padding: 1rem;
		padding-top: 1.5rem;
		height: 4rem;
	}

	.SooList .Stock_Img {
		margin-left: -0.5rem;
	}

	.Stock_Img img {
		width: 40px;
		height: 40px;
	}

	.SooList a {
		font-size: 17px;
		font-weight: 600;
		vertical-align: middle;
	}

	.Path_Home {
		width: 10px;
		height: 10px;
	}

	.Path_Arrow {
		width: 10px;
		height: 10px;
	}

	.All_Stocks {
		display: none;
	}

	.Stock_Header1 {
		padding: 0.3rem;
		width: 100%;
	}

	::-webkit-scrollbar {
		display: none;
		/* Safari and Chrome */
	}

	.RightSide .react-tabs__tab--selected {
		font-size: 11px !important;
		border: none !important;
	}

	.imgC img {
		display: none;
	}

	.All_Stocks {
		margin-top: 0rem;
	}

	.Mobile_Stock_search h4 {
		font-size: 15px;
		font-weight: 800;
	}

	.Search_Filter_Mobile {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: 1fr;
		grid-column-gap: 0px;
		grid-row-gap: 0px;
	}

	.Search_Filter_Mobile .Search_Field img {
		float: left;
	}

	.Search_Filter_Mobile .Filter_Field img {
		float: right;
		right: 0;
	}

	.Search_Modal {
		width: 100%;
		height: 100%;
	}

	.react-responsive-modal-root {
		position: fixed;
		width: 100%;
		height: 100%;
		/* bottom: 0;
	 */
		/* left: 0;
	 */
		/* right: 0;
	 */
		z-index: 10000000000;
	}

	.react-responsive-modal-overlay {
		background: rgba(0, 0, 0, 0.5);
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: -1;
	}

	.react-responsive-modal-container {
		height: 100%;
		outline: 0;
		overflow-x: hidden;
		overflow-y: auto;
		text-align: center;
	}

	.react-responsive-modal-containerCenter:after {
		width: 0;
		height: 100%;
		content: "";
		display: inline-block;
		vertical-align: middle;
	}

	.react-responsive-modal-modal {
		max-width: 800px;
		height: 100%;
		display: inline-block;
		text-align: left;
		vertical-align: middle;
		background: #fff;
		box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
		margin: 0rem !important;
		padding: 3rem;
		position: relative;
		overflow-y: auto;
		/* border-radius: 15px;
	 */
		width: 100%;
	}

	.react-responsive-modal-closeButton {
		position: absolute;
		top: 3px !important;
		right: 14px;
		height: 14px;
		width: 14px;
		border: none;
		padding: 0;
		cursor: pointer;
		background-color: transparent;
		display: flex;
	}

	@keyframes react-responsive-modal-overlay-in {
		0% {
			opacity: 0;
		}

		100% {
			opacity: 1;
		}
	}

	@keyframes react-responsive-modal-overlay-out {
		0% {
			opacity: 1;
		}

		100% {
			opacity: 0;
		}
	}

	@keyframes react-responsive-modal-modal-in {
		0% {
			transform: scale(0.96);
			opacity: 0;
		}

		100% {
			transform: scale(100%);
			opacity: 1;
		}
	}

	@keyframes react-responsive-modal-modal-out {
		0% {
			transform: scale(100%);
			opacity: 1;
		}

		100% {
			transform: scale(0.96);
			opacity: 0;
		}
	}

	.react-responsive-modal-modal input[type="search"] {
		outline: none;
		border: hidden;
		border-bottom: 1px solid #d1d1d1;
		width: 100%;
		font-size: 13px;
		height: 3rem;
	}

	.react-responsive-modal-modal input[type="search"]::placeholder {
		font-size: 13px;
		padding: 5px;
	}

	.react-responsive-modal-modal .mostpop {
		font-size: 13px;
		color: #abb2ba;
		padding-bottom: 0px;
	}

	.react-responsive-modal-modal .Top_Stock_List {
		width: 100%;
		position: relative;
	}

	.Top_Stock_List div:first-child {
		width: 75%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		color: #000;
		font-size: 15px;
	}

	.react-responsive-modal-modal .Top_Stock_List p {
		font-size: 15px;
		/* width: 75%;
	 */
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		color: #000;
		padding-bottom: 16px;
	}

	.Top_Stock_List {
		display: flex;
		justify-content: space-between;
	}

	.Fund_Opt {
		display: flex;
		justify-content: space-between;
		color: #aab1b9;
		font-size: 15px;
	}

	.Filter_Field .Fund_Opt input[type="checkbox"] {
		width: 10px !important;
		height: 10px !important;
		outline: none;
	}

	.Fund_Opt_SubC {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: repeat(3, 1fr);
		grid-column-gap: 10px;
		grid-row-gap: 0px;
		color: #aab1b9;
		font-size: 15px;
	}

	.Filter_Button {
		display: flex;
	}

	.Filter_Button button:first-child {
		width: 6rem;
		height: 2rem;
		/* margin-left: 1rem;
	 */
		margin-top: 0.5rem;
		border-radius: 15px;
		text-align: center;
		background-color: #042b62;
		border: 1px solid #042b62;
		color: #fff;
		font-weight: bold;
	}

	.Filter_Button button:nth-child(2) {
		width: 6rem;
		height: 2rem;
		margin-left: 1rem;
		font-weight: bold;
		margin-top: 0.5rem;
		border-radius: 15px;
		text-align: center;
		background-color: #c0c3c2 !important;
		border: 1px solid #c0c3c2;
		color: gray;
	}

	.StList button {
		display: none;
	}

	.SooList {
		display: flex;
		justify-content: space-between;
	}

	.Stock_Img .imgCExplore {
		width: 20px;
		height: 20px;
		margin-top: 1rem;
	}

	.SoTList {
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		grid-template-rows: 1fr;
		grid-column-gap: 0px;
		grid-row-gap: 0px;
		font-size: 12px;
		margin-top: 1rem;
	}

	.Sample img {
		width: 10px !important;
		height: 10px !important;
	}

	.TabHeader {
		display: flex;
		justify-content: space-between;
		/* align-items: center;
	 */
		outline: none;
	}

	.TabHeader div:nth-child(2) {
		width: 6rem;
		font-weight: 500;
		font-size: 14px;
		text-align: center;
		font-weight: 600;
	}

	.Stock_Details .Path div {
		padding-right: 0.4rem;
		/* font-size: 13px;
	 */
	}

	.makeStyles-root-1 {
		padding-left: 0rem !important;
	}

	.fixed_top {
		position: fixed;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 26.5rem !important;
		justify-content: space-around;
		top: 56px;
		left: 0;
		width: 1007px;
		z-index: 10000;
		/* left: 5rem;
	 */
		box-shadow: 0 2px 24px 0 #000;
		background-color: #f7f7f7 !important;
		animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
		padding: 1rem;
	}

	.mf_header {
		/* margin-top: 6rem;
	 */
		display: block;
		height: 11rem;
	}

	.Share {
		position: relative;
		top: -168px;
		left: 225px;
	}

	.Rupee {
		width: 15px;
		height: 15px;
		position: relative;
		top: 0rem;
		left: 0rem;
	}

	.latest_value {
		font-size: 15px;
		margin-left: 0rem;
		font-weight: 700;
	}

	.latest {
		float: none;
		font-size: 14px;
		font-weight: 500;
	}

	.Percent_Val {
		float: none;
		color: green;
		font-size: 14px;
		padding-left: 1rem;
	}

	.fixed_top {
		display: grid;
		justify-content: space-between;
		/* margin-top: 10rem;
	 */
	}

	.linkShare {
		display: inline;
	}

	.linkShare span:nth-child(2) {
		padding-left: 1rem;
	}

	.mf_details .tab {
		margin-top: 0rem;
		width: 24.2rem;
	}

	.Mf_Name {
		margin-left: 0rem;
	}

	.mf_subdetails .Mf_Details_info {
		display: flex;
		color: gray;
		font-size: 12px;
		font-weight: 300;
		padding-left: 0rem;
		padding-top: 1rem;
	}

	.mf_subdetails .Line {
		border-right: 1px solid #c0c3c2;
		margin-top: 0rem !important;
		margin-left: 10px;
		height: 12px !important;
		margin-right: 0.5rem;
		margin-bottom: 1rem;
	}

	.Mf_Name h4 {
		font-size: 12px;
		font-weight: 900;
		position: relative;
		margin-top: -2.1rem;
		margin-left: 3rem;
	}

	.MfNameNew {
		word-break: normal;
		width: 10rem;
	}

	.Mf_Name .stock_icon {
		width: 40px;
		height: 40px;
		background: #fff;
		border-radius: 50%;
	}

	.mf_subdetails {
		display: inline-block;
		justify-content: space-evenly;
		/* text-align: center;
	 */
		/* padding-left: -1rem;
	 */
		/* width:300px;
	 */
		/* border-right: 1px solid #d1d1d1;
	 */
	}

	.Mf_extraInfo {
		display: flex;
		margin-top: 0rem;
		margin-left: 0rem;
		height: 5rem;
	}

	.Mf_extraInfo {
		font-size: 11px;
	}

	.Mf_Price_Value span:nth-child(2) {
		font-size: 13px;
		font-weight: bold;
	}

	.RightSide .path {
		display: none;
	}

	.RightSide {
		display: none;
	}

	.overview {
		display: grid;
		justify-content: space-between;
		padding: 1rem;
		/* width: 355px;
	 */
		height: 18rem;
		/* padding-top: 0rem;
	 */
	}

	.navTrnd {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(2, 1fr);
		grid-column-gap: 0px;
		grid-row-gap: 0px;
		font-size: 12px;
	}

	.mf_details {
		margin-top: 0rem;
	}

	.MuiTab-wrapper {
		/* text-transform: lowercase !important;
	 */
		color: #a9a9a9;
		font-size: 11px !important;
		font-weight: 600;
	}

	.MuiTabs-flexContainer {
		display: flex;
		justify-content: initial !important;
	}

	.MuiTypography-body1 {
		padding: 0.5rem !important;
	}

	.OPanel p {
		font-size: 12px;
	}

	.toolbar {
		display: grid;
	}

	.toolbar div:first-child {
		font-size: 13px;
	}

	.toolbar div:nth-child(2) {
		padding-top: 1rem;
	}

	.Fund_subDetails {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: 1fr;
		grid-column-gap: 0px;
		grid-row-gap: 0px;
	}

	.Table_Compare .header_NM {
		font-size: 16px;
		color: gray;
		font-weight: 900;
		padding-left: 0rem !important;
	}

	.Fund_subDetails .Names span:nth-child(2) {
		font-size: 12px;
		font-weight: 600;
	}

	.Fund_subDetails .Rupee {
		width: 10px;
		height: 10px;
		vertical-align: inherit;
		position: relative;
		/* top: 0.1rem;
	 */
		left: 0rem;
	}

	.Compare {
		margin-top: 0rem !important;
	}

	.Table_Compare button {
		width: 7rem;
		height: 1.7rem;
		margin-left: 1.4rem;
		/* padding: 3px;
	 */
		background-color: #042b62;
		color: #fff;
		font-size: 0.9em;
		border-radius: 15px;
		font-weight: 900;
		border: 2px solid #fff;
		cursor: pointer;
	}

	#DMF_table {
		margin-top: 2rem;
		font-size: 12px;
		display: none;
	}

	.Table_Compare p {
		display: none;
	}

	.Mobile_Compare_Opt .Mobile_Table {
		position: relative;
		display: flex;
	}

	.Mobile_Table th,
	tr,
	td {
		border-color: inherit;
		/* border-style:initial !important;
	 */
		border-width: 1px !important;
	}

	.Mobile_Table th,
	td {
		padding: 8px 8px;
		border: 1px solid #9a9a9a;
		font-size: 13px;
	}

	.Mobile_Table .table-center {
		overflow-x: scroll;
	}

	.Mobile_Table .table-center::-webkit-scrollbar {
		display: inline !important;
		width: 5px;
		height: 2px;
		background-color: #aaa;
	}

	.Mobile_Table .table-center::-webkit-scrollbar-thumb {
		background: #000;
	}

	.Mobile_Table .tablr_Head th {
		padding-left: 0rem !important;
		position: static;
		border: none;
		background: white;
		text-align: center;
		font-size: 13px;
		white-space: pre;
	}

	.table-center {
		-webkit-overflow-scrolling: touch;
	}

	.table-center td {
		text-align: center;
		font-size: 13px;
		white-space: nowrap;
	}

	.Mobile_Table .table-left table th {
		border-top-left-radius: 30px;
		border-bottom-left-radius: 30px;
		background: white;
		font-size: 13px;
		white-space: pre;
	}

	.tablr_Head th {
		background: #fff;
	}

	.Mobile_Table .tablr_Head th:last-child {
		border-top-right-radius: 30px;
		border-bottom-right-radius: 30px;
		height: 1rem;
	}

	.Mobile_Table .tablr_Head td {
		font-size: 13px;
	}

	.Allocation .TabData {
		width: 100%;
		/* height: 400px;
	 */
		border-radius: 20px;
		margin-top: 2rem !important;
		border: none;
		background: none !important;
	}

	.header_NM {
		font-size: 15px;
		color: gray;
		font-weight: 900 !important;
		padding-left: 0rem !important;
	}

	.TabData h4 {
		font-size: 15px;
		font-weight: 900;
		color: gray;
	}

	.bloc-tabs .tabs {
		padding: 15px;
		text-align: center;
		width: 50%;
		background: rgba(128, 128, 128, 0.075);
		/* border-top-left-radius: 20px;
	 */
		font-size: 12px;
		font-weight: 900;
		color: #969696;
		cursor: pointer;
		border-bottom: 1px solid #9dd0e7;
		box-sizing: content-box;
		position: relative;
		outline: none;
	}

	.Sector_All {
		display: block;
		grid-template-columns: repeat(1, 1fr);
		grid-template-rows: repeat(1, 1fr);
		grid-column-gap: 0px;
		grid-row-gap: 0px;
		margin-top: 0rem;
	}

	.Table_Hold {
		display: none;
	}

	.Checkall {
		display: none;
	}

	.Mobile_Holdings {
		/* border-top: 1px solid #d1d1d1;
	 */
		/* margin-top: -1rem;
	 */
		display: block;
	}

	.Mobile_Holdings .RangeData div:nth-child(2) {
		/* display: flex;
	 */
		margin-top: 10px;
		/* justify-content: space-between;
	 */
	}

	.Mobile_Holdings div {
		font-size: 13px;
		font-weight: 500;
	}

	.check {
		color: #fff;
		font-size: 13px;
		border: 2px solid #fff;
		border-radius: 15px;
		font-weight: 600;
		height: 2rem;
		text-align: center;
		width: 8rem;
		background-color: #042b62;
	}

	.Risk_mesures .Table_Hold {
		display: block !important;
	}

	.Risk_mesures table th {
		text-align: center;
		background-color: none !important;
		color: Gray;
		font-size: 11px;
		padding-bottom: 1rem;
		border-bottom: 1px solid #d1d1d1 !important;
	}

	.Risk_mesures table th:first-child {
		padding-left: 0.4rem;
		text-align: left;
		font-size: 13px;
		font-weight: 600;
		width: 18rem;
	}

	.Risk_mesures td {
		/* padding: 1rem;
	 */
		width: 12rem;
		font-size: 10px;
		text-align: center;
		height: 20px;
		padding-bottom: 0.5rem;
	}

	.Risk_mesures td:first-child {
		color: #042b62;
		font-size: 12px;
		font-weight: bold;
		/* padding: 2rem;
	 */
		padding-top: 10px;
		text-align: left;
		width: 20rem;
	}

	.Risk_Measure_List {
		margin-top: 1rem !important;
	}

	.Risk_Measure_List h4 {
		font-weight: 600;
		color: gray;
		font-size: 17px;
	}

	.Risk_Measure_List table {
		border: none !important;
		margin-top: 1.8rem;
	}

	.Risk_Measure table,
	tr,
	td,
	th {
		border: none !important;
	}

	.Risk_Measure th:first-child {
		font-size: 13px;
		color: gray;
		font-weight: 900;
		text-align: left;
	}

	.Risk_Measure th {
		border-bottom: 1px solid #d1d1d1;
		padding-bottom: 1rem;
		font-size: 13px;
	}

	.Risk_Measure td:first-child {
		color: #042b62;
		font-size: 13px;
		font-weight: bold;
		/* padding: 2rem;
	 */
		text-align: left;
	}

	.Risk_Measure td {
		padding: 0.5rem;
		padding-left: 0rem;
		font-size: 12px;
		/* text-align: center;
	 */
		height: 20px;
		/* border-bottom: 1px solid #d8dbda;
	 */
		/* color: grey;
	 */
	}

	.for_Mob_Share {
		padding-left: 1rem;
	}

	.Desktop_Share {
		display: none;
	}

	.Mobile_Space {
		padding-top: 1.2rem;
	}

	.OthersPanel h4 {
		color: gray;
		font-weight: 900;
	}

	.Other_Detail {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: 1fr;
		grid-column-gap: 0px !important;
		grid-row-gap: 0px;
		color: gray;
		width: 15rem;
		margin-left: 0rem;
		color: gray;
	}

	.OthersPanel img {
		width: 30px;
		height: 30px;
	}

	.OthersPanel .Nm {
		padding-left: 1em;
		color: #000;
		font-size: 1.2em;
		font-weight: 900;
	}

	.Other_First span:first-child {
		font-size: 12px;
	}

	.OthersPanel .Other_First span:nth-child(2) {
		font-size: 13px;
		font-weight: 900;
	}

	.diff {
		overflow: hidden;
	}

	.UserNM {
		color: #000;
		font-size: 11px;
		font-weight: 900;
	}

	.mobile_Button {
		display: flex;
		justify-content: space-around;
		background-color: #000;
		bottom: 14px;
		position: fixed;
		width: 98%;
		height: 3rem;
		color: #fff;
		margin-top: 5rem;
		border-radius: 28px;
		margin-left: 5px;
		margin-right: 5px;
	}

	.for_Mob_Share {
		display: block;
	}

	.mobile_Button button:first-child {
		bottom: 1.4rem;
		width: 165px;
		border-radius: 15px;
		position: fixed;
		background-color: #042b62;
		color: #fff;
		left: 1rem;
		height: 2rem;
		border: none;
		font-size: 18px;
		font-weight: normal;
		margin-top: 0rem;
		outline: none;
	}

	.mobile_Button button:nth-child(2) {
		bottom: 1.4rem;
		left: 13rem;
		border-radius: 15px;
		width: 165px;
		/* left: 50%;
	 */
		position: fixed;
		background-color: #fff;
		color: gray;
		height: 2rem;
		outline: none;
		border: none;
		font-size: 18px;
		font-weight: normal;
		margin-top: 0rem;
	}

	.Compare_Details {
		/* width: 95%;
	 */
		/* overflow: hidden !important;
	 */
		margin-top: 0rem;
		/* display: grid;
	 */
		/* place-items: center;
	 */
		/* margin-left: 10px;
	 */
		margin-top: 5rem;
	}

	.Compare_Details h4,
	h5 {
		font-size: 14px !important;
	}

	.Header_Compare {
		background-color: #fcfcfc;
		width: 100%;
		border-top-left-radius: 20px;
		border-top-right-radius: 20px;
	}

	.Header_Compare .Header_Name {
		display: block;
		padding: 1rem;
		border-bottom: 1px solid #d1d1d1 !important;
	}

	.Header_Name h4 {
		font-size: 14px;
	}

	.Header_Compare .Header_Name input[type="search"] {
		outline: none;
		/* width: 21rem;
	 */
		height: 2rem;
		padding: 0.8rem;
		padding-top: 0.8rem;
		border-radius: 15px;
		font-size: 14px;
		border: 1px solid #d1d1d1;
	}

	.Header_Compare .Header_Name input[type="search"]::placeholder {
		font-size: 10px;
		padding-top: 0.5rem;
	}

	.Stock_Compare_List h4 {
		font-size: 14px;
	}

	.Stock_Compare_List div {
		border: none;
	}

	.Stock_Compare_List .compare_dec {
		display: none;
	}

	.Stock_Compare_List p {
		font-size: 12px;
		color: gray;
		font-size: 12px;
		padding-top: 0rem;
		font-weight: 400;
	}

	.Stock_Compare_List div {
		border: none;
		/* padding: 1rem;
	 */
		/* border: none;
	 */
		/* width: 20rem !important;
	 */
	}

	.Stock_Compare_List .items-main-container {
		width: 100% !important;
	}

	.comapre-remove-button {
		top: 0px !important;
	}

	.Stock_Compare_List .Funds_Name {
		border: none;
		/* display: flex;
	 */
		/* margin-top: -2rem;
	 */
		/* height: 6rem;
	 */
		color: #000;
		font-weight: 800;
		padding-bottom: 0.5em;
	}

	.Stock_Compare_List .Fund_Options {
		border: none !important;
		/* display: flex;
	 */
		/* justify-content: space-between;
	 */
		/* margin-left: -3rem;
	 */
		color: gray;
		/* padding-left: 60px;
	 */
		/* font-weight: 600;
	 */
		font-size: 12px;
	}

	.Stock_Compare_List .Fund_Options .Line {
		border-right: 1px solid #c0c3c2;
		margin-top: 0.2rem !important;
		height: 0.8rem;
	}

	.Close_Fund {
		cursor: pointer;
		font-size: 10px;
	}

	.CartSelectSIP .paymentgatewayoption .select-bank .fintoo-checkbox img {
		width: 16px;
		height: 15px;
		margin-top: 0.5em;
	}

	.NeftBox img {
		margin-top: 0.5em;
	}

	.desktopspace {
		margin-left: 0em;
	}

	.NEFTMobile {
		display: block;
	}

	.NEFTMobile .paymentgatewayoption {
		padding: 1rem;
	}

	.NEFTMobile .paymentgatewayoption .MobileFlexNEFT {
		display: flex;
	}

	.NEFTMobile .paymentgatewayoption .select-bank {
		margin-top: 0em;
	}

	.NEFTMobile .paymentgatewayoption .select-bank .fintoo-checkbox img {
		width: 16px;
		height: 15px;
		margin-top: 0.2em;
	}

	.NEFTMobile .paymentgatewayoption .AccountDetails {
		margin-top: 0.1em;
		margin-left: 1.5em;
	}

	.NEFTMobile .paymentgatewayoption .AddManadateLabel {
		margin-left: 3em;
	}

	.NeftBox .BankLogo img {
		width: 26px;
		height: 26px;
		border: 1px solid #a3a3a3;
		border-radius: 50%;
		margin-top: 0em;
	}

	.Stock_Compare_List .Invest_Btn button {
		width: 90%;
		height: 2rem;
		font-size: 12px;
		border-radius: 25px;
		padding: 1px;
		font-weight: 900;
		border: 2px solid #fff;
		color: #fff;
		background-color: #042b62;
	}

	.AllComapareDetails {
		background-color: #fff;
		width: 100%;
		/* margin-top: -1rem;
	 */
		padding: 10px;
		border-top-left-radius: 0px;
		border-top-right-radius: 0px;
	}

	.Funds_Name div span:nth-child(2) {
		font-size: 12px;
	}

	.Funds_Name img {
		width: 40px;
		height: 40px;
	}

	.NavTrend #chart .toolbar {
		display: flex !important;
		justify-content: flex-end;
	}

	.Asset_Allocation .GraphAllocation {
		/* display: grid;
	 */
		/* display: grid;
	 grid-template-columns: repeat(0, 1fr) !important;
	 grid-template-rows: 1fr;
	 grid-column-gap: 0px;
	 grid-row-gap: 0px;
	 */
		margin-top: 0rem;
	}

	.Asset_Allocation p {
		font-size: 14px !important;
	}

	.PortFolio p {
		font-size: 14px !important;
	}

	.Sector_Allocation p {
		font-size: 14px !important;
	}

	.TopHoldings p {
		font-size: 14px !important;
	}

	.Asset_Allocation .GraphAllocation .DivGraph {
		border: none;
	}

	.Asset_Allocation .GraphAllocation .borderGraph {
		border: none;
		margin-left: 0rem;
	}

	.MobileGraphAllocation .borderGraph {
		width: 33.33%;
	}

	.title-bottom p {
		flex-grow: 0;
	}

	.details-section .section {
		padding-top: 0;
	}

	.Cagr div p {
		font-size: 0.8em !important;
	}

	.title-bottom-bx:last-child {
		margin-top: 1em;
	}

	.footer .social-media-links {
		justify-content: flex-start;
	}



	.footer .footer-heading {
		font-size: 16px;
		margin-top: 1rem;
	}

	.footer .logo {
		margin-bottom: 20px;
		text-align: left;
	}

	.footer li,
	p {
		font-size: 14px;
	}

	.page-header,
	h2 {
		line-height: 32px;
		font-size: 26px;
	}

	.footer .footer-navigation li a:hover {
		color: #10d0f8;
	}

	.Transaction {
		padding-top: 0rem;
	}

	.InvestFundbox .cart-details .cart-data {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-column-gap: 0px;
		grid-row-gap: 0px;
	}

	.InvestFundbox .cart-details img {
		margin-top: 153px;
	}

	.SelectedBank {
		display: flex;
	}

	.InvestSelectBank .bank-details {
		display: flex;
	}

	.InvestSelectBank .bank-details .bank-data .bank-data-title {
		padding-left: 0rem;
		line-height: 3px;
		margin-top: 0.4em;
	}

	.InvestSelectBank .bank-details .bank-data .bank_nm {
		padding-left: 0.6rem;
	}

	.InvestSelectBank .bank-details .next-page {
		width: 15px;
		height: 15px;
		margin-top: -2rem;
		float: right;
		margin-right: 2rem;
	}

	.InvestSelectBank {
		padding: 1rem;
	}

	.InvestSelectBank .BankLogo img {
		width: 30px;
		height: 30px;
		margin-top: 0.6rem;
		border: 1px solid #a3a3a3;
		border-radius: 50%;
	}

	.InvestSelectBank .AccountNo p {
		white-space: nowrap;
		color: #000;
		font-size: 13px;
		font-weight: 500;
	}

	.InvestSelectBank .BankName p {
		white-space: nowrap;
		color: #000;
		font-size: 13px;
		font-weight: 500;
	}

	.InvestSelectBank .BranchName p {
		white-space: nowrap;
		color: #000;
		font-size: 13px;
		font-weight: 500;
	}

	.pay-type {
		display: grid;
	}

	.pay-type .payement-option .pay-details {
		display: flex;
	}

	.pay-type .payement-option .pay-details .PayOptionInfo {
		text-align: justify;
		letter-spacing: 1px;
	}

	.pay-type .payement-option .pay-details .AutoPayOPtion img {
		margin-top: 2rem;
	}

	.pay-type .payement-option .pay-details .next-page {
		margin-top: 3rem !important;
		margin-right: 0rem;
	}

	.footer .logo {
		margin-bottom: 20px;
		text-align: left;
	}

	.footer li,
	p {
		font-size: 14px;
	}

	.page-header,
	h2 {
		line-height: 32px;
		font-size: 26px;
	}

	.footer .footer-navigation li a:hover {
		color: #10d0f8;
	}

	.Transaction {
		padding-top: 0.2rem;
	}

	.Transaction .mobile-space {
		padding-left: 0rem !important;
	}

	.InvestFundbox .cart-details .cart-data {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-column-gap: 0px;
		grid-row-gap: 0px;
	}

	.InvestFundbox .cart-details img {
		margin-top: 153px;
	}

	.SelectedBank {
		display: grid;
	}

	.UPIData {
		margin-top: 0.8rem !important;
	}

	.InvestSelectBank .InvestfundHeading {
		margin-top: 0.5em;
	}

	.InvestSelectBank .bank-details .Phone-next {
		margin-top: 1.5rem;
		font-size: 16px !important;
	}

	.InvestSelectBank .mobileBankBranch {
		display: none;
	}

	.InvestSelectBank .mobileBankName {
		display: none;
	}

	.InvestSelectBank .bank-details {
		justify-content: space-between;
	}

	.InvestSelectBank .bank-details .bank-data {
		flex-direction: column;
	}

	.InvestSelectBank .bank-details .bank-data .MobilebankNm {
		padding-top: 2em;
		padding-left: 0.9em;
	}

	.InvestSelectBank .bank-details .bank-data .bank_ac_no {
		line-height: 17px;
		padding-left: 3.5em;
		margin-top: -1.3em;
	}

	.InvestSelectBank .bank-details .mobile-next {
		margin-top: 0.5rem;
		margin-left: -1em;
	}

	.InvestSelectBank .bank-details .next-page {
		width: 15px;
		height: 15px;
		margin-top: -2rem;
		float: right;
		margin-right: 2rem;
	}

	.InvestSelectBank {
		padding: 0rem;
	}

	.InvestSelectBank .mobileViewBank {
		display: none;
	}

	.InvestSelectBank .BankLogo img {
		width: 30px;
		height: 30px;
		margin-top: 0.6rem;
		border: 1px solid #a3a3a3;
		border-radius: 50%;
	}

	.InvestSelectBank .AccountNo p {
		white-space: nowrap;
		color: #000;
		font-size: 13px;
		font-weight: 500;
	}

	.InvestSelectBank .BankName p {
		white-space: nowrap;
		color: #000;
		font-size: 13px;
		font-weight: 500;
	}

	.InvestSelectBank .BranchName p {
		white-space: nowrap;
		color: #000;
		font-size: 13px;
		font-weight: 500;
	}

	.pay-type {
		display: grid;
	}

	.pay-type .payement-option .pay-details {
		display: flex;
	}

	.pay-type .payement-option .pay-details .PayOptionInfo {
		text-align: left;
	}

	.pay-type .payement-option .pay-details .AutoPayOPtion img {
		margin-top: 1.2rem;
	}

	.pay-type .payement-option .pay-details .next-page {
		margin-top: 3rem !important;
		margin-right: 0rem;
	}

	.PaymentLayout {
		margin-top: 2rem;
	}

	.PaymentLayout .payment-box {
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.PaymentLayout .payment-box .add-bank-new {
		margin-top: 0.4em;
	}

	.select-bank {
		margin-top: 0.5rem;
	}

	.Transaction .MainPanel {
		margin-top: 1rem;
	}

	.Transaction .MainPanel .text-label {
		margin-top: 0em;
		margin-left: 2em;
		line-height: 14px;
	}

	.Transaction .MainPanel .text-label h4 {
		font-size: 1.1em;
	}

	.Transaction .MainPanel .text-label .PaymentText {
		font-size: 0.8em;
		color: #a0a0a0;
		font-weight: 500;
	}

	.CartSelectSIP h4 {
		font-size: 1em;
	}

	.mandate .bank-title .AccountNo {
		font-size: 0.7em !important;
	}

	.ErrorBtn {
		display: flex !important;
	}

	.benificiary-details .benificiary-data .benificiary-title {
		padding: 0.5em;
		color: #a3a3a3;
		font-size: 0.7em;
		font-weight: 500;
		width: 50%;
		line-height: 32px;
	}

	.benificiary-details .benificiary-data .benificiary-data-text {
		color: #000;
		font-size: 0.6em;
		font-weight: 500;
		text-align: left;
	}

	.Res-modal .continue-btn {
		width: 9rem;
		font-size: 0.7em;
		font-weight: 500;
		height: 2.3rem;
		border-radius: 30px;
		margin-left: 1rem;
	}

	.PaymentSuccess {
		margin: auto;
		margin-top: 9rem;
		width: 90%;
		padding: 10px;
		margin-bottom: 5rem;
		background-color: #fff;
		border-radius: 12px;
		max-width: 500px;
	}


}

@media only screen and (max-width: 768px) and (-webkit-min-device-pixel-ratio: 0) {
	input[type="range"] {
		overflow: hidden;
		width: 15rem;
		-webkit-appearance: none;
		background-color: #d1d1d1;
		border-radius: 15px;
	}

	input[type="range"]::-webkit-slider-runnable-track {
		height: 10px;
		-webkit-appearance: none;
		color: #042b62;
		margin-top: -1px;
	}

	input[type="range"]::-webkit-slider-thumb {
		width: 0px;
		-webkit-appearance: none;
		height: 0px;
		cursor: ew-resize;
		background: #434343;
		box-shadow: -80px 0 0 80px #042b62;
	}


}

@media screen and (max-width: 1024px) {
	.navbar-nav {
		font-size: 1rem;
	}

	.navbar {
		z-index: 1000;
	}

	.react-responsive-modal-modal {
		margin: 0 !important;
		width: 100% !important;
		max-width: 100% !important;
	}


}

.AmountSet {
	font-size: 1em;
	font-weight: 500;
	color: #737373;

}

#header-menu .Portfolio-Header {
	display: flex;
	justify-content: space-between;
	margin: 1rem;

}

#header-menu .Portfolio-Header .navbar {
	background: transparent !important;
	box-shadow: none !important;
	position: inherit !important;

}

#header-menu .Portfolio-Header .navbar .ProfileName {
	color: #000;
	font-weight: 500;
	cursor: pointer;

}

#header-menu .Portfolio-Header .navbar .nav-link {
	margin: 6px;
	text-align: center;
	border: 2px solid transparent;
	border-radius: 1rem;

}

#header-menu .Portfolio-Header .navbar .nav-link:hover {
	background: none;
	color: #000;
	font-size: 1em;

}

.NSEBSE .SubCategory_Filter .fintoo-checkbox {
	padding-top: 0px;
	padding-bottom: 0px;
	font-size: 1em !important;

}

.NSEBSE .Filterhead {
	font-size: 1.1em;

}

.NSEBSE .SubCategory_Filter {
	max-height: 26vh !important;
	overflow: auto;
	padding-bottom: 4.5rem;
	padding-top: 0rem;

}

.NSEBSE .st-filter-list {
	padding-top: 1rem;
	padding-bottom: 0rem;
	max-height: 30vh !important;
	overflow: auto;

}

@media screen and (max-width: 768px) {
	.NSEBSE .SubCategory_Filter {
		max-height: 17vh !important;
		overflow: auto;
		padding-bottom: 0rem;
		padding-top: 0rem;
	}

	.NSEBSE .st-filter-list {
		padding-top: 1rem;
		padding-bottom: 0rem;
		max-height: 25vh !important;
		overflow: auto;
	}


}

@media only screen and (min-width: 200px) and (max-width: 360px) {
	.item-list .MarketMobile {
		display: block;
		margin-right: 0.1rem !important;
		margin-top: 0.9em !important;
	}

	.explore-now-bx {
		padding-left: 1em;
	}

	.Res_OPP {
		margin-right: 1rem !important;
	}

	.NoteStock {
		font-size: 0.8em;
		color: gray;
		max-width: 315px;
	}

	.BSE_line {
		border-bottom: 1px solid #d8dbda;
		padding-top: 1em;
		width: 400px !important;
		margin-left: -1rem !important;
	}

	.TabsNSEBSE ul.nav1 {
		width: 75%;
		margin-left: 1em !important;
	}
}

.h-100 {
	min-height: 100vh;
}

.cart-badge {
	position: absolute;
	background: #042b62;
	color: #fff;
	width: 1.2rem;
	line-height: 1.2rem;
	border-radius: 1rem;
	font-size: 0.8rem;
	text-align: center;
	top: -12px;
	right: 20px;

}

.page-profile-inside .profile-container .ProfileDashboard .add-member-field input[type="number"] {
	outline: none;
	border: none;
	border-bottom: 1px solid #d6d6d6;
	background: transparent;
	font-size: 1.2rem;
	height: 1.5rem;
	font-weight: 500;
}

.LeftSection #FundDetails .inner-item-details-top-bx .__react_component_tooltip {
	background: #fff;
	color: gray;
	font-style: italic;
}

.LeftSection #FundDetails .inner-item-details-top-bx .__react_component_tooltip .NameData {
	padding: 0rem !important;
	padding-top: 1rem;
}

.LeftSection #FundDetails .inner-item-details-top-bx .__react_component_tooltip .NameData li {
	list-style-type: none;
	padding-top: .6rem;
}

.LeftSection #FundDetails .inner-item-details-top-bx .__react_component_tooltip::after {
	background-color: #fff;
}

.startInvestingImg img {
	display: block;
	margin-left: auto;
	margin-right: auto;
	align-items: center;
	width: 45%;
}

.startInvesting {
	width: 10rem;
	height: 2.5rem;
	display: block;
	margin-left: auto;
	margin-right: auto;
	align-items: center;
	border-radius: 20px;
	font-size: 18px;
	font-weight: 400;
	color: #fff;
	text-align: center;
	background-color: #042b62;
	border: 1px solid #042b62;
}

.startInvesting-holdings {
	width: 15rem;
	height: 2.5rem;
	display: block;
	margin-left: auto;
	margin-right: auto;
	align-items: center;
	border-radius: 20px;
	font-size: 18px;
	font-weight: 400;
	color: #fff;
	text-align: center;
	background-color: #042b62;
	border: 1px solid #042b62;
}

.startInvesting-header {
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	align-items: center;
	width: 50%;
}

.ProfileDetails-Page header {
	display: none;
}

.ReportSelect__control {
	border-radius: 9px !important;
}

.ReportSelect__indicator-separator {
	display: none;
}

.ReportSelect__indicator svg {
	display: none;
}

.ReportSelect__indicator::after {
	/* background-image: ; */
	color: #000 !important;
	content: '';
	width: 0;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-top: 7px solid #042b62;
	position: absolute;
	right: 13px;
	top: 15px;
}

.hrlinefund {
	border-right: 1px solid gray;
	height: 10px;
	margin: 0 6px;
	margin-top: 4px;
}

.fundoptiondetails {
	font-size: .8rem;
}

.table.ComplaintTable .borderColor td {
	border: 1px solid #b1abab47 !important;
}