.Our_Process {
  background-color: #e8f6fc;
  padding-top: 2rem;
  padding-bottom: 2rem;
 
}
.card-widget-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 0px;
  width: 80%;
  margin: auto;
}
.card-widget-item {
  background-color: #ffffff;
  padding: 10px 20px 10px 20px;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #f5f4f5;
  border-radius: 5px 5px 5px 5px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -ms-grid-row-align: center;
  align-items: center;
}

.card-widget-item:hover {
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #007bff;
  transform: translatey(0px);
  animation: float 2s ease-in-out infinite;
}

.card-widget-item-container {
  display: flex;
  padding: 1.5rem 1rem;
  gap: 1.5rem;
}

.card-widget-item-icon {
  width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-widget-item-text-content {
  width: 90%;
  display: flex;
  align-items: center;
}

.icon-container {
    aspect-ratio: 1 / 1;
    width: 100%;
    background-color: #2575FC;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    font-weight: 400;
    color: #fff;
}

/* --------------------------------------------------------------heading related styles-------------------------------------------------- */
.section-title {
  font-size: 36px;
  font-weight: 500;
  transition: all 0.3s ease;
  margin: 0;
  margin-bottom: 20px;
  text-align: center;
}

.section-sub-title {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  margin: -10px 0px 0px 0px;
}

.info-title {
  padding: 0px 0px 0px 0px;
  color: #000000;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.5px;
  font-family: Poppins, sans-serif;
}

.info-content {
  padding: 0px 0px 0px 0px;
  color: #8f8f8f;
  font-size: 1.1rem;
  letter-spacing: 0.5px;
  line-height: 1.6rem;
}

.heading-description {
  padding: 0px 0px 0px 0px;
  color: #5d5d5d;
  font-size: 1.1rem;
  letter-spacing: 0.5px;
  line-height: 1.6rem;
  font-family: Poppins, sans-serif;
}
.card-widget-item:hover .icon-container {
  border: 5px solid #2575FC;
  background-color: #ffff;
  color: #2575FC;
  transform: translatey(0px);
  animation: float 2s ease-in-out infinite;
}
/* -----------------------------------------------------------------float------------------------------------------------------------------------ */
@keyframes float {
  0% {
    transform: translatey(0px);
  }

  50% {
    transform: translatey(-10px);
  }

  100% {
    transform: translatey(0px);
  }
}
@media only screen and (max-width: 768px) {
  .icon-container {
    aspect-ratio: 1 / 1;
    width: 100%;
    background-color: #f5f5f5;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    color: #22a6de;
  }
  .card-widget-container {
    display: block;
    width: 90%;
  }
  .card-widget-item {
    padding: 0px;
  }
  .card-widget-item-icon {
    width: 22%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
