.PortfolioBalClose{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    color: #000;
}
.PortfolioBalClose svg{
    font-size: 2rem;
    font-weight: bold;
    text-align: right;
    cursor: pointer;
}
.PortfolioBalbody{
    text-align: center;
    margin: 1rem 3rem;
}
.PortfolioBalbody .Verifiedimg svg{
    width: 250px !important;
}
.PortfolioBalbody .PortfolioBalbodytxt{
    font-size: 1.6rem;
    font-weight: 600;
    padding: 1rem 0;
    margin-top: 1rem;
}
.ContinueBtn1{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    outline: 0;
    padding: .8rem 1rem;
    border-radius: 25px;
    margin: 2rem 0;
    background-color: #042b62;
    color: #fff;
    font-weight: 500;
    font-size: 1.2rem;
}
.PortfolioBalbody .PortfoliotoatlBal{
    font-size: 2rem;
    font-weight: bold;
    color: #042b62;
}
@media only screen and (max-width: 600px) {
    .PortfolioBalbody{
        margin: 4rem 0rem;
    }
    .PortfolioBalbody .PortfolioBalbodytxt{
        font-size: 1.1rem;
    }
}

.small-modal {
    width: 35rem;
}