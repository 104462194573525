.WhyinvestInternationalSection {
    padding: 5rem 0;
}

.WhyinvestInternationaltitle {
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
    color: #042b62;

}

.WhyinvestInternationalSection .bgImage {
    border-radius: 12px;
    background: #042b62;
    padding: 1rem;
    /* width: 504px; */
    height: 450px;
}
.WhyinvestInternationalSection .bgImage img{
  width: 536px;
}
.WhyinvestInternationalSection .FeatureView {
    padding-top: 3rem;
}

.WhyinvestInternationalSection .FeatureView .FeatureCard:last-child {
    display: flex;
    justify-content: center;
    align-items: center;
}

.WhyinvestInternationalSection .FeatureView .FeatureCard {
    padding: 2rem;
    display: flex;
    gap: 1rem;
    border-radius: 20px;
    border: 1px solid rgba(46, 46, 31, 0.12);
    /* box-shadow: 0px 5px 0px 0px #191A23; */
    background-color: rgba(0, 0, 0, 0.02);
    cursor: pointer;
    align-items: center;
}

.WhyinvestInternationalSection .FeatureCardTitle {
    font-size: 1.3rem;
    color: #042b62;
    font-weight: bold;
}

.WhyinvestInternationalSection .FeatureCardDes {
    font-size: 1rem;
    color: #042b62;
    padding: 1rem 0;
}

.WhyinvestInternationalSection .investBox {
    border-radius: 12px;
    padding: 1.2rem 2rem;
    margin-bottom: 2rem;
    border-top: 1px solid #042b62;
    background: #FFF;
    position: relative;
    border: 1px solid #ccc;
    transition: all 0.3s ease-in-out;
}
.WhyinvestInternationalSection .investBox:hover {
    /* background-color: #f0f0f0; */
    border-top: 1px solid #042b62;
    box-shadow: 12px 12px 50px 0px rgba(0, 0, 0, 0.08);
}
.WhyinvestInternationalSection .numberBox{
    padding: 6px;
    background-color: #042b62;
    border-radius: 50%;
    width: 40px;
    text-align: center;
    border: 1px solid #042b62;
    font-weight: 600;
    color: #fff;
}
.WhyinvestInternationalSection .investBox .textBold{
    font-weight: bold;
    color: #042b62;
}
.WhyinvestInternationalSection .investBox .investboxDes{
    padding-top: .6rem;
    color:#042b62;
    font-size: .9rem;
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    line-height: 1.4;
}
.investBox:hover .investboxDes {
    opacity: 1;
    max-height: 100px; 
}

@media only screen and (max-width: 600px) {
    .Whyinvestequitytitle {
        font-size: 1.5rem;
    }

    .WhyinvestInternationalSection {
        padding: 2rem;
    }

    .WhyinvestInternationalSection .FeatureView {
        grid-template-columns: 1fr;
        place-content: center;
        /* place-items: center; */
    }

    .WhyinvestInternationalSection .FeatureView .FeatureCard {
        display: grid;
        place-items: center;
    }

    .WhyinvestInternationalSection .FeatureView .FeatureCard img {
        width: 100px;
        place-items: center;
    }

    .WhyinvestInternationalSection .FeatureCardTitle {
        font-size: 1rem;
    }
    .WhyinvestInternationaltitle{
        font-size: 1.5rem;
    }
    .WhyinvestInternationalSection .investBox .textBold{
        font-size: .9rem;
    }

    .WhyinvestInternationalSection .FeatureCardDes {
        font-size: .9rem;
    }
}