.PrivacyPolicypage {
    width: 100%;
    background: #fff;
    padding-bottom: 2rem;
    /* margin-bottom: 2rem; */
}
.PrivacyPolicypage .aboutusText{
    color: #fff;
    text-align: center;
    font-size: 2.2rem;
    font-weight: bold;
}
.PrivacyPolicypage .primargBg {
    position: relative;
    background: url("https://fintooinvest.in/assets/img/inner-wave.svg") center bottom/100% no-repeat #02003c;
    padding: 100px 0;
}
.PrivacyPolicypage .privacyContent{
    margin: 4rem 0;
}
.PrivacyPolicypage .privacyContent .PrivacyPolicytitle{
    color: #000;
    font-size: 1.3rem;
    font-weight: 500;
}
.PrivacyPolicypage .privacyContent .PrivacyPolicyDec{
    line-height: 1.7rem;
    font-size: 1rem;
    padding-top: 1rem;
}
.PrivacyPolicyDec ol {
    padding-top: 1rem;
}
.PrivacyPolicyDec ol li{
    margin-bottom: 0;
    font-size: 1rem;
}
@media screen and (max-width: 650px) {
    .primargBg {
        padding: 50px 0;
        background-size: contain;
    }
    .PrivacyPolicypage .aboutusText{
        text-align: left;
        padding-left: 1rem;
    }
   
}