.land-txt {
  top: 9rem;
  color: #fff;
}
.videobox {
  height: 100vh;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.h1 {
  color: #ffffff;
  font-size: 4rem;
  font-weight: 700;
  margin-top: 11rem;
}
.SpaceReduce {
  color: #ffffff;
  font-size: 4rem;
  font-weight: 700;
  margin-top: 4rem;
}
.land-txt p {
  font-size: 1.6rem;
  font-weight: 500;
}
.TextHello{
  font-size: 3rem;
  font-weight: 700;
  text-align: center;
  color: #fff;
}
.vBox {
  position: absolute;
}

.mouseicon {
  width: 25px;
  height: 45px;
  border-radius: 15px;
  position: relative;
  border: 2px solid #dedede;
  text-align: center;
  cursor: pointer;
}
.mouseicon span {
  width: 3px;
  height: 8px;
  margin: 2px auto 0;
  display: block;
  background: #dedede;
  border-radius: 5px;
  -webkit-animation: 1.2s ease infinite wheel-up-down;
  animation: 1.2s ease infinite wheel-up-down;
}
.bgOverlay {
  background-color: #000000ba;
  opacity: 0.54;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0;
  position: absolute;
}
.VideoLayout {
  height: 100vh;
  width: 100vw;
  object-fit: cover;
}
@-webkit-keyframes wheel-up-down {
  0% {
    margin-top: 2px;
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  100% {
    margin-top: 20px;
    opacity: 0;
  }
}

@keyframes wheel-up-down {
  0% {
    margin-top: 2px;
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  100% {
    margin-top: 20px;
    opacity: 0;
  }
}
@media (max-width: 767px) {
  .h1 {
    font-size: 1.4rem;
    margin-top: 8rem;
  }
  .SpaceReduce {
    font-size: 1.4rem;
    margin-top: 2rem;
  }
  .land-txt p {
    font-size: 1.4rem;
  }
}
@media only screen and (max-width: 600px) {
  .VideoLayout {
    height: 100vh;
    width: 100vw;
    object-fit: cover;
  }
  .land-txt {
    top: 7rem;
  }
  .TextHello{
    font-size: 2rem;
    margin-top: 0rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1280px) {
  .land-txt {
    top: 6rem;
  }
  .h1 {
    font-size: 2rem;
  }
  .land-txt p {
    font-size: 1rem;
  }
  .mouseicon {
    width: 22px;
    height: 33px;
  }
}
@media only screen and (min-width: 1281px) and (max-width: 1680px) {
  /* .h1{
      color: #FFFFFF;
      font-size: 2rem;
      font-weight: 700;
  } */
}
