.Herosection .Herosectioncard{
    display: flex;
    justify-content: center;
    align-items: center;
}
.Herosection .title {
    font-size: 3rem;
    font-weight: 500;
    color: #042b62;
}

.Herosection .subtitle {
    font-size: 1.2rem;
    padding: 25px 0;
    color: #435169;
}

.Herosection button {
    border-radius: 10px;
    background: #042b62;
    display: flex;
    padding: 10px 20px;
    align-items: flex-start;
    gap: 10px;
    color: #FFF;
    outline: 0;
    border: 0;
    font-weight: bold;
}
@media only screen and (max-width: 600px){
    .Herosection .Herosectioncard{
        display: flex;
        flex-direction: column-reverse;
    }
    .Herosection .title{
        font-size: 1.6rem;
        font-weight: bold;
        /* text-align: center; */
    }
    .Herosection .subtitle{
        font-size: 1rem;
    }
}