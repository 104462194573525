.Whyinsurancesection {
    padding: 5rem 0;
}

.Whyinvestequitytitle {
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
    color: #042b62;

}

.Whyinsurancesection .FeatureView {
    padding-top: 3rem;
    display: flex;
    gap: 1rem;
    justify-content: center;
    text-align: center;
    margin: auto;
}

.Whyinsurancesection .FeatureView .FeatureViewbox {
    border-radius: 10px;
    background: #F4F4F5;
    padding: 2rem;
    width: 500px;
}

.Whyinsurancesection .FeatureView .FeatureViewbox .loantype {
    font-size: 1.2rem;
    font-weight: bold;
    text-transform: uppercase;
}

.Whyinsurancesection .FeatureView .FeatureViewbox .loandesc {
    padding: 1rem 0;
}

.Whyinsurancesection .FeatureView .FeatureViewbox img {
    width: 150px;
}

.Whyinsurancesection .FeatureView .FeatureViewbox button {
    display: flex;
    padding: 1rem 3rem;
   justify-content: center;
    gap: 10px;
    align-items: center;
    flex-shrink: 0;
    border-radius: 5px;
    background: #fff;
    width: 70%;
    margin: auto;
    border: 0;
    outline: 0;
    font-weight: 600;
}

.Whyinsurancesection .FeatureView .FeatureCard:last-child {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Whyinsurancesection .FeatureView .FeatureCard {
    padding: 2rem;
    display: flex;
    gap: 1rem;
    border-radius: 20px;
    border: 1px solid rgba(46, 46, 31, 0.12);
    /* box-shadow: 0px 5px 0px 0px #191A23; */
    background-color: rgba(0, 0, 0, 0.02);
    cursor: pointer;
    align-items: center;
}

.Whyinsurancesection .FeatureCardTitle {
    font-size: 1.3rem;
    color: #042b62;
    font-weight: bold;
}

.Whyinsurancesection .FeatureCardDes {
    font-size: 1rem;
    color: #042b62;
    padding: 1rem 0;
}

.Whyinsurancesection .FeatureView .FeatureViewbox:hover{
    background-color: #000;
    color: #fff;
    transform: scale(1.05);
    transition: .3s;
}
.FeatureViewboxLife:hover img {
    content: url(./life_insurance.svg);
}
.FeatureViewboxHealth:hover img {
    content: url(./Health_insurance.svg);
}
@media only screen and (max-width: 600px) {
    .Whyinvestequitytitle {
        font-size: 1.2rem;
    }
    .Whyinsurancesection .FeatureView .FeatureViewbox{
        width: 420px;
    }
    .Whyinsurancesection {
        padding: 2rem;
    }

    .Whyinsurancesection .FeatureView {
        display: grid;
        grid-template-columns: 1fr;
        place-content: center;
        margin: 0rem 2rem;
        /* place-items: center; */
    }

    .Whyinsurancesection .FeatureView .FeatureCard {
        display: grid;
        place-items: center;
    }

    .Whyinsurancesection .FeatureView .FeatureCard img {
        width: 100px;
        place-items: center;
    }

    .Whyinsurancesection .FeatureCardTitle {
        font-size: 1rem;
    }

    .Whyinsurancesection .FeatureCardDes {
        font-size: .9rem;
    }
}