.imagebox {
    background-color: #042b62;
    /* padding: 10px; */
    border-radius: 50%;
    overflow: hidden;
    width: 20px;
    height: 20px;
}
.imagebox img {
    width: 100%;
    height: 100%;
    margin-top: -4px;
}
.chooseText {
    color: #042b62;
    font-weight: 600;
    padding-left: 15px;
}
.buttonChoose {
    border: 2px solid #042b62;
    padding: 5px 10px;
    border-radius: 20px;
}
.documentDropdown {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    border-bottom: 1px solid #000;
    padding: 6px;
}
.drpTitle {
    margin: 0px;
}
.trField td {
    padding-top: 20px;
    padding-bottom: 20px;
}
.addMoreBtn {
    color: #042b62;
    margin-bottom: 0px;
    font-weight: 600;
    cursor: pointer;
    user-select: none;
}
.textarea {
    width: 100%;
    border-radius: 12px;
    padding: 20px;
}
.btn {
    margin: auto;
    border-radius: 20px;
    background: #042b62;
    color: #fff;
    border: 0px;
    font-weight: 600;
    display: block;
    padding: 10px 20px;
}

.btn_skip {
    /* margin: auto; */
    /* border-radius: 20px; */
    /* background: #042b62; */
    color: #000000;
    /* border: 0px; */
    font-weight: 600;
    /* display: block; */
    /* padding: 05px 15px; */
}

.subtitle {
    font-size: 1.2rem;
    color: #888;
}
.buttonChoose input[type="file"]{
    /* display: none; */
}
.buttonChoose label{
    height: 20px;
    /* width: 100%; */
}
/* .CustomFile{
    color: transparent;
} */
.preview-link {
    text-decoration: none;
    color: #042b62;
    cursor: pointer;
}
.preview-link:hover {
    color: #000;
}

/* DG Modal */
.modal-cntn-www {
    /* height: 300px; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    padding: 3rem;
}

.OK-btns {
    background: transparent;
    border: 1px solid #042b62;
    color: #042b62;
    padding: 6px 28px;
    border-radius: 30px;
    outline: none;
    font-weight: 600;
}

.OK-btns:hover {
    color: #fff;
    background: #042b62;
}

.linkmodaltext {
    font-size: 1rem;
    white-space: nowrap;
    font-weight: bold;
    text-align: center;
    padding: 1rem 0;
}

.linkmodalDestext {
    font-size: .9rem;
    text-align: center;
    opacity: .7;
    color: #000;
}