.Security_Section{
    background-color: #F9FAFC;
    padding: 5rem 0;
}
.Security_Section .whyinvesttitle{
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
    color: #042b62;
}
.Security_Section .investCard{
    padding-top: 6rem;
    display: flex;
    justify-content: space-between;
    gap: 3rem;
    text-align: center;
}
.Security_Section .whyinvestkeypoint{
    color: #042b62;
    font-size: 1rem;
    font-weight: bold;
    padding: 1rem 0;
}
.Security_Section .whyinvestkeypointdec{
    color: #042b62;
    font-size: .9rem;
}
@media only screen and (max-width: 600px) {
    .Security_Section .investCard{
        display: grid;
        place-items: center;
        padding-top: 3rem;
        gap: 2rem;
        place-content: center;
    }
    .Security_Section .whyinvesttitle{
        font-size: 1.5rem;
    }
    .Security_Section .whyinvestkeypoint{
        padding: 1rem 0;
        font-size: 1rem;
    }
    .Security_Section .whyinvestkeypointdec{
        font-size: .9rem;
    }
}
/*  */