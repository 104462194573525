.WhyinvestequitySection {
    padding: 5rem 0;
}

.Whyinvestequitytitle {
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
    color: #042b62;

}

.WhyinvestequitySection .FeatureView {
    padding-top: 3rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 3rem;
    grid-row-gap: 3rem;
}

.WhyinvestequitySection .FeatureView .FeatureCard:last-child {
    display: flex;
    justify-content: center;
    align-items: center;
}

.WhyinvestequitySection .FeatureView .FeatureCard {
    padding: 2rem;
    display: flex;
    gap: 1rem;
    border-radius: 20px;
    border: 1px solid rgba(46, 46, 31, 0.12);
    /* box-shadow: 0px 5px 0px 0px #191A23; */
    background-color: rgba(0, 0, 0, 0.02);
    /* cursor: pointer; */
    align-items: center;
}

.WhyinvestequitySection .FeatureCardTitle {
    font-size: 1.3rem;
    color: #042b62;
    font-weight: bold;
}

.WhyinvestequitySection .FeatureCardDes {
    font-size: 1rem;
    color: #042b62;
    padding: 1rem 0;
}

@media only screen and (max-width: 600px) {
    .Whyinvestequitytitle {
        font-size: 1.5rem;
    }

    .WhyinvestequitySection {
        padding: 2rem;
    }

    .WhyinvestequitySection .FeatureView {
        grid-template-columns: 1fr;
        place-content: center;
        /* place-items: center; */
    }
    .WhyinvestequitySection .FeatureView .FeatureCard{
        display: grid;
        place-items: center;
    }
    .WhyinvestequitySection .FeatureView .FeatureCard img{
        width: 100px;
        place-items: center;
    }
    .WhyinvestequitySection .FeatureCardTitle {
        font-size: 1rem;
    }

    .WhyinvestequitySection .FeatureCardDes {
        font-size: .9rem;
    }
}