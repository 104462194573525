
.scrolltopdiv {
    position: fixed;
    z-index: 99999;
    bottom: 30px;
    right: 30px;
    cursor: pointer;
}

.scrolltopdiv img {
    width: 40px;
    height: 40px;
}
.homescrolltopdiv {
    bottom: 120px;
}