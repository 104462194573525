.Teampage {
    width: 100%;
    background: #fff;
    padding-bottom: 2rem;
    /* margin-bottom: 2rem; */
}
.Teampage .overviewDesc{
    width: 80%;
    margin: auto;
    font-size: 1.3rem;
    text-align: center;
}
.OverViewSection {
    width: 80%;
    margin: auto;
    padding-top: 4rem;
}

.Teampage .aboutusText {
    color: #fff;
    text-align: center;
    font-size: 2.2rem;
    font-weight: bold;
}

.Teampage .primargBg {
    position: relative;
    background: url("https://fintooinvest.in/assets/img/inner-wave.svg") center bottom/100% no-repeat #02003c;
    padding: 100px 0;
}
/* .Teampage .primargBg::after {
    content: "";
    background: url("https://fintooinvest.in/assets/img/inner-wave.svg") center top/cover no-repeat;
    width: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
    height: 130px;
} */
.overViewTitle{
    font-size: 1.8rem;
    font-weight: bold;
    text-align: center;
    padding: 1rem 0;
}
.whyfintooinvest{
    margin-top: 4rem;
}
.whyfintooinvest .title{
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 3rem;
}
.whyfintooinvest .contentBox{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 60%;
    margin: auto;
    margin-bottom: 3rem;
}
.whyfintooinvest .contentDesc{
    margin-left: 2rem;
}
.whyfintooinvest .contenttitle{
    font-size: 1.3rem;
    font-weight: 600;
}
.features-section {
    background-color: #E8F6FC;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.features-4 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2rem
}

.features-3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem
}

.feature-item {
    background-color: white;
    padding: 2rem 1.5rem;
    transition: all 0.5s;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: -1px 7px 15px 0 rgb(0 0 0/7%);
}

.feature-item-icon {
    font-size: 3rem;
}

.feature-item-heading {
    padding: 0px 0px 0px 0px;
    color: #000000;
    /* font-family: "Poppins", Sans-serif; */
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.feature-item-content {
    color: #656565;
    /* font-family: "Poppins", Sans-serif; */
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 1px;
}

.feature-item:hover {
    background-color: #042b62;
}

.feature-item:hover .feature-item-icon {
    color: white;
}

.feature-item:hover .feature-item-heading {
    color: white;
}

.feature-item:hover .feature-item-content {
    color: white;
}

/* ---------------------------------------------------media queries--------------------------------------------------- */
@media only screen and (max-width: 800px) {
    .features-4 {
        grid-template-columns: 1fr;
    }

    .features-3 {
        grid-template-columns: 1fr;
    }
}

@media only screen and (max-width: 600px) {
    .features-4 {
        grid-template-columns: 1fr;
    }

    .features-3 {
        grid-template-columns: 1fr;
    }
}
@media screen and (max-width: 650px) {
    .primargBg {
        padding: 50px 0;
        background-size: contain;
    }
    .Teampage .overviewDesc{
        width: 100%;
        font-size: 1rem;
        text-align: center;
    }
}