.USPSection {
    margin-bottom: 3rem;
}

.USPSection .USPtitle {
    text-align: center;
    color: #042b62;
    font-size: 2rem;
    font-weight: 500;
}

.USPSection .USPCardbox {
    border-radius: 45px;
    background-color: #f3f3f3;
    /* box-shadow: 0px 5px 5px 5px #191A23; */
    margin-top: 2rem;
    /* background-size: 400px; */
    position: relative;
}

.USPCardbox .USPinfo {
    padding: 5rem;
}

.USPCardbox .USPcardtitle {
    font-size: 1.4rem;
    font-weight: 500;
    color: #042b62;
}

.USPCardbox .USPlist {
    padding: 1rem 0;
}

.USPCardbox .USPlist .usplistview {
    padding: .6rem 0;
}

.usplistview .ps-2 {
    color: #042b62;
    font-size: 400;
}

.USPSection button {
    border-radius: 10px;
    background: #042b62;
    display: flex;
    padding: 10px 20px;
    align-items: flex-start;
    gap: 10px;
    color: #FFF;
    outline: 0;
    border: 0;
    font-weight: bold;
}

.USPCardbox img {
    position: absolute;
    bottom: -3.8rem;
    right: -3rem;
    /* width: 700px; */
}
.USPCardimg2{
    margin-top: 30rem;
}
.USPCardbox .USPCardimg2 img {
    position: absolute;
    bottom: -4.6rem;
    left: -2.5rem;
}
.USPCardbox:last-child .USPCardimg img {
    position: absolute;
    bottom: -3.5rem;
    right: -3rem;
    /* width: 700px; */
}
@media only screen and (max-width: 600px){
    .USPSection .USPCardbox{
        margin: 1rem .5rem;
    }
    .USPCardbox .USPinfo{
        padding: 2rem;
    }
    .USPCardimg, .USPCardimg2 {
        display: none;
    }
}