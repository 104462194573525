.downloadSpinner {
  width: 20px;
  height: 20px;
  border: 2px solid #042b62;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.PortfolioReportSection .tabBx {
  width: 12rem;
  text-align: center;
}

.PortfolioReportSection .tabBx span {
  border-bottom: 1px solid #888;
  padding: 10px;
}

.PortfolioReportSection .insideTabBoxd {
  border-bottom: 2px solid #eee;
  overflow-y: auto;
}

.PortfolioReportSection .tabText {
  padding-bottom: 10px;
  border-bottom: 3px solid transparent;
  display: inline-block;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1.1rem;
  font-weight: 600;
  color: darkgray;
  text-align: center;
}

.PortfolioReportSection .tabBx.active .tabText {
  border-bottom: 3px solid #042b62;
  color: #000;
}

.PortfolioReportSection .VRline {
  border-right: 1px solid #eee;
  height: 30px;
  padding: 0 2rem;
}

.PortfolioReportSection .insidePage {
  padding-left: 2rem;
  padding-right: 2rem;
}

.PortfolioReportSection .tabBx.inactive .tabText {
  color: #888;
}

/* Report Section */
.PortfolioReportSection .DataSection {
  margin: 2rem 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 3rem;
  grid-row-gap: 3rem;
}

.PortfolioReportSection .DataSection .ReportCard {
  border: 1px solid #eee;
  padding: 5rem 2rem;
  border-radius: 20px;
  cursor: pointer;
  box-shadow: 0 5px 0 0 rgb(199 199 199);
  position: relative;
}

.PortfolioReportSection .DataSection .ReportCard:hover {
  box-shadow: 0 5px 0 0 #042b62;
  color: #042b62;
}

.PortfolioReportSection .ReportName {
  font-size: 1.1rem;
  font-weight: 500;
  padding: 0rem 1rem;
}

.ReportDetailSection .ReportDetailsOptions {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: 0px;
}

.ReportDetailSection .ReportName {
  font-size: 2rem;
  font-weight: bold;
}

.ReportDetailSection .ReportLabel {
  font-size: 1.1rem;
  font-weight: bold;
}

.ReportDetailSection .ReportButton {
  background-color: #042b62;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  padding: 0.3rem 2.5rem;
  border: 0;
  border-radius: 20px;
}

.ReportDetailSection .DmfReportView {
  display: grid;
  place-items: center;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 0px;
  background-color: #f9f9f9;
  padding: 3.4rem 3rem;
  border-radius: 15px;
}

.ReportDetailSection .DmfReportViewsecond {
  display: grid;
  place-items: center;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 0px;
  background-color: #f9f9f9;
  padding: 3.4rem 3rem;
  border-radius: 15px;
}

.DmfReportView .LabelText {
  text-align: center;
  font-size: 1.1rem;
  font-weight: 500;
}

.DmfReportView .Repotval {
  padding-top: 1rem;
  font-size: 1.2rem;
  color: #c4c4c4;
  font-weight: bold;
}

.DmfReportView .DmfReportSeprateline {
  border-right: 1px solid #d0d0d0;
  height: 7rem;
}

.ReportDetailSection .seprateLine {
  border-right: 1px solid #d0d0d0;
  height: 20px;
}

.ReportDetailSection .ReportOptions {
  float: right;
  color: #c4c4c4;
  font-size: 1.1rem;
  font-weight: 500;
}

.Reportsshareoptions {
  position: absolute;
  bottom: 1rem;
  right: 2rem;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .PortfolioReportSection .VRline {
    padding: 0 1rem;
  }

  .PortfolioReportSection .tabText {
    font-size: 1rem;
  }

  .PortfolioReportSection .DataSection {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 2rem;
    margin: 2rem;
  }

  .ReportDetailSection .ReportName {
    font-size: 1.1rem
  }

  .ReportDetailSection .ReportDetailsOptions {
    grid-template-columns: 1fr;
    grid-row-gap: 1rem;
  }

  .ReportDetailSection .ReportLabel {
    font-size: 1rem;
  }

  .ReportDetailSection .ReportGenOption {
    display: grid;
    place-items: center;
    margin-top: 1rem;
  }

  .ReportDetailSection .DmfReportView {
    grid-template-columns: 1fr;
    padding: 3.4rem 2rem;
  }

  .ReportDetailSection .DmfReportView .LabelText {
    padding-top: 2rem;
  }

  .DmfReportSepratelinevr {
    border-bottom: 1px solid #d0d0d0;
    width: 100%;
    padding: 1rem 0rem;
  }

  .ReportDetailSection .rpdetailbox {
    margin-top: 2rem;
  }

  .ReportDetailSection .ReportOptions {
    font-size: .9rem;
    float: left;
  }

}

.submitbtn {
  outline: none;
  border: none;
  padding: .5rem 2.5rem;
  border-radius: 20px;
  color: #ffff;
  background: #042b62;
  font-weight: 600;
}
.submitbtn:disabled {
  background-color: #eee !important;
  color: #666 !important;
}