.FeaturesSection {
    margin-bottom: 3rem;
}

.FeaturesSection .FeaturesSectionTxt {
    text-align: center;
    color: #042b62;
    font-size: 2rem;
    font-weight: 500;
}

.FeaturesSection .CardView {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 2.5rem;
    grid-row-gap: 2.5rem;
    margin: 1rem 0;
}

.FeaturesSection .cardBox {
    border: 1px solid #191A23;
    background: #F3F3F3;
    box-shadow: 0px 5px 0px 0px #191A23;
    border-radius: 45px;
    /* padding: 1rem 2rem; */
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.FeaturesSection .cardBox .textSection {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0rem 2rem;
}

.FeaturesSection .cardBox .cardboxtitle {
    font-size: 1.4rem;
    font-weight: 500;
    color: #042b62;
}

.FeaturesSection .cardBox .cardboxdes {
    padding: 1rem 0;
    color: #4A4A4A;
    font-weight: 400;
    margin-bottom: 15px;
}

.FeaturesSection .cardBox .learnMore {
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
}

.FeaturesSection .cardBoximg {
    width: 250px;
}

.commingSoinbtn {
    border-radius: 10px;
    background: #042b62;
    display: flex;
    padding: 10px 20px;
    align-items: flex-start;
    gap: 10px;
    color: #FFF;
    outline: 0;
    border: 0;
    font-weight: bold;
}

@media only screen and (max-width: 600px) {
    .FeaturesSection .CardView {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
    }

    .FeaturesSection .cardBox {
        padding: 1rem;
        height: 300px;
    }

    .FeaturesSection .FeaturesSectionTxt {
        font-size: 1.5rem;
    }
}