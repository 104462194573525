.fn-date .react-date-picker__clear-button {
    visibility: hidden !important;
}

.fintoo-datepicker .react-datepicker__month-select,
.fintoo-datepicker .react-datepicker__year-select {
    text-align: center;
    appearance: none !important;
    border: none !important;
    border-bottom: solid 1px #424242 !important;
    width: 5rem !important;
    background-color: #e5edef !important;
    outline: none;
}

.fintoo-datepicker .react-datepicker__current-month {
    display: block !important;
    outline: none;
}

.fintoo-datepicker .react-datepicker__month-dropdown-container,
.fintoo-datepicker .react-datepicker__year-dropdown-container {
    padding: 0px 10px;
    -webkit-appearance: none;
    background: #e5edef;
    visibility: visible !important;
}

.loanDate .fintoo-datepicker .react-datepicker-wrapper .react-datepicker__input-container input {
    background-image: url('../../../Pages//datagathering/AssetsLibDG/CIIBIL_Report/DateImg.svg') !important;
    background-size: 20px !important;
    background-repeat: no-repeat !important;
    background-position: 98% 7px !important;
}

.fintoo-datepicker .react-datepicker__navigation--previous,
.fintoo-datepicker .react-datepicker__navigation--next {
    content: '';
    display: block;
    top: 0.7rem;
    width: 20px;
    height: 8px;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid #424242 !important;
}

.fintoo-datepicker .react-datepicker__navigation--previous {
    left: 2px !important;
}

.fintoo-datepicker .react-datepicker__navigation--next {
    right: 2px !important;
    transform: rotate(180deg);
}

.fintoo-datepicker .react-datepicker__day-names {
    padding-top: 0.6rem;
}

.fintoo-datepicker .react-datepicker__input-container input {
    color: #000;
    padding: 7px;
    padding-left: 7px !important;
    border-radius: 10px;
    cursor: pointer;
}

.fintoo-datepicker input {
    background-image: url('./Images/calendar73.svg') !important;
    background-size: 30px !important;
    background-repeat: no-repeat !important;
    background-position: 94% 7px !important;
    border: 1px solid #c1c1c1 !important;
}
.loanDate .fintoo-datepicker input{
    border: none !important;
}
.fintoo-datepicker input.no-style-calendar {
    background-image: none !important;
    border: 1px transparent !important;
}

.fintoo-datepicker .react-datepicker-popper {
    z-index: 444;
}

.fintoo-datepicker input[type="text"] {
    background: #fff;
    font-weight: 100;
    color: gray;
    padding-left: 1rem;
    width: 100%;
}
.fintoo-datepicker {
    width: 12rem;
}

.datePickerDMF input[type="text"] {
    font-weight: bold !important;
    color: #000 !important;
}
.datePickerDMFReport input[type="text"]{
    font-weight: 400 !important;
    color: #000 !important;
}
.ReportDatePicker{
    width: 100%;
}
.loanDate .fintoo-datepicker{
    width: 100% !important;
}
.loanDate .react-datepicker-wrapper{
    width: 100% !important;
}

.sip-datepicker input[type="text"] {
    background: transparent !important;
    border: 0px !important;
    border-bottom: 1px solid #d1d1d1 !important;
    border-radius: 0px !important;
    padding-top: 0px;
    padding-bottom: 0px;
}

.sip-datepicker .react-datepicker__current-month {
    display: block !important;
}
.sip-datepicker-0 input[type="text"] {
    padding: 0px!important;
    border-bottom: 0px!important;
    color: #000;
    font-weight: 600;
}