@media (min-width: 1400px) {
    .container {
        width: 1140px!important;
    }
}

.DiffText{
    color: #dd7300 !important;
}

.card {
    padding-top: 10px;
    padding-bottom: 10px;
}

.cardinner {
    padding: 10px;
    padding: 130px 20px 65px 20px;
}
.cardbox {
    padding: 0px 80px 0px 80px;
}
.cardcontainer {
    padding: 10px;
}
.cardheading {
    padding: 0px 0px 0px 0px;
    color: #042b62;
    font-family: "Poppins", Sans-serif;
    font-size: 21px;
    font-weight: 400;
}
.cardparagraph {
    color: #333333;
    font-family: "Lato", Sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
}
.card-fin {
    background-image: url('./images/goal.png');
    background-position: center left;
    background-repeat: no-repeat;
    background-size: auto;
    box-shadow: 0px 10px 60px 0px rgb(0 0 0 / 10%);
    border-style: solid;
    border-width: 0px 0px 0px 0px;
    border-color: #f5f5f5;
    border-radius: 5px 5px 5px 5px;
    background-color: #fff;
}
.cardextrapadding {
    margin-top: 70px;
}
.investsection {
    /* background-color: #EBF6FB; */
    /* background-image: url('./images/software_pattern__.png');
    background-position: 0px 0px;
    background-repeat: no-repeat;
    background-size: cover; */
    padding: 0px 0px 30px 0px;
}
.section-title {
    color: #060606;
    font-size: 2.3rem;
    font-weight: 700;
    line-height: 3.5rem;
    text-align: center;
    color: #2f3436;
    letter-spacing: 1px;
}
/*  */
.SlideShow{
    margin-top: 4rem;
    height: 100%;
    width: 100%;
  }
   .Odd {
   margin-top: 5rem;
   width: 100%;
   height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  }
  
  .TestimonialCard {
    width: 80% !important;
    background: #fff;
    border: 1px solid #ebebf0;
    box-sizing: border-box;
    border-radius: 8px;
    transition: 0.3s ease-in-out;
    overflow: hidden;
    padding: 20px;
    display: block;
    margin: 1rem 4rem;
  }
  .TestimonialCardHeader {
    display: flex;
  }
  .CardProfileImg img {
    max-width: 40px;
    max-height: 40px;
    border-radius: 50%;
  }
  .ProfileName {
    font-size: 1.1rem;
    font-weight: 600;
  }
  .ProfileDesignation {
    font-size: 0.9rem;
    font-size: 400;
    color: #555770;
  }
  .socialIcon {
    margin-left: 2rem;
  }
  .socialIcon svg {
    font-size: 2rem;
  }
  .clientReviewTxt {
    padding: 10px;
  }
  .CardProfileImg p {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #e7e7e7;
  }