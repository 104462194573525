.wrapper {
    display: flex;
}
.navBarCon {
    width: 10rem;
    display: flex;
    position: sticky;
    top: 9.2rem;
    align-self: flex-start;

}
.navbar {
    width: 100%;
    margin-left: 2rem;
    background: #fff;
    text-align: center;
    /* min-height: 100vh; */
    /* height: 80vh; */
    border-radius: 20px;
    /* margin-left: 2rem; */
    padding-top: 3rem;
}
.stickyNav {
    position: fixed;
    
}
.navbar svg {
    color: #042b62;
    width: 2rem;
    height: 2rem;
}
.menuitem {
    /* margin-top: 3.0rem; */
    margin-bottom: 2.2rem;
    border-left: 5px solid transparent;
}

.menutext {
    font-size: 0.8rem;
    font-weight: 600;
    color: #9b9b9b;
    text-decoration: none;

}
.contentWrapper {
    display: flex;
    width: 100%;
    flex-direction: column!important;
}
.menuLogo {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.mobileHumburger img{
    width: 20px;
}

.menu-link {
    text-decoration: none;
    /* color: #042b62; */
    color: #9b9b9b;
    font-size: 0.8em;
    font-weight: 600;
   
}

.menu-link.active .menuitem {
    border-left:5px solid #042b62;
 }
 .linkholdings{
    width: 100%;
    height: 100vh;
    margin-top: 1.8rem;
    /* background-color: #fff; */
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
 }

 .Heading{
    font-size: 1.5rem;
    font-weight: 700;
    padding-bottom: 1.6rem;
    border-bottom: 1px solid #e7e7e7;
 }
 .Text{
    color: gray;
    font-size: .9rem;
 }
 .formSection{
    display: grid;
    place-items: center;
 }
 .FormsFillup{
    width: 90%;
    margin: auto;
 }
 .FormlableName{
    color: gray;
    font-size: 1rem;
    font-weight: 600;
 }
 .memberSelect{
    border-bottom: 1px solid #e7e7e7 !important;
 }
 .inputs{
    border: 0;
    border-bottom: 2px solid #e7e7e7;
    outline: none;
    text-transform: uppercase;
    padding-top: 4px;
 }
 .inputs::placeholder{
    font-size: .8rem;
    color: gray;
    font-weight: 600;
 }
 .OtpBtn{
    display: flex;
    justify-content: center;
    margin-top: 3rem;
 }
 .OtpBtn button{
    outline: none;
    border: none;
    padding: .5rem 2.5rem;
    border-radius: 20px;
    color: #ffff;
    background: #042b62;
    font-weight: 600;
 }
 .inputs:focus{
    outline: none;
    border-bottom: 2px solid #e7e7e7;
 }
 .Msgbox{
   text-align: center;
   margin-top: 3rem;
 }
 .Msgbox p{
    color: #636363;
    font-weight: 600;
 }
 .Msgbox span{
    text-decoration: none;
    color: #042b62;
    font-size: 1.2rem;
    font-weight: 700;
    cursor: pointer;
 }
@media only screen and (min-width: 768px) {
    .container {
        padding-left: 2rem;
        padding-right: 2rem;
    }    
}
@media only screen and (max-width: 768px) {
    .navbar {
        display: none;
    }
    .wrapper {
        display: flex;
        flex-direction: column;
    }
    .mobileNavBox{
        background: #fff;
        border-radius: 10px;
        margin: 1rem 1rem;
        overflow: hidden;
    }
}
.OTpConfirm {
    background: #042b62;
    text-align: center;
    font-weight: bold;
    color: #fff;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    cursor: pointer;
}