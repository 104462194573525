.InvestmentCard {
    background-color: #042b62;
    margin: 3rem 0;
    padding: 4rem 0;
    color: #fff;
    text-align: center;
}

.InvestmentCard .title {
    font-size: 2rem;
    font-weight: 400;
}

.InvestmentCard .subtitle {
    font-size: 2.3rem;
    font-weight: 500;
}
.InvestmentCard .investbtnView{
    position: relative;
}
.InvestmentCard .investbtnView img {
    position: absolute;
    top: 30px;
    right: 33vw;
    max-width: 100%;
}

.InvestmentCard button {
    border-radius: 50px;
    background: #fff;
    padding: 10px 3rem;
    gap: 10px;
    color: #042b62;
    outline: 0;
    margin: 1rem 0;
    font-weight: bold;
    border: 0;
    text-align: center;
    z-index: 1; /* Ensure the button stays above the image */
}

@media only screen and (max-width: 600px) {
    .InvestmentCard {
        display: grid;
        gap: 1rem;
        place-items: center;
    }
    .InvestmentCard .title{
        font-size: 1.5rem;
    }
    .InvestmentCard .subtitle{
        font-size: 1.7rem;
    }
    .InvestmentCard button{
        padding: 5px 2rem;
        font-size: 1rem;
    }
    .InvestmentCard .investbtnView img{
        right: -18vw;
        top: 50px;
    }
}